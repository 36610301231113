import React from "react";
//import UserHeader from "../components/AdminPage/AdminUserPage/UserInfoHeader";

import PageHeader from "../components/AdminPage/AdminHeader";

import "../components/AdminPage/AdminUserPage/UserInfo.css";


function AdminInfo() {
  return (
      <>
        <PageHeader />
      </>
  );
}

export default AdminInfo;
