import React from "react";
import ItemChecklistHeader from "../components/ItemChecklist/ItemChecklistHeader";

import "../components/ItemChecklist/ItemChecklist.css";

const ItemChecklist = () => {
  return (
    <>
      <ItemChecklistHeader />
    </>
  );
};

export default ItemChecklist;
