import React, { useEffect } from "react";

const Pagination = ({ table }) => {
  const { pageIndex, pageSize } = table.getState().pagination;

  // Store the current page index in local storage
  useEffect(() => {
    localStorage.setItem("InventoryPage", pageIndex);
  }, [pageIndex]);

  const totalRows = table.getPrePaginationRowModel().rows.length;
  const startRow = pageIndex * pageSize + 1;
  const endRow = Math.min(startRow + pageSize - 1, totalRows);

  return (
    <div className="pagination2">
      <div className="results-info">
        Showing {startRow} to {endRow} of {totalRows} results
      </div>
      <div className="pagination-controls">
        <button
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          Previous
        </button>
        <span>{pageIndex + 1}</span>
        <button
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Pagination;
