import React, { useEffect, useRef, useState } from "react";
import "./Login.css";

import { useAuth } from "../../context/AuthContext";

import { useNavigate, Link } from "react-router-dom";
import { MdOutlineLock, MdPersonOutline } from "react-icons/md";
import LogonLogo from "../../PublicPhotos/LogonIcon.png";
import { useAuthState } from "react-firebase-hooks/auth";

const LoginHeader = () => {
  const emailRef = useRef();
  const passwordRef = useRef();

  const { login, auth } = useAuth();
  const [user, loading2] = useAuthState(auth);

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  //const location = useLocation();

  useEffect(() => {
    if (loading2) {
      // maybe trigger a loading screen
      return;
    }
    if (user) navigate("/"); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loading2]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setError("");
      setLoading(true);

      await login(emailRef.current.value, passwordRef.current.value);

      setLoading(false);
      /*if (location.state?.from) {
        console.log("111111");

        navigate(location.state.from.pathname, {
          state: { search: location.state.from.search },
        });
      } else {
        console.log("33333");

        navigate("/");
      }*/
    } catch (error) {
      var errorCode = error.code;
      // var errorMessage = error.message;

      if (errorCode === "auth/too-many-requests") {
        setError("To many login attempts try resetting your password");
        setLoading(false);
      } else if (errorCode === "auth/wrong-password") {
        setError(`Wrong Password`);
        setLoading(false);
      } else if (errorCode === "auth/user-not-found") {
        setError("No user found");
        setLoading(false);
      } else {
        setError("Failed to sign in");
        setLoading(false);
      }
    }
  };

  // font-family: "work sans";
  return (
    <div className="loginContainer">
      <div className="loginTitle">
        <img src={LogonLogo} alt="Did Not Load" className="logonLogoIMG" />
      </div>

      {error && <div className="alert">{error}</div>}
      <form onSubmit={handleSubmit}>
        <div className="loginInputHolder">
          <div className="loginInputHolderTopColor">
            <div className="loginInputTitle"> Sign In </div>
            <div className="loginInput">
              <div className="loginUsername">Email</div>
              <div className="login__field">
                <i className="login__icon fas fa-user">
                  <MdPersonOutline />
                </i>
                <input
                  className="login__input"
                  placeholder="Email"
                  type="email"
                  ref={emailRef}
                  required
                />
              </div>
            </div>
            <div className="loginInput">
              <div className="loginUsername">Password</div>
              <div className="login__field">
                <i className="login__icon fas fa-user">
                  <MdOutlineLock />
                </i>
                <input
                  className="login__input"
                  placeholder="Password"
                  type="password"
                  ref={passwordRef}
                  required
                />
              </div>
            </div>

            <div className="loginBtnContainer">
              <button disabled={loading} type="submit" className="loginBtn">
                Sign In
              </button>
            </div>
          </div>
        </div>
      </form>

      <div className="forgotInputHolder">
        <div className="loginForgotPassword">
          <Link className="loginForgotPasswordLink" to="/forgotPassword">
            Forgot password?
          </Link>
        </div>
        <div className="createAccountHolder">
          <Link className="newAccountLink" to="/signup">
            Create new account
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LoginHeader;
