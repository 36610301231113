import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";

import ChecklistFinder from "../../apis/ChecklistFinder";

import Header from "../Header/Header";
import PopupWeb from "../Popup/PopupNew";

import { useNavigate } from "react-router-dom";

import { IoMdArrowRoundBack } from "react-icons/io";

import ClipLoader from "react-spinners/ClipLoader";

import { BsThreeDotsVertical } from "react-icons/bs";
const ChecklistFrequency = () => {
  //Popup
  const { currentUserSiteId } = useAuth();

  const [loading, setLoading] = useState(false);


  const [frequency, setFrequency] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ChecklistFinder.get(`/allFrequency`, {
          params: {
            currentUserSiteId,
          },
        });
        setFrequency(response.data.data.Frequency);
      } catch (err) {
        // console.log(err);
      }
    };

    if (currentUserSiteId) {
      fetchData();
    }
  }, [currentUserSiteId]); // eslint-disable-line react-hooks/exhaustive-deps

  const history = useNavigate();


  //
  //
  const [error, setError] = useState("");

  //
  const [frequencyName, setFrequencyName] = useState("");
  const [frequencyDays, setFrequencyDays] = useState("1");
  const [frequencyUnit, setFrequencyUnit] = useState("");

  const handleNewChecklistSubmit = async (e) => {
    //stop the page from reloading
    setError("");
    e.preventDefault();

    try {
      setLoading(true);
      // eslint-disable-next-line 
      const response = await ChecklistFinder.post("/addFrequency", {
        frequencyName,
        currentUserSiteId,
        frequencyDays,
        frequencyUnit,
      });
      setLoading(false);



      const response1 = await ChecklistFinder.get(`/allFrequency`, {
        params: {
          currentUserSiteId,
        },
      });
      //  // console.log(response.data.data.checklist);
      setFrequency(response1.data.data.Frequency);
      togglePopup()
    } catch (err) {
      setLoading(false);
      setError("Failed");
    }
  };


  const handleUpdateChecklistSubmit = async (e) => {
    //stop the page from reloading
    setError("");
    e.preventDefault();

    try {
      setLoading(true);
            // eslint-disable-next-line 
      const response = await ChecklistFinder.post("/updateFrequency", {
        frequencyName,
        currentUserSiteId,
        id: upadteFrequencyId,
        frequencyDays,
        frequencyUnit,
      });
      setLoading(false);

      const response1 = await ChecklistFinder.get(`/allFrequency`, {
        params: {
          currentUserSiteId,
        },
      });
      //  // console.log(response.data.data.checklist);
      setFrequency(response1.data.data.Frequency);
      togglePopup5()

    } catch (err) {
      console.log(err)
      setLoading(false);
      setError("Failed");
    }
  };

  //const handleNewChecklistPush = (id) => {
 //   history(`/editchecklist/${id}`);
 // };

 const fetchData3 = async (id,name,number,unit) => {
  try {
    togglePopup5();
    setFrequencyName(name);
    setUpadteFrequencyId(id);
    setFrequencyDays(number);
    setFrequencyUnit(unit)
  } catch (err) {
    // console.log(err);
  }
};


  const sideBar = document.getElementById("sidebar");



  const [isOpen1, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen1);
    setFrequencyName("");
    setFrequencyDays("1");
    setFrequencyUnit("")
  };




  const [upadteFrequencyId, setUpadteFrequencyId] = useState("");

  
  const [isOpen5, setIsOpen5] = useState(false);
  const togglePopup5 = () => {
    setIsOpen5(!isOpen5);

  };



  const [deleteFrequencyId, setDelteFrequencytId] = useState('');


  const deleteChecklist = async (e) => {
    try {
      // eslint-disable-next-line 
      const response = await ChecklistFinder.post("/delteFrequency", {
     
        id: deleteFrequencyId,
          currentUserSiteId,
            });

            const response1 = await ChecklistFinder.get(`/allFrequency`, {
              params: {
                currentUserSiteId,
              },
            });
            //  // console.log(response.data.data.checklist);
            setFrequency(response1.data.data.Frequency);
      togglePopup222()
    } catch (err) {
   //   console.log(err);
    }
  };

  const [isOpen2, setIsOpen2] = useState(false);


  const togglePopup2 = (clid) => {
    setIsOpen2(true);
    setIsOpen(false);
    setDelteFrequencytId(clid)
  };
  const togglePopup22 = () => {
    setIsOpen2(false);
    setIsOpen(false);
    setDelteFrequencytId('')

  };
  const togglePopup222 = () => {
    setIsOpen2(false);    
  };

  return (
    <>
      <div
        id="topbar"
        className={
          sideBar === null
            ? "topbar headerActive"
            : sideBar.clientWidth === 80
            ? "topbar headerActive collapse"
            : "topbar headerActive"
        }
      >
        <div className="goBackDiv" onClick={() => history(-1)}>
          <IoMdArrowRoundBack />
        </div>
        <Header content={<b>Checklist</b>} />
        <div className="addItem" onClick={togglePopup}>
          +
        </div>
      </div>


      <div className="detailsPopupContainer222"></div>

      <div className="checklistListHolder">
        <div className="inventoryList">
          <div className="ReportResultsContainer">
            <div className="reportsPopupContainer">
              {frequency ? (
                <>
                  <table id="reportsTable">
                    <tbody>
                      <tr>
                        <th>Name</th>
                        <th>Number</th>
                        <th>Unit</th>
                        <th></th>
                      </tr>
                      {frequency.map((data, i) => {
                        console.log(data)
                        return (
                          <tr key={i}>
                            <td onClick={() => fetchData3(data.id,data.name,data.number,data.unit)}>
                              {data.name}
                            </td>
                            <td>
                              {data.number}
                            </td>
                            <td>
                              {data.unit}
                            </td>
                            <td><div onClick={() => togglePopup2(data.id)}><BsThreeDotsVertical /></div></td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>

      {isOpen1 && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleNewChecklistSubmit}>
        
                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>

                <div>
                  {error && <div className="alert">{error}</div>}

                  <div className="popupContainer">
                    <label
                      className="NewInputLabel"
                      data-domain="Checklist Name"
                    >
                      <input
                        className="NewInput"
                        type="text"
                        placeholder=""
                        value={frequencyName}
                        onChange={(e) => setFrequencyName(e.target.value)}
                        required
                      />
                    </label>

                    <label className="NewInputLabel" data-domain="Days">
                 

                    <input
                        className="NewInput"
                        type="number"
                        placeholder=""
                        value={frequencyDays}
                        onChange={(e) => setFrequencyDays(e.target.value)}
                        required
                      />
                    </label>


                    <label className="NewInputLabel" data-domain="Unit">
                  <select
                    className="NewInput"
                    onChange={(event) => setFrequencyUnit(event.target.value)}
                    required
                    defaultValue={frequencyUnit}
                  >
                     <option value="" disabled hidden>
                      Select
                    </option>
                    <option value="Day" >
                      Day
                    </option>
                    <option value="Week">
                    Week
                    </option>
                    <option value="Month">
                    Month
                    </option>
                    <option value="Year">
                    Year
                    </option>
                  </select>
                </label>

                  </div>
                </div>
              </form>
            </>
          }
     


          handleClose={togglePopup}
          isOpen={isOpen1}
          handleSubmit={handleNewChecklistSubmit}
          showSubmitButton={true}
          headerText={     <b>New Frequency</b>}
          submitButtonText={loading ? "Loading..." : "Add"} 

        />
      )}




{isOpen5 && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleUpdateChecklistSubmit}>
            
                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>

                <div>
                  {error && <div className="alert">{error}</div>}

                  <div className="popupContainer">
        
                  <label
                      className="NewInputLabel"
                      data-domain="Checklist Name"
                    >
                      <input
                        className="NewInput"
                        type="text"
                        placeholder=""
                        value={frequencyName}
                        onChange={(e) => setFrequencyName(e.target.value)}
                        required
                      />
                    </label>

                    <label className="NewInputLabel" data-domain="Days">
                 

                    <input
                        className="NewInput"
                        type="number"
                        placeholder=""
                        value={frequencyDays}
                        onChange={(e) => setFrequencyDays(e.target.value)}
                        required
                      />
                    </label>
                    <label className="NewInputLabel" data-domain="Unit">
                  <select
                    className="NewInput"
                    onChange={(event) => setFrequencyUnit(event.target.value)}
                    required
                    defaultValue={frequencyUnit}
                  >
                    <option value="Day" >
                      Day
                    </option>
                    <option value="Week">
                    Week
                    </option>
                    <option value="Month">
                    Month
                    </option>
                    <option value="Year">
                    Year
                    </option>
                  </select>
                </label>
                  </div>
                </div>
              </form>
            </>
          }

          handleClose={togglePopup5}
          isOpen={isOpen5}
          handleSubmit={handleUpdateChecklistSubmit}
          showSubmitButton={true}
          headerText={   <b>Update Frequency</b>}
          submitButtonText={loading ? "Loading..." : "Submit"} 

        />
      )}

{isOpen2 && (
        <PopupWeb
          content={
            <>
          
              <div className="deleteInfoApparatusContainer">
                <b>Are you sure you want to delete this Frequency?</b>
              </div>

              {/*<div className="deleteInfoApparatusContainer">
                <b>
                  THIS CURRENTLY WILL ONLY DELETE THE location AND NOT
                  COMPARTMENTS OR ITEMS
                </b>
          </div>*/}

              <div className="editApparatusBtnContainer">
                <div
                  className="confirmCancelApparatusBtn"
                  onClick={togglePopup222}
                >
                  <b>Cancel</b>
                </div>
                <div
                  className="confirmDeleteApparatusBtn"
                  onClick={deleteChecklist}
                  //onClick={togglePopup2}
                >
                  <b>Delete</b>
                </div>
              </div>
            </>
          }

          handleClose={togglePopup22}
          isOpen={isOpen2}
          showSubmitButton={false}
          headerText={ <b>Delete Frequency </b>}

        />
      )}
    </>
  );
};

export default ChecklistFrequency;
