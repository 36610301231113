import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import InventoryFinder from "../../apis/InventoryFinder";
import { useNavigate, useLocation } from "react-router-dom";
//import Header from "../Header/Header";
import { AiOutlinePlus } from "react-icons/ai";

import PublicFolder from "../../apis/PublicFolder";

//import PopupWeb from "../Popup/PopupWeb";
import PopupWeb from "../Popup/PopupNew";

import ItemListPageListDetailed from ".//ItemListPageListDetailed";

import ItemListPageEvent from ".//ItemListPageEvent";
import ItemListPageList from ".//ItemListPageList";
import Select from "react-select";
import { useAuth } from "../../context/AuthContext";

import { RiImageAddFill } from "react-icons/ri";

import { MdArrowBackIosNew } from "react-icons/md";

import ClipLoader from "react-spinners/ClipLoader";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import { useMainItemListPageData } from "./fetchItemListPageData";

const ItemListPageHeader = (props) => {
  //reload page
  const location = useLocation();
  const history = useNavigate();
  //const navigate = useNavigate();
  const { id } = useParams();
  const { currentUserId, currentUserRole, currentUserSiteId } = useAuth();

  const { data, refetch } = useMainItemListPageData(currentUserSiteId, id);

  //console.log(data);

  const [loading, setLoading] = useState(false);

  const [selectedHydrostaticDate, setSelectedHydrostaticDate] = useState();

  //edit item holder
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [type, setType] = useState("");

  const [manufacture, setManufacture] = useState("");
  const [description, setDescription] = useState("");
  const [oldImgPath, setOldPhotoImg] = useState("");
  const [file, setFile] = useState(null);

  const [error, setError] = useState("");

  useEffect(() => {
    if (data) {
      setName(data.data.data.itemInfo[0].name);
      setCategory(data?.data.data.itemInfo[0].categoryid);
      setType(data?.data.data.itemInfo[0].categorytypeid);
      setManufacture(data?.data.data.itemInfo[0].manufacture);
      setDescription(data?.data.data.itemInfo[0].description);
      setOldPhotoImg(data?.data.data.itemInfo[0].image);
    }
  }, [data]);

  let options = data?.data.data.memberNames.map(function (data) {
    return {
      value: data.username,
      label: data.firstname + " " + data.lastname,
      id: data.user_id,
    };
  });

  //Add Task

  //Popup
  const [isOpen1, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen1);
  };

  //Popup Add Item Task
  const [addItemOpen, setAddItemOpen] = useState(false);

  const createItemPopup = () => {
    setAddItemOpen(!addItemOpen);
    setIsOpen(!isOpen1);
  };

  //select compartment Popup
  const [compartmentOpen, setCompartmentOpen] = useState(false);

  const togglePopup555 = () => {
    setCompartmentOpen(!compartmentOpen);
    setAddItemOpen(!addItemOpen);
  };

  const togglePopup2 = () => {
    setCompartmentOpen(!compartmentOpen);
    setAddItemOpen(!addItemOpen);
  };

  //select status popup
  const [statusOpen, setStatusOpen] = useState(false);

  const togglePopup5 = () => {
    setAddItemOpen(!addItemOpen);
    setStatusOpen(!statusOpen);
  };

  //select status popup
  const [editItemOpen, setEditItemOpen] = useState(false);
  const toggleEditItem = () => {
    setEditItemOpen(!editItemOpen);
    setIsOpen(!isOpen1);
  };

  const toggleEditItemClose = () => {
    setEditItemOpen(!editItemOpen);
    setIsOpen(!isOpen1);
    setSelectedImage("");
    setFile("");
  };

  const toggleEditItemReturn = () => {
    setEditItemOpen(false);
    setIsOpen(false);
  };

  function toggleClass(id) {
    document.getElementById(id).classList.toggle("show");
  }
  //Input useState
  const [addMember, setAddMember] = useState("");

  const [selectedAmount, setSelectedAmount] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("Good");

  //optional Input useState
  const [selectedIdentificationName, setSelectedIdentificationName] =
    useState("");
  const [selectedSerialNumber, setSelectedSerialNumber] = useState("");
  const [selectedExpirationDate, setSelectedExpirationDate] = useState();
  const [selectedManufactureDate, setSelectedManufactureDate] = useState();

  // const [nextInspectionDate, setNextInspectionDate] = useState("");

  //Submit user new item
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (addMember === "") {
      setError(`Add a member`);
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      return false;
    } else {
      try {
        setLoading(true);

        // eslint-disable-next-line
        const response = await InventoryFinder.post(`/addItemUser`, {
          inventoryId: id,
          name,
          addMember,
          selectedAmount,
          selectedStatus,
          selectedIdentificationName,
          selectedSerialNumber:
            selectedSerialNumber === "" ? null : selectedSerialNumber,
          selectedManufactureDate,
          selectedExpirationDate,
          selectedHydrostaticDate,
          currentUserId,
          currentUserSiteId,
        });
        setLoading(false);

        history("/");
        history(location.pathname);
      } catch (err) {
        setLoading(false);
      }
    }
  };

  //edit item holder
  const headers = {
    "content-type": "multipart/form-data",
  };

  const editItemHolderSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    try {
      setLoading(true);

      const formData = new FormData();

      formData.set("inventoryId", id);
      formData.set("name", name);
      formData.set("category", category);
      formData.set("type", type);
      formData.set("manufacture", manufacture);
      formData.set("description", description);
      formData.set("oldImgPath", oldImgPath);
      formData.set("currentUserSiteId", currentUserSiteId);

      if (file) {
        formData.append("file", file);
      }
      // eslint-disable-next-line
      const response = await InventoryFinder.post(
        "/editItemHolder",
        formData,
        headers
      );
      setLoading(false);
      setSelectedImage("");
      setFile(null);
      refetch();
      toggleEditItemReturn();
    } catch (err) {
      setLoading(false);
      // console.log(err);
    }
  };

  const handleFileInput = (e) => {
    setFile(e.target.files[0]);
    setSelectedImage(e.target.files[0]);
  };

  //select status popup
  const [deleteItemOpen, setDeleteItemOpen] = useState(false);

  const toggleDeleteItem2 = () => {
    setDeleteItemOpen(!deleteItemOpen);
    setIsOpen(!isOpen1);
  };

  const deleteItemHolderSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    try {
      setLoading(true);

      const formData = new FormData();

      formData.set("inventoryId", id);
      formData.set("oldImgPath", oldImgPath);
      formData.set("currentUserSiteId", currentUserSiteId);

      if (file) {
        formData.append("file", file);
      }
      // eslint-disable-next-line
      const response = await InventoryFinder.post(
        "/deleteInventory",
        formData,
        headers
      );
      setLoading(false);
      history(`/inventory`);
    } catch (err) {
      setLoading(false);
      // console.log(err);
    }
  };
  //custom image
  const fileRef = useRef();
  const [selectedImage, setSelectedImage] = useState();

  const sideBar = document.getElementById("sidebar");

  return (
    <>
      <div
        id="topbar"
        className={
          sideBar === null
            ? "topbar headerActive"
            : sideBar.clientWidth === 80
            ? "topbar headerActive collapse"
            : "topbar headerActive"
        }
      >
        <div className="goBackDiv" onClick={() => history(-1)}>
          <div className="goBackDivarrow">
            <MdArrowBackIosNew />
          </div>
          <div className="goBackDivtxt">Inventory</div>
        </div>
        <div className="addItemReact" onClick={togglePopup}>
          <AiOutlinePlus size={28} />
        </div>
        {/* <Header content={<b>{name}</b>} />*/}
      </div>

      {data?.data.data.itemInfo &&
        data?.data.data.itemInfo.map((data, i) => {
          //loops 3 times idk why

          return (
            <div key={i}>
              <div className="itemListHeaderContainer">
                {data.image ? (
                  <img
                    src={PublicFolder.baseURL + data.image}
                    alt="fix later"
                    className="itemPageListImg"
                  />
                ) : (
                  <div className="itemPageListImgEmpty"></div>
                )}

                <div className="itemListDetailsContainer">
                  <b>{data.name}</b>
                </div>

                <div className="itemDetailsContainer">
                  <div className="itemManufacturer">
                    Manufacturer
                    <div className="itemManufacturer2">{data.manufacture}</div>
                  </div>
                  <div className="itemDescription">
                    Description
                    <div className="itemDescription2">{data.description}</div>
                  </div>
                </div>
              </div>
              <div className="itemAmountContainer">
                <div className="totalItemAmount">
                  <b>Amount: {data.amount ? data.amount : "0"}</b>
                </div>
              </div>
            </div>
          );
        })}

      {currentUserRole === "Admin" || currentUserRole === "Advanced" ? (
        <div className="silderHolder">
          <Tabs>
            <TabList>
              <Tab>
                <b>Inventory</b>
              </Tab>
              <Tab>
                <b>Sorted</b>
              </Tab>
              <Tab>
                <b>Task</b>
              </Tab>
            </TabList>

            <TabPanel>
              <ItemListPageList />
            </TabPanel>
            <TabPanel>
              <ItemListPageListDetailed />
            </TabPanel>
            <TabPanel>
              <ItemListPageEvent />
            </TabPanel>
          </Tabs>
        </div>
      ) : (
        <div className="silderHolder">
          <Tabs>
            <TabList>
              <Tab>
                <b>Inventory</b>
              </Tab>
              <Tab>
                <b>Sorted</b>
              </Tab>
            </TabList>

            <TabPanel>
              <ItemListPageList />
            </TabPanel>
            <TabPanel>
              <ItemListPageListDetailed />
            </TabPanel>
          </Tabs>
        </div>
      )}

      <div className="itemListPageEmpty"></div>
      {isOpen1 && (
        <PopupWeb
          content={
            <>
         
              <div className="popupBtnContainers">
                <div className="btnDiv">
                  <div className="addMemberBtn" onClick={createItemPopup}>
                    <b>New User Item</b>
                  </div>
                </div>
                <div className="btnDiv">
                  <div className="addMemberBtn" onClick={toggleEditItem}>
                    <b> Edit Item Holder</b>
                  </div>
                </div>
                <div className="btnDiv">
                  <div className="addMemberBtn" onClick={toggleDeleteItem2}>
                    <b>Delete Item Holder</b>
                  </div>
                </div>
              </div>
            </>
          }
          handleClose={togglePopup}

          isOpen={isOpen1}
          showSubmitButton={false}
          headerText={  <b>{name} Holder</b>}

        />
      )}

      {addItemOpen && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleSubmit}>
       
                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>

                {error && (
                  <div className="errorDiv">
                    <div className="alert2">{error}</div>
                  </div>
                )}
                <div className="popupContainer">
                  <div className="essentialText">Essential Information</div>
                  <div
                    id="compartmentSelectHolder"
                    className="compartmentSelectHolder"
                    onClick={togglePopup2}
                  >
                    <div className="compartmentSelectText">
                      <b>Member</b>
                    </div>
                    <div className="compartmentSelected2">&nbsp;&nbsp;&gt;</div>
                    <div
                      id="compartmentSelected"
                      className="compartmentSelected"
                    >
                      {addMember.label}
                    </div>
                  </div>

                  <div
                    id="compartmentSelectHolder"
                    className="compartmentSelectHolder"
                    onClick={togglePopup5}
                  >
                    <div className="compartmentSelectText">
                      <b>Status</b>
                    </div>
                    <div className="compartmentSelected2">&nbsp;&nbsp;&gt;</div>
                    <div
                      id="compartmentSelected"
                      className="compartmentSelected"
                    >
                      {selectedStatus}
                    </div>
                  </div>

                  <label className="NewInputLabel" data-domain="Amount">
                    <input
                      className="NewInput"
                      type="text"
                      placeholder=""
                      value={selectedAmount}
                      onChange={(e) => setSelectedAmount(e.target.value)}
                      required
                    />
                  </label>

                  <div className="optionalText">Optional Information</div>
                  <div
                    className="InspectionShowDiv"
                    onClick={() => {
                      toggleClass(`IdentificationShow`);
                    }}
                  >
                    <div className="bagDropdwonArrow2">
                      <i className="arrowdown"></i>
                    </div>
                    Identification info
                  </div>
                  <div className="InspectionDownDown" id="IdentificationShow">
                    <label
                      className="NewInputLabel"
                      data-domain="Identification Name"
                    >
                      <input
                        className="NewInput"
                        type="text"
                        placeholder=""
                        value={selectedIdentificationName}
                        onChange={(e) =>
                          setSelectedIdentificationName(e.target.value)
                        }
                      />
                    </label>

                    <label
                      className="NewInputLabel"
                      data-domain="Serial Number"
                    >
                      <input
                        className="NewInput"
                        type="text"
                        placeholder=""
                        value={selectedSerialNumber}
                        onChange={(e) =>
                          setSelectedSerialNumber(e.target.value)
                        }
                      />
                    </label>
                  </div>

                  <div
                    className="InspectionShowDiv"
                    onClick={() => {
                      toggleClass(`ManufactureShow`);
                    }}
                  >
                    <div className="bagDropdwonArrow2">
                      <i className="arrowdown"></i>
                    </div>
                    Manufacture info
                  </div>
                  <div className="InspectionDownDown" id="ManufactureShow">
                    <label
                      className="NewInputLabel"
                      data-domain="Manufactured Date"
                    >
                      <input
                        className="NewInput"
                        type="date"
                        onChange={(e) =>
                          setSelectedManufactureDate(e.target.value)
                        }
                        onFocus={(event) =>
                          (event.nativeEvent.target.defaultValue = "")
                        }
                      />
                    </label>

                    <label
                      className="NewInputLabel"
                      data-domain="Retirement Date"
                    >
                      <input
                        className="NewInput"
                        type="date"
                        onChange={(e) =>
                          setSelectedExpirationDate(e.target.value)
                        }
                        onFocus={(event) =>
                          (event.nativeEvent.target.defaultValue = "")
                        }
                      />
                    </label>
                  </div>

                  <div
                    className="InspectionShowDiv"
                    onClick={() => {
                      toggleClass(`HydrostaticShow`);
                    }}
                  >
                    <div className="bagDropdwonArrow2">
                      <i className="arrowdown"></i>
                    </div>
                    Inspection Info
                  </div>
                  <div className="InspectionDownDown" id="HydrostaticShow">
                    <label
                      className="NewInputLabel"
                      data-domain="Mandatory Service Date"
                    >
                      <input
                        className="NewInput"
                        type="date"
                        onChange={(e) =>
                          setSelectedHydrostaticDate(e.target.value)
                        }
                        onFocus={(event) =>
                          (event.nativeEvent.target.defaultValue = "")
                        }
                      />
                    </label>
                  </div>

                  <div className="InspectionDownDown" id="InspectionShow"></div>
                </div>
              </form>
            </>
          }

          handleClose={createItemPopup}
          isOpen={addItemOpen}
          handleSubmit={handleSubmit}
          showSubmitButton={true}
          headerText={  <b>Add User Item</b>}
          submitButtonText={loading ? "Loading..." : "Add"} 

        />
      )}

      {compartmentOpen && (
        <PopupWeb
          content={
            <>
              <div id="holderTest" className="holderTest">
                <button
                  type="submit"
                  className="submitItemBtn2"
                  disabled={loading}
                  onClick={() => {
                    togglePopup555();
                  }}
                >
                  Save
                </button>
                <div className="popupTitle3">
                  <b>Select User</b>
                </div>
              </div>
              <div className="emptySpace"></div>
              <div className="text-center p-3">
                <ClipLoader loading={loading} className="override" size={35} />
              </div>
              <div className="popupContainer">
                <div className="select-container">
                  <Select
                    options={options}
                    onChange={setAddMember}
                    name="addMembers"
                    className="basic-multi-select"
                    classNamePrefix="select"
                  />
                </div>
              </div>
            </>
          }

          handleClose={togglePopup2}
          isOpen={compartmentOpen}
          handleSubmit={togglePopup555}
          showSubmitButton={true}
          headerText={  <b>Select User</b>}
          submitButtonText={loading ? "Loading..." : "Save"} 
        />
      )}

      {statusOpen && (
        <PopupWeb
          content={
            <>
           
              <div className="text-center p-3">
                <ClipLoader loading={loading} className="override" size={35} />
              </div>

              <div className="popupContainer">
                <div
                  className="statusList"
                  onClick={() => {
                    setSelectedStatus(`Good`);
                    togglePopup5();
                  }}
                >
                  Good
                </div>
                <div
                  className="statusList"
                  onClick={() => {
                    setSelectedStatus(`Worn`);
                    togglePopup5();
                  }}
                >
                  Worn
                </div>
                <div
                  className="statusList"
                  onClick={() => {
                    setSelectedStatus(`Bad`);
                    togglePopup5();
                  }}
                >
                  Bad
                </div>

                <div
                  className="statusList"
                  onClick={() => {
                    setSelectedStatus(`Bad`);
                    togglePopup5();
                  }}
                >
                  Bad
                </div>

                <div
                  className="statusList"
                  onClick={() => {
                    setSelectedStatus(`Monitor`);
                    togglePopup5();
                  }}
                >
                  Monitor
                </div>

                <div
                  className="statusList"
                  onClick={() => {
                    setSelectedStatus(`Out For Repair`);
                    togglePopup5();
                  }}
                >
                  Out For Repair
                </div>

                <div
                  className="statusList"
                  onClick={() => {
                    setSelectedStatus(`Missing`);
                    togglePopup5();
                  }}
                >
                  Missing
                </div>

                <div
                  className="statusList"
                  onClick={() => {
                    setSelectedStatus(`Retired`);
                    togglePopup5();
                  }}
                >
                  Retired
                </div>
              </div>
            </>
          }

          handleClose={togglePopup5}
          isOpen={statusOpen}
          showSubmitButton={false}
          headerText={<b>Select Status</b>}
          submitButtonText={loading ? "Loading..." : "Add"} 

        />
      )}

      {editItemOpen && (
        <PopupWeb
          content={
            <>
              <form onSubmit={editItemHolderSubmit}>
  
                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>
                <div className="popupContainer">
                  <label className="NewInputLabel" data-domain="Item Name">
                    <input
                      className="NewInput"
                      type="text"
                      placeholder=""
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      required
                    />
                  </label>

                  <label className="NewInputLabel" data-domain="Manufacturer">
                    <input
                      className="NewInput"
                      type="text"
                      placeholder=""
                      onChange={(e) => setManufacture(e.target.value)}
                      value={manufacture}
                    />
                  </label>

                  <label className="NewInputLabel" data-domain="Description">
                    <input
                      className="NewInput"
                      type="text"
                      placeholder=""
                      onChange={(e) => setDescription(e.target.value)}
                      value={description}
                    />
                  </label>

                  <label className="NewInputLabel" data-domain="Category">
                    <select
                      className="NewInput"
                      onChange={(event) => setCategory(event.target.value)}
                      required
                      defaultValue={category}
                    >
                      <option value="" disabled hidden>
                        Select a Category
                      </option>

                      {data?.data.data.categoryResults.map(function (e, i) {
                        return (
                          <option key={i} value={e.categoryid}>
                            {e.name}
                          </option>
                        );
                      })}
                    </select>
                  </label>

                  <label className="NewInputLabel" data-domain="Type">
                    <select
                      className="NewInput"
                      onChange={(event) => setType(event.target.value)}
                      required
                      defaultValue={type}
                    >
                      <option value="" disabled hidden>
                        Select a Type
                      </option>

                      {data?.data.data.typeResults.map(function (e, i) {
                        return (
                          <option key={i} value={e.categoryid}>
                            {e.name}
                          </option>
                        );
                      })}
                    </select>
                  </label>

                  <div className="NewInputLabel" data-domain="Add Image">
                    <input
                      ref={fileRef}
                      onChange={handleFileInput}
                      multiple={false}
                      type="file"
                      accept="image/*"
                      hidden
                    />
                    <div
                      onClick={() => fileRef.current.click()}
                      className="NewInputImage"
                    >
                      <RiImageAddFill size={30} />
                      {selectedImage ? (
                        <div className="addImageHolder">
                          <img
                            src={URL.createObjectURL(selectedImage)}
                            alt="imageDidNotLoad"
                            className="addImage"
                          />
                        </div>
                      ) : (
                        oldImgPath && (
                          <div className="addImageHolder">
                            <img
                              src={
                                PublicFolder.baseURL + "preview_" + oldImgPath
                              }
                              alt="imageDidNotLoad"
                              className="addImage"
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </>
          }

          handleClose={toggleEditItemClose}
          isOpen={editItemOpen}
          handleSubmit={editItemHolderSubmit}
          showSubmitButton={true}
          headerText={   <b>Edit {name} Holder</b>}
          submitButtonText={loading ? "Loading..." : "Add"} 

        />
      )}

      {deleteItemOpen && (
        <PopupWeb
          content={
            <>
              <div className="popupTitle">
                
              </div>

              <div className="deleteInfoApparatusContainer">
                <b>
                  This item holder will no longer be available and will delete
                  all items associated with this item holder
                </b>
              </div>

       
            </>
          }

          handleClose={toggleDeleteItem2}
          isOpen={deleteItemOpen}
          handleSubmit={deleteItemHolderSubmit}
          showSubmitButton={true}
          headerText={<b>Delete {name}</b>}
          submitButtonText={loading ? "Loading..." : "Delete"} 

        />
      )}
    </>
  );
};

export default ItemListPageHeader;
