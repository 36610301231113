import React from "react";
import ItemChecklistTaskHeader from "../components/ItemChecklistTask/ItemChecklistTaskHeader";

import "../components/ItemChecklistTask/ItemChecklistTask.css";

const ItemChecklistTask = () => {
  return (
    <>
      <ItemChecklistTaskHeader />
    </>
  );
};

export default ItemChecklistTask;
