import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

import ChecklistFinder from "../../apis/ChecklistFinder";

import Header from "../Header/Header";
import PopupWeb from "../Popup/PopupNew";

import { useNavigate } from "react-router-dom";
import Select from "react-select";

import { IoMdArrowRoundBack } from "react-icons/io";

import ClipLoader from "react-spinners/ClipLoader";
import { BsThreeDotsVertical } from "react-icons/bs";

import ToastNotifier, { notify } from "../Popup/ToastNotifier";

const ChecklistLocationPage = () => {
  //Popup
  const { id } = useParams();
  const { currentUserSiteId } = useAuth();

  const [loading, setLoading] = useState(false);

  //
  //

  const [checklist, setChecklist] = useState([]);
  const [locationList, setLocationList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ChecklistFinder.get(`/allLocationChecklist`, {
          params: {
            currentUserSiteId,
          },
        });
        setChecklist(response.data.data.checklist);
        setLocationList(response.data.data.locations);
      } catch (err) {
        // // console.log(err);
      }
    };

    if (currentUserSiteId) {
      fetchData();
    }
  }, [currentUserSiteId]); // eslint-disable-line react-hooks/exhaustive-deps

  const history = useNavigate();

  //
  //
  const [error, setError] = useState("");

  //
  const [checklistName, setChecklistName] = useState("");
  const [appliesTo, setAppliesTo] = useState("");

  const [inventoryInfo, setInventoryInfo] = useState([]);

  const [selectedSelectedLocatioName, setSelectedSelectedLocatioName] =
    useState("");
  const [selectedSelectedLocatioId, setSelectedSelectedLocatioId] =
    useState("");

  const handleNewChecklistSubmit = async (e) => {
    //stop the page from reloading
    setError("");
    e.preventDefault();

    try {
      setLoading(true);
      // eslint-disable-next-line
      const response = await ChecklistFinder.post("/newLocationChecklist", {
        checklistName,
        currentUserSiteId,
        appliesTo,
      });
      setLoading(false);

      const response1 = await ChecklistFinder.get(`/allChecklist`, {
        params: {
          currentUserSiteId,
        },
      });
      //  // // console.log(response.data.data.checklist);
      setChecklist(response1.data.data.checklist);
    } catch (err) {
      setLoading(false);
      setError("Failed");
    }
  };

  const handleUpdateChecklistSubmit = async (e) => {
    //stop the page from reloading
    setError("");
    e.preventDefault();

    try {
      setLoading(true);
      // eslint-disable-next-line
      const response = await ChecklistFinder.post("/updatechecklist", {
        checklistName,
        selectedApparatus: id,
        currentUserSiteId,
        inputFields,
        clid: updateClid,
      });
      setLoading(false);

      const response1 = await ChecklistFinder.get(`/allChecklist`, {
        params: {
          currentUserSiteId,
        },
      });
      //  // // console.log(response.data.data.checklist);
      setChecklist(response1.data.data.checklist);
    } catch (err) {
      // console.log(err);
      setLoading(false);
      setError("Failed");
    }
  };

  //const handleNewChecklistPush = (id) => {
  //   history(`/editchecklist/${id}`);
  // };

  const sideBar = document.getElementById("sidebar");

  const [inputFields, setInputFields] = useState([
    { Title: "", Desc: "", id: crypto.randomUUID() },
  ]);

  const [isOpen1, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen1);
    setInputFields([{ Title: "", Desc: "", id: crypto.randomUUID() }]);
    setChecklistName("");
    setAppliesTo("");
    setSelectedSelectedLocatioId("");
    setSelectedSelectedLocatioName("");
  };

  const addFields = () => {
    let newfield = { Title: "", Desc: "", id: crypto.randomUUID() };

    setInputFields([...inputFields, newfield]);
  };

  const [updateClid, setUpadteClid] = useState("");

  const [isOpen5, setIsOpen5] = useState(false);
  const togglePopup5 = () => {
    setIsOpen5(!isOpen5);
  };

  const fetchData3 = async (clid, name, apparatusid, locationName) => {
    try {
      // // // console.log(apparatusId);
      const response = await ChecklistFinder.get("/locationChecklistGetSteps", {
        params: {
          lclid: clid,
          currentUserSiteId,
        },
      });

      setInputFields(
        response.data.data.checklistSteps.map(function (e) {
          return {
            Title: e.taskname,
            Desc: e.taskdescription,
            id: crypto.randomUUID(),
          };
        })
      );

      setTaskGroup(response.data.data.taskgroupInfo);
      setTask(response.data.data.taskInfo);
      setCompartments(response.data.data.comartments);
      setInventoryInfo(response.data.data.inventoryInfo);

      togglePopup5();
      setChecklistName(name);
      setUpadteClid(clid);
      setAppliesTo(apparatusid);
      setSelectedSelectedLocatioId(apparatusid);
      setSelectedSelectedLocatioName(locationName);
    } catch (err) {
      // // console.log(err);
    }
  };

  let options = locationList.map(function (data) {
    return {
      value: data.id,
      label: data.name,
    };
  });

  const fetchTaskInformation = async (clid) => {
    try {
      // // // console.log(apparatusId);
      const response = await ChecklistFinder.get(
        "/locationChecklistGetStepsUpdate",
        {
          params: {
            lclid: clid,
            currentUserSiteId,
          },
        }
      );

      // console.log("df,mlskd;fms;dlfmnjsdl;jfnmskjl;dfnmjskdnfskdlfnd");
      setTaskGroup(response.data.data.taskgroupInfo);
      setTask(response.data.data.taskInfo);
    } catch (err) {
      // // console.log(err);
    }
  };

  const [compartments, setCompartments] = useState([]);

  //Popup Apparatus Details
  //addTaskGroupName
  const [addTaskGroupName, setTaskGroupName] = useState("");
  const [addTaskGroupId, setTaskGroupId] = useState("");
  const [editAllItems, setEditAllItems] = useState("");
  const [addTaskGroupdDisplayOrder, setTaskGroupDisplayOrder] = useState("");
  const [isOpen3, setIsOpen3] = useState(false);
  const togglePopup3 = () => {
    setTaskGroupName("");
    setTaskGroupDisplayOrder("");
    setEditAllItems("");
    setIsOpen5(!isOpen5);

    setIsOpen3(!isOpen3);
  };

  const [linkedCompartment, setLinkedCompartment] = useState("");

  const [taskGroup, setTaskGroup] = useState([
    { tgid: 1, taskGroupName: "a string" },
  ]);

  const [task, setTask] = useState([
    //{ tid: 1, name: "task name", type: "passFail", tgid: 1 },
  ]);
  const handleNewTaskGroup = async (e) => {
    let holder = null;

    linkedCompartment === "" ? (holder = null) : (holder = linkedCompartment);

    //stop the page from reloading
    e.preventDefault();
    //// // console.log([addTaskType]);
    try {
      setLoading(true);

      //eslint-disable-next-line
      const response = await ChecklistFinder.post("/taskgroup", {
        addTaskGroupName,
        clid: updateClid,
        linkedCompartment: holder,
        addTaskGroupdDisplayOrder,
        currentUserSiteId,
      });
      setLoading(false);

      fetchTaskInformation(updateClid);
      setTaskGroupName("");

      togglePopup3();
    } catch (err) {
      setLoading(false);
    }
  };
  const [addTaskName, setTaskName] = useState("");
  const [currentTaskGroupId, setCurrentTaskGroupId] = useState("");
  const [currentCid, setCurrentCid] = useState("");
  let currentEvent = null;
  const [currentTaskItemId, setCurrentTaskItemId] = useState("");

  const [itemid, setItemId] = useState();
  const [bagid, setBagId] = useState();

  const [taskDesciption, setTaskDesciption] = useState("");

  //Popup creating new task
  const [isOpen2, setIsOpen2] = useState(false);

  const togglePopup2 = (taskId, cid) => {
    setIsOpen2(!isOpen2);
    setCurrentTaskGroupId(taskId);
    setCurrentCid(cid);
    setIsOpen5(!isOpen5);
  };

  //Popup passFail creating new task
  const [isOpen4, setIsOpen4] = useState(false);
  const togglePopup4 = () => {
    setIsOpen4(!isOpen4);
    setIsOpen2(!isOpen2);
    setTaskDesciption("");
    setTaskName("");
  };
  const togglePopup44 = () => {
    setIsOpen4(!isOpen4);
  };

  //Popup Item creating new task
  const [isOpen6, setIsOpen6] = useState(false);
  const togglePopup6 = () => {
    setIsOpen6(!isOpen6);
    setIsOpen2(!isOpen2);
  };

  const handleNewInventory = async (e) => {
    //   // // console.log("workung");
    //stop the page from reloading
    e.preventDefault();
    try {
      setLoading(true);

      //eslint-disable-next-line
      const response = await ChecklistFinder.post("/task", {
        taskname: addTaskName[0],
        tgid: currentTaskGroupId,
        clid: updateClid,
        bagid: bagid,
        itemid: itemid,
        taskDesciption,
        currentUserSiteId,
      });
      setLoading(false);
      fetchTaskInformation(updateClid);
      setIsOpen5(!isOpen5);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleNewTask = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    try {
      setLoading(true);

      //eslint-disable-next-line
      const response = await ChecklistFinder.post("/task", {
        taskname: addTaskName[0],
        tgid: currentTaskGroupId,
        clid: updateClid,
        bagid: bagid,
        itemid: itemid,
        taskDesciption,
        currentUserSiteId,
      });
      setLoading(false);

      fetchTaskInformation(updateClid);

      setTaskName("");
      setBagId();
      setItemId();
      setCurrentTaskGroupId("");
      setTaskDesciption("");
      togglePopup44();
      setIsOpen5(!isOpen5);
    } catch (err) {
      setLoading(false);
    }
  };

  const [selectedItemEvents, setSelectedItemEvents] = useState([]);

  const [selectedItemOpen, setSelectedItemOpen] = useState(false);
  const [selectedItemName, setSelectedItemName] = useState("");

  const togglePopupSelectedItem = () => {
    setSelectedItemOpen(!selectedItemOpen);
    setIsOpen6(!isOpen6);
  };

  const fetchSelectedItemTask = async (invid, name) => {
    // // console.log(itemid);
    // // console.log(invid);
    try {
      const response = await ChecklistFinder.get("/getSelectedItemChecklsit", {
        params: { invid: invid, currentUserSiteId },
      });
      setSelectedItemEvents(response.data.data.itemevent);
      setSelectedItemName(name);
      togglePopupSelectedItem();
    } catch (err) {
      // // console.log(err);
    }
  };

  const addItemEvent = async (clid) => {
    try {
      const response = await ChecklistFinder.post("/selectedItemAddEvent", {
        clid: clid,
        currentUserSiteId,
        tgid: currentTaskGroupId,
      });
      // setTask(response.data.data.taskInfo);
      fetchTaskInformation(updateClid);

      setIsOpen6(!isOpen6);
      setIsOpen5(!isOpen5);
    } catch (err) {
      // // console.log(err);
    }
  };

  const [isOpen7, setIsOpen7] = useState(false);
  const togglePopup7 = () => {
    setIsOpen7(!isOpen7);
    setIsOpen5(!isOpen5);
  };

  //Edit task group
  const editTaskGroup = async (
    tgid,
    taskgroupname,
    cid,
    name,
    allitems,
    displayorder
  ) => {
    setTaskGroupId(tgid);
    setTaskGroupName(taskgroupname);
    setLinkedCompartment(cid);
    setEditAllItems(allitems);
    setTaskGroupDisplayOrder(displayorder);
    togglePopup7();
    // console.log(taskgroupname);
  };

  const handleEditTaskGroup = async (e) => {
    let holder = null;

    linkedCompartment === "" ? (holder = null) : (holder = linkedCompartment);

    //stop the page from reloading
    e.preventDefault();
    //// // console.log([addTaskType]);
    try {
      setLoading(true);

      //eslint-disable-next-line
      const response = await ChecklistFinder.post("/editTaskgroup", {
        addTaskGroupId,
        addTaskGroupName,
        linkedCompartment: holder,
        addTaskGroupdDisplayOrder,
        clid: updateClid,
        allItems: editAllItems === true || "true" ? "true" : null,
        currentUserSiteId,
      });
      setLoading(false);
      fetchTaskInformation(updateClid);

      togglePopup7();
    } catch (err) {
      setLoading(false);
    }
  };

  const handleDeleteTaskGroup = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    //// // console.log([addTaskType]);
    try {
      setLoading(true);

      //eslint-disable-next-line
      const response = await ChecklistFinder.post("/deleteTaskgroup", {
        addTaskGroupId,
        clid: updateClid,
        currentUserSiteId,
      });
      setLoading(false);
      fetchTaskInformation(updateClid);
      setIsOpen66(!isOpen66);
      setIsOpen5(!isOpen5);
    } catch (err) {
      setLoading(false);
    }
  };

  const [isOpen66, setIsOpen66] = useState(false);
  const togglePopup66 = () => {
    setIsOpen66(!isOpen66);
    setIsOpen7(!isOpen7);
  };

  //Popup passFail edit task
  const [isOpen44, setIsOpen44] = useState(false);
  const togglePopup444 = () => {
    setIsOpen44(!isOpen44);
    setIsOpen5(!isOpen5);
  };

  //Popup passFail delete task
  const [isOpen444, setIsOpen444] = useState(false);
  const togglePopup4444 = () => {
    setIsOpen444(!isOpen444);
    setIsOpen44(false);
  };

  const [setItemEvent] = useState([]);
  const [setItemEventId] = useState("");
  const fetchTask = async (invid) => {
    try {
      const response = await ChecklistFinder.get("/getItemEventInfo", {
        params: { invid: invid, currentUserSiteId },
      });
      // // // console.log(response.data.data.itemevent);
      setItemEvent(response.data.data.itemevent);
    } catch (err) {
      // // console.log(err);
    }
  };

  const [taskId, setTaskId] = useState("");

  const editTask = async (tid, taskname) => {
    // // console.log(invid);
    setTaskId(tid);
    setTaskName(taskname);
    togglePopup444();
  };

  const handleEditTask = async (e) => {
    //stop the page from reloading
    //  // // console.log("kjldmfkjsdn");
    e.preventDefault();
    try {
      setLoading(true);

      const response = await ChecklistFinder.post("/editTask", {
        taskId: taskId,
        addTaskName,
        clid: updateClid,
        currentUserSiteId,
      });
      setLoading(false);

      //// // console.log(response.data.data.taskInfo);
      fetchTaskInformation(updateClid);

      setBagId();
      setItemId();
      setCurrentTaskGroupId("");

      setTaskDesciption("");
      togglePopup444();
    } catch (err) {
      setLoading(false);
    }
  };

  const handleDeleteTask = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    try {
      //eslint-disable-next-line
      const response = await ChecklistFinder.post("/deleteTask", {
        taskId: taskId,
        clid: updateClid,
        currentUserSiteId,
      });
      //   // // console.log(response.data.data.taskInfo);
      fetchTaskInformation(updateClid);

      setIsOpen444(false);
      setIsOpen5(!isOpen5);
    } catch (err) {}
  };

  const [isOpen22, setIsOpen22] = useState(false);

  const [deleteFrequencyId, setDelteFrequencytId] = useState("");

  const togglePopup22 = (clid) => {
    setIsOpen22(!isOpen22);

    setDelteFrequencytId(clid);
  };

  const deleteChecklist = async () => {
    try {
      // // // console.log(apparatusId);
      // eslint-disable-next-line
      const response = await ChecklistFinder.post("/deleteLocationChecklist", {
        clid: deleteFrequencyId,
        currentUserSiteId,
      });

      const response1 = await ChecklistFinder.get(`/allChecklist`, {
        params: {
          currentUserSiteId,
        },
      });
      //  // // console.log(response.data.data.checklist);
      setChecklist(response1.data.data.checklist);
      setIsOpen22(!isOpen22);
    } catch (err) {
      // // console.log(err);
    }
  };

  return (
    <>
      <div
        id="topbar"
        className={
          sideBar === null
            ? "topbar headerActive"
            : sideBar.clientWidth === 80
            ? "topbar headerActive collapse"
            : "topbar headerActive"
        }
      >
        <div className="goBackDiv" onClick={() => history(-1)}>
          <IoMdArrowRoundBack />
        </div>
        <Header content={<b>Checklist Locations</b>} />
        <div className="addItem" onClick={togglePopup}>
          +
        </div>
      </div>

      <div className="detailsPopupContainer222"></div>

      <div className="checklistListHolder">
        <div className="inventoryList">
          <div className="ReportResultsContainer">
            <div className="reportsPopupContainer">
              {checklist ? (
                <>
                  <table id="reportsTable">
                    <tbody>
                      <tr>
                        <th>Name</th>
                        <th>Location</th>
                        <th></th>
                      </tr>
                      {checklist.map((data, i) => {
                        // console.log(data);
                        return (
                          <tr key={i}>
                            <td
                              onClick={() =>
                                fetchData3(
                                  data.lclid,
                                  data.checklistname,
                                  data.apparatusid,
                                  data.name
                                )
                              }
                            >
                              {data.checklistname}
                            </td>
                            <td>
                              {data.apparatusid} {data.name}
                            </td>

                            <td>
                              <div onClick={() => togglePopup22(data.clid)}>
                                <BsThreeDotsVertical />
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>

      {isOpen1 && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleNewChecklistSubmit}>
                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>

                <div>
                  {error && <div className="alert">{error}</div>}

                  <div className="popupContainer">
                    <label
                      className="NewInputLabel"
                      data-domain="Checklist Name"
                    >
                      <input
                        className="NewInput"
                        type="text"
                        placeholder=""
                        value={checklistName}
                        onChange={(e) => setChecklistName(e.target.value)}
                        required
                      />
                    </label>

                    <label className="NewInputLabel" data-domain="Added To">
                      <Select
                        options={options}
                        onChange={setAppliesTo}
                        name="addMembers"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    </label>

                    <div className="ChecklistAddStep" onClick={addFields}>
                      + Add Step
                    </div>

                    <div className="ChecklistAddStep" onClick={togglePopup3}>
                      + Add Task Group
                    </div>
                  </div>
                </div>
              </form>
            </>
          }
          handleClose={togglePopup}
          isOpen={isOpen1}
          handleSubmit={handleNewChecklistSubmit}
          showSubmitButton={true}
          headerText={<b>New Location Checklist</b>}
          submitButtonText={loading ? "Loading..." : "Add"}
        />
      )}

      {isOpen5 && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleUpdateChecklistSubmit}>
           
                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>

                <div>
                  {error && <div className="alert">{error}</div>}

                  <div className="popupContainer">
                    <label
                      className="NewInputLabel"
                      data-domain="Checklist Name"
                    >
                      <input
                        className="NewInput"
                        type="text"
                        placeholder=""
                        value={checklistName}
                        onChange={(e) => setChecklistName(e.target.value)}
                        required
                      />
                    </label>

                    <label className="NewInputLabel" data-domain="Assign To">
                      {appliesTo.value !== "" ? (
                        <Select
                          options={options}
                          onChange={setAppliesTo}
                          name="addMembers"
                          className="basic-multi-select"
                          classNamePrefix="select"
                          defaultValue={{
                            label: selectedSelectedLocatioName,
                            value: selectedSelectedLocatioId,
                          }}
                        />
                      ) : (
                        <Select
                          options={options}
                          onChange={setAppliesTo}
                          name="addMembers"
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      )}
                    </label>

                    {taskGroup &&
                      taskGroup.map((data, i) => {
                        return (
                          <div key={i}>
                            <div className="editTaskGroupContainer">
                              <div className="editTaskGroupName">
                                <div className="taskGroupHeader">
                                  <div className="taskDiv">
                                    <b>{data.taskgroupname}</b>
                                  </div>

                                  <div
                                    className="editTaskDiv"
                                    onClick={() =>
                                      editTaskGroup(
                                        data.ltgid,
                                        data.taskgroupname,
                                        data.cid,
                                        data.name,
                                        data.allitems,
                                        data.displayorder
                                      )
                                    }
                                  >
                                    <b>Edit</b>
                                  </div>
                                </div>

                                <div className="taskGroupContainer">
                                  {task &&
                                    task.map((data2, ii) => {
                                      // console.log(data2);
                                      if (data2.ltgid === data.ltgid) {
                                        if (data2.checklistname != null) {
                                          return (
                                            <div key={ii}>
                                              <div className="taskContainer">
                                                <div className="task">
                                                  {data2.checklistname}
                                                  <div className="checklistPageTaskDescription">
                                                    Item - {data2.name}
                                                    {data2.identificationname
                                                      ? " - " +
                                                        data2.identificationname
                                                      : ""}
                                                  </div>
                                                  <div className="checklistPageTaskDescription">
                                                    Task -{" "}
                                                    {data2.taskdescription}
                                                  </div>
                                                </div>

                                                <div
                                                  className="editTaskDiv2 "
                                                  onClick={() =>
                                                    editTask(data2.ltclid)
                                                  }
                                                >
                                                  Edit
                                                </div>
                                                <div className="taskItemEventHolder">
                                                  {/*data2.itemevents.map((data3, i) => (
                                      <div
                                        key={i}
                                        className="taskItemEventList"
                                      >
                                        - {data3.eventname}
                                      </div>
                                    ))*/}
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        } else {
                                          return (
                                            <div key={ii}>
                                              <div className="taskContainer">
                                                <div className="task">
                                                  {data2.taskname}
                                                  <div className="checklistPageTaskDescription"></div>
                                                </div>

                                                <div
                                                  className="editTaskDiv2"
                                                  onClick={() =>
                                                    editTask(
                                                      data2.ltclid,
                                                      data2.taskname
                                                    )
                                                  }
                                                >
                                                  Edit
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        }
                                      } else {
                                        return <div key={ii}></div>;
                                      }
                                    })}

                                  <div
                                    className="addTaskDiv"
                                    onClick={() =>
                                      togglePopup2(data.ltgid, data.cid)
                                    }
                                  >
                                    + Add Task
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}

                    <div className="ChecklistAddStep" onClick={togglePopup3}>
                      + Add Task Group
                    </div>
                  </div>
                </div>
              </form>
            </>
          }
          handleClose={togglePopup5}
          isOpen={isOpen5}
          handleSubmit={handleUpdateChecklistSubmit}
          showSubmitButton={true}
          headerText={  <b>Update Checklist</b>}
          submitButtonText={loading ? "Loading..." : "Update"}
        />
      )}

      {isOpen3 && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleNewTaskGroup}>
         
                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>
                <div className="popupContainer">
                  <label className="NewInputLabel" data-domain="Add Name">
                    <input
                      className="NewInput"
                      type="text"
                      placeholder=""
                      value={addTaskGroupName}
                      required
                      onChange={(e) => setTaskGroupName(e.target.value)}
                    />
                  </label>

                  <label
                    className="NewInputLabel"
                    data-domain="Compartment Link"
                  >
                    <select
                      className="NewInput"
                      onChange={(event) =>
                        setLinkedCompartment(event.target.value)
                      }
                      required
                      defaultValue=""
                    >
                      <option value="" disabled>
                        Compartment Link
                      </option>
                      <option value="">None</option>

                      {compartments.map(function (e, i) {
                        return (
                          <option key={i} value={e.cid}>
                            {e.compartmentname}
                          </option>
                        );
                      })}
                    </select>
                  </label>

                  <label className="NewInputLabel" data-domain="Display Order">
                    <input
                      className="NewInput"
                      type="number"
                      placeholder=""
                      value={
                        addTaskGroupdDisplayOrder === null
                          ? ""
                          : addTaskGroupdDisplayOrder
                      }
                      onChange={(e) => setTaskGroupDisplayOrder(e.target.value)}
                    />
                  </label>
                  {/*}   <label className="checkboxLabel">
                    <input
                      type="checkbox"
                      checked={
                        editAllItems === true || editAllItems === "true"
                          ? true
                          : false
                      }
                      className="NewInputCheckBox"
                      onChange={(e) => handleCheckBoxChange2(e.target.value)}
                      name="checkbox-checked"
                    />
                    <div className="checkboxLabel">
                      Include all items in the compartment
                    </div>
                    </label>*/}
                </div>
              </form>
            </>
          }

          handleClose={togglePopup3}
          isOpen={isOpen3}
          handleSubmit={handleNewTaskGroup}
          showSubmitButton={true}
          headerText={         <b>New Task Group</b>}
          submitButtonText={loading ? "Loading..." : "Add"}

        />
      )}

      {isOpen2 && (
        <PopupWeb
          content={
            <>
              <div id="holderTest" className="holderTest">
                <div className="popupTitle3">
                  <b>New Task Type </b>
                </div>
              </div>
              <div className="emptySpace"></div>
              <div className="text-center p-3">
                <ClipLoader loading={loading} className="override" size={35} />
              </div>
              <div className="popupContainer">
                <div className="btnDiv">
                  <div className="addTaskTypeBtn" onClick={togglePopup4}>
                    <div className="addTaskTypeBtn2">Add Custom Task</div>
                  </div>
                  {currentCid ? (
                    <div className="addTaskTypeBtn" onClick={togglePopup6}>
                      <div className="addTaskTypeBtn2"> Add Item Task </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="popupBtnContainers"></div>
            </>
          }

          handleClose={togglePopup2}
          isOpen={isOpen2}
          showSubmitButton={false}
          headerText={          <b>New Task Type </b>}

        />
      )}
      {isOpen4 && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleNewTask}>
          
                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>

                <div className="popupContainer">
                  <label
                    className="NewInputLabel"
                    data-domain=" Task Description"
                  >
                    <input
                      className="NewInput"
                      type="text"
                      placeholder=""
                      value={taskDesciption}
                      onChange={(e) => setTaskDesciption(e.target.value)}
                      required
                    />
                  </label>
                </div>
              </form>
            </>
          }

          handleClose={togglePopup4}
          isOpen={isOpen4}
          handleSubmit={handleNewTask}
          showSubmitButton={true}
          headerText={      <b>New Custom Task</b>}
          submitButtonText={loading ? "Loading..." : "Add"}


        />
      )}
      {isOpen6 && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleNewInventory}>
    
                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>

                <div className="popupContainer">
                  {inventoryInfo &&
                    //eslint-disable-next-line
                    inventoryInfo.map((post, i) => {
                      // console.log(post);

                      return (
                        <div key={i}>
                          <div
                            className="checklistEditItemContainer"
                            onClick={() => {
                              addItemEvent(post.clid, post.checklistname);
                            }}
                          >
                            <div className="checklistEditItem">
                              {post.checklistname}
                            </div>
                            <div className="checklistEditItemright">
                              {post.checklistname}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </form>
            </>
          }

          
          handleClose={togglePopup6}
          isOpen={isOpen6}
          handleSubmit={handleNewInventory}
          showSubmitButton={true}
          headerText={      <b>Select Checklist</b>}
          submitButtonText={loading ? "Loading..." : "Add"}


        />
      )}
      {selectedItemOpen && (
        <PopupWeb
          content={
            <>
     
              <div className="text-center p-3">
                <ClipLoader loading={loading} className="override" size={35} />
              </div>

              <div className="popupContainer">
                {selectedItemEvents.length > 0 ? (
                  selectedItemEvents.map((data, i) => {
                    //loops 3 times idk why
                    // console.log(data);
                    if (currentEvent === data.clid) {
                      return (
                        <div key={i}>
                          <div className="itemListTaskDescContainer">
                            <div className="itemListTaskDesc">
                              {data.taskname}
                            </div>
                          </div>
                        </div>
                      );
                    } else {
                      currentEvent = data.clid;
                      return (
                        <div key={i}>
                          <div className="itemFirstEventName">
                            <div className="itemListEventName">
                              {data.checklistname}
                            </div>
                            <div
                              className="addItemTaskBtn"
                              onClick={() => addItemEvent(data.clid)}
                            >
                              Add
                            </div>
                          </div>
                          {data.taskname ? (
                            <div className="itemListTaskDescContainer">
                              <div className="itemListTaskDesc">
                                {data.taskname}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    }
                  })
                ) : (
                  <>
                    <div className="apparatusEmptyHolder">
                      <div className="apparatusEmptyName">
                        No Saved Tasked For This Item
                      </div>
                      <div className="apparatusEmptyButton"></div>
                    </div>
                  </>
                )}
              </div>
            </>
          }
          
          
          handleClose={togglePopupSelectedItem}
          isOpen={selectedItemOpen}
          handleSubmit={handleNewInventory}
          showSubmitButton={true}
          headerText={ <b>{selectedItemName} Saved Events</b>}
          submitButtonText={loading ? "Loading..." : "Add"}


        />
      )}

      {isOpen7 && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleEditTaskGroup}>

                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>

                <div className="popupContainer">
                  <label className="NewInputLabel" data-domain="Add Name">
                    <input
                      className="NewInput"
                      type="text"
                      placeholder=""
                      value={addTaskGroupName}
                      required
                      onChange={(e) => setTaskGroupName(e.target.value)}
                    />
                  </label>

                  <label
                    className="NewInputLabel"
                    data-domain="Compartment Link"
                  >
                    <select
                      className="NewInput"
                      onChange={(event) =>
                        setLinkedCompartment(event.target.value)
                      }
                      required
                      defaultValue={linkedCompartment}
                    >
                      <option value="" disabled>
                        Compartment Link
                      </option>
                      <option value="">None</option>
                      {compartments.map(function (e, i) {
                        return (
                          <option key={i} value={e.cid}>
                            {e.compartmentname}
                          </option>
                        );
                      })}
                    </select>
                  </label>

                  <label className="NewInputLabel" data-domain="Display Order">
                    <input
                      className="NewInput"
                      type="number"
                      placeholder=""
                      value={
                        addTaskGroupdDisplayOrder === null
                          ? ""
                          : addTaskGroupdDisplayOrder
                      }
                      onChange={(e) => setTaskGroupDisplayOrder(e.target.value)}
                    />
                  </label>

    
                </div>
              </form>

              <div className="editApparatusBtnContainer">
                <div className="editApparatusBtn" onClick={togglePopup66}>
                  <b>Delete</b>
                </div>
              </div>
            </>
          }

          handleClose={togglePopup7}
          isOpen={isOpen7}
          handleSubmit={handleEditTaskGroup}
          showSubmitButton={true}
          headerText={  <b>Edit Task Group</b>}
          submitButtonText={loading ? "Loading..." : "Submit"}

        />
      )}

      {isOpen66 && (
        <PopupWeb
          content={
            <>
         
              <div className="editApparatusBtnContainer">
                <div
                  className="confirmCancelApparatusBtn"
                  onClick={togglePopup66}
                >
                  <b>Cancel</b>
                </div>
                <div
                  className="confirmDeleteApparatusBtn"
                  onClick={handleDeleteTaskGroup}
                  //onClick={togglePopup2}
                >
                  <b>Delete</b>
                </div>
              </div>
            </>
          }

          handleClose={togglePopup66}
          isOpen={isOpen66}
          handleSubmit={handleEditTaskGroup}
          showSubmitButton={false}
          headerText={     <b>Delete Task Group - {addTaskGroupName} </b>}


        />
      )}

      {isOpen44 && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleEditTask}>
       
                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>

                <div className="popupContainer">
                  <label
                    className="NewInputLabel"
                    data-domain="Task Description"
                  >
                    <input
                      className="NewInput"
                      type="text"
                      placeholder=""
                      value={addTaskName}
                      onChange={(e) => setTaskName(e.target.value)}
                      required
                    />
                  </label>

                  <div className="editApparatusBtnContainer">
                    <div className="editApparatusBtn" onClick={togglePopup4444}>
                      <b>Delete</b>
                    </div>
                  </div>
                </div>
              </form>
            </>
          }

          handleClose={togglePopup444}
          isOpen={isOpen44}
          handleSubmit={handleEditTask}
          showSubmitButton={true}
          headerText={         <b>Edit Task </b>}
          submitButtonText={loading ? "Loading..." : "Submit"}


        />
      )}

      {isOpen444 && (
        <PopupWeb
          content={
            <>
             
              <div className="editApparatusBtnContainer">
                <div
                  className="confirmCancelApparatusBtn"
                  onClick={togglePopup4444}
                >
                  <b>Cancel</b>
                </div>
                <div
                  className="confirmDeleteApparatusBtn"
                  onClick={handleDeleteTask}
                  //onClick={togglePopup2}
                >
                  <b>Delete</b>
                </div>
              </div>
            </>
          }

          
          handleClose={togglePopup4444}
          isOpen={isOpen444}
          handleSubmit={handleEditTask}
          showSubmitButton={false}
          headerText={     addTaskName ? (
            <div className="popupTitle">
              <b>Delete Task - {addTaskName} </b>
            </div>
          ) : (
            ""
          )}

          
        />
      )}

      {isOpen22 && (
        <PopupWeb
          content={
            <>
              <div className="popupTitle">
                <b>Delete Location Checklist </b>
              </div>

              <div className="deleteInfoApparatusContainer">
                <b>Are you sure you want to delete this location checklist?</b>
              </div>

              {/*<div className="deleteInfoApparatusContainer">
                <b>
                  THIS CURRENTLY WILL ONLY DELETE THE location AND NOT
                  COMPARTMENTS OR ITEMS
                </b>
          </div>*/}

              <div className="editApparatusBtnContainer">
                <div
                  className="confirmCancelApparatusBtn"
                  onClick={togglePopup22}
                >
                  <b>Cancel</b>
                </div>
                <div
                  className="confirmDeleteApparatusBtn"
                  onClick={deleteChecklist}
                  //onClick={togglePopup2}
                >
                  <b>Delete</b>
                </div>
              </div>
            </>
          }

          
          handleClose={togglePopup22}
          isOpen={isOpen22}
          showSubmitButton={false}
          headerText={<b>Delete Location Checklist </b>}



        />
      )}
    </>
  );
};

export default ChecklistLocationPage;
