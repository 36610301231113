import React, { useState } from "react";
import { useMainHomePageData } from "./fetchHomePageData";
import { useToDoData } from "../ToDo/fetchToDoData";
import ToDoFinder from "../../apis/ToDo";

import { useAuth } from "../../context/AuthContext";
import PopupWeb from "../Popup/PopupNew";
import HomePageFinder from "../../apis/HomePageFinder";
import ClipLoader from "react-spinners/ClipLoader";
import { FaTasks, FaCommentDots } from "react-icons/fa";
import { Link } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import Select from "react-select";
import { notify } from "../../ToastNotifier";

const ToDoWidget = (props) => {
  const {
    currentUserSiteId,
    currentUserId,
    currentFirstName,
    currentLastName,
  } = useAuth();

  const { data, refetch } = useMainHomePageData(currentUserSiteId);
  const [status, setStatus] = useState("Active");
  const [newDate, setNewDate] = useState();
  const [loading, setLoading] = useState(false);

  const [isOpen3, setIsOpen3] = useState(false);
  const togglePopup3 = () => {
    setIsOpen3(!isOpen3);
  };

  const [comment, setComment] = useState("");
  const [commentId, setCommentId] = useState("");

  let num = 0;

  //Popup Bag
  const [alertDetails, setAlertDetails] = useState("");
  const [alertDetailsComment, setAlertDetailsComment] = useState("");

  const [errors, setErrors] = useState({});

  const validationRules = {
    trainingForm: {
      newToDoTitle: "Please enter a name",
      newToDoNote: "Please select a category",
      newDate: "Please select a date",
    },
    // Add more validation rules for other forms here
  };
  const validateForm = (values, rules) => {
    const newErrors = {};
    let isValid = true;

    Object.keys(rules).forEach((key) => {
      if (!values[key]) {
        newErrors[key] = rules[key];
        isValid = false;
      }
    });

    setErrors(newErrors);

    if (!isValid) {
      notify("Please fill out all required fields", "error");
    }

    return isValid;
  };

  const clearForm = () => {
    setLoading(false);
    setErrors({});
    setNewToDoNote("");
    setNewToDoTitle("");
    setNewDate("");
    setStatus("Active");
  };

  const StatusOptions = [
    { value: "Active", label: "Active" },
    { value: "Completed", label: "Completed" },
  ];

  const toggleComment = (id) => {
    togglePopup3();
    setCommentId(id);
    setComment("");
    // // // console.log(id);
  };

  const toggleComment2 = (id) => {
    togglePopup3();
    setIsOpen4(!isOpen4);

    setCommentId(id);
    //  setIsOpen4(!isOpen4);
    setComment("");
    // // // console.log(id);
  };

  const handleNewToDoSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    if (loading) return; // Prevent double submission
    const formValues = {
      newToDoTitle,
      newToDoNote,
      newDate,
    };

    if (!validateForm(formValues, validationRules.trainingForm)) {
      return;
    }

    setLoading(true);

    try {
      //eslint-disable-next-line
      const response = await ToDoFinder.post("/newToDoList", {
        toDoName: newToDoTitle,
        toDoNote: newToDoNote,
        date: newDate,
        status: status,
        currentUserId,
        currentUserSiteId,
        currentFirstName,
        currentLastName,
      });

      toggleNewToDoPopup();
      clearForm();
      refetch();
      notify("Event created successfully!", "success"); // Show success notification
    } catch (err) {
      setLoading(false);
      notify("Failed to create event. Please try again.", "error"); // Show error notification
    }
  };

  const handleCommentSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {
      //eslint-disable-next-line
      const response = await HomePageFinder.post(`/addToDoComment`, {
        commentId,
        comment,
        currentUserSiteId,
        currentFirstName,
        currentLastName,
      });

      togglePopup3();
      fetchData3(commentId);
      refetch();
    } catch (err) {}
  };

  const [isOpen4, setIsOpen4] = useState(false);
  const togglePopup4 = () => {
    setIsOpen4(!isOpen4);
  };

  const fetchData3 = async (todolistid) => {
    try {
      const response = await HomePageFinder.get("/getToDoDetails", {
        params: {
          todolistid: todolistid,
          currentUserSiteId,
        },
      });

      refetch();
      setAlertDetails(response.data.data.todoList);
      setAlertDetailsComment(response.data.data.todoListComment);
    } catch (err) {
      // // console.log(err);
    }
  };

  const fetchData2 = async (todolistid) => {
    setIsOpen4(!isOpen4);

    try {
      const response = await HomePageFinder.get("/getToDoDetails", {
        params: {
          todolistid: todolistid,
          currentUserSiteId,
        },
      });

      refetch();
      setAlertDetails(response.data.data.todoList);
      setAlertDetailsComment(response.data.data.todoListComment);
      document.getElementById("body2").style.removeProperty("position");
      document.getElementById("body").style.overflow = "auto";
    } catch (err) {
      // // console.log(err);
    }
  };

  const [editComment, setEditComment] = useState(false);
  const [alertCommentId, setAlertCommentId] = useState(false);
  const [editAlertId, setEditAlertId] = useState(false);

  const [PopupEdit, setPopupEdit] = useState(false);
  const toggleEditPopup = (id, comment, alertId) => {
    setPopupEdit(!PopupEdit);
    setAlertCommentId(id);
    setEditComment(comment);
    setEditAlertId(alertId);
  };

  const toggleEditPopup3 = (id, comment, alertId) => {
    setPopupEdit(!PopupEdit);
    setIsOpen4(!isOpen4);

    setAlertCommentId(id);
    setEditComment(comment);
    setEditAlertId(alertId);
  };

  const toggleEditPopup2 = () => {
    setPopupEdit(!PopupEdit);
  };

  const handleEditCommentSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {
      //eslint-disable-next-line
      const response = await ToDoFinder.post(`/edittodocomment`, {
        editComment,
        alertCommentId,
        editAlertId,
        currentUserId,
        currentUserSiteId,
        currentFirstName,
        currentLastName,
      });
      refetch();
      setPopupEdit(false);

      refetch();
    } catch (err) {}
  };

  const [editAlertTitle, setEditAlertTitle] = useState(false);
  const [editAlert, setEditAlert] = useState(false);
  const [editMainAlertId, setEditMainAlertId] = useState(false);

  const [newToDoNote, setNewToDoNote] = useState("");
  const [newToDoTitle, setNewToDoTitle] = useState("");

  const [PopupToDoNew, setPopupToDoNew] = useState(false);

  const toggleNewToDoPopup = () => {
    setPopupToDoNew(!PopupToDoNew);
    setNewToDoNote("");
    setNewToDoTitle("");
  };

  const [PopupAlertEdit, setPopupAlertEdit] = useState(false);
  const toggleAlertPopup = (alertId, title, comment) => {
    setPopupAlertEdit(!PopupAlertEdit);
    setIsOpen4(!isOpen4);

    setEditMainAlertId(alertId);
    setEditAlert(comment);
    setEditAlertTitle(title);
  };

  const toggleAlertPopup2 = () => {
    setPopupAlertEdit(!PopupAlertEdit);
    setIsOpen4(!isOpen4);
  };

  const handleEditAlertSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {
      //eslint-disable-next-line
      const response = await HomePageFinder.post(`/edittodo`, {
        editMainAlertId,
        editAlert,
        editAlertTitle,
        currentUserSiteId,
      });
      fetchData3(editAlertId);

      refetch();
      setPopupEdit(false);
      setIsOpen4(!isOpen4);
    } catch (err) {}
  };

  const [alertCloseText, setAlertCloseText] = useState("");

  const [PopupAlertClose, setPopupAlertClose] = useState(false);
  const toggleAlertClosePopup = (alertId) => {
    setPopupAlertClose(!PopupAlertClose);
    setIsOpen4(!isOpen4);
    setEditMainAlertId(alertId);
  };

  const toggleAlertClosePopup2 = () => {
    setPopupAlertClose(!PopupAlertClose);
  };

  const toggleAlertClosePopup3 = (alertId) => {
    setPopupAlertClose(!PopupAlertClose);
    setEditMainAlertId(alertId);
    setIsOpen4(!isOpen4);
  };

  const handleCloseAlertSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {
      //eslint-disable-next-line
      const response = await HomePageFinder.post(`/closetodo`, {
        editMainAlertId,
        currentUserId,
        alertCloseText,
        currentUserSiteId,
        currentFirstName,
        currentLastName,
      });
      refetch();

      toggleAlertClosePopup2();
    } catch (err) {}
  };

  const [PopupAlertDelete, setPopupAlertDelete] = useState(false);
  const [deleteMainAlertId, setDeleteMainAlertId] = useState(false);

  const toggleAlertDeletePopup = (alertId) => {
    setIsOpen4(!isOpen4);

    setPopupAlertDelete(!PopupAlertDelete);
    setDeleteMainAlertId(alertId);
  };

  const toggleAlertDeletePopup2 = (alertId) => {
    setDeleteMainAlertId(alertId);
    setPopupAlertDelete(!PopupAlertDelete);
  };

  const handleDeleteAlertSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {
      //eslint-disable-next-line
      const response = await ToDoFinder.post(`/deletetodo`, {
        deleteMainAlertId,
        currentUserSiteId,
      });
      refetch();
      toggleAlertDeletePopup2();
    } catch (err) {}
  };

  //Popup
  const [isOpen2, setIsOpen2] = useState(false);
  const togglePopup2 = () => {
    setIsOpen2(!isOpen2);
    setPopupEdit(!PopupEdit);
  };

  const handleDeleteComment = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {
      //eslint-disable-next-line
      const response = await HomePageFinder.post(`/deletetodocomment`, {
        alertCommentId,
        editAlertId,
        currentUserId,
        currentUserSiteId,
      });
      refetch();
      setIsOpen2(!isOpen2);
    } catch (err) {}
  };

  return (
    <>
      <div className="todo-box">
        <h2>To-Do</h2>

        {data?.data?.data?.todoList && data?.data?.data?.todoList.length > 0 ? (
          <ul className="todo-list">
            {data?.data?.data?.todoList.map((todo, index) => (
              <li key={index} className="todo-card">
                <div className="todo-header">
                  <div
                    className="todo-title-container"
                    onClick={() => fetchData2(todo.todolistid)}
                  >
                    <FaTasks className="todo-icon" />
                    <div className="todo-title">{todo.title}</div>
                  </div>
                  {todo.note && <div className="todo-note">{todo.note}</div>}
                </div>
                {todo.comments && todo.comments.length > 0 ? (
                  <ul className="todo-comments">
                    {todo.comments.map((comment, commentIndex) => (
                      <li
                        key={commentIndex}
                        onClick={() =>
                          toggleEditPopup(
                            comment.todolistcommentid,
                            comment.comment_text,
                            comment.todolistid
                          )
                        }
                      >
                        <FaCommentDots className="comment-icon" />{" "}
                        <span className="comment-text">
                          {comment.comment_text}
                        </span>
                        <div className="comment-meta">
                          <span className="comment-date">
                            {comment.date}{" "}
                            {/* Assuming date is in a readable format */}
                          </span>
                          {" by "}
                          <span className="comment-username">
                            {comment.username}
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>No comments yet.</p>
                )}
                <div className="todo-card-footer">
                  <button onClick={() => toggleComment(todo.todolistid)}>
                    Add Comment
                  </button>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>No active To-Do</p>
        )}

        <div className="todo-links">
          <Link to="/todo" className="view-all-todo-link">
            View All →
          </Link>
          <Link className="create-new-todo-link" onClick={toggleNewToDoPopup}>
            Create New →
          </Link>
        </div>
      </div>

      {isOpen3 && (
        <PopupWeb
          content={
            <>
              <label className="NewInputLabel" data-domain="Add New Comment">
                <input
                  className="NewInput"
                  type="text"
                  placeholder=""
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  required
                />
              </label>
            </>
          }
          handleClose={togglePopup3}
          isOpen={isOpen3}
          handleSubmit={handleCommentSubmit}
          showSubmitButton={true}
          headerText={<b>New Comment</b>}
          submitButtonText={loading ? "Loading..." : "Add"}
          loading={loading}
        />
      )}

      {isOpen4 &&
        alertDetails.length > 0 &&
        alertDetails.map((data1, i) => (
          <PopupWeb
            key={i}
            content={
              <>
                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>

                <div className="popupContainer">
                  <div className="widgetPopupHeader">
                    <div className="widgetDetailsHeaderHolder">
                      <div className="widgetDetailsHeaderMember">
                        <b>
                          <span className="widgetDetailsHeaderDateToDo">
                            {data1.submiteddate}
                          </span>
                          : {data1.username}
                        </b>
                        <div className="widgetApparatusAlertBoxDetails">
                          {data1.note}
                        </div>
                      </div>
                    </div>
                  </div>

                  {alertDetailsComment && alertDetailsComment.length > 0 ? (
                    <div className="widgetDetailsCommentHolder">
                      {alertDetailsComment.map((data2, ii) => (
                        <div
                          key={ii}
                          className="commentItem"
                          onClick={() =>
                            toggleEditPopup3(
                              data2.todolistcommentid,
                              data2.comment,
                              data2.todolistid
                            )
                          }
                        >
                          <div className="widgetApparatusAlertBoxInformation">
                            <div className="widgetApparatusAlertBoxMember">
                              <b>
                                <span className="widgetApparatusToDoBoxDate2">
                                  {data2.commentdate}
                                </span>
                                - {data2.username} added a comment
                              </b>
                            </div>
                          </div>
                          <div className="widgetApparatusAlertBoxDetails">
                            {data2.comment}
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="widgetDetailsCommentHolder2">
                      <p>No comments yet.</p>
                    </div>
                  )}

                  <div className="widgetApparatusAlertFooter">
                    <div
                      className="widgetApparatusAlertAdd"
                      onClick={() => toggleComment2(data1.todolistid)}
                    >
                      Add Comment
                    </div>
                    <div
                      className="widgetApparatusAlertAdd"
                      onClick={() => toggleAlertClosePopup(data1.todolistid)}
                    >
                      Close
                    </div>
                    <div
                      className="widgetApparatusAlertDelete"
                      onClick={() => toggleAlertDeletePopup(data1.todolistid)}
                    >
                      Delete
                    </div>
                    {data.itemeventname && (
                      <div className="widgetApparatusAlertAdd">Go to Item</div>
                    )}
                  </div>
                </div>
              </>
            }
            handleClose={togglePopup4}
            isOpen={isOpen4}
            handleSubmit={() => toggleAlertPopup(data1)}
            showSubmitButton={true}
            headerText={<b>{data1.title}</b>}
            submitButtonText={loading ? "Loading..." : "Edit"}
            loading={loading}
          />
        ))}

      {PopupEdit && (
        <PopupWeb
          content={
            <>
              <label className="NewInputLabel" data-domain="Edit Comment">
                <input
                  className="NewInput"
                  type="text"
                  placeholder=""
                  value={editComment}
                  onChange={(e) => setEditComment(e.target.value)}
                  required
                />
              </label>

              <div className="editApparatusBtnContainer">
                <div className="editApparatusBtn" onClick={togglePopup2}>
                  <b>Delete</b>
                </div>
              </div>
            </>
          }
          handleClose={toggleEditPopup2}
          isOpen={PopupEdit}
          handleSubmit={handleEditCommentSubmit}
          showSubmitButton={true}
          headerText={<b>Edit Comment</b>}
          submitButtonText={loading ? "Loading..." : "Save"}
          loading={loading}
        />
      )}

      {PopupAlertEdit && (
        <PopupWeb
          content={
            <>
              <div
                type="submit"
                className="submitItemBtn2"
                onClick={handleEditAlertSubmit}
              >
                Save
              </div>
              <div className="popupTitle">
                <b>Edit To Do</b>
              </div>
              <div className="topbarEmptySpace"></div>

              <label className="NewInputLabel" data-domain="Edit Title">
                <input
                  className="NewInput"
                  type="text"
                  placeholder=""
                  value={editAlertTitle}
                  onChange={(e) => setEditAlertTitle(e.target.value)}
                  required
                />
              </label>

              <label className="NewInputLabel" data-domain="Edit Note">
                <input
                  className="NewInput"
                  type="text"
                  placeholder=""
                  value={editAlert}
                  onChange={(e) => setEditAlert(e.target.value)}
                  required
                />
              </label>
            </>
          }
          handleClose={toggleAlertPopup2}
        />
      )}

      {PopupToDoNew && (
        <PopupWeb
          content={
            <>
              <div className="popupContainer">
                <label className="NewInputLabel" data-domain="Title">
                  <TextareaAutosize
                    onChange={(e) => setNewToDoTitle(e.target.value)}
                    className="NewInput"
                    maxRows={10}
                    type="text"
                    placeholder=""
                    required
                  />
                  {errors.toDoName && (
                    <div className="errorInputLabel">{errors.toDoName}</div>
                  )}
                </label>
                <label className="NewInputLabel" data-domain="Note">
                  <TextareaAutosize
                    onChange={(e) => setNewToDoNote(e.target.value)}
                    className="NewInput"
                    maxRows={10}
                    type="text"
                    placeholder=""
                  />
                </label>

                <label className="NewInputLabel" data-domain="Status">
                  <Select
                    options={StatusOptions}
                    defaultValue={StatusOptions.find(
                      (StatusOptions) => StatusOptions.value === "Active"
                    )}
                    onChange={(e) => setStatus(e.value)}
                    name="addMembers"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    required
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                </label>

                <label className="NewInputLabel" data-domain="Date">
                  <input
                    className="NewInput"
                    type="date"
                    placeholder=""
                    onChange={(e) => setNewDate(e.target.value)}
                    required
                  />
                  {errors.newDate && (
                    <div className="errorInputLabel">{errors.newDate}</div>
                  )}
                </label>
              </div>
            </>
          }
          handleClose={toggleNewToDoPopup}
          isOpen={PopupToDoNew}
          handleSubmit={handleNewToDoSubmit}
          showSubmitButton={true}
          headerText={<b>New To-Do</b>}
          submitButtonText={loading ? "Loading..." : "Add"}
          loading={loading}
        />
      )}

      {PopupAlertClose && (
        <PopupWeb
          content={
            <>
              <div className="deleteInfoApparatusContainer">
                <b>This will complete the current To Do list</b>
              </div>

              <label className="NewInputLabel" data-domain="Close Note">
                <input
                  className="NewInput"
                  type="text"
                  placeholder=""
                  value={alertCloseText}
                  onChange={(e) => setAlertCloseText(e.target.value)}
                  required
                />
              </label>
            </>
          }
          handleClose={toggleAlertClosePopup3}
          isOpen={PopupAlertClose}
          handleSubmit={handleCloseAlertSubmit}
          showSubmitButton={true}
          headerText={<b>Close To-Do</b>}
          submitButtonText={loading ? "Loading..." : "Complete"}
          loading={loading}
        />
      )}

      {PopupAlertDelete && (
        <PopupWeb
          content={
            <>
              <div className="popupTitle">
                <b>Delete To Do </b>
              </div>

              <div className="deleteInfoApparatusContainer">
                <b>This will DELETE the current To Do list</b>
              </div>
            </>
          }
          handleClose={toggleAlertDeletePopup}
          isOpen={PopupAlertDelete}
          handleSubmit={handleDeleteAlertSubmit}
          showSubmitButton={true}
          headerText={<b>Delete To-Do </b>}
          submitButtonText={loading ? "Loading..." : "Delete"}
          loading={loading}
        />
      )}
      {isOpen2 && (
        <PopupWeb
          content={
            <>
              <div className="deleteInfoApparatusContainer">
                <b>This comment will no longer be available</b>
              </div>

              {/*<div className="deleteInfoApparatusContainer">
                <b>
                  THIS CURRENTLY WILL ONLY DELETE THE location AND NOT
                  COMPARTMENTS OR ITEMS
                </b>
          </div>*/}
            </>
          }
          handleClose={togglePopup2}
          isOpen={isOpen2}
          handleSubmit={handleDeleteComment}
          showSubmitButton={true}
          headerText={<b>Delete To-Do Comment</b>}
          submitButtonText={loading ? "Loading..." : "Delete"}
          loading={loading}
        />
      )}
    </>
  );
};

export default ToDoWidget;
