import React, { useEffect, useState } from "react";
import { Html5Qrcode } from "html5-qrcode";
// import { getCameraList } from "./Utils";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import ScannerFinder from "../../apis/ScannerFinder";
import "./Scanner.css";
import Header from "../Header/Header";
import { AiOutlineMenu } from "react-icons/ai";

const qrConfig = { fps: 10, qrbox: { width: 300, height: 300 } };
const brConfig = { fps: 10, qrbox: { width: 300, height: 150 } };
let html5QrCode;

// function startCamera(){}

export const Scanner = (props) => {
  const [QRLoading, setQRLoading] = useState(false);

  useEffect(() => {
    const oldRegion = document.getElementById("qr-shaded-region");
    oldRegion && oldRegion.remove();
    return () => {
      handleStop();
    };
  }, []);

  const handleClickAdvanced = () => {
    handleStart();
    const qrCodeSuccessCallback = (decodedText, decodedResult) => {
      console.info(decodedResult, decodedText);
      props.onResult(decodedText);
      handleScan(decodedText);
      // alert(`decoded:__ ${decodedText}`);
      handleStop();
    };
    html5QrCode
      .start(
        { facingMode: "environment" },
        props.type === "QR" ? qrConfig : brConfig,
        qrCodeSuccessCallback
      )
      .then(() => {
        // const oldRegion = document.getElementById("qr-shaded-region");
        // if (oldRegion) oldRegion.innerHTML = "";
      });
    setQRLoading(true);
  };
  const getCameras = () => {
    Html5Qrcode.getCameras()
      .then((devices) => {
        /**
         * devices would be an array of objects of type:
         * { id: "id", label: "label" }
         */
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleStop = () => {
    try {
      html5QrCode
        .stop()
        .then((res) => {
          html5QrCode.clear();
        })
        .catch((err) => {
          // console.log(err.message);
        });
      setQRLoading(false);
    } catch (err) {
      //console.log(err);
    }
  };

  const handleStart = () => {
    try {
      html5QrCode = new Html5Qrcode("reader");
      getCameras();
    } catch (err) {
      console.log(err);
    }
  };

  let history = useNavigate();

  let navigate = useNavigate();

  const { currentUserSiteId } = useAuth();

  var pattern = /\d{9}/;

  const handleScan = async (data) => {
    if (data) {
      console.log(data);
      if (data.match(pattern)) {
        handleProfile(data);
      } else {
        let data2 = data.split("?")[1];
        let search = data2.split(":")[0];
        let number = data2.split(":")[1];
        console.log(search);
        console.log(number);
        if (search === "itemId") {
          handleItem(number);
        } else if (search === "bagId") {
          handleBag(number);
        } else if (search === "compartmentId") {
          handleCompartment(number);
        }
      }
    }
  };

  //Item qr code
  const handleItem = (itemid) => {
    history({
      pathname: `/`,
    });

    navigate(`/inventory/${itemid}/itempage`, { state: { itemid: itemid } });
  };
  //?compartmentId:12
  const handleBag = (bagId) => {
    fetchData2(bagId);
  };

  //needs to be fixed
  const fetchData2 = async (bagId) => {
    try {
      const response = await ScannerFinder.get("/getBagScanner", {
        params: {
          bagId: bagId,
          currentUserSiteId,
        },
      });
      let apparatusId = response.data.data.bagInfo[0].apparatusid;
      history({
        pathname: `/`,
      });

      navigate(`/apparatus/${apparatusId}`, { state: { bagId: bagId } });
    } catch (err) {
      console.log(err);
    }
  }; //?bagId:8

  //Compartment qr code
  const handleCompartment = (compartmentId) => {
    fetchData3(compartmentId);
  };

  const fetchData3 = async (compartmentId) => {
    try {
      const response = await ScannerFinder.get("/getCompartmentScanner", {
        params: {
          compartmentId: compartmentId,
          currentUserSiteId,
        },
      });
      let apparatusId = response.data.data.compartmentInfo[0].apparatusid;
      history({
        pathname: `/`,
      });

      navigate(`/apparatus/${apparatusId}`, {
        state: { compartmentId: compartmentId },
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleProfile = (cardId) => {
    fetchData4(cardId);
  };

  const fetchData4 = async (cardId) => {
    try {
      const response = await ScannerFinder.get("/getUserScanner", {
        params: {
          cardId: cardId,
          currentUserSiteId,
        },
      });
      let user_id = response.data.data.userInfo[0].user_id;
      history({
        pathname: `/`,
      });

      navigate(`/userInfo/${user_id}`, { state: {} });
    } catch (err) {
      console.log(err);
    }
  };
  const toggleNav = () => {
    let topbar = document.getElementById("topbar");
    let sideBar = document.getElementById("sidebar");
    let appContainer = document.getElementById("appContainer");
    let appContainerHidden = document.getElementById("appContainerHidden");

    topbar.classList.toggle("collapse");
    sideBar.classList.toggle("collapse");
    appContainer.classList.toggle("collapse");
    appContainerHidden.classList.toggle("collapse");
  };
  return (
    <>
      <div id="topbar" className="topbar headerActive">
        <div className="hometopbarleft" onClick={toggleNav}>
          <AiOutlineMenu />
        </div>

        <Header content={<b>Scan QR Code</b>} />
      </div>
      <div className="topbarEmptySpace"></div>

      <div style={{ position: "relative" }}>
        <div id="reader" width="100%"></div>

        {QRLoading === false ? (
          <div className="restartPageDiv">
            <button
              className="restartPageBtn"
              onClick={() => handleClickAdvanced()}
            >
              Start
            </button>
          </div>
        ) : (
          <div className="restartPageDiv">
            <button className="restartPageBtn" onClick={() => handleStop()}>
              Stop
            </button>
          </div>
        )}

        <br />
        <br />
      </div>
    </>
  );
};

function App() {
  const [decodedValue, setDecodedValue] = useState("");
  return (
    <>
      <div className="popupScanner">
        <div className="popupTitle">
          <b>Scan a QR code </b>
        </div>
        <Scanner type={"QR"} onResult={(res) => setDecodedValue(res)} />
        <br />
        <p style={{ width: "100%", wordWrap: "break-word" }}>{decodedValue}</p>
      </div>
    </>
  );
}

export default App;
