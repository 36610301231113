import React, { useState, useRef, useEffect, useMemo } from "react";
import PopupWeb from "../Popup/PopupNew";
import Header from "../Header/Header";

import InventoryFinder from "../../apis/InventoryFinder";
///import { InventoryContext } from "../../context/InventoryContext";
import { useLocation, useNavigate } from "react-router-dom";
import PublicFolder from "../../apis/PublicFolder";
import ClipLoader from "react-spinners/ClipLoader";

import { RiImageAddFill, RiCloseFill } from "react-icons/ri";
import { useAuth } from "../../context/AuthContext";

import { useMainInventoryData } from "./fetchInventoryData";

import { AiOutlineMenu } from "react-icons/ai";
import Select from "react-select";
import { BsImage } from "react-icons/bs";
import { FaArrowRight } from "react-icons/fa";
import { MdArrowBackIosNew } from "react-icons/md";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
} from "@tanstack/react-table";

import PDFReportTemplate from "../Reports/PDFReportTemplate";

const Pagination = ({ table }) => {
  const { pageIndex, pageSize } = table.getState().pagination;

  // Store the current page index in local storage
  useEffect(() => {
    localStorage.setItem("InventoryPage", pageIndex);
  }, [pageIndex]);

  const totalRows = table.getPrePaginationRowModel().rows.length;
  const startRow = pageIndex * pageSize + 1;
  const endRow = Math.min(startRow + pageSize - 1, totalRows);

  return (
    <div className="pagination2">
      <div className="results-info">
        Showing {startRow} to {endRow} of {totalRows} results
      </div>
      <div className="pagination-controls">
        <button
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          Previous
        </button>
        <span>
          {/*  Page {pageIndex + 1} of {table.getPageCount()}*/}
          {pageIndex + 1}
        </span>
        <button
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          Next
        </button>
      </div>
    </div>
  );
};

const InventoryWeb = () => {
  const {
    currentUserSiteId,
    currentUserId,
    currentLastName,
    currentFirstName,
  } = useAuth();
  const { data, refetch } = useMainInventoryData(currentUserSiteId);

  const [inventoryList, setInventoryList] = useState([]);
  const [allFrequency, setAllFrequency] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const prevLocationRef = useRef();


  useEffect(() => {
    setInventoryList(data?.data.data.inventory2);
    setAllTags(data?.data.data.allTags ? data?.data.data.allTags : []);
    setAllFrequency(
      data?.data.data.allFrequency ? data?.data.data.allFrequency : []
    );
  }, [data]);

  const Memberoptions =
    data?.data.data.memberResults.map(function (data) {
      return {
        value: data.user_id,
        label: data.fullname,
      };
    }) || [];

  // Initialize options
  const options =
    data?.data.data.categoryResults.map(function (data) {
      return {
        value: data.categoryid,
        label: data.name,
      };
    }) || [];

  const NextInspectionOptions = [
    { value: "Inspection due in 30 days", label: "Inspection due in 30 days" },
    { value: "Inspection is overdue", label: "Inspection is overdue" },
  ];

  const SelectOptions = [
    { value: "Inspect", label: "Inspect" },
    { value: "Add Tag", label: "Add Tag" },
    { value: "Add Inspection Frequency", label: "Add Inspection Frequency" },
    { value: "Remove From Inventory", label: "Remove From Inventory" },
  ];

  const combineOptions = (
    tags,
    members,
    inspections,
    tagOptions,
    Memberoptions1,
    NextInspectionOptions,
    optionsBags, // Add bags options
    apparatusOptions // Add apparatus options
  ) => {
    return [
      ...tagOptions
        .filter((option) => tags.includes(option.value))
        .map((option) => ({ ...option, type: "Tag" })),

      ...Memberoptions1.filter((option) => members.includes(option.value)).map(
        (option) => ({ ...option, type: "Member" })
      ),

      ...NextInspectionOptions.filter((option) =>
        inspections.includes(option.value)
      ).map((option) => ({ ...option, type: "Inspection" })),

      // Filter and map optionsBags
      ...optionsBags
        .filter((option) => bagFilter.includes(option.value))
        .map((option) => ({ ...option, type: "Bag" })),

      // Filter and map apparatusOptions
      ...apparatusOptions
        .filter((option) => locationFilter.includes(option.value))
        .map((option) => ({ ...option, type: "Apparatus" })),
    ];
  };

  const updateSelectedOptions = (tags, members, inspections) => {
    const combinedOptions = combineOptions(
      tags,
      members,
      inspections,
      options,
      Memberoptions,
      NextInspectionOptions,
      flatOptionsBags, // pass the flattened bags options
      apparatusoptions // pass the apparatus options
    );
    setSelectedOptions(combinedOptions);
  };

  const [inventoryLookUpItem, setInventoryLookUpItem] = useState("");
  const [tagsFilter, setTagsFilter] = useState([]);
  const [inspectionFilter, setInspectionFilter] = useState([]);
  const [memberFilter, setMemberFilter] = useState([]);
  const [bagFilter, setBagFilter] = useState([]);
  const [locationFilter, setLocationFilter] = useState([]);

  useEffect(() => {
    const storedSearchQuery = localStorage.getItem("InventorySearchQuery");
    const storedInspectionFilter =
      localStorage.getItem("InventoryInspectionFilter") || "[]";

    const storedTagsFilter = (localStorage.getItem("InventoryFilters") || "[]")
      .split(",")
      .filter(Boolean)
      .map(Number);

    const storedMemberFilter = (
      localStorage.getItem("InventoryMemberFilter") || "[]"
    )
      .split(",")
      .filter(Boolean)
      .map(Number);

    const storedBagFilter = (localStorage.getItem("InventoryBagFilter") || "[]")
      .split(",")
      .filter(Boolean)
      .map(Number);

    const storedLocationFilter = (
      localStorage.getItem("InventoryLocationFilter") || "[]"
    )
      .split(",")
      .filter(Boolean)
      .map(Number);

    if (storedSearchQuery) {
      setInventoryLookUpItem(storedSearchQuery);
    }

    if (storedTagsFilter.length > 0) {
      setTagsFilter(storedTagsFilter);
    }

    if (storedInspectionFilter.length > 0) {
      setInspectionFilter(storedInspectionFilter);
    }

    if (storedMemberFilter.length > 0) {
      setMemberFilter(storedMemberFilter);
    }

    if (storedBagFilter.length > 0) {
      setBagFilter(storedBagFilter);
    }

    if (storedLocationFilter.length > 0) {
      setLocationFilter(storedLocationFilter);
    }
  }, []);

  useEffect(() => {
    // Ensure that options are available before attempting to update selected options
    if (
      options.length &&
      Memberoptions.length &&
      NextInspectionOptions.length
    ) {
      // Only update if there's a change in the filters or the necessary options are loaded

      if (
        tagsFilter.length ||
        memberFilter.length ||
        inspectionFilter.length ||
        bagFilter.length ||
        locationFilter.length
      ) {
        updateSelectedOptions(
          tagsFilter,
          memberFilter,
          inspectionFilter,
          bagFilter,
          locationFilter
        );
      }
    }
  }, [
    tagsFilter,
    memberFilter,
    inspectionFilter,
    bagFilter,
    locationFilter,
    options.length, // Watch for when options are loaded
    Memberoptions.length, // Watch for when Memberoptions are loaded
    NextInspectionOptions.length, // Watch for when NextInspectionOptions are loaded
  ]);

  let history = useNavigate();

  const [file, setFile] = useState(null);

  const headers = {
    "content-type": "multipart/form-data",
  };

  //Popup
  const [isOpen1, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen1);
  };
  const togglePopupOpen = () => {
    setIsOpen(!isOpen1);
    setIsOpenSearch(!isOpenSearch);
    setName(lookUpItem);
    setSelectedImage("");
    setCategory("");
    setType("");
    setManufacture("");
    setDescription("");
    setFile("");
    setSelectedItem("");
  };

  const togglePopupClose = () => {
    setIsOpen(!isOpen1);
    setName("");
    setSelectedImage("");
    setCategory("");
    setType("");
    setManufacture("");
    setDescription("");
    setFile("");
    setSelectedItem("");
  };

  const [loading, setLoading] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  //Input useStae
  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [category, setCategory] = useState("");
  const [manufacture, setManufacture] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");

  const [selectedAmount, setSelectedAmount] = useState("1");
  const [selectedIdentificationName, setSelectedIdentificationName] =
    useState("");
  const [selectedSerialNumber, setSelectedSerialNumber] = useState("");
  const [selectedFirstUseDate, setSelectedFirstUseDate] = useState();
  const [selectedManufactureDate, setSelectedManufactureDate] = useState();
  const [selectedItemId, setSelectedItemId] = useState();
  const [dropdownQuery, setDropdownQuery] = useState("None");

  const [allTags, setAllTags] = useState([]);
  const [addItemTags, setAddItemTags] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const openSearchBox = () => {
    setSelectedItem(null);
    setLookUpItem(""); // Clear the search input
    setLookUpResults([]); // Clear the search results
  };

  const handleSelctedItemInfo = async (
    id,
    name,
    manufacture,
    description,
    image
  ) => {
    setSelectedItemId(id);
    setName(name);
    setManufacture(manufacture);
    setDescription(description);
    setImage(image);
    setSelectedItem({ id, name, manufacture, description, image });
  };

  const [selectedItemTags, setSelectedItemTags] = useState("");

  //Submit user new item
  const handleAddNewItem = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);

      // eslint-disable-next-line
      const response = await InventoryFinder.post(`/addNewItem`, {
        inventoryId: selectedItemId,
        name,
        manufacture,
        image,
        selectedAmount,
        selectedIdentificationName,
        selectedSerialNumber:
          selectedSerialNumber === "" ? null : selectedSerialNumber,
        selectedManufactureDate,
        selectedFirstUseDate,
        selectedItemTags,
        currentUserId,
        currentUserSiteId,
      });
      refetch();
      setLoading(false);
      togglePopupSearch();
    } catch (err) {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    try {
      setLoading(true);
      const form = document.getElementById("newItemForm"); // Get the form by its ID
      const formData = new FormData(form); // Create FormData from the form

      formData.set("name", name);
      formData.set("manufacture", manufacture);
      formData.set("currentUserSiteId", currentUserSiteId);
      formData.set("image", image);

      formData.set("selectedAmount", selectedAmount);
      formData.set("selectedIdentificationName", selectedIdentificationName);
      formData.set("selectedSerialNumber", selectedSerialNumber);
      formData.set("selectedManufactureDate", selectedManufactureDate);
      formData.set("selectedFirstUseDate", selectedFirstUseDate);
      formData.append("selectedItemTags", JSON.stringify(selectedItemTags)); // Convert array to JSON string
      formData.set("currentUserId", currentUserId);
      formData.set("currentFirstName", currentFirstName);
      formData.set("currentLastName", currentLastName);

      // Ensure selectedImage is an array
      if (Array.isArray(selectedImage)) {
        selectedImage.forEach((image, index) => {
          formData.append(`file${index}`, image);
        });
      } else if (selectedImage) {
        // If it's a single image, append it directly
        formData.append("file", selectedImage);
      }

      const response = await InventoryFinder.post("/newInventory", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      refetch();
      // setInventory(response.data.data.inventory);
      //setCategory(response.data.data.itemGroupresults);
      form.reset();
      togglePopup();
      setName("");
      setSelectedImage("");
      setCategory("");
      setType("");
      setManufacture("");
      setDescription("");
      setFile(null);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };
  const removehandleFileInput = (index) => {
    setSelectedImage(null);
  };

  const [inspectionPhotos, setInspectionPhotos] = useState([]);

  const handleFileInputInspectionPhotos = (e) => {
    const files = Array.from(e.target.files);
    setInspectionPhotos((prevImages) => [...prevImages, ...files]);
  };

  const removeInspectionPhotos = (index) => {
    setInspectionPhotos((prevImages) =>
      prevImages.filter((_, i) => i !== index)
    );
  };

  //custom image
  const fileRef = useRef();
  const [selectedImage, setSelectedImage] = useState([]);

  const toggleNav = () => {
    let topbar = document.getElementById("topbar");
    let sideBar = document.getElementById("sidebar");
    let appContainer = document.getElementById("appContainer");
    let appContainerHidden = document.getElementById("appContainerHidden");

    topbar.classList.toggle("collapse");
    sideBar.classList.toggle("collapse");
    appContainer.classList.toggle("collapse");
    appContainerHidden.classList.toggle("collapse");
  };
  const [searchPerformed, setSearchPerformed] = useState(false);

  //Popup
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const togglePopupSearch = () => {
    setIsOpenSearch(!isOpenSearch);
    setCategory("");
    setType("");
    setLookUpItem("");
    setLookUpResults("");
    setSelectedItem("");
    setSearchPerformed(false);
  };

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const togglePopupFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const [lookUpItem, setLookUpItem] = useState("");

  const [lookUpResults, setLookUpResults] = useState("");

  const handleItemSearch = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {
      //eslint-disable-next-line
      //// console.log(lookUpItem);
      const response = await InventoryFinder.get("/getDefaultItems", {
        params: {
          lookUpItem,
          currentUserSiteId,
        },
      });
      //// console.log(response.data.data);
      if (response.data.data.alert) {
        alert(response.data.data.alert);
        return;
      }
      // //// console.log(response.data.data.training);
      setLookUpResults(response.data.data.lookUpResults);
      setSearchPerformed(true);
    } catch (err) {}
  };

  const handleItem = (itemid) => {
    history({
      pathname: `/inventory/${itemid}/itempage`,
    });
  };

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(data?.data.data.inventory2.map((li) => li.itemid));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck((isCheck) => [...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  const optionsBags =
    data?.data.data.bagsList.map(function (data) {
      return {
        label: data.name,
        options: data.bags.map((data2, i) => ({
          value: data2.bagid,
          label: data2.bagname,
        })),
      };
    }) || [];

  const flatOptionsBags = optionsBags.flatMap((group) => group.options);

  const apparatusoptions =
    data?.data.data.apparatusList.map(function (data) {
      return {
        value: data.id,
        label: data.name,
      };
    }) || [];

  var categorySearch = "";

  const handleChange = (e) => {
    const categorySearch = Array.isArray(e) ? e.map((x) => x.value) : [];
    localStorage.setItem("InventoryFilters", categorySearch); // Store as JSON string
    localStorage.setItem("InventoryPage", 0); // Reset the page index
    table.setPageIndex(0); // Reset the table page index
    setTagsFilter(categorySearch); // Update the state

    refetch(); // Refetch data
  };

  const handleChangeInspection = (e) => {
    const InspectionFilter = Array.isArray(e) ? e.map((x) => x.value) : [];
    localStorage.setItem("InventoryInspectionFilter", InspectionFilter); // Store as JSON string
    localStorage.setItem("InventoryPage", 0); // Reset the page index
    table.setPageIndex(0); // Reset the table page index
    setInspectionFilter(InspectionFilter); // Update the state

    refetch(); // Refetch data
  };

  const handleChangeMember = (e) => {
    const MemberFilter = Array.isArray(e) ? e.map((x) => x.value) : [];
    localStorage.setItem("InventoryMemberFilter", MemberFilter); // Store as JSON string
    localStorage.setItem("InventoryPage", 0); // Reset the page index
    table.setPageIndex(0); // Reset the table page index
    setMemberFilter(MemberFilter); // Update the state

    refetch(); // Refetch data
  };

  const handleSearchChange = (e) => {
    const searchQuery = inventoryLookUpItem;
    localStorage.setItem("InventorySearchQuery", searchQuery); // Store the search query
    localStorage.setItem("InventoryPage", 0); // Reset the page index
    table.setPageIndex(0); // Reset the table page index
    setInventoryLookUpItem(searchQuery); // Update the state
    refetch(); // Refetch data
  };

  const handleChangeLocation = (e) => {
    const LocationFilter = Array.isArray(e) ? e.map((x) => x.value) : [];
    localStorage.setItem("InventoryLocationFilter", LocationFilter); // Store as JSON string
    localStorage.setItem("InventoryPage", 0); // Reset the page index
    table.setPageIndex(0); // Reset the table page index
    setLocationFilter(LocationFilter); // Update the state

    refetch(); // Refetch data
  };

  const handleChangeBag = (e) => {
    const BagFilter = Array.isArray(e) ? e.map((x) => x.value) : [];
    localStorage.setItem("InventoryBagFilter", BagFilter); // Store as JSON string
    localStorage.setItem("InventoryPage", 0); // Reset the page index
    table.setPageIndex(0); // Reset the table page index
    setBagFilter(BagFilter); // Update the state

    refetch(); // Refetch data
  };

  const [isOpen4, setIsOpen4] = useState(false);
  const togglePopup4 = () => {
    setIsOpen4(!isOpen4);
  };

  // Get default values for the Select component from the state
  const defaultValues = options.filter((option) =>
    tagsFilter.includes(option.value)
  );

  const InspectiondefaultValues = NextInspectionOptions.filter((option) =>
    inspectionFilter.includes(option.value)
  );

  const memberdefaultValues = Memberoptions.filter((option) =>
    memberFilter.includes(option.value)
  );

  const locationdefaultValues = apparatusoptions.filter((option) =>
    locationFilter.includes(option.value)
  );

  const bagdefaultValues = flatOptionsBags.filter((option) =>
    bagFilter.includes(option.value)
  );

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.data.value === "Remove From Inventory" ? "red" : "black", // Apply red color to "Remove From Inventory"
    }),
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  };

  const [ChecklistData, setChecklistData] = useState([]);

  let ChecklistOptionsNone = [{ value: "None", label: "None" }];

  const handleSelectOptionsChange = (e) => {
    if (e === "Inspect") {
      setDropdownQuery("None");
      setInspectionNotes("");
      fetchInspectionResults();
      togglePopup4();
    }

    if (e === "Add Tag") {
      setDropdownQuery("None");
      setAddItemTags([]);
      editItemPopupClose();
    }

    if (e === "Add Inspection Frequency") {
      setDropdownQuery("None");
      setAddFreq("");
      mainInspectionFreqPopup();
    }

    if (e === "Remove From Inventory") {
      setDropdownQuery("None");
      setAddFreq("");
      mainRemovePopup();
    }
  };

  const fetchInspectionResults = async () => {
    try {
      const response = await InventoryFinder.get("/getInventoryInspection", {
        params: {
          currentUserSiteId,
          isCheck: isCheck,
        },
      });

      setChecklistData(response.data.data.inspectionData);
    } catch (err) {
      // //// console.log(err);
    }
  };

  let ChecklistOptionsNew = ChecklistData.map(function (e) {
    //// console.log(e);
    return {
      value: e.checklistname,
      label: e.checklistname,
    };
  });

  let ChecklistOptions = [...ChecklistOptionsNone, ...ChecklistOptionsNew];

  const [inspectionStatusArray, setInspectionStatusArray] = useState([]);
  const [inspectionNotes, setInspectionNotes] = useState("");

  const handleInspectionSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    try {
      setLoading(true);
      let myForm = document.getElementById("inspectionForm"); // Reference the form by ID
      let formData = new FormData(myForm);

      // //// console.log("handleFileInput working!");

      inspectionPhotos.forEach((image, index) => {
        formData.append(`file`, image);
      });
      // eslint-disable-next-line
      // Get only the values from the object
      const inspectionStatusValues = Object.values(inspectionStatusObject);

      // Convert the values to a comma-separated string
      const inspectionStatusString = inspectionStatusValues.join(",");

      formData.set("isCheck", isCheck);
      formData.set("inspectionStatus", inspectionStatusString);
      formData.set("inspectionNotes", inspectionNotes);
      formData.set("currentUserSiteId", currentUserSiteId);
      formData.set("currentLastName", currentLastName);
      formData.set("currentFirstName", currentFirstName);
      formData.set("currentUserId", currentUserId);
      formData.set("selectedChecklist", dropdownQuery);

      //eslint-disable-next-line
      const response2 = await InventoryFinder.post(
        `/postInspection`,
        formData,
        headers
      );

      // setInventory(response.data.data.inventory);
      //setCategory(response.data.data.itemGroupresults);
      refetch();
      togglePopup4();

      setFile(null);
      setLoading(false);
    } catch (err) {
      //// console.log(err);
      setLoading(false);
    }
  };

  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor((row) => row.itemid, {
        id: "itemid",
        cell: (info) => (
          <div className="min-max-width2">
            <input
              id={info.getValue()}
              name={info.getValue()}
              type={"checkbox"}
              onChange={handleClick}
              checked={isCheck.includes(info.getValue())}
              style={{ width: "15px", height: "15px", margin: "10px" }}
            />
          </div>
        ),
        header: () => (
          <div className="min-max-width2">
            <input
              type="checkbox"
              name="selectAll"
              id="selectAll"
              onChange={handleSelectAll}
              checked={isCheckAll}
              style={{ width: "15px", height: "15px" }}
            />
          </div>
        ),
      }),
      columnHelper.accessor((row) => row.image, {
        id: "image",
        cell: (info) =>
          info.getValue() ? (
            <div
              className="min-max-widthLogo"
              onClick={() => handleItem(info.row.original.itemid)}
            >
              <img
                src={PublicFolder.baseURL + "preview_" + info.getValue()}
                alt="Did Not Load"
                className="InventoryTableImg"
              />
            </div>
          ) : (
            <div className="min-max-width">
              <BsImage size={50} />
            </div>
          ),
        header: () => <div className="min-max-widthLogo">Image</div>,
      }),
      columnHelper.accessor((row) => row.itemname, {
        id: "itemname",
        cell: (info) => (
          <div
            className="min-max-width"
            onClick={() => handleItem(info.row.original.itemid)}
          >
            {info.getValue()}
          </div>
        ),
        header: () => <div className="min-max-width">Name</div>,
      }),
      columnHelper.accessor((row) => row.identificationname, {
        id: "identificationname",
        cell: (info) => (
          <div
            className="min-max-width"
            onClick={() => handleItem(info.row.original.itemid)}
          >
            {info.getValue()}
          </div>
        ),
        header: () => <div className="min-max-width">Identification</div>,
      }),
      columnHelper.accessor((row) => row.serialnumber, {
        id: "serialnumber",
        cell: (info) => (
          <div
            className="min-max-width"
            onClick={() => handleItem(info.row.original.itemid)}
          >
            {info.getValue()}
          </div>
        ),
        header: () => <div className="min-max-width">Serial Number</div>,
      }),
      columnHelper.accessor((row) => row.itemamount, {
        id: "itemamount",
        cell: (info) => (
          <div
            className="min-max-width"
            onClick={() => handleItem(info.row.original.itemid)}
          >
            {info.getValue()}
          </div>
        ),
        header: () => <div className="min-max-width">Amount</div>,
      }),
      columnHelper.accessor((row) => row.apparatus, {
        id: "apparatus",
        cell: (info) => (
          <div
            className="min-max-width"
            onClick={() => handleItem(info.row.original.itemid)}
          >
            {info.row.original.apparatus ? (
              <>
                {info.row.original.apparatus} -{" "}
                {info.row.original.compartmentname}
                {info.row.original.bagname
                  ? ` - ${info.row.original.bagname}`
                  : ""}
              </>
            ) : info.row.original.memberitem ? (
              <>{info.row.original.memberitem}</>
            ) : (
              ""
            )}
          </div>
        ),
        header: () => <div className="min-max-width">Location</div>,
      }),
      columnHelper.accessor((row) => row.status, {
        id: "Status",
        cell: (info) => {
          const status = info.getValue();
          return (
            <div
              className="min-max-width"
              onClick={() => handleItem(info.row.original.itemid)}
            >
              {status === "Fail" ? (
                <span className="status-failed">Failed</span>
              ) : status === "Expired" ? (
                <span className="status-expired">Expired</span>
              ) : status === "Pass" ? (
                <span className="status-pass">Pass</span>
              ) : status === "Monitor" ? (
                <span className="status-monitor">Monitor</span>
              ) : (
                status
              )}
            </div>
          );
        },
        header: () => <div className="min-max-width">Status</div>,
      }),
      columnHelper.accessor((row) => row.inspectiondate, {
        id: "inspectiondate",
        cell: (info) => (
          <div
            className="min-max-width"
            onClick={() => handleItem(info.row.original.itemid)}
          >
            {info.getValue()}
          </div>
        ),
        header: () => <div className="min-max-width">Inspection Date</div>,
      }),
      columnHelper.accessor((row) => row.nextinspectiondate, {
        id: "nextinspectiondate",
        cell: (info) => (
          <div
            className="min-max-width"
            onClick={() => handleItem(info.row.original.itemid)}
          >
            {info.getValue()}
          </div>
        ),
        header: () => <div className="min-max-width">Next Inspection Date</div>,
      }),
      columnHelper.accessor((row) => row.mandatoryinspectiondate, {
        id: "mandatoryinspectiondate",
        cell: (info) => (
          <div
            className="min-max-width"
            onClick={() => handleItem(info.row.original.itemid)}
          >
            {info.getValue()}
          </div>
        ),
        header: () => (
          <div className="min-max-width">Mandatory Inspection Date</div>
        ),
      }),
      columnHelper.accessor((row) => row.manufacturedate, {
        id: "manufacturedate",
        cell: (info) => (
          <div
            className="min-max-width"
            onClick={() => handleItem(info.row.original.itemid)}
          >
            {info.getValue()}
          </div>
        ),
        header: () => <div className="min-max-width">Manufacture Date</div>,
      }),
      columnHelper.accessor((row) => row.expirationdate, {
        id: "expirationdate",
        cell: (info) => (
          <div
            className="min-max-width"
            onClick={() => handleItem(info.row.original.itemid)}
          >
            {info.getValue()}
          </div>
        ),
        header: () => <div className="min-max-width">Expiration Date</div>,
      }),
    ],
    [handleClick, handleSelectAll, isCheck, isCheckAll]
  );

  const initialPage = parseInt(localStorage.getItem("InventoryPage")) || 0;

  const table = useReactTable({
    data: inventoryList,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: { pagination: { pageIndex: initialPage, pageSize: 25 } },
  });

  const pdfColumns = [
    { header: "Name", accessor: (row) => row.itemname },
    { header: "Serial Number", accessor: (row) => row.serialnumber },
    { header: "Amount", accessor: (row) => row.itemamount },
    { header: "Status", accessor: (row) => row.status },
    {
      header: "Location",
      accessor: (row) =>
        row.apparatus
          ? `${row.apparatus} - ${row.compartmentname}${
              row.bagname ? ` - ${row.bagname}` : ""
            }`
          : row.memberitem || "",
    },
    { header: "Inspection Date", accessor: (row) => row.nextinspectiondate },

    {
      header: "Mandatory Inspection Date",
      accessor: (row) => row.mandatoryinspectiondate,
    },

    { header: "Expiration Date", accessor: (row) => row.expirationdate },
  ];

  const handleClearFilters = () => {
    setTagsFilter([]);
    setMemberFilter([]);
    setInspectionFilter([]);
    setLocationFilter([]);
    setBagFilter([]);
    setLookUpItem("");
    localStorage.removeItem("InventoryFilters");
    localStorage.removeItem("InventorySearchQuery");
    localStorage.removeItem("InventoryPage");
    localStorage.removeItem("InventoryMemberFilter");
    localStorage.removeItem("InventoryInspectionFilter");
    localStorage.removeItem("InventoryLocationFilter");
    localStorage.removeItem("InventoryBagFilter");

    setInventoryLookUpItem("");
    setSelectedOptions([]);
    refetch();
  };

  //Popup Edit item details
  const [isOpen3, setIsOpen3] = useState(false);
  const editItemPopupClose = () => {
    setIsOpen3(!isOpen3);
  };

  let optionsAllTags = allTags.map(function (data) {
    return {
      value: data.categoryid,
      label: data.name,
    };
  });

  //update inventory
  const handleTagUpdate = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {
      setLoading(true);

      // // console.log("dmdkmfds");
      const response = await InventoryFinder.post(`/editTagItem`, {
        isCheck,
        currentUserId,
        currentUserSiteId,
        currentFirstName,
        currentLastName,
        addItemTags,
      });
      // // console.log("rerwr");

      setLoading(false);
      // // console.log("dmdkmfds");
      //(response.data.data.alert);
      if (response.data.data.alert) {
        alert(response.data.data.alert);
        return;
      }

      refetch();

      editItemPopupClose();
    } catch (err) {
      setLoading(false);
    }
  };

  const [addFreq, setAddFreq] = useState("");

  const [isInspectionFreqOpen, setIsInspectionFreqOpen] = useState(false);
  const mainInspectionFreqPopup = () => {
    setIsInspectionFreqOpen(!isInspectionFreqOpen);
  };

  //Item move submit
  const handleFreqSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {
      setLoading(true);

      // eslint-disable-next-line
      const response = await InventoryFinder.post(`/updateItemFreq`, {
        isCheck,
        addFreq,
        currentUserId,
        currentUserSiteId,
        currentFirstName,
        currentLastName,
      });
      setLoading(false);
      refetch();
      mainInspectionFreqPopup();
    } catch (err) {
      setLoading(false);
    }
  };

  let optionsFreq = allFrequency.map(function (data) {
    return {
      value: data.id,
      label: data.name,
    };
  });

  const [isRemoveOpen, setIsRemoveOpen] = useState(false);
  const mainRemovePopup = () => {
    setIsRemoveOpen(!isRemoveOpen);
  };

  //Item move submit
  const handleRemoveSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {
      setLoading(true);

      // eslint-disable-next-line
      const response = await InventoryFinder.post(`/deleteItems`, {
        isCheck,
        addFreq,
        currentUserId,
        currentUserSiteId,
        currentFirstName,
        currentLastName,
      });
      setLoading(false);
      setIsCheck([]);
      setDropdownQuery("None");
      setAddFreq("");
      refetch();
      mainRemovePopup();
    } catch (err) {
      setLoading(false);
    }
  };

  const [inspectionStatusObject, setInspectionStatusObject] = useState({});

  const setInspectionStatus = (value, taskId) => {
    setInspectionStatusObject((prevObject) => ({
      ...prevObject,
      [taskId]: value,
    }));
  };

  const [fromBags, setFromBags] = useState(false);

  useEffect(() => {
    if (location.state?.from === "/bags") {
      setFromBags(true);
    }
  }, [location]);

  const goBackDiv = (
    <div className="goBackDiv" onClick={() => navigate(-1)}>
      <div className="goBackDivarrow">
        <MdArrowBackIosNew />
      </div>
      <div className="goBackDivtxt">Back</div>
    </div>
  );

  return (
    <div className="containerInventory">
      <div className="container22">
        <div className="top2">
          {" "}
          <div className="top">
            <div id="topbar" className="topbar headerActive">
              <div className="hometopbarleft" onClick={toggleNav}>
                <AiOutlineMenu />
              </div>

              {fromBags && goBackDiv}


              <Header content={<b>Inventory</b>} />

              <div className="addItemReact" onClick={togglePopupSearch}>
                <div className="addItemReactText"> Add Item </div>
              </div>
            </div>

            <div className="filter-search-toggle-container">
              <div className={`filter-search-container`}>
                <div className="search-input-container">
                  <input
                    className="inventorySearchButton"
                    placeholder="Search Inventory"
                    type="search"
                    value={inventoryLookUpItem}
                    onChange={(e) => setInventoryLookUpItem(e.target.value)}
                  />
                  <button
                    className="search-button"
                    onClick={handleSearchChange}
                  >
                    <span className="arrow-icon">
                      <FaArrowRight />
                    </span>
                  </button>
                </div>

                <button className="clear-filters" onClick={handleClearFilters}>
                  Clear
                </button>

                <PDFReportTemplate
                  data={inventoryList}
                  title="Inventory Report"
                  reportDateRange="2024-01-01 to 2024-12-31"
                  category="Inventory"
                  members={["John Doe", "Jane Smith"]}
                  logoUrl={`${PublicFolder.baseURL}LogonIcon.png`}
                  pdfColumns={pdfColumns}
                  filters={selectedOptions}
                  includeDateRange={false} // Pass these flags based on your requirement
                  includeCategory={false}
                  includeMembers={false}
                  includeFilters={true}
                />

                <button className="clear-filters" onClick={togglePopupFilter}>
                  Filters
                </button>

                <div className="selected-options-display">
                  <div className="selected-options-row">
                    {selectedOptions.map((option, index) => (
                      <div key={index} className="selected-option">
                        {option.label}
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {isCheck.length > 0 ? (
                <div className="ChecklistSelectPopup">
                  <Select
                    defaultValue={null}
                    options={SelectOptions}
                    onChange={(e) => handleSelectOptionsChange(e.value)}
                    name="addMembers"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    isSearchable={false}
                    menuPortalTarget={document.body}
                    styles={customStyles}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div className="middle2">
          <div className="table-container2">
            {inventoryList ? (
              <>
                <table>
                  <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <th key={header.id}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table.getRowModel().rows.map((row) => (
                      <tr
                        key={row.id}
                        className={
                          isCheck.includes(row.original.itemid)
                            ? "highlighted"
                            : ""
                        }
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="bottom2">
          {" "}
          <Pagination table={table} />
        </div>
      </div>

      {isOpenSearch && (
        <PopupWeb
          content={
            <form onSubmit={handleAddNewItem} className="form-class">
              <div className="text-center p-3">
                <ClipLoader loading={loading} className="override" size={35} />
              </div>
              <div className="popup-container">
                {!selectedItem ? (
                  <>
                    <div className="search-container">
                      <input
                        className="new-input"
                        type="text"
                        onChange={(e) => setLookUpItem(e.target.value)}
                        value={lookUpItem}
                        required
                        placeholder="Search by SKU name or code"
                      />
                      <button
                        className="search-button"
                        onClick={handleItemSearch}
                      >
                        Search
                      </button>
                    </div>

                    <div className="item-results-container">
                      {lookUpResults?.length > 0 ? (
                        <div className="scrollable-results">
                          {lookUpResults.map((dataLoop) => (
                            <div
                              key={dataLoop.id}
                              className="item-holder"
                              onClick={() =>
                                handleSelctedItemInfo(
                                  dataLoop.id,
                                  dataLoop.name,
                                  dataLoop.manufacture,
                                  dataLoop.description,
                                  dataLoop.image
                                )
                              }
                            >
                              <div className="item-container">
                                <img
                                  src={
                                    PublicFolder.baseURL +
                                    "preview_" +
                                    dataLoop.image
                                  }
                                  alt="Did Not Load"
                                  className="selected-item-img"
                                />
                                <div className="selected-item-info">
                                  <h3>{dataLoop.name}</h3>
                                  <p>{dataLoop.description}</p>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        searchPerformed &&
                        lookUpItem.trim() !== "" && (
                          <div className="no-results-holder">
                            <div className="no-results-name">
                              No Results Found
                            </div>
                            <button
                              className="create-button"
                              onClick={togglePopupOpen}
                            >
                              Create New
                            </button>
                          </div>
                        )
                      )}
                    </div>
                  </>
                ) : (
                  <div className="selected-item-container">
                    <img
                      src={
                        PublicFolder.baseURL + "preview_" + selectedItem.image
                      }
                      alt="Did Not Load"
                      className="selected-item-img"
                    />
                    <div className="selected-item-info">
                      <h3>{selectedItem.name}</h3>
                      <p>{selectedItem.description}</p>
                    </div>
                    <button
                      className="search-again-button"
                      onClick={openSearchBox}
                    >
                      Search for a different item
                    </button>
                  </div>
                )}

                <label className="NewInputLabel" data-domain="Tags">
                  <Select
                    isMulti
                    options={options}
                    onChange={setSelectedItemTags}
                    name="addMembers"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                </label>

                <label className="NewInputLabel" data-domain="Alternative ID">
                  <input
                    className="NewInput"
                    type="text"
                    onChange={(e) =>
                      setSelectedIdentificationName(e.target.value)
                    }
                    value={selectedIdentificationName}
                  />
                </label>
                <label className="NewInputLabel" data-domain="Amount">
                  <input
                    className="NewInput"
                    type="text"
                    onChange={(e) => setSelectedAmount(e.target.value)}
                    value={selectedAmount}
                  />
                </label>
                <label className="NewInputLabel" data-domain="Serial number">
                  <input
                    className="NewInput"
                    type="text"
                    onChange={(e) => setSelectedSerialNumber(e.target.value)}
                    value={selectedSerialNumber}
                  />
                </label>
                <label
                  className="NewInputLabel"
                  data-domain="Date of manufacture"
                >
                  <input
                    className="NewInput"
                    type="date"
                    onChange={(e) => setSelectedManufactureDate(e.target.value)}
                    value={selectedManufactureDate}
                  />
                </label>

                <label
                  className="NewInputLabel"
                  data-domain="Date of first use"
                >
                  <input
                    className="NewInput"
                    type="date"
                    onChange={(e) => setSelectedFirstUseDate(e.target.value)}
                    value={selectedFirstUseDate}
                  />
                </label>
              </div>
            </form>
          }
          handleClose={togglePopupSearch}
          isOpen={isOpenSearch}
          handleSubmit={handleAddNewItem}
          showSubmitButton={true}
          headerText={<b>Search For Item</b>}
          submitButtonText={loading ? "Loading..." : "Add"}
        />
      )}

      {isFilterOpen && (
        <PopupWeb
          content={
            <div>
              <label className="NewInputLabel123" data-domain="Tags">
                <Select
                  isMulti
                  options={options}
                  onChange={handleChange}
                  value={defaultValues}
                  className="filter-dropdown"
                  classNamePrefix="Select"
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                />
              </label>

              <label className="NewInputLabel123" data-domain="Inspection">
                <Select
                  isMulti
                  options={NextInspectionOptions}
                  onChange={handleChangeInspection}
                  value={InspectiondefaultValues}
                  className="filter-dropdown"
                  classNamePrefix="Select"
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                />
              </label>

              <label
                className="NewInputLabel123"
                data-domain="Assigned To Member"
              >
                <Select
                  isMulti
                  options={Memberoptions}
                  onChange={handleChangeMember}
                  value={memberdefaultValues}
                  className="filter-dropdown"
                  classNamePrefix="Select"
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                />
              </label>

              <label
                className="NewInputLabel123"
                data-domain="Assigned To Location"
              >
                <Select
                  isMulti
                  options={apparatusoptions}
                  onChange={handleChangeLocation}
                  value={locationdefaultValues}
                  className="filter-dropdown"
                  classNamePrefix="Select"
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                />
              </label>

              <label className="NewInputLabel123" data-domain="Assigned To Bag">
                <Select
                  isMulti
                  options={flatOptionsBags}
                  onChange={handleChangeBag}
                  value={bagdefaultValues}
                  className="filter-dropdown"
                  classNamePrefix="Select"
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                />
              </label>
            </div>
          }
          handleClose={togglePopupFilter}
          isOpen={isFilterOpen}
          showSubmitButton={false}
          headerText={<b>Filters</b>}
          submitButtonText={loading ? "Loading..." : "Add"}
        />
      )}

      {isOpen1 && (
        <PopupWeb
          content={
            <form
              onSubmit={handleSubmit}
              id="newItemForm"
              className="formClass"
            >
              <div className="text-center p-3">
                <ClipLoader loading={loading} className="override" size={35} />
              </div>
              <div className="popupContainer">
                <label className="NewInputLabel" data-domain="Item Name">
                  <input
                    className="NewInput"
                    type="text"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    required
                  />
                </label>

                <label className="NewInputLabel" data-domain="Manufacturer">
                  <input
                    className="NewInput"
                    type="text"
                    onChange={(e) => setManufacture(e.target.value)}
                    value={manufacture}
                  />
                </label>

                <label className="NewInputLabel" data-domain="Tags">
                  <Select
                    isMulti
                    options={options}
                    onChange={setSelectedItemTags}
                    name="addMembers"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                </label>

                <label className="NewInputLabel" data-domain="Alternative ID">
                  <input
                    className="NewInput"
                    type="text"
                    onChange={(e) =>
                      setSelectedIdentificationName(e.target.value)
                    }
                    value={selectedIdentificationName}
                  />
                </label>
                <label className="NewInputLabel" data-domain="Amount">
                  <input
                    className="NewInput"
                    type="text"
                    onChange={(e) => setSelectedAmount(e.target.value)}
                    value={selectedAmount}
                  />
                </label>
                <label className="NewInputLabel" data-domain="Serial number">
                  <input
                    className="NewInput"
                    type="text"
                    onChange={(e) => setSelectedSerialNumber(e.target.value)}
                    value={selectedSerialNumber}
                  />
                </label>
                <label
                  className="NewInputLabel"
                  data-domain="Date of manufacture"
                >
                  <input
                    className="NewInput"
                    type="date"
                    onChange={(e) => setSelectedManufactureDate(e.target.value)}
                    value={selectedManufactureDate}
                  />
                </label>

                <label
                  className="NewInputLabel"
                  data-domain="Date of first use"
                >
                  <input
                    className="NewInput"
                    type="date"
                    onChange={(e) => setSelectedFirstUseDate(e.target.value)}
                    value={selectedFirstUseDate}
                  />
                </label>

                <div className="NewInputLabel" data-domain="Add Image">
                  <input
                    ref={fileRef}
                    onChange={handleFileInput}
                    type="file"
                    accept="image/*"
                    hidden
                  />
                  <div
                    onClick={() => fileRef.current.click()}
                    className="NewInputImage"
                  >
                    <RiImageAddFill size={30} />
                  </div>
                  <div className="imagePreviewContainer">
                    {selectedImage && (
                      <div className="imageHolder">
                        <img
                          src={URL.createObjectURL(selectedImage)}
                          alt="imageDidNotLoad"
                          className="addImage"
                        />
                        <button
                          type="button"
                          className="removeImageButton"
                          onClick={removehandleFileInput}
                        >
                          <RiCloseFill size={20} />
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          }
          handleClose={togglePopupClose}
          isOpen={isOpen1}
          handleSubmit={handleSubmit}
          showSubmitButton={true}
          headerText={<b>New Item</b>}
          submitButtonText={loading ? "Loading..." : "Add"}
        />
      )}

      {isOpen4 && (
        <PopupWeb
          content={
            <form id="inspectionForm" className="formClass">
              <div className="text-center p-3">
                <ClipLoader loading={loading} className="override" size={35} />
              </div>
              <div className="popupContainer">
                <Select
                  defaultValue={{ value: "None", label: "None" }}
                  options={ChecklistOptions}
                  onChange={(e) => setDropdownQuery(e.value)}
                  name="addMembers"
                  className="basic-multi-select"
                  classNamePrefix="Checklist"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                />

                <div className="PassFailTaskContainer">
                  {dropdownQuery === "None" ? (
                    <>
                      <div className="taskNameHolder">
                        <div className="taskName2"> </div>
                        <div className="taskDesc2"> </div>
                      </div>
                      <input
                        value="Pass"
                        type="radio"
                        className="passBtn"
                        onChange={(e) =>
                          setInspectionStatus(e.target.value, "0")
                        }
                        onFocus={(event) =>
                          (event.nativeEvent.target.defaultValue = "")
                        }
                        id={"Pass1"}
                        name={"0"}
                      />

                      <label className="passBtn2" htmlFor={"Pass1"}>
                        Pass
                      </label>
                      <div className="space"></div>

                      <input
                        value="Fail"
                        type="radio"
                        className="passBtn"
                        onChange={(e) =>
                          setInspectionStatus(e.target.value, "0")
                        }
                        onFocus={(event) =>
                          (event.nativeEvent.target.defaultValue = "")
                        }
                        id={"Fail1"}
                        name={"0"}
                      />
                      <label className="failBtn2" htmlFor={"Fail1"}>
                        Fail
                      </label>

                      <div className="space"></div>

                      <input
                        value="Monitor"
                        type="radio"
                        className="passBtn"
                        onChange={(e) =>
                          setInspectionStatus(e.target.value, "0")
                        }
                        onFocus={(event) =>
                          (event.nativeEvent.target.defaultValue = "")
                        }
                        id={"Monitor1"}
                        name={"0"}
                      />
                      <label className="failBtn2" htmlFor={"Monitor1"}>
                        Monitor
                      </label>

                      <div>
                        <input name={"0"} type="hidden" value={"0"}></input>
                        <input name={"0"} type="hidden" value={""}></input>
                        <input name={"0"} type="hidden" value={""}></input>
                      </div>
                    </>
                  ) : (
                    <>
                      {ChecklistData.length > 0 ? (
                        ChecklistData
                          // eslint-disable-next-line
                          .filter((data) => {
                            if (dropdownQuery === "None") {
                              return data;
                            } else if (
                              data.checklistname
                                .toLowerCase()
                                .includes(dropdownQuery.toLowerCase())
                            ) {
                              return data;
                            }
                          })
                          .map((data, i) => {
                            return (
                              <>
                                {data.tasklist.map((data2, i) => (
                                  <div key={i}>
                                    <div className="taskNameHolder">
                                      <div className="taskName2">
                                        {" "}
                                        {data2.taskname}
                                      </div>
                                      <div className="taskDesc2">
                                        {" "}
                                        {data2.taskdescription}
                                      </div>
                                    </div>
                                    <input
                                      value="Pass"
                                      type="radio"
                                      className="passBtn"
                                      id={"Pass" + data2.tid}
                                      onChange={(e) =>
                                        setInspectionStatus(
                                          e.target.value,
                                          data2.tid
                                        )
                                      }
                                      name={data2.tid}
                                    />
                                    <label
                                      className="passBtn2"
                                      htmlFor={"Pass" + data2.tid}
                                    >
                                      Pass
                                    </label>
                                    <div className="space"></div>
                                    <input
                                      value="Fail"
                                      type="radio"
                                      className="passBtn"
                                      id={"Fail" + data2.tid}
                                      onChange={(e) =>
                                        setInspectionStatus(
                                          e.target.value,
                                          data2.tid
                                        )
                                      }
                                      name={data2.tid}
                                    />
                                    <label
                                      className="failBtn2"
                                      htmlFor={"Fail" + data2.tid}
                                    >
                                      Fail
                                    </label>
                                    <div className="space"></div>
                                    <input
                                      value="Monitor"
                                      type="radio"
                                      className="passBtn"
                                      id={"Monitor" + data2.tid}
                                      onChange={(e) =>
                                        setInspectionStatus(
                                          e.target.value,
                                          data2.tid
                                        )
                                      }
                                      name={data2.tid}
                                    />
                                    <label
                                      className="failBtn2"
                                      htmlFor={"Monitor" + data2.tid}
                                    >
                                      Monitor
                                    </label>

                                    <div>
                                      <input
                                        name={data2.tid}
                                        id={data2.tid}
                                        type="hidden"
                                        value={data2.tid}
                                      ></input>
                                      <input
                                        name={data2.tid}
                                        type="hidden"
                                        value={data2.taskname}
                                      ></input>
                                      <input
                                        name={data2.tid}
                                        type="hidden"
                                        value={data2.taskdescription}
                                      ></input>
                                      <input
                                        name={data2.tid}
                                        type="hidden"
                                        value={data.clid}
                                      ></input>
                                    </div>
                                  </div>
                                ))}
                              </>
                            );
                          })
                      ) : (
                        <>11</>
                      )}
                    </>
                  )}
                </div>

                <div className="NewInputLabel" data-domain="Add Image">
                  <input
                    ref={fileRef}
                    onChange={handleFileInputInspectionPhotos}
                    multiple
                    type="file"
                    accept="image/*"
                    hidden
                  />
                  <div
                    onClick={() => fileRef.current.click()}
                    className="NewInputImage"
                  >
                    <RiImageAddFill size={30} />
                  </div>
                  <div className="imagePreviewContainer">
                    {inspectionPhotos.map((image, index) => (
                      <div key={index} className="imageHolder">
                        <img
                          src={URL.createObjectURL(image)}
                          alt="imageDidNotLoad"
                          className="addImage"
                        />
                        <button
                          type="button"
                          className="removeImageButton"
                          onClick={() => removeInspectionPhotos(index)}
                        >
                          <RiCloseFill size={20} />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="inspectionImg"> Notes</div>

                <div className="textInputLeft2">
                  <textarea
                    onChange={(e) => setInspectionNotes(e.target.value)}
                    className="addItemInput"
                    type="text"
                    placeholder="Note..."
                  />
                </div>
              </div>
            </form>
          }
          handleClose={togglePopup4}
          isOpen={isOpen4}
          handleSubmit={handleInspectionSubmit}
          showSubmitButton={true}
          headerText={<b>Inspection</b>}
          submitButtonText={loading ? "Loading..." : "Save"}
        />
      )}

      {isOpen3 && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleTagUpdate}>
                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>
                <div className="popupContainer">
                  <div>
                    <label className="NewInputLabel" data-domain="Tags">
                      <Select
                        isMulti
                        options={optionsAllTags}
                        onChange={setAddItemTags}
                        name="addMembers"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    </label>
                  </div>
                </div>
              </form>
            </>
          }
          handleClose={editItemPopupClose}
          isOpen={isOpen3}
          handleSubmit={handleTagUpdate}
          showSubmitButton={true}
          headerText={<b>Edit Item Details</b>}
          submitButtonText={loading ? "Loading..." : "Save"}
        />
      )}

      {isInspectionFreqOpen && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleFreqSubmit}>
                <label className="NewInputLabel" data-domain="Frequency">
                  <Select
                    options={optionsFreq}
                    onChange={(e) => setAddFreq(e.value)}
                    name="addMembers"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                </label>
              </form>
            </>
          }
          handleClose={mainInspectionFreqPopup}
          isOpen={isInspectionFreqOpen}
          handleSubmit={handleFreqSubmit}
          showSubmitButton={true}
          headerText={<b>Inspection Frequency</b>}
          submitButtonText={loading ? "Loading..." : "Save"}
        />
      )}

      {isRemoveOpen && (
        <PopupWeb
          content={
            <>
              <div className="popup-content">
                <h3>
                  Are you sure you want to remove these items from inventory?
                </h3>
                <p>
                  You are about to remove {isCheck.length} item
                  {isCheck.length > 1 ? "s" : ""} from inventory. This action
                  cannot be undone.
                </p>
              </div>
            </>
          }
          handleClose={mainRemovePopup}
          isOpen={isRemoveOpen}
          handleSubmit={handleRemoveSubmit}
          showSubmitButton={true}
          headerText={<b>Remove</b>}
          submitButtonText={loading ? "Loading..." : "Remove"}
          submitButtonClassName="remove-button" // Add a class for the remove button
        />
      )}
    </div>
  );
};

export default InventoryWeb;
