import React from "react";
import "../components/Reports/Reports.css";
import ReportsEventsComponent from "../components/Reports/ReportsIndividualEvents";

const ReportsIndividualEvents = () => {
  return (
    <>
      <ReportsEventsComponent />
    </>
  );
};

export default ReportsIndividualEvents;
