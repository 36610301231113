import { useQuery } from "@tanstack/react-query";
import UserFinder from "../../apis/UserFinder";

const fetchMainMember = ({ queryKey }) => {
  const currentUserSiteId = queryKey[1];
  return UserFinder.get(`/getmemberlist`, {
    params: {
      currentUserSiteId,
    },
  });
};

export const useMainMemberData = (currentUserSiteId) => {
  return useQuery(["mainSiteMembers", currentUserSiteId], fetchMainMember);
};
