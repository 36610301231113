import React, { useEffect, useState } from "react";
import Header from "../Header/Header";

import "./ProTrial.css";
import {
  FaClipboardList,
  FaUsers,
  FaExclamationCircle,
  FaCalendarAlt,
  FaBell,
  FaQuestionCircle,
  FaTasks,
  FaExclamationTriangle,
  FaWrench,
  FaDollarSign,
  FaCommentDots,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useMainHomePageData } from "./fetchHomePageData";
import { AiOutlineMenu } from "react-icons/ai";
import ToDoWidget from "./ToDoWidget";
import AlertWidget from "./AlertWidget";
import AlertWidget2 from "./AlertWidget copy";

const ProTrial = () => {
  const navigate = useNavigate();
  const {
    currentFirstName,
    currentLastName,
    currentUserId,
    currentUserSiteId,
  } = useAuth();
  const { data } = useMainHomePageData(currentUserSiteId,currentUserId);

  const [apparatusList, setApparatusList] = useState("");
  const [checkAmount, setAssetAmoutn] = useState("");
  const [alertAmount, setAlertAmount] = useState("");
  const [siteMembers, setSiteMembers] = useState("");
  const [personalItems, setPersonalItems] = useState({
    eventsAttended: 0,
    itemsOverdue: 0,
    itemsDueIn30Days: 0,
    assignedItems: 0,
  });
  const [inventoryItems, setInventoryItems] = useState("");
  const [needsInspectedCount, setNeedsInspectedCount] = useState(0);
  const [dueWithin30DaysCount, setDueWithin30DaysCount] = useState(0);
  const [expiredCount, setExpiredCount] = useState(0);
  const [todos, setTodos] = useState("");

  useEffect(() => {
    if (data) {
      setApparatusList(data?.data.data.apparatus || "");
      setAssetAmoutn(data?.data.data.assestsChecks || "");
      setAlertAmount(data?.data.data.alertAmount || "");
      setSiteMembers(data?.data.data.siteMembers || "");
      setInventoryItems(data?.data.data.inventoryItemsCount || "");

      setPersonalItems(data?.data.data.personalItems || {
        eventsAttended: data?.data.data.eventsAttended || 0,
        itemsOverdue: data?.data.data.userItemsExpiredCount || 0,
        itemsDueIn30Days: data?.data.data.userItems30DaysCount || 0,
        assignedItems: data?.data.data.userItems || 0
    });
      setNeedsInspectedCount(data?.data.data.needsInspectedCount || 0);
      setDueWithin30DaysCount(data?.data.data.dueWithin30DaysCount || 0);
      setExpiredCount(data?.data.data.expiredCount || 0);
      setTodos(data?.data.data.todoList || "");
      // console.log(data?.data.data.todoList)
    }
  }, [data]);

  const handleProfileClick = () => {
    navigate(`/userInfo/${currentUserId}`);
  };

  const handleOverdueClick = () => {
    localStorage.setItem("InventoryInspectionFilter", "Inspection is overdue");
    navigate("/inventory");
  };

  const handle30dayClick = () => {
    localStorage.setItem(
      "InventoryInspectionFilter",
      "Inspection due in 30 days"
    );
    navigate("/inventory");
  };

  
  const handlePersonalEventClick = () => {
    localStorage.setItem("EventMemberFilter", currentUserId);
    navigate("/events");

  }; 


  const handlePersonalInventoryClick = () => {
    //navigate(`/userInfo/${currentUserId}/items`);
    localStorage.setItem("InventoryMemberFilter", currentUserId);
    navigate("/inventory");
  }; 

  const handlePersonalInventory30dayClick = () => {
    //navigate(`/userInfo/${currentUserId}/items`);
    localStorage.setItem(
      "InventoryInspectionFilter",
      "Inspection due in 30 days"
    );
    localStorage.setItem("InventoryMemberFilter", currentUserId);
    navigate("/inventory");
  }; 

  const handlePersonalOverdueClick= () => {
    //navigate(`/userInfo/${currentUserId}/items`);
    localStorage.setItem("InventoryInspectionFilter", "Inspection is overdue");
    localStorage.setItem("InventoryMemberFilter", currentUserId);
    navigate("/inventory");
  }; 


  const toggleNav = () => {
    let topbar = document.getElementById("topbar");
    let sideBar = document.getElementById("sidebar");
    let appContainer = document.getElementById("appContainer");
    let appContainerHidden = document.getElementById("appContainerHidden");

    topbar.classList.toggle("collapse");
    sideBar.classList.toggle("collapse");
    appContainer.classList.toggle("collapse");
    appContainerHidden.classList.toggle("collapse");
  };

  return (
    <div className="pro-trial">
      <div id="topbar" className="topbar headerActive">
        <div className="hometopbarleft" onClick={toggleNav}>
          <AiOutlineMenu />
        </div>
        <Header content={<b>Dashboard</b>} />
      </div>

      <div className="trial-banner" >
   
      </div>
      <div className="main-content">



      <div className="personal-info-section">
      <h2>My Equipment & Activities</h2>
      <div className="stats-section">
          <div className="stat-box">
            <FaClipboardList className="icon" />
            <div className="stat-title">Assigned Items</div>
            <div className="stat-value">{personalItems.assignedItems} Items</div>
            <div
              onClick={handlePersonalInventoryClick}
              style={{
                cursor: "pointer",
                color: "blue",
                textDecoration: "underline",
              }}
            >
              View Inventory →
            </div>
          </div>

          <div className="stat-box">
            <FaCalendarAlt className="icon" />
            <div className="stat-title">Events Attended</div>
            <div className="stat-value">{personalItems.eventsAttended}</div>
            <div
              onClick={handlePersonalEventClick}
              style={{
                cursor: "pointer",
                color: "blue",
                textDecoration: "underline",
              }}
            >
              View Events →
            </div>
          </div>
          <div className="stat-box">
            <FaCalendarAlt className="icon" />
            <div className="stat-title">Items Due in 30 Days</div>
            <div className="stat-value">{personalItems.itemsDueIn30Days}</div>
            <div
              onClick={handlePersonalInventory30dayClick}
              style={{
                cursor: "pointer",
                color: "blue",
                textDecoration: "underline",
              }}
            >
              View Items →
            </div>
          </div>
          <div className="stat-box">
            <FaExclamationCircle className="icon" />
            <div className="stat-title">Personal Items Overdue</div>
            <div className="stat-value">{personalItems.itemsOverdue}</div>
            <div
              onClick={handlePersonalOverdueClick}
              style={{
                cursor: "pointer",
                color: "blue",
                textDecoration: "underline",
              }}
            >
              View Overdue Items →
            </div>
          </div>
     
        </div>

        </div>
        
        <div className="site-info-section">
      <h2>Site Overview</h2>
      <div className="stats-section">
          <div className="stat-box" onClick={handleProfileClick}>
            <FaUsers className="icon" />
            <div className="stat-title">Plan</div>
            <div className="stat-value">Pro</div>
          </div>
          <div className="stat-box">
            <FaUsers className="icon" />
            <div className="stat-title">Team</div>
            <div className="stat-value">{siteMembers} Members</div>
            <Link to="/sitesettings">View Team →</Link>
          </div>
          <div className="stat-box">
            <FaClipboardList className="icon" />
            <div className="stat-title">Inventory</div>
            <div className="stat-value">{inventoryItems} Items</div>
            <Link to="/inventory">View Inventory →</Link>
          </div>

          <div className="stat-box">
            <FaExclamationCircle className="icon" />
            <div className="stat-title">Items Due in 30 Days</div>
            <div className="stat-value">{dueWithin30DaysCount}</div>
            <div
              onClick={handle30dayClick}
              style={{
                cursor: "pointer",
                color: "blue",
                textDecoration: "underline",
              }}
            >
              View Items →
            </div>
          </div>

          <div className="stat-box">
            <FaExclamationCircle className="icon" />
            <div className="stat-title">Items Overdue</div>
            <div className="stat-value">{expiredCount}</div>
            <div
              onClick={handleOverdueClick}
              style={{
                cursor: "pointer",
                color: "blue",
                textDecoration: "underline",
              }}
            >
              View Overdue Items →
            </div>
          </div>
        </div>
        </div>
        <div className="additional-sections">

         {/* 
          <div className="alerts-box">
            <h2>Alerts</h2>
            <ul>
              <li>
                <FaExclamationTriangle className="alert-icon" /> Alert message 1
              </li>
              <li>
                <FaExclamationTriangle className="alert-icon" /> Alert message 2
              </li>
            </ul>
            <Link to="/view-reports">View All Alerts →</Link>
          </div>
        
          <AlertWidget />
          <AlertWidget2 />
            */}
          <ToDoWidget />

        </div>
       {/* <div className="notifications-panel">
          <h2>Notifications</h2>
          <ul>
            <li>
              <FaBell className="notification-icon" /> New item added to
              inventory
            </li>
            <li>
              <FaBell className="notification-icon" /> Team member John
              inspected item B
            </li>
          </ul>
            </div>*/}
        <div className="activity-feed">
          <h2>Recent Activity</h2>
          <ul>
            <li>
              <FaBell className="activity-icon" /> Not setup yet
            </li>
          
          </ul>
     
        </div>





      </div>
    </div>
  );
};

export default ProTrial;
