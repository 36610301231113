import React, { useEffect,useState } from "react";
import Popup from "reactjs-popup";
import {clearAllBodyScrollLocks } from "body-scroll-lock";
import useDetectKeyboardOpen from "use-detect-keyboard-open";

const PopupWeb = (props) => {
  //const targetElement = document.getElementById("body");
  // const targetElement2 = document.querySelector("#test");
  // eslint-disable-next-line 
  var targetElement2 = null;
  
  useEffect(() => {
    doSomethingOnMount();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    return () => {
      doSomethingOnUnmount();
    };
  }, []);

  const doSomethingOnMount = () => {
    // ... some logic to show target element
    targetElement2 = document.querySelector("popup-root");
    //enableBodyScroll(targetElement2);
  };

  const doSomethingOnUnmount = () => {
    clearAllBodyScrollLocks();
  };

  let rrrrr = window.matchMedia("only screen and (max-width: 1000px)").matches;

  const [isMobile, setIsMobile] = useState(rrrrr ? "True" : "False");

  const mediaQuery = window.matchMedia("(max-width: 1000px)");

  mediaQuery.addListener(handleTabletChange);
  function handleTabletChange(e) {
    if (e.matches) {
      setIsMobile("True");
    } else {
      setIsMobile("False");
    }
  }



  const isKeyboardOpen = useDetectKeyboardOpen();


  return (
    <>
    {isMobile === "True" ? 
    <>
     <div id="Pupup-main"     
 >
      <Popup
        defaultOpen
        position="right center"
        onClose={() => {
          props.handleClose();
        }}
        closeOnDocumentClick={false}
        className={isKeyboardOpen ? "keyboard mobile" : "nokeyboard mobile"}
      >
        <div
          className="closePopup"
          id="close2"
          onClick={() => {
            props.handleClose();
          }}
        >
          Close
        </div>

        <div className={isKeyboardOpen ? "keyboard" : "nokeyboard"}>
          {props.content}
        </div>
      </Popup>
    </div>
    </>
    : 
    <>
      <Popup
        defaultOpen
        className="web centered"
        position="right center"
        onClose={() => {
          props.handleClose();
        }}
        closeOnDocumentClick={true}
      ><div
      className="closePopup"
      id="close2"
      onClick={() => {
        props.handleClose();
      }}
    >
      Close
    </div>

          {props.content}
      </Popup>
    
    
    
    </>}
    </>
  );
};

export default PopupWeb;
