import React from "react";
import InventoryHeader from "../components/Inventory/InventoryHeader";
//import InventoryList from "../components/Inventory/InventoryList";
import { InventoryContextProvider } from "../context/InventoryContext";
import "../components/Inventory/Inventory.css";

const Inventory = () => {
  return (
    <InventoryContextProvider>
      <>
        <InventoryHeader />
      </>
    </InventoryContextProvider>
  );
};

export default Inventory;
