import React, { useContext, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import ApparatusFinder from "../../apis/ApparatusFinder";
import { ApparatusInventoryContext } from "../../context/ApparatusInventoryContext";
//import PopupNormal from "../Popup/PopupNormal";
import PopupWeb from "../Popup/PopupNew";

import { useParams } from "react-router-dom";
import Header from "../Header/Header";

import PublicFolder from "../../apis/PublicFolder";
//import { useAuth } from "../../context/AuthContext";
//import Scanner from "../Scanner/Scanner";

import { RiImageAddFill } from "react-icons/ri";
import { MdArrowBackIosNew } from "react-icons/md";

import ClipLoader from "react-spinners/ClipLoader";
import { useAuth } from "../../context/AuthContext";
import { AiOutlinePlus } from "react-icons/ai";

const ApparatusInventoryLocationAdd = () => {
  const { currentUserSiteId } = useAuth();

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const { id } = useParams();
  // const { currentUserId } = useAuth();

  const { apparatusLocation, setApparatusLocation, setApparatusInventory } =
    useContext(ApparatusInventoryContext);

  const { name } = useContext(ApparatusInventoryContext);

  const { setCompartments, compartments } = useContext(
    ApparatusInventoryContext
  );
  //reload page
  const history = useNavigate();

  //Input useState
  const [selectedCompartmentLocation, setSelectedCompartmentLocation] =
    useState("");
  const [selectedCompartment, setSelectedCompartment] = useState("");

  const [zoneOrder, setZoneOrder] = useState("");
  const [compartmentOrder, setCompartmentOrder] = useState("");

  const togglePopup22 = () => {
    setCompartmentOpen22(!compartmentOpen22);
  };

  //Submit new compartment
  const handleCompartmentSubmit = async (e) => {
    e.preventDefault();

    if (selectedCompartmentLocation === "") {
      setError(`Select a location`);
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      return false;
    }
    try {
      setLoading(true);
      const formData = new FormData();
      // // console.log("handleFileInput working!");

      formData.set("selectedCompartment", selectedCompartment);
      formData.set("selectedCompartmentLocation", selectedCompartmentLocation);
      formData.set("currentUserSiteId", currentUserSiteId);
      formData.set("compartmentOrder", compartmentOrder);

      formData.set("id", id);

      if (file) {
        formData.append("file", file);
      }
      // eslint-disable-next-line
      const response = await ApparatusFinder.post(
        "/addCompartment",
        formData,
        headers
      );
      setLoading(false);

      togglePopup22();
      //// console.log(response.data.data);
      setApparatusInventory(response.data.data.apparatusInventory);
      setCompartments(response.data.data.compartments);
    } catch (err) {
      setLoading(false);
    }
  };

  // console.log(apparatusLocation);
  //edit compartment
  const handleEditCompartmentSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    // // console.log();
    try {
      setLoading(true);

      const formData = new FormData();

      formData.set("selectedCompartment", selectedCompartment);
      formData.set("selectedCompartmentId", selectedCompartmentId);
      formData.set("selectedApparatusLocationId", selectedApparatusLocationId);
      formData.set("oldImgPath", oldImgPathLocation);
      formData.set("id", id);
      formData.set("currentUserSiteId", currentUserSiteId);
      formData.set("compartmentOrder", compartmentOrder);

      if (file) {
        formData.append("file", file);
      }

      const response = await ApparatusFinder.post(
        `/editCompartment`,
        formData,
        headers
      );
      setLoading(false);
      setSelectedImage("");
      setFile(null);
      setApparatusInventory(response.data.data.apparatusInventory);
      setCompartments(response.data.data.compartments);
      togglePopup5();
    } catch (err) {
      setLoading(false);
    }
  };

  const [selectedApparatusLocation, setSelectedApparatusLocation] =
    useState("");

  //Submit new location
  const handleAparatusLocationSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {
      setLoading(true);

      const formData = new FormData();
      //  // console.log("handleFileInput working!");

      formData.set("selectedApparatusLocation", selectedApparatusLocation);
      formData.set("id", id);
      formData.set("currentUserSiteId", currentUserSiteId);
      formData.set("zoneOrder", zoneOrder);

      if (file) {
        formData.append("file", file);
      }

      const response = await ApparatusFinder.post(
        "/addLocation",
        formData,
        headers
      );
      setLoading(false);

      setApparatusLocation(response.data.data.apparatusLocation);
      setFile(null);
      setSelectedImage("");
      togglePopupLocation22();
    } catch (err) {
      setLoading(false);
    }
  };

  const [file, setFile] = useState(null);

  const headers = {
    "content-type": "multipart/form-data",
  };
  const handleFileInput = (e) => {
    setFile(e.target.files[0]);
    setSelectedImage(e.target.files[0]);
  };

  //
  //
  //
  //Popup
  const [isOpen11, setIsOpen11] = useState(false);
  const togglePopup11 = () => {
    setIsOpen11(!isOpen11);
  };

  const [selectedCompartmentId, setSelectedCompartmentId] = useState("");

  const [compartmentListOpen, setCompartmentListOpen] = useState(false);

  const togglePopupCompartmentList = () => {
    setCompartmentListOpen(!compartmentListOpen);
    setIsOpen11(!isOpen11);
  };

  const [isOpen5, setIsOpen5] = useState(false);
  const togglePopup5 = () => {
    setIsOpen5(!isOpen5);
    setCompartmentListOpen(!compartmentListOpen);
    setSelectedImage("");
    setFile(null);
  };

  //compartment Delete
  //Popup
  const [isOpen6, setIsOpen6] = useState(false);
  const togglePopup6 = () => {
    setIsOpen5(!isOpen5);
    setIsOpen6(!isOpen6);
  };
  const togglePopup66 = () => {
    setIsOpen6(!isOpen6);
    setCompartmentListOpen(!compartmentListOpen);
  };

  //Create new compartment Popup
  const [compartmentOpen22, setCompartmentOpen22] = useState(false);

  const togglePopup222 = () => {
    setCompartmentOpen22(!compartmentOpen22);
    setCompartmentListOpen(!compartmentListOpen);
    setSelectedCompartmentLocation("");
    setFile(null);
    setSelectedImage("");
    setCompartmentOrder("");
    setError("");
    setLoading(false);
  };

  //
  const [locationOpen, setLocationOpen] = useState(false);

  const togglePopupLocation = () => {
    setLocationOpen(!locationOpen);
    setIsOpen11(!isOpen11);
  };

  //
  const [locationOpen2, setLocationOpen2] = useState(false);

  const togglePopupLocation2 = () => {
    setLocationOpen2(!locationOpen2);
    setLocationOpen(!locationOpen);
    setFile(null);
    setSelectedImage("");
    setZoneOrder("");
    setSelectedApparatusLocation("");
  };
  const togglePopupLocation22 = () => {
    setLocationOpen2(false);
    setLocationOpen(!locationOpen);
  };

  //Compartment Edit
  //Input useState
  const [selectedApparatusLocationId, setSelectedApparatusLocationId] =
    useState("");
  const [selectedApparatusLocationName, setSelectedApparatusLocationName] =
    useState("");
  const [oldImgPathLocation, setOldImgPathLocation] = useState("");

  const editApparatusLocation = async (
    apparatuslocationid,
    locationname,
    displayorder,
    image
  ) => {
    // console.log(displayorder);
    setSelectedApparatusLocationId(apparatuslocationid);
    setSelectedApparatusLocationName(locationname);
    setZoneOrder(displayorder);
    setOldImgPathLocation(image);
    togglePopupLocationEdit();
  };

  const editApparatusCompartment = async (
    cid,
    name,
    image,
    locationname,
    displayorder,
    apparatuslocationid
  ) => {
    setSelectedCompartmentId(cid);
    setSelectedCompartment(name);
    setOldImgPathLocation(image);
    setSelectedApparatusLocationName(locationname);
    setCompartmentOrder(displayorder);
    setSelectedApparatusLocationId(apparatuslocationid);
    togglePopup5();
  };
  //Create
  const [locationEditOpen, setLocationEditOpen] = useState(false);

  const togglePopupLocationEdit = () => {
    setLocationEditOpen(!locationEditOpen);
    setLocationOpen(!locationOpen);
  };

  const togglePopupLocationEditClose = () => {
    setLocationEditOpen(!locationEditOpen);
    setLocationOpen(!locationOpen);
    setFile("");
    setSelectedImage("");
  };

  const togglePopupLocationEdit22 = () => {
    setLocationEditOpen(false);
    setLocationOpen(!locationOpen);
  };

  //edit compartment
  const handleAparatusLocationEditSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {
      setLoading(true);

      const formData = new FormData();
      //   // console.log("handleFileInput working!");

      formData.set("selectedCompartment", selectedApparatusLocationName);
      formData.set("selectedApparatusLocationId", selectedApparatusLocationId);
      formData.set("oldImgPath", oldImgPathLocation);
      formData.set("id", id);
      formData.set("currentUserSiteId", currentUserSiteId);
      formData.set("zoneOrder", zoneOrder);
      if (file) {
        formData.append("file", file);
      }
      // eslint-disable-next-line
      const response = await ApparatusFinder.post(
        `/editLocation`,
        formData,
        headers
      );
      setLoading(false);

      setApparatusLocation(response.data.data.apparatusLocation);
      setApparatusInventory(response.data.data.apparatusInventory);
      setFile("");
      setSelectedImage("");
      togglePopupLocationEdit22();
    } catch (err) {
      setLoading(false);
    }
  };

  //Popup
  const [isOpen2, setIsOpen2] = useState(false);
  const togglePopup2 = () => {
    setIsOpen2(!isOpen2);
    setLocationEditOpen(!locationEditOpen);
    setFile(null);
    setSelectedImage("");
  };
  const togglePopup2222 = () => {
    setIsOpen2(!isOpen2);
    setLocationOpen(!locationOpen);
  };

  //Delete apparatus

  const handleDelete = async (e) => {
    //  // console.log("ldfmsfk");
    //stop the page from reloading
    e.preventDefault();

    try {
      setLoading(true);

      const response = await ApparatusFinder.post(`/deleteLocation`, {
        selectedApparatusLocationId,
        oldImgPath: oldImgPathLocation,
        id: id,
        currentUserSiteId,
      });
      setLoading(false);

      setApparatusLocation(response.data.data.apparatusLocation);

      togglePopup2222();
    } catch (err) {
      setLoading(false);
    }
  };

  const handleDelete2 = async (e) => {
    //// console.log("ldfmsfk");
    //stop the page from reloading
    e.preventDefault();
    setLoading(true);

    try {
      const response = await ApparatusFinder.post(`/deleteCompartment`, {
        selectedCompartmentId,
        oldImgPath: oldImgPathLocation,
        id: id,
        currentUserSiteId,
      });
      setLoading(false);

      setApparatusInventory(response.data.data.apparatusInventory);
      setCompartments(response.data.data.compartments);
      togglePopup66();
    } catch (err) {
      setLoading(false);
    }
  };

  //custom image
  const fileRef = useRef();
  const [selectedImage, setSelectedImage] = useState("");

  return (
    <>
      <div
        id="topbar"
        className="topbar headerActive"
        /*
        className={
          sideBar === null
            ? "topbar headerActive"
            : sideBar.clientWidth === 80
            ? "topbar headerActive collapse"
            : "topbar headerActive"
        }*/
      >
        {/*<Scanner />*/}

        <div className="goBackDiv" onClick={() => history(-1)}>
          <div className="goBackDivarrow">
            <MdArrowBackIosNew />
          </div>
          <div className="goBackDivtxt">Locations</div>
        </div>

        <div className="addItemReact" onClick={togglePopup11}>
          <AiOutlinePlus size={28} />
        </div>
        <Header content={<b>{name}</b>} />
      </div>

      {isOpen11 && (
        <PopupWeb
          content={
            <>
            
              <div className="popupBtnContainers">
                <div className="btnDiv">
                  <div className="addMemberBtn" onClick={togglePopupLocation}>
                    <b> Add / Edit Zone</b>
                  </div>
                </div>
                <div className="btnDiv">
                  <div
                    className="addMemberBtn"
                    onClick={togglePopupCompartmentList}
                  >
                    <b> Add / Edit Compartment</b>
                  </div>
                </div>
              </div>
            </>
          }
          handleClose={togglePopup11}

          isOpen={isOpen11}
          showSubmitButton={false}
          headerText={  <b>Edit</b>}
          loading={loading}
        />
      )}

      {compartmentListOpen && (
        <PopupWeb
          content={
            <>
        
              <div className="popupContainer">
                {compartments &&
                  compartments.map((data, i) => (
                    <div
                      key={i}
                      className="apparatusInventoryHolder"
                      onClick={() =>
                        editApparatusCompartment(
                          data.cid,
                          data.name,
                          data.imagepath,
                          data.locationname,
                          data.displayorder,
                          data.compartmentlocid
                        )
                      }
                    >
                      {data.imagepath ? (
                        <div className="apparatusInventoryImgDiv">
                          <img
                            src={PublicFolder.baseURL + data.imagepath}
                            alt="fix later"
                            className="apparatusInventoryImg"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="apparatusInventoryItemName">
                        {data.name}
                      </div>
                    </div>
                  ))}
              </div>
            </>
          }
          handleClose={togglePopupCompartmentList}

          isOpen={compartmentListOpen}
          handleSubmit={togglePopup222}
          showSubmitButton={true}
          headerText={    <b>Select Compartment</b>}
          submitButtonText={loading ? "Loading..." : "New"} 
          loading={loading}

        />
      )}

      {isOpen5 && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleEditCompartmentSubmit}>
              
                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>
                <div className="popupContainer">
                  <div className="bagQRDiv">
                    <div className="addMemberBtn">
                      <div className="btnDiv"> QR Code</div>
                      https://alleganyfireinventory.com/compartment?compartmentId:
                      {selectedCompartmentId}
                    </div>
                  </div>

                  <label className="NewInputLabel" data-domain="Name">
                    <input
                      className="NewInput"
                      type="text"
                      placeholder=""
                      onChange={(e) => {
                        setSelectedCompartment(e.target.value);
                      }}
                      required
                      value={selectedCompartment}
                    />
                  </label>

                  <label className="NewInputLabel" data-domain="Type">
                    <select
                      className="NewInput"
                      onChange={(e) =>
                        setSelectedApparatusLocationId(e.target.value)
                      }
                      required
                      defaultValue={selectedApparatusLocationId}
                    >
                      <option value="" disabled>
                        Select Zone
                      </option>

                      {apparatusLocation.map(function (e, i) {
                        return (
                          <option key={i} value={e.apparatuslocationid}>
                            {e.locationname}
                          </option>
                        );
                      })}
                    </select>
                  </label>

                  <label className="NewInputLabel" data-domain="Display Order">
                    <input
                      className="NewInput"
                      type="number"
                      placeholder=""
                      value={compartmentOrder === null ? "" : compartmentOrder}
                      onChange={(e) => setCompartmentOrder(e.target.value)}
                    />
                  </label>

                  <div className="NewInputLabel" data-domain="Add Image">
                    <input
                      ref={fileRef}
                      onChange={handleFileInput}
                      multiple={false}
                      type="file"
                      accept="image/*"
                      hidden
                    />
                    <div
                      onClick={() => fileRef.current.click()}
                      className="NewInputImage"
                    >
                      <RiImageAddFill size={30} />
                      {selectedImage ? (
                        <div className="addImageHolder">
                          <img
                            src={URL.createObjectURL(selectedImage)}
                            alt="imageDidNotLoad"
                            className="addImage"
                          />
                        </div>
                      ) : (
                        oldImgPathLocation && (
                          <div className="addImageHolder">
                            <img
                              src={
                                PublicFolder.baseURL +
                                "preview_" +
                                oldImgPathLocation
                              }
                              alt="imageDidNotLoad"
                              className="addImage"
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>

                  <div className="editApparatusCompartmentBtnContainer">
                    <div className="editApparatusBtn" onClick={togglePopup6}>
                      <b>Delete</b>
                    </div>
                  </div>
                </div>
              </form>
            </>
          }
          handleClose={togglePopup5}

          isOpen={isOpen5}
          handleSubmit={handleEditCompartmentSubmit}
          showSubmitButton={true}
          headerText={  <b>Edit Compartment</b>}
          submitButtonText={loading ? "Loading..." : "Save"} 
          loading={loading}
        />
      )}

      {compartmentOpen22 && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleCompartmentSubmit}>
              
                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>
                <div className="popupContainer">
                  <div className="errorDiv">
                    {error && <div className="alert2">{error}</div>}
                  </div>

                  <label className="NewInputLabel" data-domain="Name">
                    <input
                      className="NewInput"
                      type="text"
                      placeholder=""
                      onChange={(e) => setSelectedCompartment(e.target.value)}
                      required
                    />
                  </label>

                  <label className="NewInputLabel" data-domain="Zone">
                    <select
                      className="NewInput"
                      onChange={(event) =>
                        setSelectedCompartmentLocation(event.target.value)
                      }
                      defaultValue={""}
                      required
                    >
                      <option value="" disabled>
                        Select Zone
                      </option>

                      {apparatusLocation.map(function (e, i) {
                        return (
                          <option key={i} value={e.apparatuslocationid}>
                            {e.locationname}
                          </option>
                        );
                      })}
                    </select>
                  </label>

                  <label className="NewInputLabel" data-domain="Display Order">
                    <input
                      className="NewInput"
                      type="number"
                      placeholder=""
                      value={compartmentOrder === null ? "" : compartmentOrder}
                      onChange={(e) => setCompartmentOrder(e.target.value)}
                    />
                  </label>

                  <div className="NewInputLabel" data-domain="Add Image">
                    <input
                      ref={fileRef}
                      onChange={handleFileInput}
                      multiple={false}
                      type="file"
                      accept="image/*"
                      hidden
                    />
                    <div
                      onClick={() => fileRef.current.click()}
                      className="NewInputImage"
                    >
                      <RiImageAddFill size={30} />
                      {selectedImage && (
                        <div className="addImageHolder">
                          <img
                            src={URL.createObjectURL(selectedImage)}
                            alt="imageDidNotLoad"
                            className="addImage"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </>
          }
          handleClose={togglePopup222}

          isOpen={compartmentOpen22}
          handleSubmit={handleCompartmentSubmit}
          showSubmitButton={true}
          headerText={ <b>New Compartment</b>}
          submitButtonText={loading ? "Loading..." : "Done"} 
          loading={loading}
        />
      )}

      {locationOpen && (
        <PopupWeb
          content={
            <>
            
              <div className="popupContainer">
                {apparatusLocation &&
                  apparatusLocation.map((data, i) => (
                    <div
                      key={i}
                      className="apparatusInventoryHolder"
                      onClick={() =>
                        editApparatusLocation(
                          data.apparatuslocationid,
                          data.locationname,
                          data.displayorder,
                          data.image
                        )
                      }
                    >
                      {data.image ? (
                        <div className="apparatusInventoryImgDiv">
                          <img
                            src={PublicFolder.baseURL + data.image}
                            alt="fix later"
                            className="apparatusInventoryImg"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="apparatusInventoryItemName">
                        {data.locationname}
                      </div>
                    </div>
                  ))}
              </div>
            </>
          }
          handleClose={togglePopupLocation}

          isOpen={locationOpen}
          handleSubmit={togglePopupLocation2}
          showSubmitButton={true}
          headerText={   <b>Select Zone</b>}
          submitButtonText={loading ? "Loading..." : "New"} 
          loading={loading}
        />
      )}

      {locationOpen2 && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleAparatusLocationSubmit}>
             
                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>
                <div className="popupContainer">
                  <label className="NewInputLabel" data-domain=" Name">
                    <input
                      className="NewInput"
                      type="text"
                      placeholder=""
                      onChange={(e) =>
                        setSelectedApparatusLocation(e.target.value)
                      }
                      required
                    />
                  </label>

                  <label className="NewInputLabel" data-domain="Display Order">
                    <input
                      className="NewInput"
                      type="number"
                      placeholder=""
                      value={zoneOrder === null ? "" : zoneOrder}
                      onChange={(e) => setZoneOrder(e.target.value)}
                    />
                  </label>

                  <div className="NewInputLabel" data-domain="Add Image">
                    <input
                      ref={fileRef}
                      onChange={handleFileInput}
                      multiple={false}
                      type="file"
                      accept="image/*"
                      hidden
                    />
                    <div
                      onClick={() => fileRef.current.click()}
                      className="NewInputImage"
                    >
                      <RiImageAddFill size={30} />
                      {selectedImage && (
                        <div className="addImageHolder">
                          <img
                            src={URL.createObjectURL(selectedImage)}
                            alt="imageDidNotLoad"
                            className="addImage"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </>
          }
          handleClose={togglePopupLocation2}

          isOpen={locationOpen2}
          handleSubmit={handleAparatusLocationSubmit}
          showSubmitButton={true}
          headerText={  <b>New Zone</b>}
          submitButtonText={loading ? "Loading..." : "Done"} 
          loading={loading}
        />
      )}

      {locationEditOpen && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleAparatusLocationEditSubmit}>
            
                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>

                <div className="popupContainer">
                  <label className="NewInputLabel" data-domain="Name">
                    <input
                      className="NewInput"
                      type="text"
                      placeholder=""
                      onChange={(e) => {
                        setSelectedApparatusLocationName(e.target.value);
                      }}
                      value={selectedApparatusLocationName}
                      required
                    />
                  </label>

                  <label className="NewInputLabel" data-domain="Display Order">
                    <input
                      className="NewInput"
                      type="number"
                      placeholder=""
                      value={zoneOrder === null ? "" : zoneOrder}
                      onChange={(e) => setZoneOrder(e.target.value)}
                    />
                  </label>

                  <div className="NewInputLabel" data-domain="Add Image">
                    <input
                      ref={fileRef}
                      onChange={handleFileInput}
                      multiple={false}
                      type="file"
                      accept="image/*"
                      hidden
                    />
                    <div
                      onClick={() => fileRef.current.click()}
                      className="NewInputImage"
                    >
                      <RiImageAddFill size={30} />
                      {selectedImage ? (
                        <div className="addImageHolder">
                          <img
                            src={URL.createObjectURL(selectedImage)}
                            alt="imageDidNotLoad"
                            className="addImage"
                          />
                        </div>
                      ) : (
                        oldImgPathLocation && (
                          <div className="addImageHolder">
                            <img
                              src={
                                PublicFolder.baseURL +
                                "preview_" +
                                oldImgPathLocation
                              }
                              alt="imageDidNotLoad"
                              className="addImage"
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                  <div className="editApparatusCompartmentBtnContainer">
                    <div className="editApparatusBtn" onClick={togglePopup2}>
                      <b>Delete</b>
                    </div>
                  </div>
                </div>
              </form>
            </>
          }
          handleClose={togglePopupLocationEditClose}

          isOpen={locationEditOpen}
          handleSubmit={handleAparatusLocationEditSubmit}
          showSubmitButton={true}
          headerText={   <b>Edit Zone</b>}
          submitButtonText={loading ? "Loading..." : "Done"} 
          loading={loading}
        />
      )}

      {isOpen2 && (
        <PopupWeb
          content={
            <>
         

              <div className="deleteInfoApparatusContainer">
                <b>This zone will no longer be available</b>
              </div>

              <div className="deleteInfoApparatusContainer">
                <b>THIS WILL ONLY DELETE THE ZONE </b>
              </div>

              <div className="editApparatusBtnContainer">
                <div
                  className="confirmCancelApparatusBtn"
                  onClick={togglePopup2}
                >
                  <b>Cancel</b>
                </div>
                <div
                  className="confirmDeleteApparatusBtn"
                  onClick={handleDelete}
                  //onClick={togglePopup2}
                >
                  <b>Delete</b>
                </div>
              </div>
            </>
          }
          handleClose={togglePopup2222}
          isOpen={isOpen2}
          handleSubmit={handleDelete}
          showSubmitButton={true}
          headerText={ <b>Delete - {selectedApparatusLocationName}</b>}
          submitButtonText={loading ? "Loading..." : "Delete"} 
          loading={loading}
        />
      )}

      {isOpen6 && (
        <PopupWeb
          content={
            <>
              <div className="deleteInfoApparatusContainer">
                <b>This compartment will no longer be available</b>
              </div>

              <div className="deleteInfoApparatusContainer">
                <b>THIS WILL ONLY DELETE THE COMPARTMENT </b>
              </div>

              <div className="editApparatusBtnContainer">
                <div
                  className="confirmCancelApparatusBtn"
                  onClick={togglePopup6}
                >
                  <b>Cancel</b>
                </div>
                <div
                  className="confirmDeleteApparatusBtn"
                  onClick={handleDelete2}
                  //onClick={togglePopup2}
                >
                  <b>Delete</b>
                </div>
              </div>
            </>
          }
          handleClose={togglePopup66}

          isOpen={isOpen6}
          handleSubmit={handleDelete2}
          showSubmitButton={true}
          headerText={  <b>Delete - {selectedCompartment}</b>}
          submitButtonText={loading ? "Loading..." : "Delete"} 
          loading={loading}
        />
      )}
    </>
  );
};

export default ApparatusInventoryLocationAdd;
