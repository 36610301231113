import React, { useContext, useState, useEffect } from "react";
import { auth } from "../firebase";
import {
  signOut,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  updateEmail,
  updatePassword,
  confirmPasswordReset,
} from "firebase/auth";

import UserFinder from "../apis/UserFinder";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}
export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [currentUserName, setCurrentUserName] = useState();
  const [currentFirstName, setCurrentFirstName] = useState();
  const [currentLastName, setCurrentLastName] = useState();
  const [currentUserEmail, setCurrentUserEmail] = useState();
  const [currentUserId, setCurrentUserId] = useState();
  const [currentUserRole, setCurrentUserRole] = useState();
  const [changePassword, setChangePassword] = useState("false");
  const [currentUserPhone, setCurrentUserPhone] = useState();

  const [currentUserSiteId, setCurrentUserSiteId] = useState();
  const [currentUserSiteName, setCurrentUserSiteName] = useState();

  const [loading, setLoading] = useState(true);

  function signup(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }

  const login = async (email, password) => {
    await signInWithEmailAndPassword(auth, email, password);
    //.then((userCredential) => {
    // Signed in
    //   return getUserData();
    //   console.log(userCredential);
    //const user = userCredential.user;
    // ...
    //  })
    //   .catch((error) => {
    //    console.log(error);
    //     const errorCode = error.code;
    //  const errorMessage = error.message;
    //  });
  };

  function logout() {
    return signOut(auth);
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email, {
      //url: `${process.env.site_Url}/login`,
      url: `https://alleganyfireinventory.com/login`,
    });
  }

  function resetPasswordAny(oobcode, newPassword) {
    return confirmPasswordReset(auth, oobcode, newPassword);
  }

  function updateUserEmail(uid) {
    return updateEmail(auth, uid);
  }
  function updateUserPassword(password) {
    return updatePassword(currentUser, password);
  }

  const getUserData = async (uid) => {
    try {
      const response = await UserFinder.get("/auth", {
        params: {
          userUID: uid,
        },
      });
      // console.log(response.data.data.role[0]);

      setCurrentUser(response.data.data.user[0].user_id);

      // setCurrentUser(response.data.data.user[0].user_id);

      setCurrentLastName(response.data.data.user[0].lastname);
      setCurrentFirstName(response.data.data.user[0].firstname);
      setCurrentUserName(response.data.data.user[0].username);
      setCurrentUserId(response.data.data.user[0].user_id);
      setCurrentUserEmail(response.data.data.user[0].email);
      setCurrentUserSiteId(response.data.data.user[0].site_id);
      setCurrentUserRole(response.data.data.role[0].role);
      setCurrentUserSiteName(response.data.data.user[0].sitename);
      setCurrentUserPhone(response.data.data.user[0].phone);
    } catch (err) {
      // console.log(err);
    }
  };

  const updateUserData = async (currentUserId) => {
    //  console.log("fsaddfsadfsadfs");

    try {
      const response = await UserFinder.get("/authUpdate", {
        params: {
          userUID: currentUserId,
        },
      });
      // setCurrentUser(response.data.data.user[0].user_id);

      setCurrentLastName(response.data.data.user[0].lastname);
      setCurrentFirstName(response.data.data.user[0].firstname);
      setCurrentUserName(response.data.data.user[0].username);
      setCurrentUserId(response.data.data.user[0].user_id);
      setCurrentUserEmail(response.data.data.user[0].email);
      setChangePassword(response.data.data.user[0].changepass);
      setCurrentUserSiteId(response.data.data.user[0].site_id);
      setCurrentUserRole(response.data.data.role[0].role);
      setCurrentUserSiteName(response.data.data.user[0].sitename);
      setCurrentUserPhone(response.data.data.user[0].phone);
    } catch (err) {
      // console.log(err);
    }
  };

  const updateUserSiteData = async (currentUserId) => {
    //  console.log("fsaddfsadfsadfs");

    try {
      const response = await UserFinder.get("/authUpdate", {
        params: {
          userUID: currentUserId,
        },
      });
      // setCurrentUser(response.data.data.user[0].user_id);

      setCurrentLastName(response.data.data.user[0].lastname);
      setCurrentFirstName(response.data.data.user[0].firstname);
      setCurrentUserName(response.data.data.user[0].username);
      setCurrentUserId(response.data.data.user[0].user_id);
      setCurrentUserEmail(response.data.data.user[0].email);
      setChangePassword(response.data.data.user[0].changepass);
      setCurrentUserSiteId(response.data.data.user[0].site_id);
      setCurrentUserRole(response.data.data.role[0].role);
      setCurrentUserSiteName(response.data.data.user[0].sitename);
      setCurrentUserPhone(response.data.data.user[0].phone);
    } catch (err) {
      //  console.log(err);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        getUserData(user.uid);
        setCurrentUser(user.uid);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    auth,
    currentUser,
    currentUserName,
    currentUserId,
    currentUserEmail,
    currentUserRole,
    changePassword,
    currentLastName,
    currentFirstName,
    currentUserPhone,
    login,
    signup,
    logout,
    resetPassword,
    updateUserEmail,
    updateUserPassword,
    setChangePassword,
    resetPasswordAny,
    currentUserSiteId,
    currentUserSiteName,
    setCurrentUserSiteId,
    updateUserData,
    updateUserSiteData,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
