import React, { useEffect, useState } from "react";
import "./PopupNew.css";

const PopupNew = ({ isOpen, handleClose, content, handleSubmit, showSubmitButton, headerText, submitButtonText, loading }) => {
    const [isMobile, setIsMobile] = useState(window.matchMedia("(max-width: 1500px)").matches);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.matchMedia("(max-width: 1500px)").matches);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const container = document.getElementById('container');
        const appContainer = document.getElementById('appContainer');

        if (isOpen) {
            document.body.classList.add('no-scroll');
            container.classList.add('no-scroll');
            appContainer.classList.add('no-scroll');

        } else {
            document.body.classList.remove('no-scroll');
            container.classList.remove('no-scroll');
            appContainer.classList.remove('no-scroll');

        }

        return () => {
            document.body.classList.remove('no-scroll');
            container.classList.remove('no-scroll');
            appContainer.classList.remove('no-scroll');

        };
    }, [isOpen]);

    if (!isOpen) return null;

    return (
        <>
            <div className="modal-overlay" onClick={handleClose}>
                <div className={`modal ${isMobile ? 'modal-mobile' : 'modal-desktop'}`} onClick={e => e.stopPropagation()}>
                    <button className="close-button" onClick={handleClose}>&times;</button>
                    <div className="modal-header">
                        <h2>{headerText}</h2>
                    </div>
                    <div className="modal-body-wrapper">
                        <div className="modal-body">
                            {content}
                        </div>
                    </div>
                    {showSubmitButton && (
                        <div className="modal-footer">
                            <button className="cancel-button"type="submit"  onClick={handleClose}>Cancel</button>
                            <button className="submit-button" type="submit" onClick={handleSubmit} disabled={loading}>
                                {submitButtonText}
                            </button>
                        </div>
                    )}
                </div>
            </div>

        </>
    );
};

export default PopupNew;
