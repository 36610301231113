import React, { useEffect, useState } from "react";
import UserFinder from "../../../apis/UserFinder";
import { useParams } from "react-router-dom";
import Header from "../../Header/Header";

//import PopupNormal from "../../Popup/PopupNormal";
import PopupOverflowHidden from "../../Popup/PopupOverflowHidden";

import { useNavigate } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";

import Select from "react-select";

import { IoMdArrowRoundBack } from "react-icons/io";
import { useAuth } from "../../../context/AuthContext";

import jsPDF from "jspdf";
import PDFObject from "pdfobject";
import "jspdf-autotable";
import PublicFolder from "../../../apis/PublicFolder";

const IndividualTraining = () => {
  const { currentUserRole, currentUserSiteId } = useAuth();

  const { id } = useParams();

  const [loading, setLoading] = useState(false);

  //reload page
  const history = useNavigate();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [eventInfo, setEventInfo] = useState([]);
  const [addMember, setAddMember] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [yearList, setYearList] = useState([]);

  ///
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await UserFinder.get(`/getIndividualTraining`, {
          params: {
            userid: id,
            currentUserSiteId,
          },
        });

        setEventInfo(response.data.data.trainingInfo);
        setFirstName(response.data.data.userInfo[0].firstname);
        setLastName(response.data.data.userInfo[0].lastname);
        setMemberList(response.data.data.memberNames);
        setCategoryList(response.data.data.category);
        setYearList(response.data.data.yearList);
      } catch (err) {
        //  console.log(err);
      }
    };

    if (currentUserSiteId) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [currentUserSiteId]);

  const [dropdownQuery, setDropdownQuery] = useState("");
  const [dropdownQueryYear, setDropdownQueryYear] = useState("");

  let loopingGroup = null;

  const [trainingId, setTrainingId] = useState("");
  const [dateHolder, setDateHolder] = useState("");

  const [trainingName, setTrainingName] = useState("");
  const [trainingDesc, setTrainingDesc] = useState("");
  const [trainer, setTrainer] = useState("");

  const [otherMembers, setOtherMembers] = useState("");

  const [eventType1, setEventType1] = useState("");
  const [eventType2, setEventType2] = useState("");

  const [newDate, setNewDate] = useState();
  const [memberList, setMemberList] = useState([]);

  let options = memberList.map(function (data) {
    return {
      value: data.username,
      label: data.firstname + " " + data.lastname,
      id: data.user_id,
    };
  });

  //Popup
  const [isOpen1, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen1);
    setTrainingName("");
    setTrainingDesc("");
    setTrainer("");
    setEventType1("");
    setEventType2("");
    setNewDate();
  };

  const handleNewTrainingSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {
      setLoading(true);
      // // console.log(eventType);

      const response = await UserFinder.post("/getIndividualTraining", {
        training_name: trainingName,
        training_desc: trainingDesc,
        trainer: trainer,
        event_type: eventType1,
        date: newDate,
        addMember: addMember,
        firstName: firstName,
        lastName: lastName,
        user_id: id,
        currentUserSiteId,
      });

      if (response.data.data.alert) {
        alert(response.data.data.alert);
        return;
      }
      setLoading(false);

      setTrainingName("");
      setTrainingDesc("");
      setTrainer("");
      setEventType1("");
      setEventType2("");
      setNewDate();

   //   setEventType(response.data.data.eventTypeResults);
      setEventInfo(response.data.data.trainingInfo);

      togglePopup();

    } catch (err) {
      setLoading(false);
    }
  };

  const handleTraining = async (individualtrainingid, training_id) => {
    try {
      // // console.log(individualtrainingid, training_id);
      if (individualtrainingid === null) {
        history(`/trainingInfo/${training_id}`);
      }

      const response = await UserFinder.get("/getSelectedIndividualTraining", {
        params: {
          individualtrainingid: individualtrainingid,
          user_id: id,
          currentUserSiteId,
        },
      });
      setTrainingId(individualtrainingid);
      setTrainingName(response.data.data.trainingInfo[0].training_name);
      setTrainingDesc(response.data.data.trainingInfo[0].description);
      setTrainer(response.data.data.trainingtrainer2[0].trainer);

      //console.log(response.data.data.trainingInfo[0]);
      //  // console.log(response.data.data.trainingtrainer2[0].trainer);
      //  // console.log(response.data.data.trainingtrainer2[0].trainer);
      setEventType1(response.data.data.trainingInfo[0].categoryid);
      setEventType2(response.data.data.trainingInfo[0].eventtype);
      setDateHolder(response.data.data.trainingInfo[0].date3);
      setOtherMembers(response.data.data.trainingMembers);

      setNewDate(
        response.data.data.trainingInfo[0].date === null
          ? ""
          : new Date(response.data.data.trainingInfo[0].date3)
              .toISOString()
              .substring(0, 10)
      );
      togglePopup2();
    } catch (err) {
      // console.log(err);
    }
  };

  //Popup
  const [isOpen2, setIsOpen2] = useState(false);
  const togglePopup2 = () => {
    setIsOpen2(!isOpen2);
  };

  //Popup
  const [isOpen3, setIsOpen3] = useState(false);
  const togglePopup3 = () => {
    setIsOpen3(!isOpen3);
    setIsOpen2(!isOpen2);
  };
  const togglePopup33 = () => {
    setIsOpen3(!isOpen3);
  };

  const setTrainingNote = (e) => {
    setTrainingDesc(e.target.value);
    e.target.style.height = "auto";
    e.target.style.height = e.target.scrollHeight + "px";
  };

  const handleUpdateTrainingSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {
      setLoading(true);
      // // console.log(eventType);
      //eslint-disable-next-line
      const response = await UserFinder.post("/updateIndividualTraining", {
        training_name: trainingName,
        training_desc: trainingDesc,
        trainer: trainer,
        event_type: eventType1,
        date: newDate,
        user_id: id,
        firstName: firstName,
        lastName: lastName,
        trainingId: trainingId,
        addMember: addMember,
        currentUserSiteId,
      });

      if (response.data.data.alert) {
        alert(response.data.data.alert);
        return;
      }
      setLoading(false);

      setTrainingName("");
      setTrainingDesc("");
      setEventType1("");
      setEventType2("");

      setTrainingId("");
      setDateHolder("");
      setNewDate();

      setEventInfo(response.data.data.trainingInfo);
      togglePopup33();
    } catch (err) {
      setLoading(false);
    }
  };

  //Popup
  const [isOpen4, setIsOpen4] = useState(false);
  const togglePopup4 = () => {
    setIsOpen3(!isOpen3);
    setIsOpen4(!isOpen4);
  };

  const togglePopup44 = () => {
    setIsOpen4(!isOpen4);
  };
  const handleDelete = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {
      setLoading(true);
      // eslint-disable-next-line
      const response = await UserFinder.post(`/deleteIndividualTraining`, {
        user_id: id,
        trainingId: trainingId,
        currentUserSiteId,
      });
      setLoading(false);
      setEventInfo(response.data.data.trainingInfo);
      togglePopup44();
    } catch (err) {
      setLoading(false);
    }
  };

  const [isOpenPdf, setIsOpenPdf] = useState(false);
  const togglePDF = () => {
    setIsOpenPdf(!isOpenPdf);
  };
  // eslint-disable-next-line
  const generatePDF = () => {
    setIsOpenPdf(!isOpenPdf);
    var doc = new jsPDF("p", "pt", "a4");
    const pngImg = new Image();
    pngImg.src = PublicFolder.baseURL + "LogonIcon.png";

    doc.addFont("helvetica", "normal");

    doc.setFontSize(15);
    doc.setFont(undefined, "bold");

    const data = eventInfo.map((elt, i) => [
      i + 1,
      elt.eventtype + " - " + elt.description,
      elt.training_name,
      elt.date,
    ]);

    doc.autoTable({
      startY: 100,
      columnStyles: { price: { halign: "right" } },
      body: data,
      columns: [
        { header: "", dataKey: "" },
        { header: "Type", dataKey: "Type" },
        { header: "Name", dataKey: "Name" },
        { header: "Date", dataKey: "Date" },
      ],
    });

    const pageCount = doc.internal.getNumberOfPages();

    for (var i = 1; i <= pageCount; i++) {
      // Go to page i
      doc.setPage(i);
      //Header
      doc.addImage(pngImg, "png", 25, 25, 50, 50);
      doc.setFontSize(25);
      doc.setFont(undefined, "bold");
      doc.text(150, 60, firstName + " " + lastName + " - Training Report");

      //Footer
      doc.setFontSize(10);
      doc.setFont(undefined, "bold");
      doc.text(250, 820, "Page " + String(i) + " of " + String(pageCount));
    }

    var string = doc.output("datauristring");
    PDFObject.embed(string, "#PdfEmbed", {
      id: "embeded",
    });
  };
  const sideBar = document.getElementById("sidebar");

  return (
    <>
      <div className="eventHolder">
        <div
          id="topbar"
          className={
            sideBar === null
              ? "topbar headerActive"
              : sideBar.clientWidth === 80
              ? "topbar headerActive collapse"
              : "topbar headerActive"
          }
        >
          <div className="goBackDiv" onClick={() => history(-1)}>
            <IoMdArrowRoundBack />
          </div>
          <div className="addItem" onClick={togglePopup}>
            +
          </div>
          <Header
            content={
              <b>
                {firstName} {lastName}
              </b>
            }
          />
        </div>

        <div className="eventsHolder">
          <b>
            {firstName} {lastName} - Individual Events
          </b>
        </div>

        <div className="downloadPdfBtnHolder">
          <div className="downloadPdfBtnTrianing">Generate PDF</div>
          {/*<div className="downloadPdfBtnTrianing" onClick={generatePDF}>
            Generate PDF
          </div>*/}
        </div>

        <div className="inventorySelectHolder">
          <div className="inventorySelect1">
            <label className="NewInputLabel" data-domain="Category">
              <select
                className="NewInput"
                onChange={(event) => setDropdownQuery(event.target.value)}
              >
                <option value="">All</option>

                {categoryList.map(function (e, i) {
                  return (
                    <option key={i} value={e.name}>
                      {e.name}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>

          <div className="inventorySelect22">
            <label className="NewInputLabel" data-domain="Year">
              <select
                className="NewInput"
                onChange={(event) => setDropdownQueryYear(event.target.value)}
              >
               <option value="">All</option>
                {yearList.map(function (e, i) {
                  return (
                    <option key={i} value={e.date}>
                      {e.date}
                    </option>
                  );
                })}
              </select>
            </label>
          </div>
        </div>

        {eventInfo.length > 0 ? (
          eventInfo
            // eslint-disable-next-line
            .filter((post) => {
              if (dropdownQuery === "" && dropdownQueryYear === "") {
                return post;
              } else if (
                post.eventtype
                  .toLowerCase()
                  .includes(dropdownQuery.toLowerCase()) &&
                dropdownQueryYear === ""
              ) {
                return post;
              } else if (
                post.month
                  .toLowerCase()
                  .includes(dropdownQueryYear.toLowerCase()) &&
                dropdownQuery === ""
              ) {
                return post;
              } else if (
                post.month
                  .toLowerCase()
                  .includes(dropdownQueryYear.toLowerCase()) &&
                post.eventtype
                  .toLowerCase()
                  .includes(dropdownQuery.toLowerCase())
              ) {
                return post;
              }
            })
            .map((data, i) => {
              if (loopingGroup === data.month) {
                return (
                  <div key={i}>
                    <div
                      className="itemListContainerHolder"
                      onClick={() =>
                        handleTraining(
                          data.individualtrainingid,
                          data.training_id
                        )
                      }
                    >
                      <div className="userItemListPageHolder">
                        <div className="itemListPageName">
                          {data.training_name}
                        </div>
                        <div className="userInfoEventTypeName">
                          {data.eventtype}
                        </div>
                        <div className="userInfoEventTypeName">
                          {data.description}
                        </div>
                      </div>
                      <div className="userItemListPageAmount">{data.date2}</div>
                    </div>
                  </div>
                );
              } else {
                loopingGroup = data.month;
                return (
                  <div key={i}>
                    <div className="itemListHeader">
                      <b>{data.month}</b>
                    </div>
                    <div
                      className="itemListContainerHolder"
                      onClick={() =>
                        handleTraining(
                          data.individualtrainingid,
                          data.training_id
                        )
                      }
                    >
                      <div className="userItemListPageHolder">
                        <div className="itemListPageName">
                          {data.training_name}
                        </div>
                        <div className="userInfoEventTypeName">
                          {data.eventtype}
                        </div>
                        <div className="userInfoEventTypeName">
                          {data.description}
                        </div>
                      </div>
                      <div className="userItemListPageAmount">{data.date2}</div>
                    </div>
                  </div>
                );
              }
            })
        ) : (
          <>No attended events</>
        )}

        {isOpenPdf && (
          <div className="PdfPopupDivHolder">
            <div className="PdfPopupDiv">
              <span className="PDFclose" onClick={togglePDF}>
                &times;
              </span>
              <div id="PdfEmbed"></div>
            </div>
          </div>
        )}

        {isOpen1 && (
          <PopupOverflowHidden
            content={
              <>
                <form onSubmit={handleNewTrainingSubmit}>
                  <div id="holderTest" className="holderTest">
                    <button
                      type="submit"
                      className="submitItemBtn2"
                      disabled={loading}
                    >
                      Add
                    </button>
                    <div className="popupTitle3">
                      <b>New Event</b>
                    </div>
                  </div>
                  <div className="emptySpace"></div>
                  <div className="text-center p-3">
                    <ClipLoader
                      loading={loading}
                      className="override"
                      size={35}
                    />
                  </div>

                  <div className="popupContainer">
                    <label className="NewInputLabel" data-domain="Event Name">
                      <input
                        className="NewInput"
                        type="text"
                        placeholder=""
                        onChange={(e) => setTrainingName(e.target.value)}
                        required
                      />
                    </label>

                    <label className="NewInputLabel" data-domain="Category">
                      <select
                        className="NewInput"
                        onChange={(event) => setEventType1(event.target.value)}
                        required
                        defaultValue={eventType1}
                      >
                        <option value="" disabled hidden>
                          Select a Category
                        </option>

                        {categoryList.map(function (e, i) {
                          return (
                            <option key={i} value={e.categoryid}>
                              {e.name}
                            </option>
                          );
                        })}
                      </select>
                    </label>

                    <label className="NewInputLabel" data-domain="Date">
                      <input
                        className="NewInput"
                        type="date"
                        value={newDate}
                        onChange={(e) => setNewDate(e.target.value)}
                        onFocus={(event) =>
                          (event.nativeEvent.target.defaultValue = "")
                        }
                        required
                      />
                    </label>

                    <div className="trainerDiv">
                      <Select
                        isMulti
                        options={options}
                        onChange={setAddMember}
                        name="addMembers"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        placeholder={"Other Members"}
                      />
                    </div>

                    <label
                      className="NewInputLabel"
                      data-domain="Event Details"
                    >
                      <textarea
                        value={trainingDesc}
                        onChange={(e) => setTrainingNote(e)}
                        className="NewInputArea"
                        placeholder=""
                      ></textarea>
                    </label>
                  </div>
                </form>
              </>
            }
            handleClose={togglePopup}
          />
        )}

        {isOpen2 && (
          <PopupOverflowHidden
            content={
              <>
                {(currentUserRole === "Advanced" ||
                  currentUserRole === "Admin") && (
                  <div
                    className="submitItemBtn2"
                    onClick={() => togglePopup3()}
                  >
                    Edit
                  </div>
                )}

                <div className="popupTitle">
                  <b>{trainingName}</b>
                </div>
                <div className="detailsPopupContainer22">
                  <div className="individualTrainingInfoTopHolder">
                    <div className="individualTrainingInfoDateHolder">
                      {dateHolder}
                    </div>
                    <div className="individualTrainingInfoEventHolder">
                      {eventType2}
                    </div>
                  </div>
                  <div className="individualTrainingInfoDescHolder">
                    <b> {trainingDesc}</b>
                  </div>

                  <div className="individualTrainingInfoTrainerName">
                    <b>Other Members</b>
                    <div className="individualTrainingInfoTrainerHolder">
                      {otherMembers.map((data2, i) => (
                        <div key={i} className="trainerName">
                          {i === 0 ? (
                            <>{data2.fullname}</>
                          ) : (
                            <> , {data2.fullname}</>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </>
            }
            handleClose={togglePopup2}
          />
        )}

        {isOpen3 && (
          <PopupOverflowHidden
            content={
              <>
                <form onSubmit={handleUpdateTrainingSubmit}>
                  <div className="holderTest">
                    <button
                      type="submit"
                      className="submitItemBtn2"
                      disabled={loading}
                    >
                      Update
                    </button>

                    <div className="popupTitle3">
                      <b>Update - {trainingName}</b>
                    </div>
                  </div>
                  <div className="emptySpace"></div>
                  <div className="text-center p-3">
                    <ClipLoader
                      loading={loading}
                      className="override"
                      size={35}
                    />
                  </div>

                  <div className="popupContainer">
                    <label className="NewInputLabel" data-domain="Event Name">
                      <input
                        className="NewInput"
                        type="text"
                        placeholder=""
                        value={trainingName}
                        onChange={(e) => setTrainingName(e.target.value)}
                        required
                      />
                    </label>

                    <label className="NewInputLabel" data-domain="Category">
                      <select
                        className="NewInput"
                        onChange={(event) => setEventType1(event.target.value)}
                        required
                        defaultValue={eventType1}
                      >
                        <option value="" disabled hidden>
                          Select a Category
                        </option>

                        {categoryList.map(function (e, i) {
                          return (
                            <option key={i} value={e.categoryid}>
                              {e.name}
                            </option>
                          );
                        })}
                      </select>
                    </label>

                    <label className="NewInputLabel" data-domain="Date">
                      <input
                        className="NewInput"
                        type="date"
                        placeholder=""
                        value={newDate}
                        onChange={(e) => setNewDate(e.target.value)}
                        required
                      />
                    </label>

                    <div className="trainerDiv">
                      {otherMembers === undefined || null ? (
                        <Select
                          isMulti
                          options={options}
                          onChange={setAddMember}
                          name="addMembers"
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      ) : (
                        <Select
                          isMulti
                          defaultValue={otherMembers.map(function (data2) {
                            return {
                              value: data2.fullname,
                              label: data2.fullname,
                              id: data2.user_id,
                            };
                          })}
                          options={options}
                          onChange={setAddMember}
                          name="addMembers"
                          className="basic-multi-select"
                          classNamePrefix="select"
                        />
                      )}
                    </div>

                    <label
                      className="NewInputLabel"
                      data-domain="Event Details"
                    >
                      <textarea
                        value={trainingDesc}
                        onChange={(e) => setTrainingNote(e)}
                        className="NewInputArea"
                        placeholder=""
                      ></textarea>
                    </label>
                  </div>
                </form>

                <div className="editApparatusBtnContainer">
                  <div
                    className="confirmDeleteApparatusBtn"
                    // onClick={handleDelete}
                    onClick={togglePopup4}
                  >
                    <b>Delete</b>
                  </div>
                </div>
              </>
            }
            handleClose={togglePopup33}
          />
        )}

        {isOpen4 && (
          <PopupOverflowHidden
            content={
              <>
                <div className="popupTitle">
                  <b>Delete - {trainingName}</b>
                </div>

                <div className="deleteInfoApparatusContainer">
                  <b>This training will no longer be available</b>
                </div>

                <div className="editApparatusBtnContainer">
                  <div
                    className="confirmCancelApparatusBtn"
                    onClick={togglePopup4}
                  >
                    <b>Cancel</b>
                  </div>
                  <div
                    className="confirmDeleteApparatusBtn"
                    onClick={handleDelete}
                    //onClick={togglePopup2}
                  >
                    <b>Delete</b>
                  </div>
                </div>
              </>
            }
            handleClose={togglePopup4}
          />
        )}
      </div>
    </>
  );
};

export default IndividualTraining;
