import React from "react";
import ItemPageHeader from "../components/ItemPage/ItemPageHeader";
import "../components/ItemListPage/ItemListPage.css";
import { ItemPageContextProvider } from "../context/ItemPageContext";
import "../components/ItemPage/ItemPage.css";

const ItemPage = () => {
  return (
    <ItemPageContextProvider>
      <>
        <ItemPageHeader />
      </>
    </ItemPageContextProvider>
  );
};

export default ItemPage;
