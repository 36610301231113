import React, { useRef, useState } from "react";
import "./Login.css";

//import { useAuth } from "../../context/AuthContext";
import UserFinder from "../../apis/UserFinder";
import LogonLogo from "../../PublicPhotos/LogonIcon.png";
import { MdOutlineLock, MdPersonOutline } from "react-icons/md";
import { useNavigate, Link } from "react-router-dom";

const LoginHeader = () => {
  const history = useNavigate();

  const emailRef = useRef();
  const passwordRef = useRef();
  //const [userName, setUserName] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");

  // const { signup } = useAuth();
  const [error] = useState();
  const [loading, setLoading] = useState(false);

  //
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      //  const response = await signup(
      ////    emailRef.current.value,
      //    passwordRef.current.value
      //  );

      // console.log(response._tokenResponse.email);
      /*
      const creatededUser = await UserFinder.post("/create", {
        userUID: response._tokenResponse.localId,
        email: response._tokenResponse.email,
        firstName: firstName,
        lastName: lastName,
      });
      */
      const creatededUser = await UserFinder.post("/createNewUser", {
        firstName: firstName,
        lastName: lastName,
        email: emailRef.current.value,
        password: passwordRef.current.value,
      });

      if (creatededUser.data.data.alert) {
        alert(creatededUser.data.data.alert);
        setLoading(false);
        return;
      }

      history(`/login`);
      console.log(creatededUser);
    } catch (err) {
      // console.log(err);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="loginContainer">
        <div className="loginTitle">
          <img src={LogonLogo} alt="Did Not Load" className="logonLogoIMG" />
        </div>

        {error && <div className="alert">{error}</div>}
        <form onSubmit={handleSubmit}>
          <div className="loginInputHolder">
            <div className="loginInputHolderTopColor">
              <div className="loginInputTitle"> Sign Up </div>
              <div className="loginInput">
                <div className="login__field">
                  <i className="login__icon fas fa-user">
                    <MdPersonOutline />
                  </i>

                  <input
                    className="login__input"
                    type="text"
                    placeholder="First Name"
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                    required
                  />
                </div>
                <div className="login__field">
                  <i className="login__icon fas fa-user">
                    <MdPersonOutline />
                  </i>

                  <input
                    className="login__input"
                    type="text"
                    placeholder="Last Name"
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                    required
                  />
                </div>

                <div className="loginUsername">Email</div>
                <div className="login__field">
                  <i className="login__icon fas fa-user">
                    <MdPersonOutline />
                  </i>
                  <input
                    className="login__input"
                    placeholder="Email"
                    type="email"
                    ref={emailRef}
                    required
                  />
                </div>
              </div>
              <div className="loginInput">
                <div className="loginUsername">Password</div>
                <div className="login__field">
                  <i className="login__icon fas fa-user">
                    <MdOutlineLock />
                  </i>
                  <input
                    className="login__input"
                    placeholder="Password"
                    type="password"
                    ref={passwordRef}
                    required
                  />
                </div>
              </div>

              <div className="loginBtnContainer">
                <button disabled={loading} type="submit" className="loginBtn">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>

        <div className="forgotInputHolder">
          <div className="loginForgotPassword">
            <Link className="loginForgotPasswordLink" to="/forgotPassword">
              Forgot password?
            </Link>
          </div>
          <div className="createAccountHolder">
            <Link className="newAccountLink" to="/login">
              Sign In
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginHeader;
