import { useQuery } from "@tanstack/react-query";
import ToDoFinder from "../../apis/ToDo";

const fetchToDoPage = ({ queryKey }) => {

  const currentUserSiteId = queryKey[1];
  return ToDoFinder.get(`/main`, {
    params: {
      currentUserSiteId,
    },
  });
};

export const useToDoData = (currentUserSiteId) => {
  return useQuery(["mainToDo", currentUserSiteId], fetchToDoPage);
};
