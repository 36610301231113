import React from "react";
import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  flexRender,
} from "@tanstack/react-table";
import Pagination from "./Pagination"; // Ensure this import is correct based on your file structure

const ReportTable = ({ results, columns }) => {
  const table = useReactTable({
    data: results,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: { pagination: { pageIndex: 0, pageSize: 25 } },
  });

  return (
    <>
       <div className="middle-container">
        <div className="middle">
          <div className="inventoryList no-extra-content">
            <div className="ReportResultsContainer no-extra-content">
              <div className="reportsPopupContainer no-extra-content">
             
                  <>
                
                    <div
                      className={`table-container`}
                    >
                      <table className="custom-table">
                        <thead className="custom-table-header-container">
                          {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id}>
                              {headerGroup.headers.map((header) => (
                                <th
                                  key={header.id}
                                  className="custom-table-header"
                                >
                                  {header.isPlaceholder
                                    ? null
                                    : flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        <tbody>
                          {table.getRowModel().rows.map((row) => (
                            <tr
                           
                            >
                              {row.getVisibleCells().map((cell) => (
                                <td key={cell.id} className="custom-table-cell">
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </>
           
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottom">
        <Pagination table={table} />
      </div>

    </>
  );
};

export default ReportTable;
