import React, { useState } from "react";

import PopupWeb from "../Popup/PopupNew";

import TrainingFinder from "../../apis/TrainingFinder";

import { useNavigate } from "react-router-dom";

import Header from "../Header/Header";
import { useAuth } from "../../context/AuthContext";

import TextareaAutosize from "react-textarea-autosize";
import { AiOutlineMenu } from "react-icons/ai";

import { useTrainingData } from "./fetchTrainingData";

import Select from "react-select";
import { notify } from "../../ToastNotifier";

const TrainingHeader = () => {
  const { currentUserSiteId } = useAuth();
  const { refetch } = useTrainingData(currentUserSiteId);

  //reload page
  const history = useNavigate();
  const [loading, setLoading] = useState(false);

  //Popup

  //Popup create new events
  const [isOpen7, setIsOpen7] = useState(false);
  const newUpcomingPopupClose = () => {
    setIsOpen7(!isOpen7);
    clearForm();
  };

  const newUpcomingPopup = () => {
    setIsOpen7(!isOpen7);
    // setIsOpen8(!isOpen8);
  };

  //Popup create new events
  const [isOpen8, setIsOpen8] = useState(false);
  const newSelectPopup = () => {
    setIsOpen8(!isOpen8);
  };

  //Input useStae

  const [trainingName, setTrainingName] = useState("");
  const [trainingDesc, setTrainingDesc] = useState("");

  const [trainer] = useState([]);

  const [newDate, setNewDate] = useState();

  const [status, setStatus] = useState("Active");

  //note

  const [category, setCategory] = useState("");

  const [eventType, setEventType] = useState("");

  const { data } = useTrainingData(currentUserSiteId);
  const [errors, setErrors] = useState({});

  const validationRules = {
    trainingForm: {
      trainingName: "Please enter a name",
      category: "Please select a category",
      newDate: "Please select a date",
    },
    // Add more validation rules for other forms here
  };

  const validateForm = (values, rules) => {
    const newErrors = {};
    let isValid = true;

    Object.keys(rules).forEach((key) => {
      if (!values[key]) {
        newErrors[key] = rules[key];
        isValid = false;
      }
    });

    setErrors(newErrors);

    if (!isValid) {
      notify("Please fill out all required fields", "error");
    }

    return isValid;
  };

  const clearForm = () => {
    setLoading(false);
    setErrors({});
    setTrainingName("");
    setTrainingDesc("");
    setCategory("");
    setEventType("");
    setNewDate("")
    setStatus("Active")
  };

  const handleNewTrainingSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    if (loading) return; // Prevent double submission
    const formValues = {
      trainingName,
      category,
      newDate,
    };

    if (!validateForm(formValues, validationRules.trainingForm)) {
      return;
    }

    setLoading(true);

    try {
           //eslint-disable-next-line
      const response = await TrainingFinder.post("/newtraingevent", {
        training_name: trainingName,
        training_desc: trainingDesc,
        trainer: trainer,
        categoryid: category,
        eventType: eventType,
        date: newDate,
        status: status,
        currentUserSiteId,
      });

      newUpcomingPopupClose();
      clearForm();
      refetch();
      notify("Event created successfully!", "success"); // Show success notification
    } catch (err) {
      setLoading(false);
      notify("Failed to create event. Please try again.", "error"); // Show error notification
    }
  };

  const selecttraining = (id) => {
    history(`/training/${id}`);
  };

  const StatusOptions = [
    { value: "Active", label: "Active" },
    { value: "Completed", label: "Completed" },
  ];

  const setCategoryFunc = (label) => {
    setCategory(label);
    setEventType("");
  };

  const toggleNav = () => {
    let topbar = document.getElementById("topbar");
    let sideBar = document.getElementById("sidebar");
    let appContainer = document.getElementById("appContainer");
    let appContainerHidden = document.getElementById("appContainerHidden");

    topbar.classList.toggle("collapse");
    sideBar.classList.toggle("collapse");
    appContainer.classList.toggle("collapse");
    appContainerHidden.classList.toggle("collapse");
  };

  let options = data?.data.data.categoryList.map(function (data) {
    return {
      value: data.categoryid,
      label: data.name,
      id: data.categoryid,
    };
  });

  return (
    <>
      <div id="topbar" className="topbar headerActive">
        <div className="hometopbarleft" onClick={toggleNav}>
          <AiOutlineMenu />
        </div>

        <div className="submitItemBtn2" onClick={newUpcomingPopup}>
          + New Event
        </div>
        <Header content={<b>Events</b>} />
      </div>
      <div className="topbarEmptySpace"></div>

      <div className="detailsPopupContainer">
        <div className="detailsPopupContainer">
          {/* <div className="detailsTitle"></div>*/}
          {data?.data.data.trainingActive.length !== 0 ? (
            data?.data.data.trainingActive.map((data, i) => {
              //Loops alot for some reason
              return (
                <div key={i}>
                  <div className="trainingContainer">
                    <div
                      className="trainingListContainer"
                      onClick={() => selecttraining(data.id)}
                    >
                      <div className="trainingName">
                        <b>{data.training_name}</b>
                      </div>

                      <div className="trainingDay">{data.date2}</div>
                      <div className="trainingDesc">
                        <b>
                          {data.category}
                          {data.eventtype !== "" ? ` - ${data.eventtype}` : ""}
                        </b>
                      </div>
                      <div className="trainingDesc">
                        Description &nbsp;<b> {data.description}</b>
                      </div>
                      <div className="trainingCompletedCountHolder">
                        {" "}
                        <div className="trainingCompletedCountName">
                          Members{" "}
                          <div className="trainingCompletedCountNumber">
                            {data.attended}
                          </div>
                        </div>
                        <div className="trainingCompletedCountName">
                          Notes{" "}
                          <div className="trainingCompletedCountNumber">
                            {data.notecount}
                          </div>
                        </div>
                        <div className="trainingCompletedCountName">
                          Items{" "}
                          <div className="trainingCompletedCountNumber">
                            {data.itemcount}
                          </div>
                        </div>
                        <div className="trainingCompletedCountName">
                          Photos{" "}
                          <div className="trainingCompletedCountNumber">
                            {data.photocount}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="EmptyTrainingDiv">
              <b>No Events In Progress</b>
            </div>
          )}
        </div>
      </div>

      {isOpen7 && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleNewTrainingSubmit}>
                <div className="popupContainer">
                  <label className="NewInputLabel" data-domain="Event Name">
                    <TextareaAutosize
                      onChange={(e) => setTrainingName(e.target.value)}
                      className="NewInput"
                      maxRows={10}
                      type="text"
                      placeholder=""
                      required
                    />
                    {errors.trainingName && (
                      <div className="errorInputLabel">
                        {errors.trainingName}
                      </div>
                    )}
                  </label>
                  <label
                    className="NewInputLabel"
                    data-domain="Event Description"
                  >
                    <TextareaAutosize
                      onChange={(e) => setTrainingDesc(e.target.value)}
                      className="NewInput"
                      maxRows={10}
                      type="text"
                      placeholder=""
                    />
                  </label>

                  <label className="NewInputLabel" data-domain="Category">
                    <Select
                      options={options}
                      onChange={(e) => setCategoryFunc(e.value)}
                      name="addMembers"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      required
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    {errors.category && (
                      <div className="errorInputLabel">{errors.category}</div>
                    )}
                  </label>
                  <label className="NewInputLabel" data-domain="Status">
                
                    <Select
                      options={StatusOptions}
                      defaultValue={StatusOptions.find(StatusOptions => StatusOptions.value === 'Active')}

                      onChange={(e) => setStatus(e.value)}
                      name="addMembers"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      required
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                 

                  </label>

                  <label className="NewInputLabel" data-domain="Date">
                    <input
                      className="NewInput"
                      type="date"
                      placeholder=""
                      onChange={(e) => setNewDate(e.target.value)}
                      required
                    />
                    {errors.newDate && (
                      <div className="errorInputLabel">{errors.newDate}</div>
                    )}
                  </label>
                </div>

                {/*<div className="BottomFixPosition">

                <div className="PopupBottomHolder">
                  <button className="button-39" type="submit">
                    Create
                  </button>
                    </div>                </div>*/}
              </form>
            </>
          }
          handleClose={newUpcomingPopupClose}
          isOpen={isOpen7}
          handleSubmit={handleNewTrainingSubmit}
          showSubmitButton={true}
          headerText={<b>New Event</b>}
          submitButtonText={loading ? "Loading..." : "Add"}
          loading={loading}
        />
      )}

      {isOpen8 && (
        <PopupWeb
          content={
            <>
              <div className="popupBtnContainers">
                <div className="btnDiv">
                  <div className="addMemberBtn" onClick={newUpcomingPopup}>
                    <b>New Event</b>
                  </div>
                </div>
              </div>
            </>
          }
          handleClose={newSelectPopup}
          isOpen={isOpen8}
          showSubmitButton={false}
          headerText={<b>New Event</b>}
        />
      )}
    </>
  );
};

export default TrainingHeader;
