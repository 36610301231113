import React, { useEffect } from "react";
import Popup from "reactjs-popup";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

import useDetectKeyboardOpen from "use-detect-keyboard-open";

const PopupOverflowHidden = (props) => {
  //const targetElement = document.getElementById("body");
  // const targetElement2 = document.querySelector("#test");
  var targetElement2 = null;

  useEffect(() => {
    doSomethingOnMount();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    return () => {
      doSomethingOnUnmount();
    }; // eslint-disable-next-line
  }, []);

  function scrollFunc() {
    if (document.documentElement.scrollTop > 0) {
      document.documentElement.scrollTop = 0;
    }
  }

  const doSomethingOnMount = () => {
    targetElement2 = document.querySelector(".popup-content");
    disableBodyScroll(targetElement2);
    document.addEventListener("scroll", scrollFunc);
    /*
    if (sideBar === null) {
      // console.log("yes");
    } else if (sideBar.clientWidth === 80) {
      // console.log("yes2");
      overlay !== undefined ? overlay.classList.add("collapse2") : <></>;
      holderTest !== null ? holderTest.classList.add("holderTestNav") : <></>;
      holderTest !== null ? holderTest.classList.toggle("collapse") : <></>;
    } else {
      // console.log("ye3");
      overlay !== undefined ? overlay.classList.add("collapse") : <></>;
      holderTest !== null ? holderTest.classList.add("holderTestNav") : <></>;
    }
    */
  };

  const doSomethingOnUnmount = () => {
    clearAllBodyScrollLocks();

    document.removeEventListener("scroll", scrollFunc);
  };

  const isKeyboardOpen = useDetectKeyboardOpen();

  document.querySelectorAll(".addItemInput").forEach((element) => {
    /* element.addEventListener("click", (event) => {
      event.stopPropagation();
      element.style.transform = "TranslateY(-1000px)";
      element.focus();
      setTimeout(function () {
        element.style.transform = "none";
      }, 100);
    });*/
  });

  return (
    <div id="Pupup-main" className={" "} >
      <Popup
        defaultOpen
        position="right center"
        onClose={() => {
          props.handleClose();
        }}
        closeOnDocumentClick={false}
        className={isKeyboardOpen ? "keyboard PupupMain" : "nokeyboard PupupMain"}
      >
        <div
          className="closePopup"
          id="close2"
          onClick={() => {
            props.handleClose();
          }}
        >
          Close
        </div>

        <div className={isKeyboardOpen ? "keyboard" : "nokeyboard"}>
          {props.content}
        </div>
      </Popup>
    </div>
  );
};

export default PopupOverflowHidden;
