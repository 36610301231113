import { useQuery } from "@tanstack/react-query";
import HomePageFinder from "../../apis/HomePageFinder";

const fetchHomePage = ({ queryKey }) => {
  const currentUserSiteId = queryKey[1];
  const currentUserId = queryKey[2];
  return HomePageFinder.get(`/main`, {
    params: {
      currentUserSiteId,
      currentUserId,
    },
  });
};



export const useMainHomePageData = (currentUserSiteId, id) => {
  return useQuery(["homePage", currentUserSiteId, id], fetchHomePage);
};



