import React, { useEffect, useState } from "react";

import ChecklistFinder from "../../apis/ChecklistFinder";

import PopupWeb from "../Popup/PopupWeb";

import { useNavigate } from "react-router-dom";

import Select from "react-select";

import ClipLoader from "react-spinners/ClipLoader";
import { useAuth } from "../../context/AuthContext";

const ChecklistEditPage = () => {
  const { currentUserSiteId } = useAuth();

  const history = useNavigate();

  const [repeatNumber, setRepeatNumber] = useState("");
  const [repeatMonth, setRepeatMonth] = useState("");
  const [date, setDate] = useState();

  const [loading, setLoading] = useState(false);

  //Popup Apparatus Details
  const [isOpen2, setIsOpen2] = useState(false);
  const apparatusChecklistPopupClose = () => {
    setIsOpen2(!isOpen2);
  };

  //
  //

  const [checklistName, setChecklistName] = useState("");
  const [selectedApparatus, setSelectedApparatus] = useState("");

  //

  //
  //
  const handleNewChecklistSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {
      setLoading(true);
      //eslint-disable-next-line
      const response = await ChecklistFinder.post("/addchecklist", {
        checklistName,
        selectedApparatus,
        repeatNumber,
        repeatMonth,
        date,
        currentUserSiteId,
      });
      setLoading(false);

      handleNewChecklistPush(response.data.data.checklist.clid);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleNewChecklistPush = (id) => {
    history(`/editchecklist/${id}`);
  };

  //
  //
  const [apparatusList, setApparatusList] = useState([]);
  //
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ChecklistFinder.get("/addchecklist");
        setApparatusList(response.data.data.apparatusList);
      } catch (err) {
        // console.log(err);
      }
    };

    fetchData();
    // eslint-disable-next-line
  }, []);

  let options = apparatusList.map(function (data) {
    return { value: data.name, label: data.name, value2: data.id };
  });

  let optionsRepeat = [
    { value: "1 month", label: "On a specific day of the month" },
    {
      value: "day",
      label: "A number of days from the last Scheduled Date",
    },
    {
      value: "month",
      label: "A number of months from the last Scheduled Date",
    },
  ];

  return (
    <>
      <PopupWeb
        content={
          <>
            <button
              type="submit"
              className="submitItemBtn2"
              onClick={handleNewChecklistSubmit}
              disabled={loading}
            >
              Save
            </button>

            <div className="popupTitle">
              <b>Add Apparatus Checklist</b>
            </div>

            <div className="text-center p-3">
              <ClipLoader loading={loading} className="override" size={35} />
            </div>
            <div className="editChecklistName">
              <input
                value={checklistName}
                onChange={(e) => setChecklistName(e.target.value)}
                className="addChecklistNameInput"
                type="text"
                placeholder="Add Checklist Name"
              />
            </div>
            <div className="editChecklistApparatus">
              <Select
                options={options}
                onChange={setSelectedApparatus}
                name="addMembers"
                className="basic-multi-select"
                classNamePrefix="select"
                isSearchable={false}
              />
            </div>

            <div className="editChecklistApparatus">
              <Select
                options={optionsRepeat}
                onChange={setRepeatMonth}
                name="addMembers"
                className="basic-multi-select"
                classNamePrefix="select"
                isSearchable={false}
              />
              <input
                value={repeatNumber}
                onChange={(e) => setRepeatNumber(e.target.value)}
                className="addItemInput"
                type="text"
                placeholder="Repeat Number"
              />

              <input
                type="date"
                id="start"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                onFocus={(event) =>
                  (event.nativeEvent.target.defaultValue = "")
                }
                required
              ></input>
            </div>
          </>
        }
        handleClose={apparatusChecklistPopupClose}
      />
    </>
  );
};

export default ChecklistEditPage;
