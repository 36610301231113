import { useQuery } from "@tanstack/react-query";
import UserFinder from "../../apis/UserFinder";

const fetchSelectedMemberPage = ({ queryKey }) => {
  const currentUserSiteId = queryKey[1];
  const id = queryKey[2];
  return UserFinder.get(`/userInfo`, {
    params: {
      currentUserSiteId,
      userid: id,
    },
  });
};

export const useSelectedMemberData = (currentUserSiteId, id) => {
  return useQuery(
    ["selectedMember", currentUserSiteId, id],
    fetchSelectedMemberPage
  );
};
