import React from "react";
import Scanner from "../components/Scanner/Scanner";

const ScannerPage = () => {
  return (
    <>
      <Scanner />
    </>
  );
};

export default ScannerPage;
