import React from "react";
import Select from "react-select";

const FilterPanel = ({
  selectedReport,
  setSelectedReport,
  from,
  setFrom,
  thru,
  setThru,
  memberList,
  categoryList,
  trainingList,
  selectedMembers,
  setSelectedMembers,
  selectedCategories,
  setSelectedCategories,
  isCollapsed,
  toggleCollapse,
}) => {
  const renderFilters = () => {
    if (!selectedReport) return null;

    switch (selectedReport.value) {
      case "Item Usage":
        return (
          <div className="inventorySelectHolder">
            <label className="NewInputLabel" data-domain="Category">
              <select
                multiple
                className="NewInput"
                value={selectedCategories.map((option) => option.value)}
                onChange={(e) =>
                  setSelectedCategories(
                    Array.from(e.target.selectedOptions, (option) => ({
                      value: option.value,
                      label: option.text,
                    }))
                  )
                }
              >
                {categoryList.map((e, i) => (
                  <option key={i} value={e.type}>
                    {e.type}
                  </option>
                ))}
              </select>
            </label>
            <label className="NewInputLabel" data-domain="Event">
              <select
                multiple
                className="NewInput"
                value={selectedMembers.map((option) => option.value)}
                onChange={(e) =>
                  setSelectedMembers(
                    Array.from(e.target.selectedOptions, (option) => ({
                      value: option.value,
                      label: option.text,
                    }))
                  )
                }
              >
                {trainingList.map((e, i) => (
                  <option key={i} value={e.training_name}>
                    {e.training_name}
                  </option>
                ))}
              </select>
            </label>
          </div>
        );
      case "Item Information":
        return (
          <div className="inventorySelectHolder">
            {/* Add filters specific to "Item Information" */}
          </div>
        );
      case "Checklist History":
        return (
          <div className="inventorySelectHolder">
            {/* Add filters specific to "Checklist History" */}
          </div>
        );
      case "Events":
        return (
          <>
            <label className="NewInputLabel123" data-domain="Members">
              <Select
                isMulti
                options={memberList.map((member) => ({
                  value: member.user_id,
                  label: `${member.firstname} ${member.lastname}`,
                }))}
                onChange={(options) => setSelectedMembers(options || [])}
                className="filter-dropdown"
                classNamePrefix="Select"
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 1 }) }}
              />
            </label>
            <label className="NewInputLabel123" data-domain="Category">
              <Select
                isMulti
                options={categoryList.map((category) => ({
                  value: category.categoryid,
                  label: category.category,
                }))}
                onChange={(options) => setSelectedCategories(options || [])}
                className="filter-dropdown"
                classNamePrefix="Select"
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 1 }) }}
              />
            </label>
          </>
        );
      case "Individual Events":
        return (
          <div className="inventorySelectHolder">
            {/* Add filters specific to "Individual Events" */}
          </div>
        );
      case "To Do List":
        return (
          <div className="inventorySelectHolder">
            {/* Add filters specific to "To Do List" */}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <label className="NewInputLabel123" data-domain="Report Type">
        <Select
          options={[
            { value: "Item Usage", label: "Item Usage" },
            { value: "Item Information", label: "Item Information" },
            { value: "Checklist History", label: "Checklist History" },
            { value: "Events", label: "Events" },
            { value: "Individual Events", label: "Individual Events" },
            { value: "To Do List", label: "To Do List" },
          ]}
          onChange={setSelectedReport}
          className="filter-dropdown"
          classNamePrefix="Select"
          menuPortalTarget={document.body}
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 1 }) }}
        />
      </label>

      <div className="filter-search-toggle-container2">
        <button className="toggle-button2" onClick={toggleCollapse}>
          {isCollapsed ? "Show Filters" : "Hide Filters"}
        </button>
        <div
          className={`filter-search-container2 ${
            isCollapsed ? "collapsed" : ""
          }`}
        >
          <div className="inventorySelectHolder">
            <div className="inventorySelect1">
              <label className="NewInputLabel" data-domain="From">
                <input
                  className="NewInput"
                  type="date"
                  value={from}
                  onChange={(e) => setFrom(e.target.value)}
                  required
                />
              </label>
            </div>
            <div className="inventorySelect2">
              <label className="NewInputLabel" data-domain="Thru">
                <input
                  className="NewInput"
                  type="date"
                  value={thru}
                  onChange={(e) => setThru(e.target.value)}
                  required
                />
              </label>
            </div>
          </div>
          {renderFilters()}
        </div>
      </div>
    </div>
  );
};

export default FilterPanel;
