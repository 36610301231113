import React, { useContext, useEffect, useRef, useState } from "react";
import InspectionsFinder from "../../apis/InspectionsFinder";

import { useAuth } from "../../context/AuthContext";

import PopupWeb from "../Popup/PopupNew";

import ClipLoader from "react-spinners/ClipLoader";
import { RiImageAddFill, RiCloseFill } from "react-icons/ri";

import { ItemPageContext } from "../../context/ItemPageContext";

import "react-swipeable-list/dist/styles.css";
import { useMainItemPageData } from "./fetchItemPageData";
import InventoryFinder from "../../apis/InventoryFinder";
import Select from "react-select";

const ItemPageInspectionTask = () => {
  const {
    currentUserSiteId,
    currentUserId,
    currentLastName,
    currentFirstName,
  } = useAuth();
  const { inspectionOpen, setInspectionOpen, itemId } =
    useContext(ItemPageContext);
  const { refetch } = useMainItemPageData(currentUserSiteId, itemId);

  const headers = {
    "content-type": "multipart/form-data",
  };
  const [inspectionOpenMain, setInspectionOpenMain] = useState(true);

  //const [deleteMemberId, setDeleteMemberId] = useState("");

  const [inspectionStatusArray, setInspectionStatusArray] = useState([]);
  const [inspectionNotes, setInspectionNotes] = useState("");

  const [inspectionPhotos, setInspectionPhotos] = useState([]);

  const handleFileInputInspectionPhotos = (e) => {
    const files = Array.from(e.target.files);
    setInspectionPhotos((prevImages) => [...prevImages, ...files]);
  };

  const removeInspectionPhotos = (index) => {
    setInspectionPhotos((prevImages) =>
      prevImages.filter((_, i) => i !== index)
    );
  };

  //custom image
  const fileRef = useRef();

  //const sideBar = document.getElementById("sidebar");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await InspectionsFinder.get(`/getItemInspection`, {
          params: {
            currentUserSiteId,
            selectedInvId: itemId,
          },
        });
        fetchInspectionResults();
      } catch (err) {
        // console.log(err);
      }
    };

    if (currentUserSiteId) {
      fetchData();
    } // eslint-disable-next-line
  }, [currentUserSiteId]);

  const [dropdownQuery, setDropdownQuery] = useState({
    value: "None",
    label: "None",
  });

  const handleInspectionSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    try {
      setLoading(true);
      let myForm = document.getElementById('inspectionForm'); // Reference the form by ID
      let formData = new FormData(myForm);

      inspectionPhotos.forEach((image, index) => {
        formData.append(`file`, image);
      });
    // Get only the values from the object
    const inspectionStatusValues = Object.values(inspectionStatusObject);

    // Convert the values to a comma-separated string
    const inspectionStatusString = inspectionStatusValues.join(',');

      formData.set("isCheck", itemId);
      formData.set("inspectionStatus", inspectionStatusString);
      formData.set("inspectionNotes", inspectionNotes);
      formData.set("currentUserSiteId", currentUserSiteId);
      formData.set("currentLastName", currentLastName);
      formData.set("currentFirstName", currentFirstName);
      formData.set("currentUserId", currentUserId);
      formData.set("selectedChecklistClid", dropdownQuery.value);
      formData.set("selectedChecklistName", dropdownQuery.label);

      //eslint-disable-next-line
      const response2 = await InventoryFinder.post(
        `/postInspection`,
        formData,
        headers
      );
      console.log("dlkfmdlfmjk")
      togglePopup4();
      setLoading(false);
      refetch();
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const togglePopup4 = () => {
    setInspectionOpen(!inspectionOpen);
    setInspectionOpenMain(!inspectionOpenMain);
  };

  const [ChecklistData, setChecklistData] = useState([]);

  let ChecklistOptionsNone = [{ value: "None", label: "None" }];

  let ChecklistOptionsNew = ChecklistData.map(function (e) {
    console.log(e);
    return {
      value: e.clid,
      label: e.checklistname,
    };
  });
  let ChecklistOptions = [...ChecklistOptionsNone, ...ChecklistOptionsNew];

  const fetchInspectionResults = async () => {
    try {
      const response = await InventoryFinder.get("/getInventoryInspection", {
        params: {
          currentUserSiteId,
          isCheck: [itemId],
        },
      });

      setChecklistData(response.data.data.inspectionData);
    } catch (err) {
      // //console.log(err);
    }
  };


  const [inspectionStatusObject, setInspectionStatusObject] = useState({});

  const setInspectionStatus = (value, taskId) => {
    setInspectionStatusObject((prevObject) => ({
      ...prevObject,
      [taskId]: value,
    }));
  };
  return (
    <div className="sliderHolder">
      {inspectionOpenMain && (
        <PopupWeb
          content={
            <form id="inspectionForm"  className="formClass">
              <div className="emptySpace"></div>
              <div className="text-center p-3">
                <ClipLoader loading={loading} className="override" size={35} />
              </div>
              <div className="popupContainer">
                <Select
                  defaultValue={{ value: "None", label: "None" }}
                  options={ChecklistOptions}
                  onChange={(e) => {
                    const { value, label } = e;
                    setDropdownQuery({ value, label });
                  }}
                  name="addMembers"
                  className="basic-multi-select"
                  classNamePrefix="Checklist"
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                />

                <div className="PassFailTaskContainer">
                  {dropdownQuery.value === "None" ? (
                    <>
         
                      <div className="taskNameHolder">
                        <div className="taskName2"> </div>
                        <div className="taskDesc2"> </div>
                      </div>
                      <input
                        value="Pass"
                        type="radio"
                        className="passBtn"
                        onChange={(e) => setInspectionStatus(e.target.value, "0")}
                        onFocus={(event) =>
                          (event.nativeEvent.target.defaultValue = "")
                        }
                        id={"Pass1"}
                        name={"0"}
                      />
                      <label className="passBtn2" htmlFor={"Pass1"}>
                        Pass
                      </label>
                      <div className="space"></div>
                      <input
                        value="Fail"
                        type="radio"
                        className="passBtn"
                        onChange={(e) => setInspectionStatus(e.target.value, "0")}
                        onFocus={(event) =>
                          (event.nativeEvent.target.defaultValue = "")
                        }
                        id={"Fail1"}
                        name={"0"}
                      />
                      <label className="failBtn2" htmlFor={"Fail1"}>
                        Fail
                      </label>
                      <div className="space"></div>
                      <input
                        value="Monitor"
                        type="radio"
                        className="passBtn"
                        onChange={(e) => setInspectionStatus(e.target.value, "0")}
                        onFocus={(event) =>
                          (event.nativeEvent.target.defaultValue = "")
                        }
                        id={"Monitor1"}
                        name={"0"}
                      />
                      <label className="failBtn2" htmlFor={"Monitor1"}>
                        Monitor
                      </label>
                      <div>
                        <input name={"0"} type="hidden" value={"0"}></input>
                        <input name={"0"} type="hidden" value={""}></input>
                        <input name={"0"} type="hidden" value={""}></input>
                      </div>
                    </>
                  ) : (
                    <>
                      {ChecklistData.length > 0 ? (
                        ChecklistData
                          // eslint-disable-next-line
                          .filter((data) => {
                            if (dropdownQuery.value === "") {
                              return data;
                            } else if (
                              data.clid
                                .toLowerCase()
                                .includes(dropdownQuery.value)
                            ) {
                              return data;
                            }
                          })
                          .map((data, i) => {
                            return (
                              <>
                                {data.tasklist.map((data2, i) => (
                                  <div key={i}>
                                    <div className="taskNameHolder">
                                      <div className="taskName2">
                                        {" "}
                                        {data2.taskname}
                                      </div>
                                      <div className="taskDesc2">
                                        {" "}
                                        {data2.taskdescription}
                                      </div>
                                    </div>
                                    <input
                                      value="Pass"
                                      type="radio"
                                      className="passBtn"
                                      id={"Pass" + data2.tid}
                                      onChange={(e) => setInspectionStatus(e.target.value, data2.tid)}

                                      name={data2.tid}
                                    />
                                    <label
                                      className="passBtn2"
                                      htmlFor={"Pass" + data2.tid}
                                    >
                                      Pass
                                    </label>
                                    <div className="space"></div>
                                    <input
                                      value="Fail"
                                      type="radio"
                                      className="passBtn"
                                      id={"Fail" + data2.tid}
                                      onChange={(e) => setInspectionStatus(e.target.value, data2.tid)}

                                      name={data2.tid}
                                    />
                                    <label
                                      className="failBtn2"
                                      htmlFor={"Fail" + data2.tid}
                                    >
                                      Fail
                                    </label>
                                    <div className="space"></div>
                                    <input
                                      value="Monitor"
                                      type="radio"
                                      className="passBtn"
                                      id={"Monitor" + data2.tid}
                                      onChange={(e) => setInspectionStatus(e.target.value, data2.tid)}

                                      name={data2.tid}
                                    />
                                    <label
                                      className="failBtn2"
                                      htmlFor={"Monitor" + data2.tid}
                                    >
                                      Monitor
                                    </label>

                                    <div>
                                      <input
                                        name={data2.tid}
                                        id={data2.tid}
                                        type="hidden"
                                        value={data2.tid}
                                      ></input>
                                      <input
                                        name={data2.tid}
                                        type="hidden"
                                        value={data2.taskname}
                                      ></input>
                                      <input
                                        name={data2.tid}
                                        type="hidden"
                                        value={data2.taskdescription}
                                      ></input>
                                      <input
                                        name={data2.tid}
                                        type="hidden"
                                        value={data.clid}
                                      ></input>
                                    </div>
                                  </div>
                                ))}
                              </>
                            );
                          })
                      ) : (
                        <>11</>
                      )}
                    </>
                  )}
                </div>

                <div className="NewInputLabel" data-domain="Add Image">
                  <input
                    ref={fileRef}
                    onChange={handleFileInputInspectionPhotos}
                    multiple
                    type="file"
                    accept="image/*"
                    hidden
                  />
                  <div
                    onClick={() => fileRef.current.click()}
                    className="NewInputImage"
                  >
                    <RiImageAddFill size={30} />
                  </div>
                  <div className="imagePreviewContainer">
                    {inspectionPhotos.map((image, index) => (
                      <div key={index} className="imageHolder">
                        <img
                          src={URL.createObjectURL(image)}
                          alt="imageDidNotLoad"
                          className="addImage"
                        />
                        <button
                          type="button"
                          className="removeImageButton"
                          onClick={() => removeInspectionPhotos(index)}
                        >
                          <RiCloseFill size={20} />
                        </button>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="inspectionImg"> Notes</div>

                <div className="textInputLeft2">
                  <textarea
                    onChange={(e) => setInspectionNotes(e.target.value)}
                    className="addItemInput"
                    type="text"
                    placeholder="Note..."
                  />
                </div>
              </div>
            </form>
          }
          handleClose={togglePopup4}
          isOpen={inspectionOpenMain}
          handleSubmit={handleInspectionSubmit}
          showSubmitButton={true}
          headerText={<b>Inspection</b>}
          submitButtonText={loading ? "Loading..." : "Submit"} // Optionally change the text to indicate loading
        />
      )}
    </div>
  );
};

export default ItemPageInspectionTask;
