import { useQuery } from "@tanstack/react-query";
import TrainingFinder from "../../apis/TrainingFinder";

const fetchPerviousTrainingPage = ({ queryKey }) => {
  const currentUserSiteId = queryKey[1];
  return TrainingFinder.get(`/completed`, {
    params: {
      currentUserSiteId,
    },
  });
};

export const usePerviousTrainingData = (currentUserSiteId) => {
  return useQuery(
    ["perviousTraining", currentUserSiteId],
    fetchPerviousTrainingPage
  );
};
