import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";

import ChecklistFinder from "../../apis/ChecklistFinder";

import PopupWeb from "../Popup/PopupWeb";

import Header from "../Header/Header";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Select from "react-select";

import { IoMdArrowRoundBack } from "react-icons/io";

import ClipLoader from "react-spinners/ClipLoader";

const ChecklistEditPage = () => {
  const { id } = useParams();
  const history = useNavigate();
  const { currentUserSiteId } = useAuth();

  const [loading, setLoading] = useState(false);
  const [memberList, setMemberList] = useState([]);
  const [allMemberList, setAllMemberList] = useState([]);

  //

  const [alertMembersText, setAlertMembersText] = useState([]);
  const [alertMembersEmail, setAlertMembersEmail] = useState([]);

  const [addTextMember, setAddTextMember] = useState([]);
  const [addEmailMember, setAddEmailMember] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response2 = await ChecklistFinder.get(`/editchecklist`, {
          params: {
            currentUserSiteId,
            checklistId: id,
          },
        });

        setTaskGroup(response2.data.data.taskgroupInfo);
        setTask(response2.data.data.taskInfo);
        setInventoryInfo(response2.data.data.inventoryInfo);
        setCompartments(response2.data.data.comartments);

        setChecklistInfo(response2.data.data.checklistInfo);
        setChecklistId(response2.data.data.checklistInfo[0].clid);
        setChecklistName(response2.data.data.checklistInfo[0].checklistname);
        setRepeatMonth(response2.data.data.checklistInfo[0].repeat);
        setRepeatNumber(response2.data.data.checklistInfo[0].repeatnumber);
        setSelectedStatus(response2.data.data.checklistInfo[0].status);
        setScheduledDate(
          response2.data.data.checklistInfo[0].unformateddate === null
            ? null
            : new Date(response2.data.data.checklistInfo[0].unformateddate)
                .toISOString()
                .substring(0, 10)
        );
        setMemberList(response2.data.data.memberNames);
        setAlertMembersText(response2.data.data.alertMembersText);
        setAlertMembersEmail(response2.data.data.alertMembersEmail);

        setAddTextMember(response2.data.data.alertMembersText);
        setAddEmailMember(response2.data.data.alertMembersEmail);
        setAllMemberList(response2.data.data.allMemberList);
      } catch (err) {
        // // console.log(err);
      }
    };

    if (currentUserSiteId) {
      fetchData();
    }
    // eslint-disable-next-line
  }, [currentUserSiteId]);

  //// // console.log(memberList);
  let options22 = memberList.map(function (data) {
    // // // console.log(data);
    return {
      value: data.user_id,
      label: data.firstname + " " + data.lastname,
      user_id: data.user_id,
    };
  });

  //// // console.log(memberList);
  let optionsTextDefault = alertMembersText.map(function (data) {
    // // // console.log(data);
    return {
      value: data.user_id,
      label: data.firstname + " " + data.lastname,
      user_id: data.user_id,
    };
  });

  //// // console.log(memberList);
  let optionsEmailDefault = alertMembersEmail.map(function (data) {
    // // // console.log(data);
    return {
      value: data.user_id,
      label: data.firstname + " " + data.lastname,
      user_id: data.user_id,
    };
  });

  //Popup Apparatus Details
  //Popup creating new task group
  const [isOpen3, setIsOpen3] = useState(false);
  const togglePopup3 = () => {
    setTaskGroupName("");
    setTaskGroupDisplayOrder("");
    setEditAllItems("");

    setIsOpen3(!isOpen3);
  };

  //Popup checklist popup
  const [editChecklistOpen, setEditChecklistOpen] = useState(false);
  const editChecklistPopup = () => {
    setEditChecklistOpen(!editChecklistOpen);
  };

  //Popup delete checklist popup
  const [editChecklistOpen2, setEditChecklistOpen2] = useState(false);
  const editChecklistPopup2 = () => {
    setEditChecklistOpen2(!editChecklistOpen2);
    setEditChecklistOpen(!editChecklistOpen);
  };

  //Popup delete checklist popup
  const [editAlertsOpen, setEditAlertsOpen] = useState(false);
  const editAlertsPopup = () => {
    setEditAlertsOpen(!editAlertsOpen);
    setEditChecklistOpen(!editChecklistOpen);
  };

  //Popup creating new task
  const [isOpen2, setIsOpen2] = useState(false);

  const togglePopup2 = (taskId, cid) => {
    setIsOpen2(!isOpen2);
    setCurrentTaskGroupId(taskId);
    setCurrentCid(cid);
  };

  //Popup passFail creating new task
  const [isOpen4, setIsOpen4] = useState(false);
  const togglePopup4 = () => {
    setIsOpen4(!isOpen4);
    setIsOpen2(!isOpen2);
    setTaskDesciption("");
    setTaskName("");
  };
  const togglePopup44 = () => {
    setIsOpen4(!isOpen4);
  };

  //Popup passFail edit task
  const [isOpen44, setIsOpen44] = useState(false);
  const togglePopup444 = () => {
    setIsOpen44(!isOpen44);
  };

  //Popup passFail delete task
  const [isOpen444, setIsOpen444] = useState(false);
  const togglePopup4444 = () => {
    setIsOpen444(!isOpen444);
    setIsOpen44(false);
    setIsOpen55(false);
  };

  //Popup Item creating new task
  const [isOpen5, setIsOpen5] = useState(false);
  const togglePopup5 = () => {
    setIsOpen5(!isOpen5);
    setIsOpen2(!isOpen2);
  };

  //Popup Item creating new task
  const [taskOpen, setTaskOpen] = useState(false);
  const toggleTaskPopup = () => {
    setTaskOpen(!taskOpen);
    setIsOpen5(!isOpen5);
  };

  const togglePopup55 = () => {
    setIsOpen5(!isOpen5);
  };

  //Popup Item creating new task
  const [isOpen55, setIsOpen55] = useState(false);
  const togglePopup555 = () => {
    setIsOpen55(!isOpen55);
  };

  //Edit task group
  const [isOpen6, setIsOpen6] = useState(false);
  const togglePopup6 = () => {
    setIsOpen6(!isOpen6);
  };

  //Edit task group
  const [isOpen66, setIsOpen66] = useState(false);
  const togglePopup66 = () => {
    setIsOpen66(!isOpen66);
  };

  //
  //
  const [taskGroup, setTaskGroup] = useState([
    { tgid: 1, taskGroupName: "a string" },
  ]);

  //addTaskGroupName
  const [addTaskGroupName, setTaskGroupName] = useState("");
  const [addTaskGroupId, setTaskGroupId] = useState("");
  const [editAllItems, setEditAllItems] = useState("");
  const [addTaskGroupdDisplayOrder, setTaskGroupDisplayOrder] = useState("");

  //

  const handleNewTaskGroup = async (e) => {
    let holder = null;

    linkedCompartment === "" ? (holder = null) : (holder = linkedCompartment);

    //stop the page from reloading
    e.preventDefault();
    //// // console.log([addTaskType]);
    try {
      setLoading(true);

      //eslint-disable-next-line

      const response = await ChecklistFinder.post("/taskgroup", {
        addTaskGroupName,
        clid: checklistId,
        linkedCompartment: holder,
        addTaskGroupdDisplayOrder,
        allItems: editAllItems === true ? "true" : null,
        currentUserSiteId,
      });
      setLoading(false);

      setTaskGroup(response.data.data.taskgroupInfo);
      setTaskGroupName("");

      togglePopup3();
    } catch (err) {
      setLoading(false);
    }
  };

  //Edit task group
  const editTaskGroup = async (
    tgid,
    taskgroupname,
    cid,
    name,
    allitems,
    displayorder
  ) => {
    setTaskGroupId(tgid);
    setTaskGroupName(taskgroupname);
    setLinkedCompartment(cid);
    setEditAllItems(allitems);
    setTaskGroupDisplayOrder(displayorder);
    togglePopup6();
    // console.log(allitems);
  };

  const handleEditTaskGroup = async (e) => {
    let holder = null;

    linkedCompartment === "" ? (holder = null) : (holder = linkedCompartment);

    //stop the page from reloading
    e.preventDefault();
    //// // console.log([addTaskType]);
    try {
      setLoading(true);

      //eslint-disable-next-line
      const response = await ChecklistFinder.post("/editTaskgroup", {
        addTaskGroupId,
        addTaskGroupName,
        linkedCompartment: holder,
        addTaskGroupdDisplayOrder,
        clid: checklistId,
        allItems: editAllItems === true || "true" ? "true" : null,
        currentUserSiteId,
      });
      setLoading(false);

      setTaskGroup(response.data.data.taskgroupInfo);
      togglePopup6();
    } catch (err) {
      setLoading(false);
    }
  };

  const handleDeleteTaskGroup = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    //// // console.log([addTaskType]);
    try {
      setLoading(true);

      //eslint-disable-next-line
      const response = await ChecklistFinder.post("/deleteTaskgroup", {
        addTaskGroupId,
        clid: checklistId,
        currentUserSiteId,
      });
      setLoading(false);

      setTaskGroup(response.data.data.taskgroupInfo);
      togglePopup66();
      togglePopup6();
    } catch (err) {
      setLoading(false);
    }
  };

  //
  const [addTaskName, setTaskName] = useState("");
  const [currentTaskGroupId, setCurrentTaskGroupId] = useState("");
  const [currentCid, setCurrentCid] = useState("");
  let currentEvent = null;
  const [currentTaskItemId, setCurrentTaskItemId] = useState("");

  const [itemid, setItemId] = useState();
  const [bagid, setBagId] = useState();

  const [taskDesciption, setTaskDesciption] = useState("");

  //note is required
  const [requiredNoteChecked, setRequiredNoteChecked] = useState(false);
  const handleNoteRequiredChecked = () => {
    setRequiredNoteChecked(!requiredNoteChecked);
  };

  const [task, setTask] = useState([
    //{ tid: 1, name: "task name", type: "passFail", tgid: 1 },
  ]);

  const handleNewTask = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    try {
      setLoading(true);

      //eslint-disable-next-line
      const response = await ChecklistFinder.post("/task", {
        taskname: addTaskName[0],
        tgid: currentTaskGroupId,
        clid: checklistId,
        bagid: bagid,
        itemid: itemid,
        taskDesciption,
        requiredNoteChecked,
        currentUserSiteId,
      });
      setLoading(false);

      setTaskGroup(response.data.data.taskgroupInfo);
      setTask(response.data.data.taskInfo);

      setTaskName("");
      setBagId();
      setItemId();
      setCurrentTaskGroupId("");
      setTaskDesciption("");
      setRequiredNoteChecked(false);
      togglePopup44();
    } catch (err) {
      setLoading(false);
    }
  };

  const handleEditTask = async (e) => {
    //stop the page from reloading
    //  // // console.log("kjldmfkjsdn");
    e.preventDefault();
    try {
      setLoading(true);

      const response = await ChecklistFinder.post("/editTask", {
        taskId: taskId,
        taskDesciption,
        requiredNoteChecked,
        clid: checklistId,
        currentUserSiteId,
      });
      setLoading(false);

      //// // console.log(response.data.data.taskInfo);
      setTaskGroup(response.data.data.taskgroupInfo);
      setTask(response.data.data.taskInfo);

      setBagId();
      setItemId();
      setCurrentTaskGroupId("");

      setTaskDesciption("");
      setRequiredNoteChecked(false);
      setIsOpen44(false);
      setIsOpen55(false);
    } catch (err) {
      setLoading(false);
    }
  };

  const handleNewInventory = async (e) => {
    //   // // console.log("workung");
    //stop the page from reloading
    e.preventDefault();
    try {
      setLoading(true);

      //eslint-disable-next-line
      const response = await ChecklistFinder.post("/task", {
        taskname: addTaskName[0],
        tgid: currentTaskGroupId,
        clid: checklistId,
        bagid: bagid,
        itemid: itemid,
        taskDesciption,
        requiredNoteChecked,
        currentUserSiteId,
      });
      setLoading(false);
      setTaskGroup(response.data.data.taskgroupInfo);
      setTask(response.data.data.taskInfo);
      setBagId();
      setItemId();
      setCurrentTaskGroupId("");
      togglePopup55();
      setTaskDesciption("");
      setRequiredNoteChecked(false);
    } catch (err) {
      setLoading(false);
    }
  };
  //Edit task
  //const [itemEvent, setItemEvent] = useState([]);
  //const [itemEventId, setItemEventId] = useState("");

  const [setItemEvent] = useState([]);
  const [setItemEventId] = useState("");

  const fetchTask = async (invid) => {
    try {
      const response = await ChecklistFinder.get("/getItemEventInfo", {
        params: { invid: invid, currentUserSiteId },
      });
      // // // console.log(response.data.data.itemevent);
      setItemEvent(response.data.data.itemevent);
    } catch (err) {
      // // console.log(err);
    }
  };
  /*
  const handleNewTaskEvent = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      //eslint-disable-next-line
      const response = await ChecklistFinder.post("/getItemEventInfo", {
        taskId,
        itemEventId,
        currentUserSiteId,
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };*/

  const [taskId, setTaskId] = useState("");

  const editTask = async (
    tid,
    taskdescription,
    noterequired,
    taskname,
    invid
  ) => {
    // // console.log(invid);
    fetchTask(invid);
    setTaskId(tid);
    setTaskDesciption(taskdescription);
    setRequiredNoteChecked(noterequired);
    setTaskName(taskname);
    togglePopup444();
  };

  const [bagName, setBagName] = useState("");

  const editTaskBag = async (
    tid,
    taskdescription,
    noterequired,
    bagname,
    bagid,
    clid
  ) => {
    //  // // console.log(tid, taskdescription, noterequired, bagname, bagid, clid);
    setTaskId(tid);
    setTaskDesciption(taskdescription);
    handleNoteRequiredChecked(noterequired);
    setBagName(bagname);
    setCurrentCid(clid);
    togglePopup555();
  };

  const handleDeleteTask = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    try {
      //eslint-disable-next-line
      const response = await ChecklistFinder.post("/deleteTask", {
        taskId: taskId,
        clid: checklistId,
        currentUserSiteId,
      });
      //   // // console.log(response.data.data.taskInfo);
      setTaskGroup(response.data.data.taskgroupInfo);
      setTask(response.data.data.taskInfo);

      setIsOpen444(false);
    } catch (err) {}
  };

  const [checklistInfo, setChecklistInfo] = useState("");
  const [inventoryInfo, setInventoryInfo] = useState([]);
  const [compartments, setCompartments] = useState([]);

  //call to add

  const setBagEventId = (bagid) => {
    setBagId(bagid);
    setItemId();
  };

  const [linkedCompartment, setLinkedCompartment] = useState("");

  const [checklistId, setChecklistId] = useState("");
  const [checklistName, setChecklistName] = useState("");
  const [repeatNumber, setRepeatNumber] = useState("");
  const [repeatMonth, setRepeatMonth] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [scheduledDate, setScheduledDate] = useState("");

  let optionsRepeat = [
    { value: "None", label: "None" },
    { value: "Month", label: "On a specific day of the month" },
    {
      value: "Days",
      label: "A number of days from the last Scheduled Date",
    },
    {
      value: "Months",
      label: "A number of months from the last Scheduled Date",
    },
  ];

  let optionsStatus = [
    { value: "Active", label: "Active" },
    {
      value: "Disabled",
      label: "Disabled",
    },
  ];

  //update checklist
  const handleUpdate = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    try {
      setLoading(true);
      // eslint-disable-next-line
      const response2 = await ChecklistFinder.post(`/editChecklist`, {
        checklistId: checklistId,
        checklistName,
        repeatNumber: repeatMonth === "Month" ? "1" : repeatNumber,
        repeatMonth,
        selectedStatus,
        scheduledDate,
        currentUserSiteId,
      });
      setLoading(false);
      // // // console.log(response2.data.data.checklistInfo[0].scheduleddate);

      setChecklistInfo(response2.data.data.checklistInfo);
      setChecklistId(response2.data.data.checklistInfo[0].clid);
      setChecklistName(response2.data.data.checklistInfo[0].checklistname);
      setRepeatMonth(response2.data.data.checklistInfo[0].repeat);
      setRepeatNumber(response2.data.data.checklistInfo[0].repeatnumber);
      setSelectedStatus(response2.data.data.checklistInfo[0].status);
      setScheduledDate(
        new Date(response2.data.data.checklistInfo[0].scheduleddate)
          .toISOString()
          .substring(0, 10)
      );
      editChecklistPopup();
    } catch (err) {
      setLoading(false);
    }
  };

  const handleDeleteChecklist = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    try {
      //eslint-disable-next-line
      const response = await ChecklistFinder.post("/deleteChecklist", {
        clid: checklistId,
        currentUserSiteId,
      });

      history(`/checklist`);
    } catch (err) {}
  };

  const [selected, setSelected] = useState("");

  function changeColor(id) {
    setSelected(id);
    //  // // console.log(selected);
  }

  const handleAlertMember = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {
      setLoading(true);

      const response = await ChecklistFinder.post("/editAlertMembers", {
        addTextMember,
        addEmailMember,
        clid: checklistId,
        currentUserSiteId,
      });
      setLoading(false);

      setAlertMembersText(response.data.data.alertMembersText);
      setAlertMembersEmail(response.data.data.alertMembersEmail);
      setAllMemberList(response.data.data.allMemberList);

      editAlertsPopup();
    } catch (err) {
      setLoading(false);
    }
  };

  const fetchSelectedItemTask = async (invid, name) => {
    // // console.log(itemid);
    // // console.log(invid);
    try {
      const response = await ChecklistFinder.get("/getSelectedItemChecklsit", {
        params: { invid: invid, currentUserSiteId },
      });
      setSelectedItemEvents(response.data.data.itemevent);
      setSelectedItemName(name);
      setCurrentTaskItemId(invid);
      togglePopupSelectedItem();
    } catch (err) {
      // // console.log(err);
    }
  };

  const [selectedItemEvents, setSelectedItemEvents] = useState([]);

  const [selectedItemOpen, setSelectedItemOpen] = useState(false);
  const [selectedItemName, setSelectedItemName] = useState("");

  const togglePopupSelectedItem = () => {
    setSelectedItemOpen(!selectedItemOpen);
    setIsOpen5(!isOpen5);
  };

  const addItemEvent = async (itemeventid) => {
    try {
      const response = await ChecklistFinder.post("/selectedItemAddEvent", {
        itemeventid: itemeventid,
        invid: currentTaskItemId,
        clid: checklistId,
        currentUserSiteId,
        tgid: currentTaskGroupId,
      });
      setSelectedItemOpen(!selectedItemOpen);
      setTask(response.data.data.taskInfo);
    } catch (err) {
      // // console.log(err);
    }
  };

  const sideBar = document.getElementById("sidebar");



  return (
    <>
      {checklistInfo &&
        checklistInfo.map((data, i) => {
          return (
            <div key={i}>
              <div
                id="topbar"
                className={
                  sideBar === null
                    ? "topbar headerActive"
                    : sideBar.clientWidth === 80
                    ? "topbar headerActive collapse"
                    : "topbar headerActive"
                }
              >
                <div className="goBackDiv" onClick={() => history(-1)}>
                  <IoMdArrowRoundBack />
                </div>
                <div className="addItem" onClick={editChecklistPopup}>
                  +
                </div>
                <Header content={<b>{data.checklistname}</b>} />
              </div>

              <div className="checklistPageheader">
                <div className="itemPageName">
                  <b>{data.checklistname}</b>
                </div>

                <div className="itemPageSideRightContainer">
                  <div className="itemPageAmountContainer">
                    <div className="itemPageAmountName">
                      <b>Location</b>
                    </div>
                    <div className="itemPageAmountNumber">
                      <b>{data.apparatusname}</b>
                    </div>
                  </div>

                  <div className="itemPageAmountContainer">
                    <div className="itemPageAmountName">
                      <b>Repeat</b>
                    </div>
                    <div className="itemPageAmountNumber">
                      <b>
                        {data.repeat === "None"
                          ? data.repeat
                          : `${data.repeatnumber} - ${data.repeat}`}
                      </b>
                    </div>
                  </div>

                  <div className="itemPageAmountContainer">
                    <div className="itemPageAmountName">
                      <b>Next Scheduled Date</b>
                    </div>
                    <div className="itemPageAmountNumber">
                      <b>{data.scheduleddate}</b>
                    </div>
                  </div>

                  <div className="itemPageAmountContainer">
                    <div className="itemPageAmountName">
                      <b>Receive Alerts</b>
                    </div>

                    <div className="itemPageAmountNumber">
                      {allMemberList &&
                        allMemberList.map((data2, ii) => {
                          return (
                            <b key={ii}>
                              {ii === 0 ? (
                                <>
                                  {data2.firstname} {data2.lastname}
                                </>
                              ) : (
                                <>
                                  ,&nbsp;{data2.firstname} {data2.lastname}
                                </>
                              )}
                            </b>
                          );
                        })}
                    </div>
                  </div>

                  <div className="itemPageAmountContainer2">
                    <div className="itemPageAmountName">
                      <b>Status</b>
                    </div>
                    <div className="itemPageAmountNumber">
                      <b>{data.status}</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

      {taskGroup &&
        taskGroup.map((data, i) => {
          //// // console.log(data);
          return (
            <div key={i}>
              <div className="editTaskGroupContainer">
                <div className="editTaskGroupName">
                  <div className="taskGroupHeader">
                    <div className="taskDiv">
                      <b>{data.taskgroupname}</b>
                    </div>

                    <div
                      className="editTaskDiv"
                      onClick={() =>
                        editTaskGroup(
                          data.tgid,
                          data.taskgroupname,
                          data.cid,
                          data.name,
                          data.allitems,
                          data.displayorder
                        )
                      }
                    >
                      <b>Edit</b>
                    </div>
                  </div>

                  <div className="taskGroupContainer">
                    {task &&
                      task.map((data2, ii) => {
                        // // console.log(data2);
                        if (data2.tgid === data.tgid) {
                          if (data2.bagname != null) {
                            return (
                              <div key={ii}>
                                <div className="taskContainer">
                                  <div className="task">
                                    {data2.bagname}
                                    <div className="checklistPageTaskDescription">
                                      {data2.name}
                                      {data2.identificationname
                                        ? " - " + data2.identificationname
                                        : ""}
                                    </div>
                                  </div>

                                  <div
                                    className="editTaskDiv2"
                                    onClick={() =>
                                      editTaskBag(
                                        data2.tid,
                                        data2.taskdescription,
                                        data2.noterequired,
                                        data2.bagname,
                                        data2.bagid,
                                        data.cid
                                      )
                                    }
                                  >
                                    Edit
                                  </div>
                                </div>
                              </div>
                            );
                          } else if (data2.name != null) {
                            return (
                              <div key={ii}>
                                <div className="taskContainer">
                                  <div className="task">
                                    {data2.taskname}
                                    <div className="checklistPageTaskDescription">
                                      Item - {data2.name}
                                      {data2.identificationname
                                        ? " - " + data2.identificationname
                                        : ""}
                                    </div>
                                    <div className="checklistPageTaskDescription">
                                      Task - {data2.taskdescription}
                                    </div>
                                  </div>

                                  <div
                                    className="editTaskDiv2 "
                                    onClick={() =>
                                      editTask(
                                        data2.tid,
                                        data2.taskdescription,
                                        data2.noterequired,
                                        data2.taskdescription,
                                        data2.invid
                                      )
                                    }
                                  >
                                    Edit
                                  </div>
                                  <div className="taskItemEventHolder">
                                    {/*data2.itemevents.map((data3, i) => (
                                      <div
                                        key={i}
                                        className="taskItemEventList"
                                      >
                                        - {data3.eventname}
                                      </div>
                                    ))*/}
                                  </div>
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div key={ii}>
                                <div className="taskContainer">
                                  <div className="task">
                                    {data2.taskdescription}
                                    <div className="checklistPageTaskDescription"></div>
                                  </div>

                                  <div
                                    className="editTaskDiv2"
                                    onClick={() =>
                                      editTask(
                                        data2.tid,
                                        data2.taskdescription,
                                        data2.noterequired,
                                        data2.taskdescription
                                      )
                                    }
                                  >
                                    Edit
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        } else {
                          return <div key={ii}></div>;
                        }
                      })}

                    <div
                      className="addTaskDiv"
                      onClick={() => togglePopup2(data.tgid, data.cid)}
                    >
                      + Add Task
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}

      <div className="addTaskGropDiv">
        <div className="addTaskGroupContainer" onClick={togglePopup3}>
          <b> Add Task Group</b>
        </div>
      </div>

      {isOpen3 && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleNewTaskGroup}>
                <button
                  type="submit"
                  className="submitItemBtn2"
                  disabled={loading}
                >
                  Save
                </button>
                <div className="popupTitle">
                  <b>New Task Group</b>
                </div>
                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>
                <div className="popupContainer">
                  <label className="NewInputLabel" data-domain="Add Name">
                    <input
                      className="NewInput"
                      type="text"
                      placeholder=""
                      value={addTaskGroupName}
                      required
                      onChange={(e) => setTaskGroupName(e.target.value)}
                    />
                  </label>

                  <label
                    className="NewInputLabel"
                    data-domain="Compartment Link"
                  >
                    <select
                      className="NewInput"
                      onChange={(event) =>
                        setLinkedCompartment(event.target.value)
                      }
                      required
                      defaultValue=""
                    >
                      <option value="" disabled>
                        Compartment Link
                      </option>
                      <option value="">None</option>

                      {compartments.map(function (e, i) {
                        return (
                          <option key={i} value={e.cid}>
                            {e.compartmentname}
                          </option>
                        );
                      })}
                    </select>
                  </label>

                  <label className="NewInputLabel" data-domain="Display Order">
                    <input
                      className="NewInput"
                      type="number"
                      placeholder=""
                      value={
                        addTaskGroupdDisplayOrder === null
                          ? ""
                          : addTaskGroupdDisplayOrder
                      }
                      onChange={(e) => setTaskGroupDisplayOrder(e.target.value)}
                    />
                  </label>
               {/*}   <label className="checkboxLabel">
                    <input
                      type="checkbox"
                      checked={
                        editAllItems === true || editAllItems === "true"
                          ? true
                          : false
                      }
                      className="NewInputCheckBox"
                      onChange={(e) => handleCheckBoxChange2(e.target.value)}
                      name="checkbox-checked"
                    />
                    <div className="checkboxLabel">
                      Include all items in the compartment
                    </div>
                    </label>*/}
                </div>
              </form>
            </>
          }
          handleClose={togglePopup3}
        />
      )}

      {isOpen2 && (
        <PopupWeb
          content={
            <>
              <div id="holderTest" className="holderTest">
                <div className="popupTitle3">
                  <b>New Task Type </b>
                </div>
              </div>
              <div className="emptySpace"></div>
              <div className="text-center p-3">
                <ClipLoader loading={loading} className="override" size={35} />
              </div>
              <div className="popupContainer">
                <div className="btnDiv">
                  <div className="addTaskTypeBtn" onClick={togglePopup4}>
                    <div className="addTaskTypeBtn2">Add Custom Task</div>
                  </div>
                  {currentCid ? (
                    <div className="addTaskTypeBtn" onClick={togglePopup5}>
                      <div className="addTaskTypeBtn2"> Add Item Task </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="popupBtnContainers"></div>
            </>
          }
          handleClose={togglePopup2}
        />
      )}

      {isOpen4 && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleNewTask}>
                <div id="holderTest" className="holderTest">
                  <button
                    type="submit"
                    className="submitItemBtn2"
                    disabled={loading}
                  >
                    Done
                  </button>

                  <div className="popupTitle3">
                    <b>New Custom Task</b>
                  </div>
                </div>
                <div className="emptySpace"></div>
                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>

                <div className="popupContainer">
                  <label
                    className="NewInputLabel"
                    data-domain=" Task Description"
                  >
                    <input
                      className="NewInput"
                      type="text"
                      placeholder=""
                      value={taskDesciption}
                      onChange={(e) => setTaskDesciption(e.target.value)}
                      required
                    />
                  </label>
                </div>
              </form>
            </>
          }
          handleClose={togglePopup4}
        />
      )}

      {isOpen44 && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleEditTask}>
                <div id="holderTest" className="holderTest">
                  <button
                    type="submit"
                    className="submitItemBtn2"
                    disabled={loading}
                  >
                    Save
                  </button>

                  <div className="popupTitle3">
                    <b>Edit Task </b>
                  </div>
                </div>
                <div className="emptySpace"></div>
                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>

                <div className="popupContainer">
                  <label
                    className="NewInputLabel"
                    data-domain="Task Description"
                  >
                    <input
                      className="NewInput"
                      type="text"
                      placeholder=""
                      value={taskDesciption}
                      onChange={(e) => setTaskDesciption(e.target.value)}
                      required
                    />
                  </label>

                  <div className="editApparatusBtnContainer">
                    <div className="editApparatusBtn" onClick={togglePopup4444}>
                      <b>Delete</b>
                    </div>
                  </div>
                </div>
              </form>
            </>
          }
          handleClose={togglePopup444}
        />
      )}

      {isOpen444 && (
        <PopupWeb
          content={
            <>
              {addTaskName ? (
                <div className="popupTitle">
                  <b>Delete Task - {addTaskName} </b>
                </div>
              ) : (
                ""
              )}

              {bagName ? (
                <div className="popupTitle">
                  <b>Delete Bag Task - {bagName} </b>
                </div>
              ) : (
                ""
              )}

              <div className="editApparatusBtnContainer">
                <div
                  className="confirmCancelApparatusBtn"
                  onClick={togglePopup4444}
                >
                  <b>Cancel</b>
                </div>
                <div
                  className="confirmDeleteApparatusBtn"
                  onClick={handleDeleteTask}
                  //onClick={togglePopup2}
                >
                  <b>Delete</b>
                </div>
              </div>
            </>
          }
          handleClose={togglePopup4444}
        />
      )}

      {isOpen5 && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleNewInventory}>
                <div id="holderTest" className="holderTest">
                  <div className="popupTitle3">
                    <b>Select Item</b>
                  </div>
                </div>
                <div className="emptySpace"></div>
                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>

                <div className="popupContainer">
                  {inventoryInfo &&
                    //eslint-disable-next-line
                    inventoryInfo.map((post, i) => {
                      // console.log(post);

                      return (
                        <div key={i}>
                          <div
                            className="checklistEditItemContainer"
                            onClick={() => {
                              fetchSelectedItemTask(post.id, post.name);
                            }}
                            style={{
                              background:
                                selected === `color${i}` ? "#1fe6fb" : "",
                            }}
                          >
                            <div className="checklistEditItem">{post.name}</div>
                            <div className="checklistEditItemright">
                              {post.category}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </form>
            </>
          }
          handleClose={togglePopup5}
        />
      )}

      {selectedItemOpen && (
        <PopupWeb
          content={
            <>
              <div id="holderTest" className="holderTest">
                <button
                  type="submit"
                  className="submitItemBtn2"
                  disabled={loading}
                >
                  Custom
                </button>

                <div className="popupTitle3">
                  <b>{selectedItemName} Saved Events</b>
                </div>
              </div>
              <div className="emptySpace"></div>
              <div className="text-center p-3">
                <ClipLoader loading={loading} className="override" size={35} />
              </div>

              <div className="popupContainer">
                {selectedItemEvents.length > 0 ? (
                  selectedItemEvents.map((data, i) => {
                    //loops 3 times idk why
                    // // console.log(data);
                    if (currentEvent === data.eventname) {
                      return (
                        <div key={i}>
                          <div className="itemListTaskDescContainer">
                            <div className="itemListTaskDesc">
                              {data.taskdescription}
                            </div>
                          </div>
                        </div>
                      );
                    } else {
                      currentEvent = data.eventname;
                      return (
                        <div key={i}>
                          <div className="itemFirstEventName">
                            <div className="itemListEventName">
                              {data.eventname}
                            </div>
                            <div
                              className="addItemTaskBtn"
                              onClick={() => addItemEvent(data.itemeventid)}
                            >
                              Add
                            </div>
                          </div>
                          {data.taskdescription ? (
                            <div className="itemListTaskDescContainer">
                              <div className="itemListTaskDesc">
                                {data.taskdescription}
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      );
                    }
                  })
                ) : (
                  <>
                    <div className="apparatusEmptyHolder">
                      <div className="apparatusEmptyName">
                        No Saved Tasked For This Item
                      </div>
                      <div className="apparatusEmptyButton"></div>
                    </div>
                  </>
                )}
              </div>
            </>
          }
          handleClose={togglePopupSelectedItem}
        />
      )}

      {taskOpen && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleNewInventory}>
                <button
                  type="submit"
                  className="submitItemBtn2"
                  disabled={loading}
                >
                  Save
                </button>
                <div className="popupTitle">
                  <b>Select An Item</b>
                </div>

                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>

                <div className="checklistEditPopupDiv">
                  {inventoryInfo &&
                    //eslint-disable-next-line
                    inventoryInfo.map((post, i) => {
                      // // // console.log(inventoryInfo);
                      let loopingGroup = post.cid;
                      if (loopingGroup === currentCid) {
                        return (
                          <div key={i}>
                            {i === 0 ? (
                              <div className="checklistEditItemHeader">
                                <b>{post.compartmentname}</b>
                              </div>
                            ) : (
                              ""
                            )}

                            {post.bagname ? (
                              <div
                                className="checklistEditItemContainer"
                                onClick={() => {
                                  setBagEventId(post.bagid);
                                  changeColor(`color${i}`);
                                }}
                                style={{
                                  background:
                                    selected === `color${i}` ? "#1fe6fb" : "",
                                }}
                              >
                                <div className="checklistEditItem">
                                  {post.bagname}
                                </div>
                                <div className="itemListPageAmount">1</div>
                              </div>
                            ) : (
                              <div
                                className="checklistEditItemContainer"
                                onClick={() => {
                                  setItemEventId(post.itemid);
                                  changeColor(`color${i}`);
                                }}
                                style={{
                                  background:
                                    selected === `color${i}` ? "#1fe6fb" : "",
                                }}
                              >
                                <div className="checklistEditItem">
                                  {post.itemname}
                                </div>
                                <div className="itemListPageAmount">
                                  {post.amount}
                                </div>
                              </div>
                            )}
                          </div>
                        );
                        //eslint-disable-next-line
                      } else {
                      }
                    })}
                </div>
              </form>
            </>
          }
          handleClose={toggleTaskPopup}
        />
      )}

      {isOpen55 && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleEditTask}>
                <button
                  type="submit"
                  className="submitItemBtn2"
                  disabled={loading}
                >
                  Save
                </button>
                <div className="popupTitle">
                  <b>Edit Task Bag</b>
                </div>
                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>
                <div className="editBag">
                  <b>Current Bag - {bagName}</b>
                </div>

                <input
                  value={taskDesciption}
                  onChange={(e) => setTaskDesciption(e.target.value)}
                  className="addItemInput"
                  type="text"
                  placeholder="Add Task Description"
                  required
                />
                <div className="editApparatusBtnContainer">
                  <div className="editApparatusBtn" onClick={togglePopup4444}>
                    <b>Delete</b>
                  </div>
                </div>
              </form>
            </>
          }
          handleClose={togglePopup555}
        />
      )}

      {isOpen6 && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleEditTaskGroup}>
                <div id="holderTest" className="holderTest">
                  <button
                    type="submit"
                    className="submitItemBtn2"
                    disabled={loading}
                  >
                    Save
                  </button>
                  <div className="popupTitle3">
                    <b>Edit Task Group</b>
                  </div>
                </div>
                <div className="emptySpace"></div>

                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>

                <div className="popupContainer">
                  <label className="NewInputLabel" data-domain="Add Name">
                    <input
                      className="NewInput"
                      type="text"
                      placeholder=""
                      value={addTaskGroupName}
                      required
                      onChange={(e) => setTaskGroupName(e.target.value)}
                    />
                  </label>

                  <label
                    className="NewInputLabel"
                    data-domain="Compartment Link"
                  >
                    <select
                      className="NewInput"
                      onChange={(event) =>
                        setLinkedCompartment(event.target.value)
                      }
                      required
                      defaultValue={linkedCompartment}
                    >
                      <option value="" disabled>
                        Compartment Link
                      </option>
                      <option value="">None</option>
                      {compartments.map(function (e, i) {
                        return (
                          <option key={i} value={e.cid}>
                            {e.compartmentname}
                          </option>
                        );
                      })}
                    </select>
                  </label>

                  <label className="NewInputLabel" data-domain="Display Order">
                    <input
                      className="NewInput"
                      type="number"
                      placeholder=""
                      value={
                        addTaskGroupdDisplayOrder === null
                          ? ""
                          : addTaskGroupdDisplayOrder
                      }
                      onChange={(e) => setTaskGroupDisplayOrder(e.target.value)}
                    />
                  </label>

          {/*     <label className="checkboxLabel">
                    <input
                      type="checkbox"
                      checked={
                        editAllItems === true || editAllItems === "true"
                          ? true
                          : false
                      }
                      className="NewInputCheckBox"
                      onChange={(e) => handleCheckBoxChange2(e.target.value)}
                      name="checkbox-checked"
                    />
                    <div className="checkboxLabel">
                      Include all items in the compartment
                    </div>
                    </label>*/}
                </div>
              </form>

              <div className="editApparatusBtnContainer">
                <div className="editApparatusBtn" onClick={togglePopup66}>
                  <b>Delete</b>
                </div>
              </div>
            </>
          }
          handleClose={togglePopup6}
        />
      )}

      {isOpen66 && (
        <PopupWeb
          content={
            <>
              <div className="popupTitle">
                <b>Delete Task Group - {addTaskGroupName} </b>
              </div>

              <div className="editApparatusBtnContainer">
                <div
                  className="confirmCancelApparatusBtn"
                  onClick={togglePopup66}
                >
                  <b>Cancel</b>
                </div>
                <div
                  className="confirmDeleteApparatusBtn"
                  onClick={handleDeleteTaskGroup}
                  //onClick={togglePopup2}
                >
                  <b>Delete</b>
                </div>
              </div>
            </>
          }
          handleClose={togglePopup66}
        />
      )}

      {editChecklistOpen && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleUpdate}>
                <div id="holderTest" className="holderTest">
                  <button
                    type="submit"
                    className="submitItemBtn2"
                    disabled={loading}
                  >
                    Save
                  </button>
                  <div className="popupTitle3">
                    <b>Edit Checklist Details</b>
                  </div>
                </div>
                <div className="emptySpace"></div>

                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>
                <div className="popupContainer">
                  {checklistInfo &&
                    checklistInfo.map((data, i) => {
                      //// // console.log(data);
                      return (
                        <div key={i}>
                          <div className="itemPageInspectionContainer">
                            <label
                              className="NewInputLabel"
                              data-domain="Checklist Name"
                            >
                              <input
                                className="NewInput"
                                type="text"
                                placeholder=""
                                value={checklistName}
                                onChange={(e) =>
                                  setChecklistName(e.target.value)
                                }
                                required
                              />
                            </label>

                            <div className="editChecklistSelectHolder">
                              <Select
                                isSearchable={false}
                                options={optionsRepeat}
                                onChange={(e) => setRepeatMonth(e.value)}
                                name="addMembers"
                                className="basic-multi-select"
                                classNamePrefix="select"
                                defaultValue={
                                  data.repeat === "Month"
                                    ? {
                                        value: "Month",
                                        label: "On a specific day of the month",
                                      }
                                    : data.repeat === "Days"
                                    ? {
                                        value: "Days",
                                        label:
                                          "A number of days from the last Scheduled Date",
                                      }
                                    : data.repeat === "None"
                                    ? {
                                        value: "Mone",
                                        label: "None",
                                      }
                                    : {
                                        value: "Months",
                                        label:
                                          "A number of months from the last Scheduled Date",
                                      }
                                }
                              />
                            </div>
                            <div className="editChecklistSelectInputHolder">
                              {repeatMonth === "Month" ? (
                                ""
                              ) : repeatMonth === "None" ? (
                                ""
                              ) : (
                                <input
                                  value={repeatNumber}
                                  onChange={(e) =>
                                    setRepeatNumber(e.target.value)
                                  }
                                  className="addItemInput"
                                  type="number"
                                  placeholder="Repeat Number"
                                  required
                                />
                              )}
                            </div>

                            <div className="selectOptionDivChecklist">
                              <Select
                                isSearchable={false}
                                defaultValue={
                                  data.status === "Active"
                                    ? { label: "Active", value: "Active" }
                                    : { label: "Disabled", value: "Disabled" }
                                }
                                options={optionsStatus}
                                onChange={(e) => setSelectedStatus(e.value)}
                                classNamePrefix="select"
                                className="basic-multi-select"
                              />
                            </div>

                            <label
                              className="NewInputLabel"
                              data-domain="Start Date"
                            >
                              <input
                                className="NewInput"
                                type="date"
                                value={scheduledDate}
                                onChange={(e) =>
                                  setScheduledDate(e.target.value)
                                }
                                onFocus={(event) =>
                                  (event.nativeEvent.target.defaultValue = "")
                                }
                                required
                              />
                            </label>
                          </div>

                          <div className="editAlertContainer">
                            <div
                              className="apparatusInventoryLink"
                              onClick={editAlertsPopup}
                            >
                              <b>Edit Alerts</b>
                            </div>
                          </div>

                          <div className="editChecklistDeleteContainer">
                            <div
                              className="editApparatusBtn"
                              onClick={editChecklistPopup2}
                            >
                              <b>Delete</b>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </form>
            </>
          }
          handleClose={editChecklistPopup}
        />
      )}

      {editChecklistOpen2 && (
        <PopupWeb
          content={
            <>
              <div className="popupTitle">
                <b>Delete Checklist - {checklistName} </b>
              </div>

              <div className="deleteInfoApparatusContainer">
                <b>This checklist will no longer be available.</b>
              </div>

              <div className="editApparatusBtnContainer">
                <div
                  className="confirmCancelApparatusBtn"
                  onClick={editChecklistPopup2}
                >
                  <b>Cancel</b>
                </div>
                <div
                  className="confirmDeleteApparatusBtn"
                  onClick={handleDeleteChecklist}
                  //onClick={togglePopup2}
                >
                  <b>Delete</b>
                </div>
              </div>
            </>
          }
          handleClose={editChecklistPopup2}
        />
      )}

      {editAlertsOpen && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleAlertMember}>
                <div id="holderTest" className="holderTest">
                  <button
                    type="submit"
                    className="submitItemBtn2"
                    disabled={loading}
                  >
                    Save
                  </button>
                  <div className="popupTitle">
                    <b>Edit Alerts </b>
                  </div>
                </div>
                <div className="emptySpace"></div>
                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>
                <div className="popupContainer">
                  <div className="alertMemberHeader">
                    <b>Member Text Alerts</b>
                  </div>
                  <div className="detailsPopupContainer">
                    <Select
                      isMulti
                      options={options22}
                      onChange={setAddTextMember}
                      name="addMembers"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      defaultValue={optionsTextDefault}
                    />
                  </div>
                  <div className="alertMemberHeader2"></div>
                  <div className="alertMemberHeader">
                    <b>Member Email Alerts</b>
                  </div>
                  <div className="detailsPopupContainer">
                    <Select
                      isMulti
                      options={options22}
                      onChange={setAddEmailMember}
                      name="addMembers"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      defaultValue={optionsEmailDefault}
                    />
                  </div>
                </div>
              </form>
            </>
          }
          handleClose={editAlertsPopup}
        />
      )}
    </>
  );
};

export default ChecklistEditPage;
