import React, { useState, useRef } from "react";

import ApparatusFinder from "../../apis/ApparatusFinder";
import Header from "../Header/Header";

import { RiImageAddFill, RiCloseFill } from "react-icons/ri";

import ClipLoader from "react-spinners/ClipLoader";
import { useAuth } from "../../context/AuthContext";
//import Scanner from "../Scanner/Scanner";
import { AiOutlinePlus } from "react-icons/ai";

import { useMainLocationsData } from "./fetchApparatusData";

import { AiOutlineMenu } from "react-icons/ai";
import Select from "react-select";
import PopupWeb from "../Popup/PopupNew";
import ToastNotifier, { notify } from "../Popup/ToastNotifier";

const ApparatusHeader = () => {
  const { currentUserSiteId } = useAuth();
  const { refetch } = useMainLocationsData(currentUserSiteId);


  const [loading, setLoading] = useState(false);

  //Input useStae
  const [name, setName] = useState("");
  //const [status, setStatus] = useState("");

  const [status, setStatus] = useState("");
  const [apparatusType, setApparatusType] = useState("");
  const [appartusOrder, setApparatusOrder] = useState("");

  const [file, setFile] = useState(null);

  const headers = {
    "content-type": "multipart/form-data",
  };

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!name) newErrors.name = "Please enter a name";
    if (!apparatusType) newErrors.apparatusType = "Please select a type";
    if (!status) newErrors.status = "Please select a status";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };


  const clearForm = () => {
    setSelectedImage(null);
    setName("");
    setApparatusType("");
    setStatus("");
    setApparatusOrder(null);
    setFile(null);
    setErrors({});
  };

  //Popup
  const [isOpen1, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen1);
    setLoading(false);
    clearForm();
  };


  const handleSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    try {
      if (!validateForm()) {
        return;
      }

      setLoading(true);
      const formData = new FormData();
      // // console.log("handleFileInput working!");

      formData.set("name", name);
      formData.set("status", status);
      formData.set("apparatusType", apparatusType);
      formData.set("currentUserSiteId", currentUserSiteId);
      formData.set("appartusOrder", appartusOrder);

      // Ensure selectedImage is an array
      if (Array.isArray(selectedImage)) {
        selectedImage.forEach((image, index) => {
          formData.append(`file${index}`, image);
        });
      } else if (selectedImage) {
        // If it's a single image, append it directly
        formData.append("file", selectedImage);
      }
      // eslint-disable-next-line
      const response = await ApparatusFinder.post(
        "/createapparatus",
        formData,
        headers
      );
      refetch();
       clearForm();
      togglePopup();
      notify("Location created successfully!", "success"); // Show success notification
    } catch (err) {
      // console.log(err);
      setLoading(false);
      notify("Failed to create location. Please try again.", "error"); // Show error notification
    
    }
  };


  const handleFileInput = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };
  const removehandleFileInput = (index) => {
    setSelectedImage(null);
  };

  const options = [
    { value: "IN-SERVICE", label: "IN-SERVICE" },
    { value: "OUT-OF-SERVICE", label: "OUT-OF-SERVICE" },
    { value: "N/A", label: "N/A" },
  ];

  const options2 = [
    { value: "Vehicle", label: "Vehicle" },
    { value: "Storage", label: "Storage" },
  ];

  //custom image
  const fileRef = useRef();
  const [selectedImage, setSelectedImage] = useState("");

  const toggleNav = () => {
    let topbar = document.getElementById("topbar");
    let sideBar = document.getElementById("sidebar");
    let appContainer = document.getElementById("appContainer");
    let appContainerHidden = document.getElementById("appContainerHidden");

    topbar.classList.toggle("collapse");
    sideBar.classList.toggle("collapse");
    appContainer.classList.toggle("collapse");
    appContainerHidden.classList.toggle("collapse");
  };



  return (
    <>
      <div
        id="topbar"
        className="topbar headerActive"
        /* className={
          sideBar === null
            ? "topbar headerActive"
            : sideBar.clientWidth === 80
            ? "topbar headerActive collapse"
            : "topbar headerActive"
        }*/
      >
        <div className="hometopbarleft" onClick={toggleNav}>
          <AiOutlineMenu />
        </div>
        <div className="addItemReact" onClick={togglePopup}>
          <AiOutlinePlus size={28} />
        </div>
        <Header content={<b>Locations</b>} />
      </div>
      <div className="topbarEmptySpace"></div>

      {isOpen1 && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleSubmit}>
                <div className="emptySpace"></div>

                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>
                <div className="popupContainer">
                  <label className="NewInputLabel" data-domain="Name">
                    <input
                      className="NewInput"
                      type="text"
                      placeholder=""
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                    {errors.name && (
                      <div className="errorInputLabel">{errors.name}</div>
                    )}
                  </label>

                  <label className="NewInputLabel" data-domain="Type">
                    <Select
                      options={options2}
                      onChange={(e) => setApparatusType(e.value)}
                      name="addMembers"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    {errors.apparatusType && (
                      <div className="errorInputLabel">
                        {errors.apparatusType}
                      </div>
                    )}
                  </label>

                  <label className="NewInputLabel" data-domain="Status">
                    <Select
                      options={options}
                      onChange={(e) => setStatus(e.value)}
                      name="addMembers"
                      className="basic-multi-select"
                      classNamePrefix="select"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    {errors.status && (
                      <div className="errorInputLabel">{errors.status}</div>
                    )}
                  </label>

                  <label className="NewInputLabel" data-domain="Display Order">
                    <input
                      className="NewInput"
                      type="number"
                      placeholder=""
                      value={appartusOrder === null ? "" : appartusOrder}
                      onChange={(e) => setApparatusOrder(e.target.value)}
                    />
                  </label>

              

                  <div className="NewInputLabel" data-domain="Add Image">
                  <input
                    ref={fileRef}
                    onChange={handleFileInput}
                    type="file"
                    accept="image/*"
                    hidden
                  />
                  <div
                    onClick={() => fileRef.current.click()}
                    className="NewInputImage"
                  >
                    <RiImageAddFill size={30} />
                  </div>
                  <div className="imagePreviewContainer">
                    {selectedImage && (
                      <div className="imageHolder">
                        <img
                          src={URL.createObjectURL(selectedImage)}
                          alt="imageDidNotLoad"
                          className="addImage"
                        />
                        <button
                          type="button"
                          className="removeImageButton"
                          onClick={removehandleFileInput}
                        >
                          <RiCloseFill size={20} />
                        </button>
                      </div>
                    )}
                  </div>
                </div>


                </div>
              </form>
            </>
          }
          handleClose={togglePopup}
          isOpen={isOpen1}
          handleSubmit={handleSubmit}
          showSubmitButton={true}
          headerText={<b> New Location</b>}
          submitButtonText={loading ? "Loading..." : "Add"} 
          loading={loading}

        />
      )}


    <ToastNotifier />
    </>
  );
};

export default ApparatusHeader;
