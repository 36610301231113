import React from "react";

import ChecklistEditPage from "../components/Checklist/ChecklistEditPage";

import "../components/Checklist/Checklist.css";

function ChecklistEdit() {
  return (
    <div>
      <ChecklistEditPage />
    </div>
  );
}

export default ChecklistEdit;
