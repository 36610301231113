import React from "react";
import SignUpHeader from "../components/Login/SignUpHeader";

const Login = () => {
  return (
    <>
      <SignUpHeader />
    </>
  );
};

export default Login;
