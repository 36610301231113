import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import InventoryFinder from "../../apis/InventoryFinder";
import { useAuth } from "../../context/AuthContext";
import { ItemPageContext } from "../../context/ItemPageContext";

import imageToBase64 from "image-to-base64/browser";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import PublicFolder from "../../apis/PublicFolder";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ItemPageUsage = () => {
  const { id } = useParams();

  const { currentUserSiteId } = useAuth();

  const history = useNavigate();
  const { itemInfo } = useContext(ItemPageContext);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await InventoryFinder.get(`/getItemUsageOnLoad`, {
          params: {
            currentUserSiteId,
          },
        });
        console.log("test");

        setTrainingList(response.data.data.trainingList);
        console.log(response.data.data.trainingList);
      } catch (err) {
        // console.log(err);
      }
    };

    if (currentUserSiteId) {
      fetchData();
    }
  }, [currentUserSiteId]);

  let loopingMonth = null;

  const handleTraining = (id) => {
    history(`/trainingInfo/${id}`);
  };

  const [results, setResults] = useState([]);
  const [trainingList, setTrainingList] = useState([]);
  const [from, setFrom] = useState("");
  const [thru, setThru] = useState("");

  const [pdfMemberList, setPdfMemberList] = useState("");
  const [pdfFrom, setPdfFrom] = useState("");
  const [pdfThru, setPdfThru] = useState("");
  const [pdfTableResults, setPdfTableResults] = useState([]);

  const getReset = () => {
    setResults([]);
    setFrom("");
    setThru("");

    var options = document.getElementById("select-member");

    for (var ii = 0; ii < options.length; ii++) {
      options[ii].selected = false;
    }
  };

  const getResults = async (e) => {
    e.preventDefault();

    fetchResults();
    convertToBase64();
  };

  const getPDF = () => {
    generatePdf();
  };

  const fetchResults = async () => {
    try {
      var options = document.getElementById("select-member").selectedOptions;
      var members = Array.from(options).map(({ value }) => value);
      var membersNames = Array.from(options).map(({ innerHTML }) => innerHTML);

      const response = await InventoryFinder.get("/getItemUsage", {
        params: {
          itemId: id,
          currentUserSiteId,
          from,
          thru,
          event: members.length === 0 ? "" : members,
        },
      });

      const fromholder = new Date(from).toLocaleDateString();
      const thruholder = new Date(thru).toLocaleDateString();
      setPdfFrom(fromholder);
      setPdfThru(thruholder);

      setPdfMemberList(
        membersNames.length === 0 ? "Not specified" : membersNames.toString()
      );

      setResults(response.data.data.results);
      setPdfTableResults(response.data.data.tableResults);
    } catch (err) {
      // console.log(err);
    }
  };

  const [base64IMG, setBase64IMG] = useState("");

  const convertToBase64 = () => {
    imageToBase64(PublicFolder.baseURL + "LogonIcon.png") // Path to the image
      .then((response) => {
        setBase64IMG(response);
      });
  };

  const test = () => {
    document.getElementById("myDropdown").classList.toggle("show");

    let arrowCollapseRight = document.getElementById("bx-arrow-from-right2");
    let arrowCollapseLeft = document.getElementById("bx-arrow-from-left2");
    arrowCollapseRight.classList.toggle("collapse");

    if (arrowCollapseRight.classList.contains("collapse")) {
      arrowCollapseRight.classList.remove("disabled");
      arrowCollapseLeft.classList.add("disabled");
    } else {
      arrowCollapseRight.classList.add("disabled");
      arrowCollapseLeft.classList.remove("disabled");
    }
  };

  let heading = [
    [
      { text: "Category", bold: true },
      { text: "Equipment", bold: true },
      { text: "Serial Number", bold: true },
      { text: "Date Used", bold: true },
      { text: "Event Name", bold: true },
    ],
  ];

  const options2 = pdfTableResults.map((elt, i) => [
    elt.type,
    elt.identificationname ? elt.identificationname : elt.name,
    elt.serialnumber,

    elt.date,
    elt.training_name,
  ]);

  let bodyData = heading.concat(options2);
  const generatePdf = async () => {
    var docDefinition = {
      // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
      pageMargins: [20, 60, 20, 20],
      // pageOrientation: "landscape",

      footer: function (currentPage, pageCount) {
        var columns = [
          {
            text: currentPage.toString() + " of " + pageCount,
            width: "auto",
            alignment: "center",
          },
        ];
        return columns;
      },

      header: {
        columns: [
          {
            text: "Inventory Report",
            bold: true,
            fontSize: 26,

            margin: [20, 20, 0, 0],
          },
          {
            image: `data:image/png;base64,${base64IMG}`,
            width: 50,
            height: 50,
            alignment: "right",
            margin: [0, 10, 15, 0],
          },
        ],
      },

      content: [
        {
          text: `Name:   ${itemInfo[0].name === null ? "-" : itemInfo[0].name}`,
          fontSize: 10,
          margin: [20, 0, 0, 10],
        },

        {
          text: `Identification Name:   ${
            itemInfo[0].identificationname === null
              ? "-"
              : itemInfo[0].identificationname
          }`,
          fontSize: 10,
          margin: [20, 0, 0, 10],
        },

        {
          text: `Amount:   ${
            itemInfo[0].amount === null ? "-" : itemInfo[0].amount
          }`,
          fontSize: 10,
          margin: [20, 0, 0, 10],
        },

        {
          text: `Serial Number:   ${
            itemInfo[0].serialnumber === null ? "-" : itemInfo[0].serialnumber
          }`,
          fontSize: 10,
          margin: [20, 0, 0, 10],
        },
        {
          text: `Manufacture Date:   ${
            itemInfo[0].manufacturedate === null
              ? "-"
              : itemInfo[0].manufacturedate
          }`,
          fontSize: 10,
          margin: [20, 0, 0, 10],
        },

        {
          text: `Usage Search`,

          bold: true,
          fontSize: 20,

          margin: [0, 10, 0, 10],
        },

        {
          text: `Date range:    ${pdfFrom} - ${pdfThru}`,
          fontSize: 10,
          margin: [20, 0, 0, 10],
        },
        {
          text: `Event:       ${pdfMemberList}`,
          fontSize: 10,
          margin: [20, 0, 0, 10],
        },
        { width: "*", text: "", margin: [0, 0, 0, 30] },
        {
          text: `Number of records: ${results.length}`,
          fontSize: 10,
          alignment: "center",
          margin: [0, 0, 0, 10],
        },
        {
          style: "tableSyle",

          // layout: "lightHorizontalLines", // optional
          table: {
            // headers are automatically repeated if the tasble spans over multiple pages
            // you can declare how many rows should be treated as headers
            headerRows: 1,
            widths: [100, 100, 100, 100, 110],

            body: bodyData,
          },
        },
      ],

      styles: {
        tableSyle: {
          fontSize: 10,
        },
      },
    };

    //pdfMake.createPdf(docDefinition).download();
    pdfMake.createPdf(docDefinition).open();
  };

  return (
    <>
      <div className="emptySpace20"></div>
      <form onSubmit={getResults} className="formClass">
        <div className="ReportResultsCommandsHolder">
          {results.length > 0 ? (
            <div className="ReportResultsCommandsPDF">
              <div className="ReportResultsCommandsBtnPDF" onClick={getPDF}>
                <b>PDF</b>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="ReportResultsCommands">
            <button className="ReportResultsCommandsGenerate" type="submit">
              <b>Generate</b>
            </button>
            <div className="ReportResultsCommandsReset" onClick={getReset}>
              <b>Reset</b>
            </div>
          </div>
        </div>
        <div className="inventorySelectHolder">
          <div className="inventorySelect1">
            <label className="NewInputLabel" data-domain="From">
              <input
                className="NewInput"
                type="date"
                placeholder=""
                value={from}
                onChange={(e) => setFrom(e.target.value)}
                required
              />
            </label>
          </div>

          <div className="inventorySelect2">
            <label className="NewInputLabel" data-domain="Thru">
              <input
                className="NewInput"
                type="date"
                placeholder=""
                value={thru}
                onChange={(e) => setThru(e.target.value)}
                required
              />
            </label>
          </div>
        </div>
      </form>
      <div className="filterdropdown">
        <button className="filterdropbtn" onClick={() => test()}>
          <div className="filterHolder">
            <div className="filterDiv">
              <b>Filter and sort options</b>
            </div>
          </div>
          <div className="filterArrow disabled" id="bx-arrow-from-right2">
            <i className="bx" id="logo-name__icon">
              <AiOutlineArrowUp />
            </i>
          </div>
          <div className="filterArrow" id="bx-arrow-from-left2">
            <i className="bx" id="logo-name__icon">
              <AiOutlineArrowDown />
            </i>
          </div>
        </button>

        <div id="myDropdown" className="dropdown-content">
          <label className="NewInputLabel" data-domain="Event">
            <select multiple className="NewInput" id="select-member">
              {trainingList.map(function (e, i) {
                return (
                  <option key={i} value={e.training_name}>
                    {e.training_name}
                  </option>
                );
              })}
            </select>
          </label>
        </div>
      </div>
      {results.length > 0 ? (
        <div className="ReportRecords">Number of records: {results.length}</div>
      ) : (
        ""
      )}
      <div className="itemPageChecklist2">
        <div className="primary222">
          <div className="primaryContainer222">
            {results &&
              results.map((data, i) => {
                // // console.log(data);

                let currentMonth = data.month;

                if (loopingMonth === currentMonth) {
                  return (
                    <div key={i}>
                      <div className="trainingContainer">
                        <div
                          className="trainingListContainer"
                          onClick={() => handleTraining(data.training_id)}
                        >
                          <div className="trainingName">
                            <b>{data.name}</b>
                          </div>

                          <div className="trainingDay">{data.startdate}</div>
                          <div className="trainingDesc">
                            <b> Category: &nbsp;</b>
                            {data.category}
                          </div>
                          <div className="trainingDesc">
                            <b> Description:&nbsp;</b>
                            {data.description}
                          </div>
                          <div className="trainingDesc">
                            <b> Status: &nbsp; </b>
                            {data.status}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                } else {
                  loopingMonth = currentMonth;

                  return (
                    <div key={i}>
                      <div className="trainingMonth">{currentMonth}</div>

                      <div className="trainingContainer">
                        <div
                          className="trainingListContainer"
                          onClick={() => handleTraining(data.training_id)}
                        >
                          <div className="trainingName">
                            <b>{data.name}</b>
                          </div>

                          <div className="trainingDay">{data.startdate}</div>
                          <div className="trainingDesc">
                            <b> Category: &nbsp;</b>
                            {data.category}
                          </div>
                          <div className="trainingDesc">
                            <b> Description:&nbsp;</b>
                            {data.description}
                          </div>
                          <div className="trainingDesc">
                            <b> Status: &nbsp; </b>
                            {data.status}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ItemPageUsage;
