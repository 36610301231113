import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";

import { ItemListPageContext } from "../../context/ItemListPageContext";
import { useNavigate } from "react-router-dom";

import InventoryFinder from "../../apis/InventoryFinder";
import ClipLoader from "react-spinners/ClipLoader";

import { useAuth } from "../../context/AuthContext";

const ItemListPageListDetailed = () => {
  const { id } = useParams();
  const { currentUserSiteId } = useAuth();

  const { filterItems, setFilterItems } = useContext(ItemListPageContext);
  let history = useNavigate();

  const handleItem = (itemid) => {
    history({
      pathname: `/inventory/${itemid}/itempage`,
    });
  };
  const [filterType, setFilterType] = useState("");

  let options = [
    { value: "Manufacture Date", label: "Manufacture Date" },
    { value: "Retirement Date", label: "Retirement Date" },
    { value: "Mandatory Inspection Date", label: "Mandatory Inspection Date" },
    { value: "Serial Number", label: "Serial Number" },
    { value: "Amount", label: "Amount" },
  ];

  var dt = null;
  var dt1 = null;
  dt = new Date();
  dt1 = new Date();
  dt1.setMonth(dt.getMonth() + 1);

  const fetchData2 = async (value) => {
    setFilterType(value);
    try {
      const response = await InventoryFinder.get("/filtered", {
        params: {
          type: value,
          itemId: id,
          currentUserSiteId,
        },
      });
      // console.log(response.data.data.filterItems);
      setFilterItems(response.data.data.filterItems);
    } catch (err) {
      // console.log(err);
    }
  };

  return (
    <>
      <div className="searchDiv3">
        <div className="detailedSelect">
          <select
            className="addItemInput"
            onChange={(event) => fetchData2(event.target.value)}
            required
            defaultValue={""}
          >
            <option value="" disabled>
              Select A Category
            </option>

            {options.map(function (e, i) {
              return (
                <option key={i} value={e.label}>
                  {e.label}
                </option>
              );
            })}
          </select>
        </div>

        {filterType === "Retirement Date" ||
        filterType === "Mandatory Inspection Date" ? (
          <div className="itemPageHelp">
            <div className="itemPageHelpYellowHolder">
              <div className="itemPageHelpYellowColor"></div>
              <div className="itemPageHelpYellowText">
                <b>* Less than a month</b>
              </div>
            </div>
            {filterType === "Mandatory Inspection Date" ? (
              <>
                <div className="itemPageHelpRedHolder">
                  <div className="itemPageHelpOrangeColor"></div>
                  <div className="itemPageHelpOrangeText">
                    <b>* Out of Inspection Date</b>
                  </div>
                </div>
                <div className="itemPageHelpRedHolder">
                  <div className="itemPageHelpRedColor"></div>
                  <div className="itemPageHelpRedext">
                    <b>* Out of Inspection Date && Expired</b>
                  </div>
                </div>
              </>
            ) : (
              <div className="itemPageHelpRedHolder">
                <div className="itemPageHelpRedColor"></div>
                <div className="itemPageHelpRedext">
                  <b>* Expired</b>
                </div>
              </div>
            )}
          </div>
        ) : (
          ""
        )}

        {filterType === "Hydrostatic Date" ? (
          <div className="itemListPageLastHydro">
            <b>All dates are multiplied by 5 years</b>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="itemPageContainer">
        {filterItems &&
          filterItems.map((data, i) => {
            console.log(data);
            let classType = "itemListContainerHolder";

            if (filterType === "Retirement Date") {
              let expirationdate = new Date(data.expirationdate);

              if (data.expirationdate === null || "") {
                classType = "itemListContainerHolder";
              } else if (expirationdate <= dt) {
                classType = "itemListContainerHolderRed";
              } else if (expirationdate <= dt1) {
                classType = "itemListContainerHolderYellow";
              }
            } else if (filterType === "Mandatory Inspection Date") {
              /*  let expirationdate = new Date(data.expirationdate)
                .toISOString()
                .substring(0, 10);
              expirationdate = expirationdate.setHours(0, 0, 0, 0);
              let hydrostaticdate = new Date(data.mandatoryinspectiondate)
                .toISOString()
                .substring(0, 10);
              hydrostaticdate = hydrostaticdate.setHours(0, 0, 0, 0);

              var year = new Date(hydrostaticdate)
                .getFullYear()
                .toISOString()
                .substring(0, 10);
              var month = new Date(hydrostaticdate)
                .getMonth()
                .toISOString()
                .substring(0, 10);
              var day = new Date(hydrostaticdate)
                .getDate()
                .toISOString()
                .substring(0, 10);
              hydrostaticdate = new Date(year, month, day);*/

              let mandatoryinspectiondate = new Date(
                data.mandatoryinspectiondate
              );

              if (data.mandatoryinspectiondate === null || "") {
                classType = "itemListContainerHolder";
              } else if (mandatoryinspectiondate <= dt) {
                classType = "itemListContainerHolderRed";
              } else if (mandatoryinspectiondate <= dt1) {
                classType = "itemListContainerHolderYellow";
              }
            } else {
              classType = "itemListContainerHolder";
            }

            return (
              <div key={i}>
                <>
                  <div
                    className={classType}
                    onClick={() => handleItem(data.itemid, data.id)}
                  >
                    <div className="itemListPageHolder">
                      <div className="itemListPageName">
                        {data.identificationname
                          ? `${data.identificationname}`
                          : data.itemname}
                      </div>
                      <div className="itemPageLocation">
                        {data.compartmentname
                          ? `${data.apparatus} - ${data.compartmentname}`
                          : data.firstname
                          ? `${data.firstname} ${data.lastname}`
                          : "Unassigned"}

                        {data.bagname ? ` - ${data.bagname}` : ""}
                      </div>
                    </div>

                    {filterType === "Retirement Date" ? (
                      <>
                        <div className="itemListPageAmount">
                          {data.expirationdate}
                        </div>
                      </>
                    ) : filterType === "Manufacture Date" ? (
                      <>
                        <div className="itemListPageAmount">
                          {data.manufacturedate}
                        </div>
                      </>
                    ) : filterType === "Serial Number" ? (
                      <>
                        <div className="itemListPageAmount">
                          {data.serialnumber}
                        </div>
                      </>
                    ) : filterType === "Mandatory Inspection Date" ? (
                      <>
                        <div className="itemListPageAmount">
                          {data.mandatoryinspectiondate}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="itemListPageAmount">
                          {data.itemamount}
                        </div>
                      </>
                    )}
                  </div>
                </>
              </div>
            );
          })}

        {!filterItems && (
          <div className="text-center p-3">
            <ClipLoader className="override" size={35} />
          </div>
        )}
      </div>
    </>
  );
};

export default ItemListPageListDetailed;
