import React from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./context/AuthContext";

const PrivateRoutes = ({ allowedRoles }) => {
  const { currentUser, currentUserRole } = useAuth();
  const location = useLocation();

  if (!currentUser) {
    // not logged in so redirect to login page with the return url
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  if (allowedRoles && allowedRoles.includes(currentUserRole) === -1) {
    // role not authorised so redirect to home page
    return <Navigate to="/" />;
  }
  return <Outlet />;
};

export default PrivateRoutes;
