import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import ProfileWidget from "./ProfileWidget";
import { ProfileContext } from "../../context/ProfileContext";

const ProfileItems = () => {
  let history = useNavigate();

  const { userItems } = useContext(ProfileContext);

  const handleItem = (itemid, invid) => {
    history({
      pathname: `/inventory/${itemid}/itempage`,
      state: {
        invid: invid,
      },
    });
  };

  let loopingItem = null;

  return (
    <>
      <div className="dd">
        <div className="widgetTitleContainer">
          <b>Assigned Items</b>
        </div>
        <ProfileWidget
          content={
            <>
              {userItems.length > 0 ? (
                <div className="ProfileWidgetInformation">
                  {userItems.map((data, i) => {
                    //  // console.log(data);
                    let currentItem = data.itemname;

                    if (loopingItem === currentItem) {
                      return (
                        <div key={i}>
                          <div
                            className="ProfileTrainingContainer"
                            onClick={() => handleItem(data.itemid, data.id)}
                          >
                            <div className="ProfileTrainingListContainer">
                              <div className="ProfileTrainingName"></div>

                              <div className="ProfileTrainingDesc">
                                <b>
                                  {data.identificationname
                                    ? `${data.identificationname} - `
                                    : ""}
                                  {data.itemname}
                                </b>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div key={i}>
                          <div className="ProfileTrainingMonth">
                            {data.itemname}
                          </div>

                          <div
                            className="ProfileTrainingContainer"
                            onClick={() => handleItem(data.itemid, data.id)}
                          >
                            <div className="ProfileTrainingListContainer">
                              <div className="ProfileTrainingName"></div>

                              <div className="ProfileTrainingDesc">
                                <b>
                                  {data.identificationname
                                    ? `${data.identificationname} - `
                                    : ""}
                                  {data.itemname}
                                </b>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              ) : (
                <div className="ProfileWidgetEmpty">
                  <b>No Assigned Items</b>
                </div>
              )}
            </>
          }
        />
      </div>
    </>
  );
};

export default ProfileItems;
