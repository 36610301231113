import React from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
  Outlet,
} from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import { ScrollRestoration } from "react-router-dom";
//import { ApparatusContextProvider } from "./context/ApparatusContext";
import Apparatus from "./routes/Apparatus";
import ApparatusChecklistMain from "./routes/ApparatusChecklistMain";
import ApparatusChecklistInProgress from "./routes/ApparatusChecklistInProgress";
import SubmitedChecklist from "./routes/SubmitedChecklist";
import Home from "./routes/Home";
import Checklist from "./routes/Checklist";
import ChecklistWeb from "./routes/ChecklistWeb";
import AdminMain from "./routes/Admin";

import AddChecklist from "./routes/AddChecklist";
import EditChecklist from "./routes/EditChecklist";
import Training from "./routes/Training";
import PreviousTraining from "./routes/PreviousTraining";
import TrainingSelected from "./routes/TrainingSelected";
import TrainingInfo from "./routes/TrainingInfo";

import Inventory from "./routes/Inventory";

import ItemListPage from "./routes/ItemListPage";
import ItemPage from "./routes/ItemPage";
import ItemChecklist from "./routes/ItemChecklist";
import ItemChecklistTask from "./routes/ItemChecklistTask";
import ApparatusInventory from "./routes/ApparatusInventory";
import Profile from "./routes/Profile";
import Settings from "./routes/Settings";
import AdminUserInfo from "./routes/AdminUserInfo";
import AlertPage from "./routes/AlertPage";
import Site from "./routes/Site";
import Scanner from "./routes/ScannerPage";

import Reports from "./routes/Reports";
import ReportsInventoryUsage from "./routes/ReportsInventoryUsage";
import ReportsEvents from "./routes/ReportsEvents";
import ReportsIndividualEvents from "./routes/ReportsIndividualEvents";
import ReportsChecklistHistory from "./routes/ReportsChecklistHistory";
import ReportsToDoList from "./routes/ReportsToDoList";
import ReportsInventoryInformation from "./routes/ReportsInventoryInformation";
import ReportMain from "./routes/ReportsMain";

import MemberList from "./routes/MemberList";
import UserEventsAttended from "./routes/UserEventsAttended";
import UserEventsTrained from "./routes/UserEventsTrained";
import IndividualTraining from "./routes/IndividualTraining";
import EventsMain from "./routes/Events";
import BagsMain from "./routes/Bags";

import UserItems from "./routes/UserItems";

import { Role } from "./roles";

import "./App.css";

import Login from "./routes/Login";
import ForgotPassword from "./routes/ForgotPassword";
import ResetPassword from "./routes/ResetPassword";
// eslint-disable-next-line
import SignUp from "./routes/SignUp";

import { AuthProvider } from "./context/AuthContext";
import PrivateRoutes from "./PrivateRoutes";
//https://www.youtube.com/watch?v=PKwu15ldZ7k&t=417s  //Used for login
//Create 404 Page
//https://stackoverflow.com/questions/32128978/react-router-no-not-found-route
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
//import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import SiteSettingsHeader from "./routes/SiteSettingsHeader";
import ToastNotifier from './ToastNotifier'; 


import ToDo from "./routes/ToDo";



const queryClient = new QueryClient();

const toggleNav = () => {
  let topbar = document.getElementById("topbar");
  let sideBar = document.getElementById("sidebar");
  let appContainer = document.getElementById("appContainer");
  let appContainerHidden = document.getElementById("appContainerHidden");

  topbar.classList.toggle("collapse");
  sideBar.classList.toggle("collapse");
  appContainer.classList.toggle("collapse");
  appContainerHidden.classList.toggle("collapse");
};

const AppLayout = () => (
  <>

    <Navbar />
  
    <ScrollRestoration />
    <div className="appContainer " id="appContainer">
      <Outlet />
    </div>
    <div
      className="appContainerHidden"
      id="appContainerHidden"
      onClick={toggleNav}
    ></div>
  </>
);

const AppLayoutAuthProvider = () => (
  <>
    <AuthProvider>
      <QueryClientProvider client={queryClient}>
        <Outlet />    
        <ToastNotifier/>

        {/*<ReactQueryDevtools initialIsOpen={false} />*/}
      </QueryClientProvider>
    </AuthProvider>
  </>
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AppLayoutAuthProvider />}>
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/resetpassword" element={<ResetPassword />} />
      <Route path="/resetpassword" element={<ResetPassword />} />
      <Route element={<AppLayout />}>
        <Route
          element={
            <PrivateRoutes
              allowedRoles={[Role.Basic, Role.Advanced, Role.Admin]}
            />
          }
        >
          <Route element={<AdminMain />} path="/admin" exact />
          
          <Route element={<MemberList />} path="/memberlist" exact />
          <Route element={<AlertPage />} path="/alert" exact />
          <Route element={<ToDo />} path="/todo" exact />
          <Route element={<BagsMain />} path="/bags" exact />

          <Route element={<Home />} path="/" exact />
          <Route element={<Profile />} path="/profile" exact />
          <Route element={<Site />} path="/site" exact />
          <Route element={<AdminUserInfo />} path="/userInfo/:id" exact />
          <Route element={<Reports />} path="/reports" exact />
          <Route
            element={<ReportsInventoryUsage />}
            path="/reports/inventoryusage"
            exact
          />
   <Route
            element={<EventsMain />}
            path="/events"
            exact
          />


           <Route
            element={<ReportsInventoryInformation />}
            path="/reports/inventoryinformation"
            exact
          />
          <Route element={<ReportsEvents />} path="/reports/events" exact />
          <Route
            element={<ReportsIndividualEvents />}
            path="/reports/individualevents"
            exact
          />
          <Route
            element={<ReportsChecklistHistory />}
            path="/reports/checklisthistory"
            exact
          />
  <Route
            element={<ReportsToDoList />}
            path="/reports/todolist"
            exact
          />
  <Route
            element={<ReportMain />}
            path="/reports/Main"
            exact
          />
          <Route
            element={<UserEventsAttended />}
            path="/userInfo/:id/events"
            exact
          />
          <Route
            element={<UserEventsTrained />}
            path="/userInfo/:id/trained"
            exact
          />
          <Route
            element={<IndividualTraining />}
            path="/userInfo/:id/individualtraining"
            exact
          />
          

          <Route element={<UserItems />} path="/userInfo/:id/items" exact />
          <Route element={<ChecklistWeb />} path="/checklistWeb" exact />

          <Route element={<Checklist />} path="/checklist/:id" exact />
          <Route element={<AddChecklist />} path="/addchecklist" exact />
          <Route element={<EditChecklist />} path="/editchecklist/:id" exact />
          <Route
            element={<PreviousTraining />}
            path="/previoustraining"
            exact
          />
          <Route element={<TrainingInfo />} path="/trainingInfo/:id" exact />
          <Route element={<TrainingSelected />} path="/training/:id" exact />
          <Route element={<Training />} path="/upcomingtrainings" exact />
          <Route element={<Apparatus />} path="/apparatus" exact />
          <Route element={<ApparatusInventory />} path="/apparatus/:id" exact />
          <Route element={<ApparatusInventory />} path="/search" exact />
          <Route element={<Scanner />} path="/qr" exact />

          <Route
            element={<ApparatusChecklistMain />}
            path="/apparatus/:id/checklist"
            exact
          />
          <Route
            element={<ApparatusChecklistInProgress />}
            path="/apparatus/:id/checklistinprogress"
            exact
          />
          <Route
            element={<SubmitedChecklist />}
            path="/apparatus/:id/submitedchecklist"
            exact
          />
          <Route element={<Inventory />} path="/inventory" exact />
          <Route element={<ItemListPage />} path="/inventory/:id/item" exact />
          <Route element={<ItemPage />} path="/inventory/:id/itemPage" exact />
          <Route element={<ItemPage />} path="/equipment" exact />

          <Route element={<ItemChecklist />} path="/itemchecklist/:id" exact />
          <Route
            element={<ItemChecklistTask />}
            path="/itemchecklist/:id/task"
            exact
          />
          <Route element={<Settings />} path="/settings" exact />
          <Route element={<SiteSettingsHeader />} path="/sitesettings" exact />
        </Route>
        <Route element={<PrivateRoutes allowedRoles={[Role.Admin]} />}>
          {/*<Route element={<Settings />} path="/settings" exact />*/}
        </Route>
      </Route>
      <Route path="*" element={<Login />} />
    </Route>
  )
);

const App = () => {
  return (
    // <ApparatusContextProvider>
    <div id="container" className="container">
      <RouterProvider router={router} />
    </div>
    //  </ApparatusContextProvider>
  );
};

export default App;
