import React, { useState, createContext } from "react";

export const UserInfoContext = createContext();

export const UserInfoContextProvider = (props) => {
  const [itemInfo, setItemInfo] = useState([]);
  const [eventInfo, setEventInfo] = useState([]);
  const [trainerInfo, setTrainerInfo] = useState([]);

  return (
    <UserInfoContext.Provider
      value={{
        itemInfo,
        setItemInfo,
        eventInfo,
        setEventInfo,
        trainerInfo,
        setTrainerInfo,
      }}
    >
      {props.children}
    </UserInfoContext.Provider>
  );
};
