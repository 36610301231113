import React from "react";

import ChecklistAddPage from "../components/Checklist/ChecklistAddPage";

import "../components/Checklist/Checklist.css";

function Checklist() {
  return (
    <div>
      <ChecklistAddPage />
    </div>
  );
}

export default Checklist;
