import React from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

import { useNavigate } from "react-router-dom";

import { useMainItemListPageData } from "./fetchItemListPageData";

const ItemListPageList = () => {
  // const { itemInfo, setItemInfo } = useContext(ItemPageContext);
  //const { bagItems } = useContext(ItemPageContext);
  const { id } = useParams();
  const { currentUserSiteId } = useAuth();
  const { data } = useMainItemListPageData(currentUserSiteId, id);

  let history = useNavigate();

  const handleItem = (itemid) => {
    history({
      pathname: `/inventory/${itemid}/itempage`,
    });
  };

  let currentGroup = null;

  return (
    <>
      <div className="itemPageContainer1">
        {data?.data.data.compartmentItems &&
          data?.data.data.compartmentItems.map((data, i) => {
            // console.log(data);

            let loopingGroup = data.apparatus;
            if (loopingGroup === currentGroup) {
              return (
                <div key={i}>
                  {data.apparatus ? (
                    <>
                      <div
                        className="itemListContainerHolder"
                        onClick={() => handleItem(data.itemid, data.id)}
                      >
                        <div className="itemListPageHolder">
                          <div className="itemListPageName">
                            {data.identificationname
                              ? `${data.identificationname}`
                              : data.itemname}
                          </div>
                          <div className="itemPageLocation">
                            {data.compartmentname}

                            {data.bagname && data.compartmentname
                              ? ` - ${data.bagname}`
                              : data.bagname
                              ? `${data.bagname}`
                              : ""}
                          </div>
                        </div>
                        <div className="itemListPageAmount">
                          {data.itemamount}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className="itemListContainerHolder"
                        onClick={() => handleItem(data.itemid, data.id)}
                      >
                        <div className="itemListPageHolder2">
                          <div className="itemListPageName">
                            {data.identificationname
                              ? `${data.identificationname}`
                              : data.itemname}
                          </div>
                          <div className="itemPageLocation">
                            {data.compartmentname}

                            {data.bagname && data.compartmentname
                              ? ` - ${data.bagname}`
                              : data.bagname
                              ? `${data.bagname}`
                              : ""}
                          </div>
                        </div>
                        <div className="itemListPageAmount2">
                          {data.itemamount}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              );
            } else {
              currentGroup = data.apparatus;
              return (
                <div key={i}>
                  {data.apparatus ? (
                    <>
                      <div className="itemListHeader">
                        <b>{data.apparatus}</b>
                      </div>
                      <div
                        className="itemListContainerHolder"
                        onClick={() => handleItem(data.itemid, data.id)}
                      >
                        <div className="itemListPageHolder">
                          <div className="itemListPageName">
                            {data.identificationname
                              ? `${data.identificationname}`
                              : data.itemname}
                          </div>
                          <div className="itemPageLocation">
                            {data.compartmentname}

                            {data.bagname ? ` / ${data.bagname}` : ""}
                          </div>
                        </div>
                        <div className="itemListPageAmount">
                          {data.itemamount}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="itemListHeader">
                        <b>{"Unassigned"}</b>
                      </div>
                      <div
                        className="itemListContainerHolder"
                        onClick={() => handleItem(data.itemid, data.id)}
                      >
                        <div className="itemListPageHolder2">
                          <div className="itemListPageName">
                            {data.identificationname
                              ? `${data.identificationname}`
                              : data.itemname}
                          </div>
                          <div className="itemPageLocation">
                            {data.compartmentname}

                            {data.bagname && data.compartmentname
                              ? ` - ${data.bagname}`
                              : data.bagname
                              ? `${data.bagname}`
                              : ""}
                          </div>
                        </div>
                        <div className="itemListPageAmount2">
                          {data.itemamount}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              );
            }
          })}
      </div>

      <div className="itemPageContainer">
        {data?.data.data.userItems.length !== 0 ? (
          <div className="itemListHeader">
            <b>User Item </b>
          </div>
        ) : (
          ""
        )}
        {data?.data.data.userItems &&
          data?.data.data.userItems.map((data, i) => {
            // // console.log(data);
            return (
              <div key={i}>
                <div
                  className="itemListContainerHolder"
                  onClick={() => handleItem(data.itemid, data.id)}
                >
                  <div className="itemListPageHolder">
                    <div className="itemListPageName">
                      {data.identificationname
                        ? `${data.identificationname} - `
                        : ""}
                      {data.itemname}
                    </div>
                    <div className="itemPageLocation">
                      {data.firstname + " " + data.lastname}
                    </div>
                  </div>
                  <div className="itemListPageAmount"> {data.amount}</div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default ItemListPageList;
