import { useQuery } from "@tanstack/react-query";
import InventoryFinder from "../../apis/InventoryFinder";

const fetchItemListPage = ({ queryKey }) => {
  const currentUserSiteId = queryKey[1];
  const id = queryKey[2];
  return InventoryFinder.get(`/list${id}`, {
    params: {
      currentUserSiteId,
    },
  });
};

export const useMainItemListPageData = (currentUserSiteId, id) => {
  return useQuery(["itemHolder", currentUserSiteId, id], fetchItemListPage);
};
