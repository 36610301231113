import React from "react";

import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import { AiOutlineMenu } from "react-icons/ai";

const ReportsHeader = () => {
  // eslint-disable-next-line
  const history = useNavigate();

  const handleReportUsage = () => {
    history(`/reports/inventoryusage`);
  };

  const handleReportChecklist = () => {
    history(`/reports/checklisthistory`);
  };
  const handleReportInformation = () => {
    history(`/reports/inventoryinformation`);
  };

  
  const handleReportEvents = () => {
    history(`/reports/events`);
  };

  const handleReportIndividualEvents = () => {
      history(`/reports/individualevents`);
   };

   const handleReportToDoList = () => {
    history(`/reports/todolist`);
 };

 const handleReportsMain = () => {
  history(`/reports/Main`);
};

  const toggleNav = () => {
    let topbar = document.getElementById("topbar");
    let sideBar = document.getElementById("sidebar");
    let appContainer = document.getElementById("appContainer");
    let appContainerHidden = document.getElementById("appContainerHidden");

    topbar.classList.toggle("collapse");
    sideBar.classList.toggle("collapse");
    appContainer.classList.toggle("collapse");
    appContainerHidden.classList.toggle("collapse");
  };

  return (
    <>
      <div
        id="topbar"
        className="topbar headerActive"
        /*
        className={
          sideBar === null
            ? "topbar headerActive"
            : sideBar.clientWidth === 80
            ? "topbar headerActive collapse"
            : "topbar headerActive"
        }*/
      >
        <div className="hometopbarleft" onClick={toggleNav}>
          <AiOutlineMenu />
        </div>
        <Header
          content={
            <>
              <b>Reports</b>
            </>
          }
        />
      </div>
      <div className="topbarEmptySpace"></div>

      <div className="popupBtnContainers">
        {/* <div className="btnDiv">
                  <div className="addMemberBtn" onClick={handleChecklist}>
                    <b>Checklist</b>
                  </div>
          </div>*/}
        <div className="btnDiv" onClick={handleReportUsage}>
          <div className="addMemberBtn">
            <b>Inventory Usage</b>
          </div>
        </div>
        <div className="btnDiv" onClick={handleReportInformation}>
          <div className="addMemberBtn">
            <b>Inventory Information</b>
          </div>
        </div>
        <div className="btnDiv" onClick={handleReportChecklist}>
          <div className="addMemberBtn">
            <b>Checklist History</b>
          </div>
        </div>

        <div className="btnDiv" onClick={handleReportEvents}>
          <div className="addMemberBtn">
            <b>Events</b>
          </div>
        </div>

       <div className="btnDiv" onClick={handleReportIndividualEvents}>
          <div className="addMemberBtn">
            <b>Individual Events</b>
          </div>
        </div>
        <div className="btnDiv" onClick={handleReportToDoList}>
          <div className="addMemberBtn">
            <b>To Do List</b>
          </div>
        </div>
        <div className="btnDiv" onClick={handleReportsMain}>
          <div className="addMemberBtn">
            <b>Main</b>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportsHeader;
