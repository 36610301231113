import React from "react";
import "../components/AlertPage/AlertPage.css";
import ReportsAlertsComponent from "../components/Reports/ReportsAlerts";

function AlertPage() {
  return (
      <>
        <ReportsAlertsComponent />
      
      </>

  );
}

export default AlertPage;




