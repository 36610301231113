import React from "react";
import "../components/Reports/Reports.css";
import ReportsMainComponent from "../components/Reports/ReportsMain";

const ReportsEvents = () => {
  return (
    <>
      <ReportsMainComponent />
    </>
  );
};

export default ReportsEvents;
