import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import UserFinder from "../../apis/UserFinder";
import { ProfileContext } from "../../context/ProfileContext";
import { IoMdArrowRoundBack } from "react-icons/io";

import PopupWeb from "../Popup/PopupNew";

import { useAuth } from "../../context/AuthContext";

const ProfileHeader = () => {
  let history = useNavigate();

  const { currentUserName, currentUserId, currentUserEmail, resetPassword } =
    useAuth();

  //
  const { setTrainingsAttended } = useContext(ProfileContext);
  const { setUserItems } = useContext(ProfileContext);

  const [setMessage] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await UserFinder.get(`/${currentUserId}`);
        //  // console.log(response.data.data.user[0]);
        // console.log(response.data.data.userItems);
        setTrainingsAttended(response.data.data.trainingList);
        setUserItems(response.data.data.userItems);
      } catch (err) {
        // console.log(err);
      }
    };

    if (currentUserId) {
      fetchData();
    }
    // eslint-disable-next-line
  }, []);

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    try {
      setMessage("");
      await resetPassword(currentUserEmail);
      setMessage("Check your inbox for further instructions");
      // console.log(resetPassword);
    } catch (err) {
      // console.log(err);
    }
  };

  //Popup
  //https://www.youtube.com/watch?v=PKwu15ldZ7k&t=417s update profile finish here
  const [isOpen1, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen1);
  };

  const sideBar = document.getElementById("sidebar");

  return (
    <>
      <div
        id="topbar"
        className={
          sideBar === null
            ? "topbar headerActive"
            : sideBar.clientWidth === 80
            ? "topbar headerActive collapse"
            : "topbar headerActive"
        }
      >
        <div className="goBackDiv" onClick={() => history(-1)}>
          <IoMdArrowRoundBack />
        </div>

        <Header content={<b>Profile</b>} />
      </div>

      <div className="profileHeaderName">
        <b>{currentUserName}</b>
      </div>

      {isOpen1 && (
        <PopupWeb
          content={
            <>
              <div type="submit" className="submitItemBtn2">
                Save
              </div>
              UserName
              <input
                value={currentUserName}
                onChange={(e) => currentUserName(e.target.value)}
                className="addItemInput"
                type="text"
                placeholder="Username"
              />
              Email
              <input
                value={currentUserEmail}
                onChange={(e) => currentUserEmail(e.target.value)}
                className="addItemInput"
                type="text"
                placeholder="Username"
              />
              <div>
                <button onClick={handlePasswordReset}>Reset password</button>
              </div>
            </>
          }
          handleClose={togglePopup}

          
        />
      )}
    </>
  );
};

export default ProfileHeader;
