import React, { useContext, useState } from "react";
import ApparatusFinder from "../../apis/ApparatusFinder";
import { ApparatusInventoryContext } from "../../context/ApparatusInventoryContext";
//import PopupNormal from "../Popup/PopupNormal";
import PopupWeb from "../Popup/PopupWeb";

import { useParams } from "react-router-dom";

import PublicFolder from "../../apis/PublicFolder";
import { useAuth } from "../../context/AuthContext";

import ClipLoader from "react-spinners/ClipLoader";
import { useNavigate } from "react-router-dom";

const ApparatusInventoryAdd = () => {
  const { id } = useParams();
  const { currentUserId, currentUserSiteId,currentFirstName,currentLastName } = useAuth();

  let history = useNavigate();

  const [query, setQuery] = useState("");
  // const [dropdownQuery, setDropdownQuery] = useState("");
  const [dropdownQuery] = useState("");

  const [loading, setLoading] = useState(false);

  //const { apparatusInventory } = useContext(ApparatusInventoryContext);
  const {
    addItemCompartmentId,
    addItemCompartmentName,
    setAddItemCompartmentName,
    setAddItemCompartmentId,
  } = useContext(ApparatusInventoryContext);

  const { setCompartmentItems } = useContext(ApparatusInventoryContext);

  const { inventory } = useContext(ApparatusInventoryContext);
  const { compartments } = useContext(ApparatusInventoryContext);
  const { bags, setBags } = useContext(ApparatusInventoryContext);

  const { compartmentOpen2, setCompartmentOpen2, setAddItemOpen, addItemOpen } =
    useContext(ApparatusInventoryContext);

  // const { itemGroup } = useContext(ApparatusInventoryContext);
  //Add Item popup

  const togglePopupAddItem = () => {
    setAddItemOpen(!addItemOpen);
    setCompartmentOpen2(!compartmentOpen2);
    setSelectedIdentificationName("");
    setSelectedBag("");
    setSelectedBagId("");
    setSelectedInventory("");
    setSelectedInventoryId("");
    setSelectedAmount("1");
    setSelectedStatus("Good");
    setSelectedSerialNumber("");
    setSelectedHydrostaticDate();
    setSelectedExpirationDate();
    setSelectedIdentificationName("");
    setSelectedManufactureDate();
  };

  //Popup

  const togglePopup = () => {
    setAddItemOpen(!addItemOpen);
    setCompartmentOpen2(!compartmentOpen2);
    // setCompartmentOpen2(false);
  };

  //select compartment Popup
  const [compartmentOpen, setCompartmentOpen] = useState(false);

  const togglePopup2 = () => {
    setCompartmentOpen(!compartmentOpen);
    setAddItemOpen(!addItemOpen);
  };

  //Create new compartment Popup

  //select bag Popup
  const [bagOpen, setBagOpen] = useState(false);

  const togglePopup3 = () => {
    setBagOpen(!bagOpen);
    setAddItemOpen(!addItemOpen);
  };

  //Create new bag Popup
  const [bagOpen33, setBagOpen33] = useState(false);

  const togglePopup33 = () => {
    setBagOpen33(!bagOpen33);
    setBagOpen(!bagOpen);
    setSelectedBag("");
    setSelectedBagId("");
  };

  const togglePopup333 = () => {
    setBagOpen33(!bagOpen33);
    setAddItemOpen(!addItemOpen);
  };

  //select inventory Popup
  const [inventoryOpen, setInventoryOpen] = useState(false);

  const togglePopup4 = () => {
    setInventoryOpen(!inventoryOpen);
    setAddItemOpen(!addItemOpen);
  };

  const [selectedBag, setSelectedBag] = useState("");
  const [selectedBagId, setSelectedBagId] = useState("");
  const [selectedInventory, setSelectedInventory] = useState("");
  const [selectedInventoryId, setSelectedInventoryId] = useState("");
  const [selectedAmount, setSelectedAmount] = useState("1");
  const [selectedStatus, setSelectedStatus] = useState("Good");

  //optional Input useState
  const [selectedIdentificationName, setSelectedIdentificationName] =
    useState("");
  const [selectedSerialNumber, setSelectedSerialNumber] = useState("");
  const [selectedHydrostaticDate, setSelectedHydrostaticDate] = useState();
  const [selectedExpirationDate, setSelectedExpirationDate] = useState();
  const [selectedManufactureDate, setSelectedManufactureDate] = useState();

  //Submit inventory
  const handleSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    // console.log(selectedManufactureDate);

    if (addItemCompartmentName === "" || selectedInventory === "") {
      addItemCompartmentName === ""
        ? alert("Select a compartment \n")
        : alert("Select a inventory item\n");

      return false;
    } else {
      try {
        setLoading(true);

        // eslint-disable-next-line
        const response = await ApparatusFinder.post(`/addInventory`, {
          selectedCompartmentId: addItemCompartmentId,
          selectedBagId,
          selectedInventory,
          selectedInventoryId,
          selectedAmount,
          apparatusid: id,
          selectedStatus,
          selectedIdentificationName,
          selectedSerialNumber:
            selectedSerialNumber === "" ? null : selectedSerialNumber,
          selectedManufactureDate,
          selectedExpirationDate,
          selectedHydrostaticDate,
          currentUserId,
          currentUserSiteId,currentFirstName,currentLastName 
        });
        setLoading(false);
        // console.log(response.data.data.compartmentItems);
        if (response.data.data.alert) {
          alert(response.data.data.alert);
          return;
        }
        setCompartmentItems(response.data.data.compartmentItems);
        setSelectedIdentificationName("");
        setSelectedBag("");
        setSelectedBagId("");
        setSelectedInventory("");
        setSelectedInventoryId("");
        setSelectedAmount("1");
        setSelectedStatus("Good");
        setSelectedSerialNumber("");
        setSelectedHydrostaticDate();
        setSelectedExpirationDate();
        setSelectedIdentificationName("");
        setSelectedManufactureDate();
        togglePopup();
      } catch (err) {
        setLoading(false);
      }
    }
  };

  const handleNewInventory = (id) => {
    history(`/inventory`);
  };

  //Submit new bag
  const handleBagSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    if (addItemCompartmentId === "") {
      alert("Select a compartment \n");
      return false;
    }

    try {
      setLoading(true);

      //  // console.log(selectedBag, addItemCompartmentId);
      const response = await ApparatusFinder.post(`/addBag`, {
        selectedBag,
        addItemCompartmentId,
        id: id,
        currentUserSiteId,
      });
      setLoading(false);
      setSelectedBag(response.data.data.bagInfo[0].bagname);
      setSelectedBagId(response.data.data.bagInfo[0].bagid);
      setBags(response.data.data.bags);
      togglePopup333();
    } catch (err) {
      setLoading(false);
    }
  };

  function toggleClass(id) {
    document.getElementById(id).classList.toggle("hiddenAddItem");
  }

  let currentGroup = null;

  return (
    <>
      {addItemOpen && (
        <PopupWeb
          content={
            <div className="AddItemHolder">
              <form onSubmit={handleSubmit}>
                <div id="holderTest" className="holderTest">
                  <button
                    type="submit"
                    className="submitItemBtn2"
                    disabled={loading}
                  >
                    Done
                  </button>
                  <div className="popupTitle3">
                    <b>New Item</b>
                  </div>
                </div>
                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>
                <div className="popupContainer">
                  <div className="essentialText">Essential Information</div>
                  <div
                    id="compartmentSelectHolder"
                    className="compartmentSelectHolder"
                  >
                    <div className="compartmentSelectText">
                      <b>Compartment</b>
                    </div>
                    <div className="compartmentSelected2">&nbsp;&nbsp;&gt;</div>
                    <div
                      id="compartmentSelected"
                      className="compartmentSelected"
                    >
                      {addItemCompartmentName ? addItemCompartmentName : "None"}
                    </div>
                  </div>
                  <div
                    id="bagSelectHolder"
                    className="bagSelectHolder"
                    onClick={togglePopup3}
                  >
                    <div className="bagSelectText">
                      <b>Bag</b>
                    </div>
                    <div className="compartmentSelected2">&nbsp;&nbsp;&gt;</div>
                    <div id="bagSelected" className="compartmentSelected">
                      {selectedBag ? selectedBag : "None"}
                    </div>
                  </div>
                  <div
                    id="compartmentSelectHolder"
                    className="compartmentSelectHolder"
                    onClick={togglePopup4}
                  >
                    <div className="compartmentSelectText">
                      <b>Inventory Item</b>
                    </div>
                    <div className="compartmentSelected2">&nbsp;&nbsp;&gt;</div>
                    <div
                      id="compartmentSelected"
                      className="compartmentSelected"
                    >
                      {selectedInventory ? selectedInventory : "None"}
                    </div>
                  </div>

                  <label className="NewInputLabel" data-domain="Status">
                    <select
                      className="NewInput"
                      onChange={(event) =>
                        setSelectedStatus(event.target.value)
                      }
                      required
                    >
                      <option value="" disabled>
                        Select Status
                      </option>

                      <option value={"Good"}>{"Good"}</option>
                      <option value={"Worn"}>{"Worn"}</option>
                      <option value={"Bad"}>{"Bad"}</option>
                      <option value={"Monitor"}>{"Monitor"}</option>
                      <option value={"Out For Repair"}>
                        {"Out For Repair"}
                      </option>
                      <option value={"Missing"}>{"Missing"}</option>
                      <option value={"Retired"}>{"Retired"}</option>
                    </select>
                  </label>

                  <label className="NewInputLabel" data-domain="Amount">
                    <input
                      className="NewInput"
                      type="text"
                      placeholder=""
                      value={selectedAmount}
                      onChange={(e) => setSelectedAmount(e.target.value)}
                      required
                    />
                  </label>

                  <div className="optionalText">Optional Information</div>
                  <div
                    className="InspectionShowDiv"
                    onClick={() => {
                      toggleClass(`IdentificationShow`);
                    }}
                  >
                    <div className="bagDropdwonArrow2">
                      <i className="arrowdown"></i>
                    </div>
                    Identification info
                  </div>
                  <div className="InspectionDownDown" id="IdentificationShow">
                    <label
                      className="NewInputLabel"
                      data-domain="Identification Name"
                    >
                      <input
                        className="NewInput"
                        type="text"
                        placeholder=""
                        value={selectedIdentificationName}
                        onChange={(e) =>
                          setSelectedIdentificationName(e.target.value)
                        }
                      />
                    </label>

                    <label
                      className="NewInputLabel"
                      data-domain="Serial Number"
                    >
                      <input
                        className="NewInput"
                        type="text"
                        placeholder=""
                        value={selectedSerialNumber}
                        onChange={(e) =>
                          setSelectedSerialNumber(e.target.value)
                        }
                      />
                    </label>
                  </div>

                  <div
                    className="InspectionShowDiv"
                    onClick={() => {
                      toggleClass(`ManufactureShow`);
                    }}
                  >
                    <div className="bagDropdwonArrow2">
                      <i className="arrowdown"></i>
                    </div>
                    Manufacture Info
                  </div>
                  <div className="InspectionDownDown" id="ManufactureShow">
                    <label
                      className="NewInputLabel"
                      data-domain="Manufactured Date"
                    >
                      <input
                        className="NewInput"
                        type="date"
                        onChange={(e) =>
                          setSelectedManufactureDate(e.target.value)
                        }
                        onFocus={(event) =>
                          (event.nativeEvent.target.defaultValue = "")
                        }
                      />
                    </label>

                    <label
                      className="NewInputLabel"
                      data-domain="Retirement Date"
                    >
                      <input
                        className="NewInput"
                        type="date"
                        onChange={(e) =>
                          setSelectedExpirationDate(e.target.value)
                        }
                        onFocus={(event) =>
                          (event.nativeEvent.target.defaultValue = "")
                        }
                      />
                    </label>
                  </div>

                  <div
                    className="InspectionShowDiv"
                    onClick={() => {
                      toggleClass(`HydrostaticShow`);
                    }}
                  >
                    <div className="bagDropdwonArrow2">
                      <i className="arrowdown"></i>
                    </div>
                    Inspection Info
                  </div>
                  <div className="InspectionDownDown" id="HydrostaticShow">
                    <label
                      className="NewInputLabel"
                      data-domain="Mandatory Service Date"
                    >
                      <input
                        className="NewInput"
                        type="date"
                        onChange={(e) =>
                          setSelectedHydrostaticDate(e.target.value)
                        }
                        onFocus={(event) =>
                          (event.nativeEvent.target.defaultValue = "")
                        }
                      />
                    </label>
                  </div>

                  <div className="InspectionDownDown" id="InspectionShow"></div>
                </div>
              </form>
            </div>
          }
          handleClose={togglePopupAddItem}
        />
      )}

      {compartmentOpen && (
        <PopupWeb
          content={
            <>
              <div className="popupTitle">
                <b>Select Compartment</b>
              </div>

              {compartments &&
                compartments.map((data, i) => (
                  <div
                    key={i}
                    className="apparatusInventoryHolder"
                    onClick={() => {
                      setAddItemCompartmentName(`${data.name}`);
                      setAddItemCompartmentId(`${data.cid}`);
                      setSelectedBagId("");
                      setSelectedBag("");
                      togglePopup2();
                    }}
                  >
                    {data.imagepath ? (
                      <div className="apparatusInventoryImgDiv">
                        <img
                          src={PublicFolder.baseURL + data.imagepath}
                          alt="fix later"
                          className="apparatusInventoryImg"
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="apparatusInventoryItemName">
                      {data.name}
                    </div>
                  </div>
                ))}
            </>
          }
          handleClose={togglePopup2}
        />
      )}

      {bagOpen && (
        <PopupWeb
          content={
            <>
              <div id="holderTest" className="holderTest">
                <button
                  type="submit"
                  className="submitItemBtn2"
                  onClick={togglePopup33}
                >
                  New
                </button>
                <div className="popupTitle">
                  <b>Select Bag</b>
                </div>
              </div>
              <div className="emptySpace"></div>
              <div className="popupContainer">
                <div
                  className="selectBagHolder"
                  onClick={() => {
                    setSelectedBag("");
                    setSelectedBagId("");
                    togglePopup3();
                  }}
                >
                  <div className="selectBag">None</div>
                  <div className="bagCompartment"></div>
                </div>
                {bags &&
                  bags

                    // eslint-disable-next-line
                    .filter((post) => {
                      if (addItemCompartmentId === "") {
                        return post;
                      } else if (
                        post.cid
                          .toLowerCase()
                          .includes(addItemCompartmentId.toLowerCase())
                      ) {
                        return post;
                      }
                    })
                    .map((data, i) => (
                      <div key={i}>
                        <div
                          className="selectBagHolder"
                          onClick={() => {
                            setSelectedBag(`${data.bagname}`);
                            setSelectedBagId(`${data.bagid}`);
                            setAddItemCompartmentName(`${data.name}`);
                            setAddItemCompartmentId(`${data.cid}`);
                            togglePopup3();
                          }}
                        >
                          <div className="selectBag">
                            {data.bagname} - {data.name}
                          </div>
                        </div>
                      </div>
                    ))}
              </div>
            </>
          }
          handleClose={togglePopup3}
        />
      )}

      {bagOpen33 && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleBagSubmit}>
                <div id="holderTest" className="holderTest">
                  <button
                    type="submit"
                    className="submitItemBtn2"
                    disabled={loading}
                  >
                    Save
                  </button>

                  <div className="popupTitle">
                    <b>Create New Bag</b>
                  </div>
                </div>
                <div className="emptySpace"></div>

                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>
                <div className="popupContainer">
                  <label className="NewInputLabel" data-domain="Bag Name">
                    <input
                      className="NewInput"
                      type="text"
                      placeholder=""
                      onChange={(e) => setSelectedBag(e.target.value)}
                      required
                    />
                  </label>

                  <div>
                    {compartments &&
                      compartments

                        // eslint-disable-next-line
                        .filter((post) => {
                          if (addItemCompartmentId === "") {
                            return post;
                          } else if (
                            post.cid
                              .toLowerCase()
                              .includes(addItemCompartmentId.toLowerCase())
                          ) {
                            return post;
                          }
                        })
                        .map((data, i) => (
                          <div
                            key={i}
                            id={`apparatusInventoryHolder${data.cid}`}
                            className="apparatusInventoryHolder"
                          >
                            {data.imagepath ? (
                              <div className="apparatusInventoryImgDiv">
                                <img
                                  src={PublicFolder.baseURL + data.imagepath}
                                  alt="fix later"
                                  className="apparatusInventoryImg"
                                />
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="apparatusInventoryItemName">
                              {data.name}
                            </div>
                          </div>
                        ))}
                  </div>
                </div>
              </form>
            </>
          }
          handleClose={togglePopup33}
        />
      )}

      {inventoryOpen && (
        <PopupWeb
          content={
            <>
              <div id="holderTest" className="holderTest55">
                <div className="popupTitle3">
                  <b>Select Inventory</b>
                </div>

                <div className="searchDiv2">
                  <label className="NewInputLabel" data-domain="Inventory">
                    <input
                      className="NewInput"
                      type="text"
                      placeholder=""
                      autoComplete="off"
                      autoCorrect="off"
                      onChange={(event) => setQuery(event.target.value)}
                    />
                  </label>
                </div>
              </div>
              <div className="emptySpace2"></div>
              <div className="resultsDiv">
                {inventory.length > 0 ? (
                  inventory
                    // eslint-disable-next-line
                    .filter((post) => {
                      if (dropdownQuery === "") {
                        if (query === "") {
                          return post;
                        } else if (
                          post.name.toLowerCase().includes(query.toLowerCase())
                        ) {
                          return post;
                        }
                      } else if (
                        post.itemgroup
                          .toLowerCase()
                          .includes(dropdownQuery.toLowerCase())
                      ) {
                        if (query === "") {
                          return post;
                        } else if (
                          post.name.toLowerCase().includes(query.toLowerCase())
                        ) {
                          return post;
                        }
                      }
                    })
                    .map((post) => {
                      let firstLetter = post.name.charAt(0);

                      if (firstLetter !== currentGroup) {
                        currentGroup = post.name.charAt(0);
                        return (
                          <div key={post.id}>
                            <div className="itemHeader">
                              <b> {post.name.charAt(0).toUpperCase()}</b>
                            </div>
                            <div
                              className="itemContainer"
                              onClick={() => {
                                setSelectedInventory(`${post.name}`);
                                setSelectedInventoryId(`${post.id}`);
                                togglePopup4();
                              }}
                            >
                              <div className="inventoryImgDiv">
                                {post.image ? (
                                  <img
                                    src={PublicFolder.baseURL + post.image}
                                    alt={post.name.charAt(0).toUpperCase()}
                                    className="inventoryImg"
                                  />
                                ) : (
                                  <div className="noPicDiv">
                                    {post.name.charAt(0).toUpperCase()}
                                  </div>
                                )}
                              </div>
                              <div className="itemDiv">
                                <div className="itemDivName">{post.name}</div>
                                <div className="itemDivType">{post.type}</div>
                              </div>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div key={post.id}>
                            <div
                              className="itemContainer"
                              onClick={() => {
                                setSelectedInventory(`${post.name}`);
                                setSelectedInventoryId(`${post.id}`);
                                togglePopup4();
                              }}
                            >
                              <div className="inventoryImgDiv">
                                {post.image ? (
                                  <img
                                    src={PublicFolder.baseURL + post.image}
                                    alt={post.name.charAt(0).toUpperCase()}
                                    className="inventoryImg"
                                  />
                                ) : (
                                  <div className="noPicDiv">
                                    {post.name.charAt(0).toUpperCase()}
                                  </div>
                                )}
                              </div>
                              <div className="itemDiv">
                                <div className="itemDivName">{post.name}</div>
                                <div className="itemDivType">{post.type}</div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    })
                ) : (
                  <>
                    <div
                      className="apparatusEmptyHolder"
                      onClick={() => {
                        handleNewInventory();
                      }}
                    >
                      <div className="emptySpace2"></div>
                      <div className="apparatusEmptyName">No Item Holders</div>
                      <div className="apparatusEmptyButton">
                        Tap here to create one
                      </div>
                    </div>
                  </>
                )}
              </div>
            </>
          }
          handleClose={togglePopup4}
        />
      )}
    </>
  );
};

export default ApparatusInventoryAdd;
