import React, { useContext, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import ApparatusFinder from "../../apis/ApparatusFinder";
import InventoryFinder from "../../apis/InventoryFinder";

import { ApparatusInventoryContext } from "../../context/ApparatusInventoryContext";
import { useNavigate, useLocation } from "react-router-dom";
import PublicFolder from "../../apis/PublicFolder";

import { useAuth } from "../../context/AuthContext";

import { RiImageAddFill } from "react-icons/ri";
import { BsArrowLeftRight } from "react-icons/bs";
import ClipLoader from "react-spinners/ClipLoader";

import { QrReader } from "react-qr-reader";
import { FcInspection } from "react-icons/fc";

import {
  SwipeableList,
  SwipeableListItem,
  SwipeAction,
  TrailingActions,
} from "react-swipeable-list";
import "react-swipeable-list/dist/styles.css";

import { MdChangeCircle } from "react-icons/md";

import ApparatusInspection from "./apparatusInspection";
import ItemPageInspectionTask from "../ItemPage/ItemPageInspectionTaskNew";

import Select from "react-select";
import PopupWeb from "../Popup/PopupNew";
import ToastNotifier, { notify } from "../Popup/ToastNotifier";
import { ItemPageContext } from "../../context/ItemPageContext";

const ApparatusInventoryHeader = () => {
  const { id } = useParams();
  const {
    currentUserId,
    currentUserSiteId,
    currentFirstName,
    currentLastName,
  } = useAuth();

  const [error, setError] = useState("");

  //reload page
  const location = useLocation();
  const history = useNavigate();

  const { name, setName } = useContext(ApparatusInventoryContext);

  function toggleClass(id) {
    document.getElementById(id).classList.toggle("show");
  }

  const { apparatusInventory, setInventory, setItemGroup } = useContext(
    ApparatusInventoryContext
  );

  const {
    addItemCompartmentId,
    addItemCompartmentName,
    setAddItemCompartmentId,
    setAddItemCompartmentName,

    //selectedInvId,
    setSelectedInvId,
  } = useContext(ApparatusInventoryContext);

  const { inspectionOpen, setInspectionOpen ,setItemId} = useContext(ItemPageContext);

  const {
    itemInfoOpen2,
    setItemInfoOpen2,
    setApparatusInventory,
    apparatusLocation,
    setApparatusLocation,
  } = useContext(ApparatusInventoryContext);
  const [itemInfoOpen, setItemInfoOpen] = useState(false);

  //Prop not using anymore should check it out
  const { setCompartments, compartments } = useContext(
    ApparatusInventoryContext
  );

  const { compartmentOpen2, setCompartmentOpen2, setAddItemOpen } = useContext(
    ApparatusInventoryContext
  );

  const { compartmentItems, setCompartmentItems } = useContext(
    ApparatusInventoryContext
  );
  const { bags, setBags } = useContext(ApparatusInventoryContext);
  const [apparatusList, setApparatusList] = useState("");

  const [loading, setLoading] = useState(false);

  //// console.log(location.state);

  // const scrollToCompartment = (id) => {
  //   var elmnt = document.getElementById(`Compartment${id}`);
  //   elmnt.scrollIntoView();
  //   window.scrollBy(0, -50); // Adjust scrolling with a negative value here
  // };

  const [errors, setErrors] = useState({});

  const [addMember, setAddMember] = useState("");
  const [memberList, setMemberList] = useState([]);
  const [apparatusListOptions, setApparatusListOptions] = useState([]);
  const [bagsListOptions, setBagsListOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id === undefined || null) {
          let search = location.state?.search;
          let number = search.split(":")[1];
          const response = await ApparatusFinder.get(`/getAppratusInventory`, {
            params: {
              currentUserSiteId,
              number,
            },
          });
          setMemberList(response.data.data.memberList);
          setApparatusListOptions(response.data.data.apparatusListNew);
          setBagsListOptions(response.data.data.bagsListNew);

          setName(response.data.data.apparatus.name);
          setApparatusInventory(response.data.data.apparatusInventory);
          setInventory(response.data.data.inventory);
          setItemGroup(response.data.data.itemGroupresults);
          setCompartments(response.data.data.compartments);
          setBags(response.data.data.bags);
          setApparatusList(response.data.data.apparatusList);
          setApparatusLocation(response.data.data.apparatusLocation);
          // console.log(response.data.data.apparatusLocation);
          if (number) {
            fetchData2(number);
            setOnlyBag(true);
          } else if (typeof location.state.compartmentId !== "undefined") {
            fetchDataCompertment(location.state.compartmentId);
          }
        } else {
          const response = await ApparatusFinder.get(`/getAppratusInventory`, {
            params: {
              id,
              currentUserSiteId,
            },
          });
          setMemberList(response.data.data.memberList);
          setApparatusListOptions(response.data.data.apparatusListNew);
          setBagsListOptions(response.data.data.bagsListNew);

          setName(response.data.data.apparatus.name);
          setApparatusInventory(response.data.data.apparatusInventory);
          setInventory(response.data.data.inventory);
          setItemGroup(response.data.data.itemGroupresults);
          setCompartments(response.data.data.compartments);
          setBags(response.data.data.bags);
          setApparatusList(response.data.data.apparatusList);
          setApparatusLocation(response.data.data.apparatusLocation);
          // console.log(response.data.data.apparatusLocation);
          if (typeof location.state === "undefined" || null) {
          } else if (typeof location.state.bagId !== "undefined") {
            fetchData2(location.state.bagId);
            console.log("kdfn kds");

            setOnlyBag(true);
          } else if (typeof location.state.compartmentId !== "undefined") {
            fetchDataCompertment(location.state.compartmentId);
          }
        }
      } catch (err) {
        // console.log(err);
      }
    };

    if (currentUserSiteId) {
      fetchData();
    } // eslint-disable-next-line
  }, [currentUserSiteId]);

  //Popup Compartment
  const toggleCompartment = () => {
    setCompartmentOpen2(!compartmentOpen2);
  };

  const [compartmentInfo, setCompartmentInfo] = useState("");

  const fetchDataCompertment = async (cid) => {
    toggleCompartment();
    try {
      const response = await ApparatusFinder.get("/getCompartment", {
        params: {
          cid: cid,
          currentUserSiteId,
        },
      });

      //setOldImgPath(response.data.data.compartmentItems[0].imagepath);
      //  console.log("ahjlbfdshjkbdsj");
      setCompartmentInfo(response.data.data.compartmentInfo);
      setAddItemCompartmentId(response.data.data.compartmentInfo[0].cid);
      setAddItemCompartmentName(response.data.data.compartmentInfo[0].name);
      setCompartmentItems(response.data.data.compartmentItems);
      setSelectedCompartmentLocation(
        response.data.data.compartmentLocation[0].apparatuslocationid
      );
      setSelectedCompartmentLocation2(response.data.data.compartmentLocation);
    } catch (err) {
      // console.log(err);
    }
  };

  const [bagItems, setBagItems] = useState("");
  const [selectedBagName, setSelectedBagName] = useState("");
  const [selectedBagId, setSelectedBagId] = useState("");

  const [onlyBag, setOnlyBag] = useState(false);

  const fetchData2 = async (bagId, cid) => {
    togglePopup();
    try {
      const response = await ApparatusFinder.get("/getBag", {
        params: {
          bagId: bagId,
          currentUserSiteId,
        },
      });
      // // console.log(response.data.data.bagItems);
      setOldImgPath(response.data.data.bagInfo[0].imagepath);
      setBagItems(response.data.data.bagItems);
      setSelectedBagName(response.data.data.bagInfo[0].bagname);
      setSelectedBagId(bagId);
      setSelectedCompartmentId(cid);
    } catch (err) {
      // console.log(err);
    }
  };

  //Looping through current compartment holders

  let currentBag = null;

  const itemOpenToggle = () => {
    setCompartmentOpen2(!compartmentOpen2);
    setItemInfoOpen(!itemInfoOpen);
  };

  // const [itemInfoOpen2, setItemInfoOpen2] = useState(false);

  const itemOpenToggle2 = () => {
    setIsOpen(!isOpen1);
    setItemInfoOpen2(!itemInfoOpen2);
  };

  const [itemid, setItemid] = useState(null);
  const [amount, setAmount] = useState(null);
  const [identificationname, setIdentificationname] = useState(null);
  const [imagepath, setImagepath] = useState(null);
  const [itemname, setItemname] = useState(null);
  const [moveable, setMoveable] = useState(null);
  const [serialnumber, setSerialnumber] = useState(null);
  const [status, setStatus] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);

  const handleInventory = (data) => {
    console.log(data);
    setSelectedInvId(data.itemid);

    setSelectedItemId(data.itemid);
    setItemid(data.itemid);
    setItemId(data.itemid);
    setAmount(data.amount);
    setIdentificationname(data.identificationname);
    setImagepath(data.imagepath);
    setItemname(data.itemname);
    setMoveable(data.moveable);
    setSerialnumber(data.serialnumber);
    setStatus(data.status);
    setSelectedStatus(data.status);

    itemOpenToggle();
  };



  const handleMoreDetailInventory = (id) => {
    history(`/inventory/${id}/itempage`);
  };

  //Add Item popup

  const togglePopupAddItem = () => {
    setAddItemOpen(true);
    setCompartmentOpen2(!compartmentOpen2);
  };

  //Popup Bag
  const [isOpen1, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen1);
    setCompartmentOpen2(false);
  };
  const togglePopupClose = () => {
    setIsOpen(false);
    setCompartmentOpen2(!compartmentOpen2);
    setOnlyBag(false);
  };

  const togglePopupClose2 = () => {
    setIsOpen(false);
    setOnlyBag(false);
  };

  //Popup Bag Edit
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen22, setIsOpen22] = useState(false);
  const [isOpen222, setIsOpen222] = useState(false);

  const togglePopupBagMain2 = () => {
    setIsOpen(!isOpen1);
    setIsOpen22(!isOpen22);
  };

  const togglePopupBagQr = () => {
    setIsOpen222(!isOpen222);
    setIsOpen22(!isOpen22);
  };

  const [moveBagCompartments, setMoveBagCompartments] = useState("");
  const [compartmentOpen, setCompartmentOpen] = useState(false);
  const [selectedApparatusId, setSelectedApparatusId] = useState("");

  const compartmentOpenToggle = () => {
    setCompartmentOpen(!compartmentOpen);
    setChangeLocation(!changeLocation);
  };

  const fetchData3 = async (apparatusId, apparatusName) => {
    setError("");
    try {
      // // console.log(apparatusId);
      const response = await ApparatusFinder.get("/moveBag", {
        params: {
          apparatusId: apparatusId,
          currentUserSiteId,
        },
      });
      setMoveBagCompartments(response.data.data.compartments);
      setSelectedApparatusId(apparatusId);
      setSelectedCompartmentId("");

      // console.log("jkdfn");
      compartmentOpenToggle();
    } catch (err) {
      // console.log(err);
    }
  };

  //bag move submit
  const handleMoveBagSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    if (selectedCompartmentId === "") {
      setError(`Select a compartment`);
      document.body.scrollTop = document.documentElement.scrollTop = 0;
      return false;
    }

    try {
      setLoading(true);

      // eslint-disable-next-line
      const response = await ApparatusFinder.post(`/moveBag`, {
        selectedCompartmentId,
        selectedBagId,
        id: id,
        selectedApparatusId,
        currentUserId,
        currentUserSiteId,
      });
      setApparatusInventory(response.data.data.apparatusInventory);
      togglePopup2222();
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  //change Location
  //Popup Add Item Task
  const [changeLocation, setChangeLocation] = useState(false);

  const changeLocationToggle = () => {
    setChangeLocation(!changeLocation);
    setIsOpen22(!isOpen22);
  };

  const togglePopup2 = () => {
    setIsOpen2(!isOpen2);
    setIsOpen22(!isOpen22);
    setSelectedImage("");
    setFile(null);
  };
  const togglePopup2222 = () => {
    setCompartmentOpen(!compartmentOpen);
  };
  const togglePopup22 = () => {
    setIsOpen2(false);

    setIsOpen(false);
  };

  //Popup Bag Delete
  const [isOpen3, setIsOpen3] = useState(false);
  const togglePopup3 = () => {
    setIsOpen3(!isOpen3);
    setIsOpen2(!isOpen2);
  };
  const togglePopup33 = () => {
    setIsOpen3(false);
  };

  //file input
  //custom image
  const fileRef = useRef();
  const [selectedImage, setSelectedImage] = useState();

  const [file, setFile] = useState(null);
  const [oldImgPath, setOldImgPath] = useState("");
  const headers = {
    "content-type": "multipart/form-data",
  };
  const handleFileInput = (e) => {
    setFile(e.target.files[0]);
    setSelectedImage(e.target.files[0]);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!selectedBagName) newErrors.name = "Please enter a name";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  //edit bag
  const handleBagSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {
      if (!validateForm()) {
        return;
      }

      setLoading(true);

      const formData = new FormData();
      //   // console.log("handleFileInput working!");

      formData.set("selectedBagName", selectedBagName);
      formData.set("selectedBagId", selectedBagId);
      formData.set("oldImgPath", oldImgPath);
      formData.set("id", id);
      formData.set("currentUserSiteId", currentUserSiteId);

      if (file) {
        formData.append("file", file);
      }

      const response = await ApparatusFinder.post(
        `/editBag`,
        formData,
        headers
      );
      setLoading(false);
      setSelectedImage("");
      setFile(null);
      setApparatusInventory(response.data.data.apparatusInventory);
      setBags(response.data.data.bags);
      togglePopup22();
      notify("Updated successfully!", "success"); // Show success notification
    } catch (err) {
      // console.log(err);
      setLoading(false);
      notify("Failed to update. Please try again.", "error"); // Show error notification
    }
  };

  //Compartment Edit
  //Input useState
  const [selectedCompartmentId, setSelectedCompartmentId] = useState("");
  // eslint-disable-next-line
  const [selectedCompartment, setSelectedCompartment] = useState("");
  const [setSelectedCompartmentLocation] = useState("");
  const [setSelectedCompartmentLocation2] = useState("");
  const [oldImgPathCompartment, setOldImgPathCopartment] = useState("");

  //Popup
  const [isOpen4, setIsOpen4] = useState(false);

  const editCompartment = async (
    compartmentCid,
    compartmentName,
    comaprtmentImgPath
  ) => {
    setSelectedCompartmentId(compartmentCid);
    setSelectedCompartment(compartmentName);
    setOldImgPathCopartment(comaprtmentImgPath);

    togglePopup5();
  };

  const editBag = async (bagid, bagname, comaprtmentImgPath) => {
    // console.log(bagid, bagname, comaprtmentImgPath);
    setSelectedBagId(bagid);
    setSelectedBagName(bagname);
    setOldImgPathCopartment(comaprtmentImgPath);
    togglePopupBagMain2();
    // togglePopupBag();
  };

  const handleDelete2 = async (e) => {
    //// console.log("ldfmsfk");
    //stop the page from reloading
    e.preventDefault();
    setLoading(true);

    try {
      const response = await ApparatusFinder.post(`/deleteBag`, {
        selectedBagId,
        oldImgPath: oldImgPathCompartment,
        id: id,
        currentUserSiteId,
      });
      setLoading(false);

      setApparatusInventory(response.data.data.apparatusInventory);
      setCompartments(response.data.data.compartments);
    } catch (err) {
      setLoading(false);
    }
  };

  //Popup
  const [isOpen5, setIsOpen5] = useState(false);
  const togglePopup5 = () => {
    setIsOpen5(!isOpen5);
    setCompartmentOpen2(!compartmentOpen2);
    setSelectedImage("");
    setFile(null);
  };

  const togglePopup55 = () => {
    setIsOpen5(false);
    setIsOpen4(false);
  };

  const [selected, setSelected] = useState("compartment");

  function changeColor(id) {
    setSelected(id);
  }

  let options = apparatusLocation.map(function (e) {
    return {
      value: e.locationname,
      label: e.locationname,
    };
  });

  const [dropdownQuery, setDropdownQuery] = useState("");

  //Scan popup
  const [isOpenScanner, setIsOpenScanner] = useState(false);
  const toggleScanner = () => {
    setIsOpenScanner(!isOpenScanner);
    setIsOpen(!isOpen1);
  };

  const handleScan = (data) => {
    if (data) {
      let data2 = data.split("?")[1];
      let search = data2.split(":")[0];
      let number = data2.split(":")[1];

      if (search === "itemId") {
        moveItem(number);
      }
    }
  };

  const moveItem = async (itemid) => {
    try {
      const response = await InventoryFinder.post(`/moveItem`, {
        itemid: itemid,
        selectedCompartmentId,
        selectedBagId,
        currentUserId,
        selectedApparatusId: id,
        currentUserSiteId,
      });

      if (response.data.data.alert) {
        setError(`Not Found`);
        return;
      }

      setIsOpenScanner(!isOpenScanner);
      fetchData2(selectedBagId);
    } catch (err) {
      // console.log(err);
    }
  };

  //Scan Remove Popup
  const [isOpenScannerRemove, setIsOpenScannerRemove] = useState(false);
  const toggleScannerRemove = (itemid, identificationname, name) => {
    setIsOpen(!isOpen1);
    setIsOpenScannerRemove(!isOpenScannerRemove);
    setScannerItemId(itemid);
    setScannerItemName(identificationname ? identificationname : name);
    setScannerType("bag");
  };

  const toggleScannerRemove1 = (itemid, identificationname, name) => {
    setIsOpenScannerRemove(!isOpenScannerRemove);
    setCompartmentOpen2(!compartmentOpen2);
    setScannerItemId(itemid);
    setScannerItemName(identificationname ? identificationname : name);
    setScannerType("compartment");
  };

  const toggleScannerRemove2 = () => {
    scannerType === "compartment"
      ? setCompartmentOpen2(!compartmentOpen2)
      : setIsOpen(!isOpen1);
    setIsOpenScannerRemove(!isOpenScannerRemove);
  };

  const [scannerItemId, setScannerItemId] = useState("");
  const [scannerItemName, setScannerItemName] = useState("");
  const [scannerType, setScannerType] = useState("");

  const trailingActions = (itemid, identificationname, name) => (
    <TrailingActions>
      <SwipeAction
        onClick={() => toggleScannerRemove(itemid, identificationname, name)}
      >
        <div className="unassignDiv">
          <b>Remove</b>
        </div>
      </SwipeAction>
    </TrailingActions>
  );

  const trailingActions2 = (itemid, identificationname, name) => (
    <TrailingActions>
      <SwipeAction
        onClick={() => toggleScannerRemove1(itemid, identificationname, name)}
        //onClick={() => // console.log("lkfalkkld")}
      >
        <div className="unassignDiv2">
          <b>Remove</b>
        </div>
      </SwipeAction>
    </TrailingActions>
  );

  const handleUnassign = async (e) => {
    //// console.log("ldfmsfk");
    e.preventDefault();
    setLoading(true);

    try {
      const response = await ApparatusFinder.post(`/unassignItem`, {
        bagId: selectedBagId,
        itemid: scannerItemId,
        cid: addItemCompartmentId,
        scannerType,
        currentUserId,
        currentUserSiteId,
        currentFirstName,
        currentLastName,
      });

      setLoading(false);

      if (response.data.data.bagItems) {
        setBagItems(response.data.data.bagItems);
      }
      if (response.data.data.compartmentItems) {
        setCompartmentItems(response.data.data.compartmentItems);
      }

      toggleScannerRemove2();
    } catch (err) {
      setLoading(false);
    }
  };

  const [itemStatusPopup, setItemStatusPopup] = useState(false);

  const toggleItemStatus = () => {
    setItemStatusPopup(!itemStatusPopup);
    setItemInfoOpen(!itemInfoOpen);
  };

  const toggleItemStatus2 = () => {
    setItemStatusPopup(!itemStatusPopup);
    setItemInfoOpen2(!itemInfoOpen2);
  };

  const toggleInspectionstatus = () => {
    setItemInfoOpen(!itemInfoOpen);

    setInspectionOpen(!inspectionOpen);
  };

  const toggleInspectionstatus2 = () => {
    setItemInfoOpen2(!itemInfoOpen2);

    setInspectionOpen(!inspectionOpen);
  };

  const handleOptionChange = (value) => {
    setSelectedStatus(value.target.value);
  };

  const handleStatusSubmit = async (e) => {
    try {
      e.preventDefault();
      // eslint-disable-next-line
      const response = await InventoryFinder.post(`/changeItemStatus`, {
        itemid: itemid,
        selectedStatus,
        currentUserId,
        currentUserSiteId,
      });
      setStatus(selectedStatus);

      const response2 = await ApparatusFinder.get("/getCompartment", {
        params: {
          cid: addItemCompartmentId,
          currentUserSiteId,
        },
      });

      setCompartmentItems(response2.data.data.compartmentItems);
      toggleItemStatus();
    } catch (err) {
      // console.log(err);
    }
  };

  const [isItemMoveOpen, setIsItemMoveOpen] = useState(false);

  const toggleItemMove = () => {
    setItemOpen4(!isItemOpen4);
    setItemInfoOpen(!itemInfoOpen);
  };

  const toggleItemMove2 = () => {
    setItemOpen4(!isItemOpen4);
    setItemInfoOpen(!itemInfoOpen);
  };

  //Popup move item
  const [isItemOpen4, setItemOpen4] = useState(false);
  const mainItemMovePopup = () => {
    setItemOpen4(!isItemOpen4);
    setItemInfoOpen(!itemInfoOpen);
  };
  const [selectedItemId, setSelectedItemId] = useState("");

  const [selectedItemCompartment, setSelectedItemCompartment] = useState("");
  const [selectedItemCompartmentId, setSelectedItemCompartmentId] =
    useState("");
  const [selectedItemBag, setSelectedItemBag] = useState("");
  const [selectedItemBagId, setSelectedItemBagId] = useState("");
  const [selectedItemApparatus, setSelectedItemApparatus] = useState("");

  const [selectedItemApparatusId, setSelectedItemApparatusId] = useState("");
  const [itemBags, setItemBags] = useState("");
  const [itemCompartments, setItemCompartments] = useState("");

  const fetchData4 = async (apparatusId, apparatusName) => {
    try {
      console.log("dlsfjjl");
      setLoading(true);
      // eslint-disable-next-line
      const response = await InventoryFinder.get("/moveItem", {
        params: {
          apparatusId: apparatusId,
          currentUserSiteId,
        },
      });
      setLoading(false);
      setSelectedItemApparatus(apparatusName);
      setItemCompartments(response.data.data.compartments);
      setItemBags(response.data.data.bags);
      setSelectedItemApparatusId(apparatusId);
      setSelectedItemCompartmentId("");
      setSelectedItemCompartment("");

      setSelectedItemBag("");
      setSelectedItemBagId("");
      mainItemMovePopup();
    } catch (err) {
      setLoading(false);

      // console.log(err);
    }
  };

  //select compartment Popup
  const [compartmentItemOpen, setCompartmentItemOpen] = useState(false);

  const toggleItemPopup2 = () => {
    setCompartmentItemOpen(!compartmentItemOpen);
    setItemOpen4(!isItemOpen4);
  };

  //select bag Popup
  const [bagItemOpen, setBagItemOpen] = useState(false);

  const toggleItemPopup3 = () => {
    setBagItemOpen(!bagItemOpen);
    setItemOpen4(!isItemOpen4);
  };

  //Item move submit
  const handleSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();

  
    try {
      setLoading(true);


      const response = await InventoryFinder.post(`/moveItem`, {
        selectedCompartmentId,
        selectedBagId,
        itemid: selectedItemId,
        currentUserId,
        selectedApparatusId,
        currentUserSiteId,
        currentFirstName,
        currentLastName,
        addMember,
      });



      const response2 = await ApparatusFinder.get("/getCompartment", {
        params: {
          cid: addItemCompartmentId,
          currentUserSiteId,
        },
      });

      //setOldImgPath(response.data.data.compartmentItems[0].imagepath);
      //  console.log("ahjlbfdshjkbdsj");
      setCompartmentItems(response2.data.data.compartmentItems);

      setItemOpen4(!isItemOpen4);
      toggleCompartment();

      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  let optionsMembers = memberList.map(function (data) {
    return {
      value: data.user_id,
      label: data.firstname + " " + data.lastname,
      id: data.user_id,
    };
  });

  let optionsApparatus = apparatusListOptions.map(function (data) {
    return {
      label: data.name,
      options: data.compartments.map((data2, i) => ({
        value: data2.cid,
        label: data2.compartmentname,
      })),
    };
  });

  let optionsBags = bagsListOptions.map(function (data) {
    return {
      label: data.name,
      options: data.bags.map((data2, i) => ({
        value: data2.bagid,
        label: data2.bagname,
      })),
    };
  });

  const renderPopupImage = (imagepath) => (
    <div className="appratusBagPopup">
      {imagepath ? (
        <img
          src={PublicFolder.baseURL + imagepath}
          alt="fix later"
          className="apparatusBagImg"
        />
      ) : (
        <div className="text-center p-3">
          <ClipLoader loading={loading} className="override" size={35} />
        </div>
      )}
    </div>
  );

  const renderPopupItemsList = (items) => (
    <SwipeableList>
      {items
        .filter(data => data.itemid) // Only keep items that contain an itemid
        .map((data, i) => (
          <SwipeableListItem
            key={i}
            trailingActions={trailingActions2(
              data.itemid,
              data.identificationname,
              data.itemname
            )}
          >
            <div className="itemRow2" onClick={() => handleInventory(data)}>
              <div className="itemName">
                {data.identificationname || data.itemname}
              </div>
              <div className="itemAmount">{data.amount}</div>
            </div>
          </SwipeableListItem>
        ))}
    </SwipeableList>
  );
  
  const renderBagItems = (bags) =>
    bags.map((data2, i) => (
      <div key={i} className="bagItemHolder">
        <div className="bagItem">
          {data2.identificationname || data2.bagitem}
        </div>
        <div className="bagAmount">{data2.bagAmount}</div>
      </div>
    ));

  const renderCompartmentItems = () => {
    const headerText =
    compartmentItems.length > 0
      ? compartmentItems[0].compartmentname
      : "Compartment Items";

  const hasCompartmentItems = compartmentItems && compartmentItems.length > 0;

    console.log(compartmentItems);
    return (
      <PopupWeb
        isOpen={compartmentOpen2}
        handleClose={toggleCompartment}
        headerText={headerText}
        handleSubmit={togglePopupAddItem}
        showSubmitButton={false}
        submitButtonText={loading ? "Loading..." : "New"} // Optionally change the text to indicate loading
        content={
          <>
            <div className="appartusBagPopupContainer">
              {compartmentItems.length > 0 ? (
                <>
                  <div className="text-center p-3">
                    <ClipLoader
                      loading={loading}
                      className="override"
                      size={35}
                    />
                  </div>
                  <div className="apparatusBagLocation" id="apparatusLocation">
                    <b>{name}</b> - {addItemCompartmentName}
                  </div>
                  {renderPopupImage(compartmentItems[0].imagepath)}
                  <div className="apparatusBagPopupitemTextHolder">
                    <div className="itemText1">Item</div>
                    <div className="amountText1">Amount</div>
                  </div>
                  {renderPopupItemsList(compartmentItems)}
                  {compartmentItems.map((compartment, i) => (
                  compartment.bagid && compartment.bags && compartment.bags.length > 0 && (
                    <div key={i} className="bagRow">
                      <div className="bagName2">
                        <div
                          className="bagName"
                          onClick={() =>
                            fetchData2(compartment.bagid, compartment.cid)
                          }
                        >
                          {compartment.bagname}
                        </div>
                        <div
                          className="bagDropdownArrow"
                          onClick={() => toggleClass(`${compartment.bagname}`)}
                        >
                          <i className="arrowdown"></i>
                        </div>
                      </div>
                      <div className="bagDropdown" id={compartment.bagname}>
                        {renderBagItems(compartment.bags)}
                      </div>
                    </div>
                  )
                ))}
                </>
              ) : compartmentInfo.length > 0 ? (
                <>
                  <div className="emptySpace"></div>
                  <div className="text-center p-3">
                    <ClipLoader
                      loading={loading}
                      className="override"
                      size={35}
                    />
                  </div>
                  <div className="apparatusBagLocation" id="apparatusLocation">
                    <b>{name}</b> - {addItemCompartmentName}
                  </div>
                  {renderPopupImage(compartmentInfo[0].imagepath)}
                  <div className="apparatusBagPopupitemTextHolder">
                    <div className="itemText1">Item</div>
                    <div className="amountText1">Amount</div>
                  </div>
                </>
              ) : (
                <div className="apparatusBagPopupitemTextHolder">
                  <div className="itemText1">Item</div>
                  <div className="amountText1">Amount</div>
                </div>
              )}
            </div>
          </>
        }
      />
    );
  };

  const renderBagPopup = () => (
    <PopupWeb
      isOpen={isOpen1}
      handleClose={onlyBag === true ? togglePopupClose2 : togglePopupClose}
      headerText={selectedBagName}
      handleSubmit={() => {
        if (bagItems.length > 0) {
          editBag(
            bagItems[0].bagid,
            bagItems[0].bagname,
            bagItems[0].imagepath
          );
        } else {
          editBag(selectedBagId, selectedBagName, oldImgPath);
        }
      }}
      showSubmitButton={true}
      submitButtonText={loading ? "Loading..." : "Edit"} // Optionally change the text to indicate loading
      content={
        <>
          <div className="appartusBagPopupContainer">
            {bagItems.length > 0 ? (
              <>
                <div className="apparatusBagLocation" id="apparatusLocation">
                  <b>{name}</b> - {addItemCompartmentName}
                  {selectedBagName ? ` - ${selectedBagName} ` : ""}
                </div>
                <div className="appratusBagPopup">
                  {bagItems[0].imagepath ? (
                    <img
                      src={PublicFolder.baseURL + bagItems[0].imagepath}
                      alt="fix later"
                      className="apparatusBagImg"
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="apparatusBagPopupitemTextHolder2">
                  <div className="itemText1">Item</div>
                  <div className="amountText1">Amount</div>
                </div>
                {renderBagItems(bagItems)}
              </>
            ) : (
              <>
                <div className="holderTestEmpty"></div>

                <div className="appratusBagPopup">
                  {oldImgPath ? (
                    <img
                      src={PublicFolder.baseURL + oldImgPath}
                      alt="fix later"
                      className="apparatusBagImg"
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div className="apparatusBagPopupitemTextHolder2">
                  <div className="itemText1">Item</div>
                  <div className="amountText1">Amount</div>
                </div>
                <div className="itemContainer2">
                  <div className="itemDiv">
                    <b>No Items Added</b>
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      }
    />
  );

  return (
    <>
      <div className="topbarEmptySpace"></div>

      <div className="popupContainer2">
        <div className="custom-select">
          <label className="NewInputLabel" data-domain="Zone">
            <select
              className="NewInput"
              onChange={(event) => setDropdownQuery(event.target.value)}
              required
            >
              <option value="" disabled>
                Select A Zone
              </option>
              <option value="">All</option>
              {options.map(function (e, i) {
                return (
                  <option key={i} value={e.label}>
                    {e.label}
                  </option>
                );
              })}
            </select>
          </label>
        </div>
      </div>

      <div className="list">
        {apparatusInventory.length > 0 ? (
          apparatusInventory
            // eslint-disable-next-line
            .filter((data) => {
              if (dropdownQuery === "") {
                return data;
              } else if (
                data.locationname
                  .toLowerCase()
                  .includes(dropdownQuery.toLowerCase())
              ) {
                return data;
              }
            })
            .map((data, i) => {
              //   console.log(data);
              if (i === 0 && dropdownQuery === "") {
                return (
                  <div key={i}>
                    <div className="holderDiv"></div>
                    <div
                      className="itemRow3"
                      onClick={() => fetchDataCompertment(data.cid)}
                    >
                      {data.imagepath ? (
                        <>
                          <div className="itemPicture">
                            <img
                              src={PublicFolder.baseURL + data.imagepath}
                              alt="IMG"
                              className="apparatusImgInventory2"
                            />
                          </div>
                          <div className="itemName2">
                            {data.compartmentname}
                          </div>
                        </>
                      ) : (
                        <div className="compartmentNameEmpty">
                          {data.compartmentname}
                        </div>
                      )}
                    </div>
                  </div>
                );
              } else if (i === 0) {
                return (
                  <div key={i}>
                    <div className="apparatusImgInventoryContainer">
                      <div className="apparatusImgInventoryDiv">
                        {data.locationimage ? (
                          <img
                            src={PublicFolder.baseURL + data.locationimage}
                            alt="IMG"
                            className="apparatusImgInventory"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div
                      className="itemRow3"
                      onClick={() => fetchDataCompertment(data.cid)}
                    >
                      {data.imagepath ? (
                        <>
                          <div className="itemPicture">
                            <img
                              src={PublicFolder.baseURL + data.imagepath}
                              alt="IMG"
                              className="apparatusImgInventory2"
                            />
                          </div>
                          <div className="itemName2">
                            {data.compartmentname}
                          </div>
                        </>
                      ) : (
                        <div className="compartmentNameEmpty">
                          {data.compartmentname}
                        </div>
                      )}
                    </div>
                  </div>
                );
              } else {
                return (
                  <div key={i}>
                    <div
                      className="itemRow3"
                      onClick={() => fetchDataCompertment(data.cid)}
                    >
                      {data.imagepath ? (
                        <>
                          <div className="itemPicture">
                            <img
                              src={PublicFolder.baseURL + data.imagepath}
                              alt="IMG"
                              className="apparatusImgInventory2"
                            />
                          </div>
                          <div className="itemName2">
                            {data.compartmentname}
                          </div>
                        </>
                      ) : (
                        <div className="compartmentNameEmpty">
                          {data.compartmentname}
                        </div>
                      )}
                    </div>
                  </div>
                );
              }
            })
        ) : (
          <>
            <div className="trainingEmptyContainer">
              <div className="trainingEmptyContainer2">
                <b>No inventory items added</b>
              </div>
            </div>
          </>
        )}
      </div>

      {compartmentOpen2 && renderCompartmentItems()}

      {isOpen1 && renderBagPopup()}
      {isOpen22 && (
        <PopupWeb
          content={
            <>
              <div className="popupBtnContainers">
                <div className="btnDiv">
                  <div className="addMemberBtn" onClick={togglePopup2}>
                    <b> Edit Bag Details</b>
                  </div>
                </div>
                <div className="btnDiv">
                  <div className="addMemberBtn" onClick={changeLocationToggle}>
                    <b>Move Bag</b>
                  </div>
                </div>
                <div className="btnDiv">
                  <div className="addMemberBtn" onClick={togglePopupBagQr}>
                    <b>QR Code</b>
                  </div>
                </div>
              </div>
            </>
          }
          handleClose={togglePopupBagMain2}
          isOpen={isOpen22}
          showSubmitButton={false}
          headerText={<b>{selectedBagName}</b>}
        />
      )}
      {isOpen2 && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleBagSubmit}>
                <div className="emptySpace"></div>

                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>
                <div className="popupContainer">
                  <label className="NewInputLabel" data-domain="Bag Name">
                    <input
                      className="NewInput"
                      type="text"
                      placeholder=""
                      onChange={(e) => {
                        setSelectedBagName(e.target.value);
                      }}
                      value={selectedBagName}
                      required
                    />
                    {errors.name && (
                      <div className="errorInputLabel">{errors.name}</div>
                    )}
                  </label>

                  <div className="NewInputLabel" data-domain="Add Image">
                    <input
                      ref={fileRef}
                      onChange={handleFileInput}
                      multiple={false}
                      type="file"
                      accept="image/*"
                      hidden
                    />
                    <div
                      onClick={() => fileRef.current.click()}
                      className="NewInputImage"
                    >
                      <RiImageAddFill size={30} />
                      {selectedImage && (
                        <div className="addImageHolder">
                          <img
                            src={URL.createObjectURL(selectedImage)}
                            alt="imageDidNotLoad"
                            className="addImage"
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="editApparatusBtnContainer">
                  <div className="editApparatusBtn" onClick={togglePopup3}>
                    <b>Delete</b>
                  </div>
                </div>
              </form>
            </>
          }
          handleClose={togglePopup2}
          isOpen={isOpen2}
          handleSubmit={handleBagSubmit}
          showSubmitButton={true}
          headerText={<b>Edit Bag</b>}
          submitButtonText={loading ? "Loading..." : "Save"} // Optionally change the text to indicate loading
        />
      )}
      {isOpen222 && (
        <PopupWeb
          content={
            <>
              <div className="popupBtnContainers">
                <div className="btnDiv">
                  <div className="addMemberBtn">
                    https://alleganyfireinventory.com/search?bagId:
                    {selectedBagId}
                  </div>
                </div>
              </div>
            </>
          }
          handleClose={togglePopupBagQr}
          isOpen={isOpen222}
          showSubmitButton={false}
          headerText={<b>QR Code Link</b>}
        />
      )}
      {changeLocation && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleSubmit}>

                <label className="NewInputLabel" data-domain="Location">
                  <Select
                    options={optionsApparatus}
                    onChange={(e) => setSelectedCompartmentId(e.value)}
                    name="addMembers"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                </label>
              </form>
            </>
          }
          handleClose={changeLocationToggle}
          isOpen={changeLocation}
          handleSubmit={handleMoveBagSubmit}
          showSubmitButton={true}
          headerText={<b>Edit Bag Location</b>}
          submitButtonText={loading ? "Loading..." : "Submit"} // Optionally change the text to indicate loading
        />
      )}
      {compartmentOpen && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleMoveBagSubmit}>
            
                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>

                {error && (
                  <div className="errorDiv">
                    <div className="alert2">{error}</div>
                  </div>
                )}
                <div className="popupContainer">
                  {moveBagCompartments &&
                    moveBagCompartments.map((data, i) => (
                      <div
                        key={i}
                        className="apparatusInventoryHolder"
                        onClick={() => {
                          setSelectedCompartment(`${data.name}`);
                          setSelectedCompartmentId(`${data.cid}`);
                        }}
                        style={{
                          background:
                            selectedCompartmentId === data.cid
                              ? "#a1e2e9d4"
                              : "",
                        }}
                      >
                        {data.imagepath ? (
                          <div className="apparatusInventoryImgDiv">
                            <img
                              src={PublicFolder.baseURL + data.imagepath}
                              alt="fix later"
                              className="apparatusInventoryImg"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="apparatusInventoryItemName">
                          {data.name}
                        </div>
                      </div>
                    ))}
                </div>
              </form>
            </>
          }
          handleClose={compartmentOpenToggle}
          isOpen={compartmentOpen}
          handleSubmit={handleMoveBagSubmit}
          showSubmitButton={true}
          headerText={  <b>Select Compartment</b>}
          submitButtonText={loading ? "Loading..." : "Save"} 


        />
      )}
      {isOpen3 && (
        <PopupWeb
          content={
            <>
              <div className="popupTitle">
                <b>Delete - {selectedBagName} </b>
              </div>

              <div className="deleteInfoApparatusContainer">
                <b>This bag will no longer be available</b>
              </div>
              <div className="deleteInfoApparatusContainer">
                <b>THIS WILL ONLY DELETE THE COMPARTMENT </b>
              </div>

            </>
          }
          handleClose={togglePopup33}

          isOpen={isOpen3}
          handleSubmit={handleDelete2}
          showSubmitButton={true}
          headerText={  <b>Delete - {selectedBagName} </b>}
          submitButtonText={loading ? "Loading..." : "Delete"} 

        />
      )}
      {isOpen4 && (
        <PopupWeb
          content={
            <>
              <div className="popupTitleCompartment">
                <div
                  className="popupTitleName"
                  style={{
                    background:
                      selected === "compartment" ? "rgb(192, 198, 199)" : "",
                  }}
                  onClick={() => {
                    changeColor("compartment");
                  }}
                >
                  <b>Compartment </b>
                </div>

                <div
                  className="popupTitleName2"
                  style={{
                    background: selected === "bag" ? "rgb(192, 198, 199) " : "",
                  }}
                  onClick={() => {
                    changeColor("bag");
                  }}
                >
                  <b>Bag </b>
                </div>
              </div>

              {selected === "compartment" ? (
                compartments.length > 0 ? (
                  compartments.map((data, i) => {
                    //  // console.log(data);
                    return (
                      <div key={i}>
                        <div
                          key={i}
                          className="apparatusInventoryHolder"
                          onClick={() =>
                            editCompartment(data.cid, data.name, data.imagepath)
                          }
                        >
                          {data.imagepath ? (
                            <div className="apparatusInventoryImgDiv">
                              <img
                                src={PublicFolder.baseURL + data.imagepath}
                                alt="fix later"
                                className="apparatusInventoryImg"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="apparatusInventoryItemName">
                            {data.name}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <></>
                )
              ) : bags.length > 0 ? (
                bags.map((data, i) => {
                  // // console.log(data);
                  return (
                    <div key={i}>
                      <div
                        key={i}
                        className="apparatusInventoryHolder"
                        onClick={() =>
                          editBag(data.bagid, data.bagname, data.imagepath)
                        }
                      >
                        {data.imagepath ? (
                          <div className="apparatusInventoryImgDiv">
                            <img
                              src={PublicFolder.baseURL + data.imagepath}
                              alt="fix later"
                              className="apparatusInventoryImg"
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="apparatusInventoryItemName">
                          {data.bagname}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </>
          }
          handleClose={togglePopup55}

          
          isOpen={isOpen4}
          handleSubmit={handleDelete2}
          showSubmitButton={false}
          headerText={                   <b>Compartment </b>
        }


        />
      )}

      {isOpenScanner && (
        <PopupWeb
          content={
            <>
             
              <div className="holderTestEmpty3"></div>
              {error && (
                <div className="errorDiv">
                  <div className="alert2">{error}</div>
                </div>
              )}

              <div className="coverUp"> &nbsp;</div>
              <div className="qrscannerContainer">
                <QrReader
                  delay={300}
                  facingMode={"environment"}
                  onScan={handleScan}
                  style={{ width: "100%" }}
                />
                {/*data*/}
              </div>
            </>
          }
          handleClose={toggleScanner}
                
          isOpen={isOpenScanner}
          showSubmitButton={false}
          headerText={          <b>Add a Item</b>
        }

        />
      )}

      {isOpenScannerRemove && (
        <PopupWeb
          content={
            <>
              <div className="popupTitle">
              
              </div>

              <div className="deleteInfoApparatusContainer">
                <b>This will unassign the current item</b>
              </div>

       
            </>
          }
          handleClose={toggleScannerRemove2}

          isOpen={isOpenScannerRemove}
          handleSubmit={handleUnassign}
          showSubmitButton={true}
          headerText={   <b>Remove {scannerItemName}</b>}
          submitButtonText={loading ? "Loading..." : "Remove"} 


        />
      )}

      {itemInfoOpen && (
        <PopupWeb
          content={
            <>
            
              {imagepath ? (
                <div className="appratusBagPopup">
                  <img
                    src={PublicFolder.baseURL + imagepath}
                    alt="fix later"
                    className="apparatusBagImg"
                  />
                </div>
              ) : (
                ""
              )}

              <div className="popupContainer">
                <div className="itemPageSideRightContainer">
                  <div className="itemPageAmountContainer">
                    <div className="itemPageAmountName">
                      <b>Item name</b>
                    </div>
                    <div className="itemPageAmountNumber">
                      <b>{itemname}</b>
                    </div>
                  </div>
                  <div className="itemPageAmountContainer">
                    <div className="itemPageAmountName">
                      <b>Amount</b>
                    </div>
                    <div className="itemPageAmountNumber">
                      <b>{amount}</b>
                    </div>
                  </div>

                  <div className="itemPageAmountContainer">
                    <div className="itemPageAmountName">
                      <b>Status</b>
                    </div>
                    <div className="itemPageAmountNumber">
                      <b>{status}</b>
                    </div>
                  </div>

                  <div className="itemPageAmountContainer">
                    <div className="itemPageAmountName">
                      <b>Identification Name</b>
                    </div>
                    <div className="itemPageAmountNumber">
                      <b>{identificationname}</b>
                    </div>
                  </div>

                  <div className="itemPageAmountContainer">
                    <div className="itemPageAmountName">
                      <b>Serial Number</b>
                    </div>
                    <div className="itemPageAmountNumber">
                      <b>{serialnumber}</b>
                    </div>
                  </div>


                  <div
                    className="itemPageAmountContainer2"
                    onClick={() => handleMoreDetailInventory(itemid)}
                  >
                    <div className="itemPageAmountName">
                      <b>More Information</b>
                    </div>
                    <div className="itemPageAmountNumber2">
                      <b>Click Here</b>
                    </div>
                  </div>
                </div>
              </div>

              <div className="quickButtonContainer">
                <div className="quickButtonHolder" onClick={ toggleItemMove}>
                  <div className="quickButtonIcon">
                    <b>
                      <BsArrowLeftRight />
                    </b>
                  </div>
                  <div className="quickButtonName">
                    <b>Move</b>
                  </div>
                </div>
                <div className="quickButtonHolder" onClick={toggleItemStatus}>
                  <div className="quickButtonIcon">
                    <b>
                      <MdChangeCircle />
                    </b>
                  </div>
                  <div className="quickButtonName">
                    <b>Status</b>
                  </div>
                </div>

                <div className="quickButtonHolder" onClick={toggleInspectionstatus}>
                  <div className="quickButtonIcon">
                    <b>
                      <FcInspection />
                    </b>
                  </div>
                  <div className="quickButtonName">
                    <b>Inspections</b>
                  </div>
                </div>
                {/* <div className="quickButtonHolder">
                  <div className="quickButtonIcon">
                    <b>
                      <MdChangeCircle />
                    </b>
                  </div>
                  <div className="quickButtonName">
                    <b>Inspections</b>
                  </div>
              </div>*/}
              </div>
            </>
          }
          handleClose={itemOpenToggle}

          isOpen={itemInfoOpen}
          showSubmitButton={false}
          headerText={  <b>{itemname}</b>}


        />
      )}

      {itemInfoOpen2 && (
        <PopupWeb
          content={
            <>
     

              {imagepath ? (
                <div className="appratusBagPopup">
                  <img
                    src={PublicFolder.baseURL + imagepath}
                    alt="fix later"
                    className="apparatusBagImg"
                  />
                </div>
              ) : (
                ""
              )}

              <div className="holderTestEmpty"></div>
              <div className="popupContainer">
                <div className="itemPageSideRightContainer">
                  <div className="itemPageAmountContainer">
                    <div className="itemPageAmountName">
                      <b>Item name</b>
                    </div>
                    <div className="itemPageAmountNumber">
                      <b>{itemname}</b>
                    </div>
                  </div>
                  <div className="itemPageAmountContainer">
                    <div className="itemPageAmountName">
                      <b>Amount</b>
                    </div>
                    <div className="itemPageAmountNumber">
                      <b>{amount}</b>
                    </div>
                  </div>

                  <div className="itemPageAmountContainer">
                    <div className="itemPageAmountName">
                      <b>Status</b>
                    </div>
                    <div className="itemPageAmountNumber">
                      <b>{status}</b>
                    </div>
                  </div>

                  <div className="itemPageAmountContainer">
                    <div className="itemPageAmountName">
                      <b>Identification Name</b>
                    </div>
                    <div className="itemPageAmountNumber">
                      <b>{identificationname}</b>
                    </div>
                  </div>

                  <div className="itemPageAmountContainer">
                    <div className="itemPageAmountName">
                      <b>Serial Number</b>
                    </div>
                    <div className="itemPageAmountNumber">
                      <b>{serialnumber}</b>
                    </div>
                  </div>

    

                  <div
                    className="itemPageAmountContainer2"
                    onClick={() => handleMoreDetailInventory(itemid)}
                  >
                    <div className="itemPageAmountName">
                      <b>More Information</b>
                    </div>
                    <div className="itemPageAmountNumber2">
                      <b>Click Here</b>
                    </div>
                  </div>
                </div>
              </div>

              <div className="quickButtonContainer">
                <div className="quickButtonHolder" onClick={toggleItemMove2}>
                  <div className="quickButtonIcon">
                    <b>
                      <BsArrowLeftRight />
                    </b>
                  </div>
                  <div className="quickButtonName">
                    <b>Move</b>
                  </div>
                </div>
                <div className="quickButtonHolder" onClick={toggleItemStatus2}>
                  <div className="quickButtonIcon">
                    <b>
                      <MdChangeCircle />
                    </b>
                  </div>
                  <div className="quickButtonName">
                    <b>Status</b>
                  </div>
                </div>

                <div className="quickButtonHolder" onClick={toggleInspectionstatus2}>
                  <div className="quickButtonIcon">
                    <b>
                      <FcInspection />
                    </b>
                  </div>
                  <div className="quickButtonName">
                    <b>Inspections</b>
                  </div>
                </div>

                {/* <div className="quickButtonHolder">
                  <div className="quickButtonIcon">
                    <b>
                      <MdChangeCircle />
                    </b>
                  </div>
                  <div className="quickButtonName">
                    <b>Inspections</b>
                  </div>
              </div>*/}
              </div>
            </>
          }
          handleClose={itemOpenToggle2}

          isOpen={itemInfoOpen2}
          showSubmitButton={false}
          headerText={   <b>{itemname}</b>}


        />
      )}

      {itemStatusPopup && (
        <PopupWeb
          content={
            <>
           <form onSubmit={handleStatusSubmit}>
          <div className="uniquePopupContainer">
            <div className="uniqueMiddleContainer">
              <div className="uniqueMiddle">
                <div className="uniqueItemStatusHeaderContainer">
                  <ul className="uniqueItemStatusList">
                    <li className="uniqueItemStatusListItem">
                      <div className="uniqueRadioLabelWrapper">
                        <label htmlFor="uniqueOptionGood">Good</label>
                        <input
                          type="radio"
                          id="uniqueOptionGood"
                          name="uniqueStatusSelector"
                          value={"Good"}
                          checked={selectedStatus === "Good"}
                          onChange={handleOptionChange}
                        />
                      </div>
                    </li>
                    <li className="uniqueItemStatusListItem">
                      <div className="uniqueRadioLabelWrapper">
                        <label htmlFor="uniqueOptionWorn">Worn</label>
                        <input
                          type="radio"
                          id="uniqueOptionWorn"
                          name="uniqueStatusSelector"
                          value={"Worn"}
                          checked={selectedStatus === "Worn"}
                          onChange={handleOptionChange}
                        />
                      </div>
                    </li>
                    <li className="uniqueItemStatusListItem">
                      <div className="uniqueRadioLabelWrapper">
                        <label htmlFor="uniqueOptionBad">Bad</label>
                        <input
                          type="radio"
                          id="uniqueOptionBad"
                          name="uniqueStatusSelector"
                          value={"Bad"}
                          checked={selectedStatus === "Bad"}
                          onChange={handleOptionChange}
                        />
                      </div>
                    </li>
                    <li className="uniqueItemStatusListItem">
                      <div className="uniqueRadioLabelWrapper">
                        <label htmlFor="uniqueOptionMonitor">Monitor</label>
                        <input
                          type="radio"
                          id="uniqueOptionMonitor"
                          name="uniqueStatusSelector"
                          value={"Monitor"}
                          checked={selectedStatus === "Monitor"}
                          onChange={handleOptionChange}
                        />
                      </div>
                    </li>
                    <li className="uniqueItemStatusListItem">
                      <div className="uniqueRadioLabelWrapper">
                        <label htmlFor="uniqueOptionOutOfService">Out Of Service</label>
                        <input
                          type="radio"
                          id="uniqueOptionOutOfService"
                          name="uniqueStatusSelector"
                          value={"Out Of Service"}
                          checked={selectedStatus === "Out Of Service"}
                          onChange={handleOptionChange}
                        />
                      </div>
                    </li>
                    <li className="uniqueItemStatusListItem">
                      <div className="uniqueRadioLabelWrapper">
                        <label htmlFor="uniqueOptionMissing">Missing</label>
                        <input
                          type="radio"
                          id="uniqueOptionMissing"
                          name="uniqueStatusSelector"
                          value={"Missing"}
                          checked={selectedStatus === "Missing"}
                          onChange={handleOptionChange}
                        />
                      </div>
                    </li>
                    <li className="uniqueItemStatusListItem">
                      <div className="uniqueRadioLabelWrapper">
                        <label htmlFor="uniqueOptionRetired">Retired</label>
                        <input
                          type="radio"
                          id="uniqueOptionRetired"
                          name="uniqueStatusSelector"
                          value={"Retired"}
                          checked={selectedStatus === "Retired"}
                          onChange={handleOptionChange}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </form>
            </>
          }
          handleClose={toggleItemStatus}

          isOpen={itemStatusPopup}
          handleSubmit={handleStatusSubmit}
          showSubmitButton={true}
          headerText={   <b>{itemname} Status</b>}
          submitButtonText={loading ? "Loading..." : "Submit"} 



        />
      )}

      {isItemMoveOpen && (
        <PopupWeb
          content={
            <>
      
              <div className="holderTestEmpty"></div>
              <div className="popupContainer">
                {apparatusList.length > 0 ? (
                  apparatusList.map((data, i) => {
                    // // console.log(data);
                    return (
                      <div key={i}>
                        <div
                          className="apparatusInventoryHolder"
                          onClick={() => fetchData4(data.id, data.name)}
                        >
                          {data.image ? (
                            <div className="apparatusInventoryImgDiv">
                              <img
                                src={PublicFolder.baseURL + data.image}
                                alt="fix later"
                                className="apparatusInventoryImg"
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="apparatusInventoryItemName">
                            {data.name}
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <></>
                )}
              </div>
            </>
          }
          handleClose={toggleItemMove}

          isOpen={isItemMoveOpen}
          handleSubmit={handleStatusSubmit}
          showSubmitButton={false}
          headerText={  <b>Edit Item Location</b>}



        />
      )}

      {isItemOpen4 && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleSubmit}>


<label className="NewInputLabel" data-domain="Members">
  <Select
    options={options}
    onChange={(e) => setAddMember(e.value)}
    name="addMembers"
    className="basic-multi-select"
    classNamePrefix="select"
    menuPortalTarget={document.body}
    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
  />
</label>

<label className="NewInputLabel" data-domain="Location">
  <Select
    options={optionsApparatus}
    onChange={(e) => setSelectedCompartmentId(e.value)}
    name="addMembers"
    className="basic-multi-select"
    classNamePrefix="select"
    menuPortalTarget={document.body}
    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
  />
</label>
<label className="NewInputLabel" data-domain="Bags">
  <Select
    options={optionsBags}
    onChange={(e) => setSelectedBagId(e.value)}
    name="addMembers"
    className="basic-multi-select"
    classNamePrefix="select"
    menuPortalTarget={document.body}
    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
  />
</label>
</form>
            </>
          }
          handleClose={mainItemMovePopup}

          isOpen={isItemOpen4}
          handleSubmit={handleSubmit}
          showSubmitButton={true}
          headerText={  <b>{itemname}</b>}
          submitButtonText={loading ? "Loading..." : "Update"} 


        />
      )}

      {compartmentItemOpen && (
        <PopupWeb
          content={
            <>
            
              <div className="popupContainer">
                {itemCompartments &&
                  itemCompartments.map((data, i) => (
                    <div
                      key={i}
                      className="apparatusInventoryHolder"
                      onClick={() => {
                        setSelectedItemCompartment(`${data.name}`);
                        setSelectedItemCompartmentId(`${data.cid}`);
                        setSelectedItemBagId("");
                        setSelectedItemBag("");
                        toggleItemPopup2();
                      }}
                    >
                      {data.imagepath ? (
                        <div className="apparatusInventoryImgDiv">
                          <img
                            src={PublicFolder.baseURL + data.imagepath}
                            alt="fix later"
                            className="apparatusInventoryImg"
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="apparatusInventoryItemName">
                        {data.name}
                      </div>
                    </div>
                  ))}
              </div>
            </>
          }
          handleClose={toggleItemPopup2}

          isOpen={compartmentItemOpen}
          showSubmitButton={false}
          headerText={     <b>Select Compartment</b>}



        />
      )}

      {/*bagItemOpen && (
        <PopupWeb
          content={
            <>
              <div id="holderTest" className="holderTest">
                <div className="popupTitle">
                  <b>Select Bag</b>
                </div>
              </div>
              <div className="HolderEmpty"></div>
              <div
                className="selectBagHolder"
                onClick={() => {
                  setSelectedItemBag("");
                  setSelectedItemBagId("");
                  togglePopup3();
                }}
              >
                <div className="selectBag">None</div>
                <div className="bagCompartment"></div>
              </div>
              <div>
                {bags &&
                  bags.map((data, i) => (
                    <div key={i}>
                      <div
                        className="selectBagHolder"
                        onClick={() => {
                          setSelectedItemBag(`${data.bagname}`);
                          setSelectedItemBagId(`${data.bagid}`);
                          setSelectedItemCompartment(`${data.name}`);
                          setSelectedItemCompartmentId(`${data.cid}`);
                          togglePopup3();
                        }}
                      >
                        <div className="selectBag">
                          {data.bagname} - {data.name}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          }
          handleClose={togglePopup3}
        />
        )*/}

      {bagItemOpen && (
        <PopupWeb
          content={
            <>
      
              <div
                className="selectBagHolder"
                onClick={() => {
                  setSelectedItemBag("");
                  setSelectedItemBagId("");
                  toggleItemPopup3();
                }}
              >
                <div className="selectBag">None</div>
                <div className="bagCompartment"></div>
              </div>
              <div>
                {itemBags &&
                  itemBags.map((data, i) => (
                    <div key={i}>
                      <div
                        className="selectBagHolder"
                        onClick={() => {
                          setSelectedItemBag(`${data.bagname}`);
                          setSelectedItemBagId(`${data.bagid}`);
                          setSelectedItemCompartment(`${data.name}`);
                          setSelectedItemCompartmentId(`${data.cid}`);
                          toggleItemPopup3();
                        }}
                      >
                        <div className="selectBag">
                          {data.bagname} - {data.name}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          }
          handleClose={toggleItemPopup3}

          isOpen={bagItemOpen}
          handleSubmit={handleSubmit}
          showSubmitButton={false}
          headerText={     <b>Select Bag</b>}



        />
      )}

      {
        //Inspections popups
      }

      {inspectionOpen && <ItemPageInspectionTask />}

      <ToastNotifier />
    </>
  );
};

export default ApparatusInventoryHeader;
