import React from "react";

import ApparatusChecklistHeader from "../components/ApparatusChecklist/ApparatusChecklistHeader";

function ApparatusChecklistMain() {
  return (
    <div>
      <ApparatusChecklistHeader />
    </div>
  );
}

export default ApparatusChecklistMain;
