import React from "react";
import "../components/Training/Training.css";
import TrainingHeader from "../components/Training/TrainingHeader";
import { TrainingContextProvider } from "../context/TrainingContext";

const Training = () => {
  return (
    <TrainingContextProvider>
      <>
        <TrainingHeader />
      </>
    </TrainingContextProvider>
  );
};

export default Training;
