import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

import ChecklistFinder from "../../apis/ChecklistFinder";

import Header from "../Header/Header";
import PopupWeb from "../Popup/PopupWeb";

import { useNavigate } from "react-router-dom";
import Select from "react-select";

import { IoMdArrowRoundBack } from "react-icons/io";

import ClipLoader from "react-spinners/ClipLoader";

const ChecklistMainPage = () => {
  //Popup
  const { id } = useParams();
  const { currentUserSiteId } = useAuth();

  const [loading, setLoading] = useState(false);

  const [isOpen1, setIsOpen] = useState(false);
  const togglePopup = () => {
    setIsOpen(!isOpen1);
  };

  //
  //

  const [checklist, setChecklist] = useState([]);
  //

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ChecklistFinder.get(`/allApparatusChecklist`, {
          params: {
            currentUserSiteId,
            apparatusId: id,
          },
        });
        //  // // console.log(response.data.data.checklist);
        setChecklist(response.data.data.checklist);
      } catch (err) {
        // // console.log(err);
      }
    };

    if (currentUserSiteId) {
      fetchData();
    }
  }, [currentUserSiteId]); // eslint-disable-line react-hooks/exhaustive-deps

  const history = useNavigate();

  const handleTraining = (id) => {
    history(`/editchecklist/${id}`);
  };
  //
  //
  const [error, setError] = useState("");

  //
  const [checklistName, setChecklistName] = useState("");
  const [repeatNumber, setRepeatNumber] = useState("");
  const [repeatMonth, setRepeatMonth] = useState("none");
  const [selectedStatus, setSelectedStatus] = useState("Disabled");
  const [scheduledDate, setScheduledDate] = useState();

  const handleNewChecklistSubmit = async (e) => {
    //stop the page from reloading
    setError("");
    e.preventDefault();

    try {
      setLoading(true);
      const response = await ChecklistFinder.post("/addchecklist", {
        checklistName,
        selectedApparatus: id,
        repeatNumber: repeatMonth === "Month" ? "1" : repeatNumber,
        repeatMonth,
        scheduledDate,
        selectedStatus,
        currentUserSiteId,
      });
      setLoading(false);
      handleNewChecklistPush(response.data.data.checklist.clid);
    } catch (err) {
      setLoading(false);
      setError("Failed");
    }
  };

  const handleNewChecklistPush = (id) => {
    history(`/editchecklist/${id}`);
  };

  let optionsRepeat = [
    { value: "None", label: "None" },
    { value: "Month", label: "On a specific day of the month" },
    {
      value: "Days",
      label: "A number of days from the last Scheduled Date",
    },
    {
      value: "Months",
      label: "A number of months from the last Scheduled Date",
    },
  ];

  let loopingGroup = null;

  const sideBar = document.getElementById("sidebar");

  return (
    <>
      <div
        id="topbar"
        className={
          sideBar === null
            ? "topbar headerActive"
            : sideBar.clientWidth === 80
            ? "topbar headerActive collapse"
            : "topbar headerActive"
        }
      >
        <div className="goBackDiv" onClick={() => history(-1)}>
          <IoMdArrowRoundBack />
        </div>
        <Header content={<b>Checklist</b>} />
        <div className="addItem" onClick={togglePopup}>
          +
        </div>
      </div>

      <div className="pageHeader">
        <b>Edit Checklist</b>
      </div>

      <div className="detailsPopupContainer222"></div>

      <div className="checklistListHolder">
        {checklist.length > 0 ? (
          checklist.map((data, i) => {
            // // // console.log(data);
            if (loopingGroup === data.apparatusname) {
              return (
                <div key={i}>
                  <div
                    className="checkContainer"
                    onClick={() => handleTraining(data.clid)}
                  >
                    {data.status === "Active" ? (
                      <div className="checklistStatusActive">
                        <b>{data.status}</b>
                      </div>
                    ) : (
                      <div className="checklistStatusDisabled">
                        <b>{data.status}</b>
                      </div>
                    )}

                    <div className="checkListContainer">
                      <div className="checkName">
                        <b>{data.checklistname}</b>
                      </div>

                      <div className="trainingTrainer">
                        Reacurring: {data.repeatnumber} {data.repeat}
                      </div>
                      <div className="trainingDesc">
                        Next Scheduled Date: {data.scheduleddate}
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else {
              loopingGroup = data.apparatusname;
              return (
                <div key={i}>
                  <div className="apparatusChecklist">{data.apparatusname}</div>
                  <div
                    className="checkContainer"
                    onClick={() => handleTraining(data.clid)}
                  >
                    {data.status === "Active" ? (
                      <div className="checklistStatusActive">
                        <b>{data.status}</b>
                      </div>
                    ) : (
                      <div className="checklistStatusDisabled">
                        <b>{data.status}</b>
                      </div>
                    )}

                    <div className="checkListContainer">
                      <div className="checkName">
                        <b>{data.checklistname}</b>
                      </div>
                      <div className="trainingTrainer">
                        Reacurring: {data.repeatnumber} {data.repeat}
                      </div>
                      <div className="trainingDesc">
                        Next Scheduled Date: {data.scheduleddate}
                      </div>
                    </div>
                  </div>
                </div>
              );
            }
          })
        ) : (
          <div className="checklistNone">No Checklist Made</div>
        )}
      </div>

      {isOpen1 && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleNewChecklistSubmit}>
                <div id="holderTest" className="holderTest">
                  <button
                    type="submit"
                    className="submitItemBtn2"
                    disabled={loading}
                  >
                    Done
                  </button>
                  <div className="popupTitle3">
                    <b>New Checklist</b>
                  </div>
                </div>
                <div className="emptySpace"></div>
                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>

                <div>
                  {error && <div className="alert">{error}</div>}

                  <div className="popupContainer">
                    <label className="NewInputLabel" data-domain="Name">
                      <input
                        className="NewInput"
                        type="text"
                        placeholder=""
                        value={checklistName}
                        onChange={(e) => setChecklistName(e.target.value)}
                        required
                      />
                    </label>

                    <div className="editChecklistSelectHolder">
                      <Select
                        options={optionsRepeat}
                        onChange={(e) => setRepeatMonth(e.value)}
                        name="addMembers"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        defaultValue={{ value: "None", label: "None" }}
                      />
                    </div>
                    <div className="editChecklistSelectInputHolder">
                      {repeatMonth === "Month" ? (
                        ""
                      ) : repeatMonth === "None" ? (
                        ""
                      ) : (
                        <input
                          value={repeatNumber}
                          onChange={(e) => setRepeatNumber(e.target.value)}
                          className="addItemInput"
                          type="number"
                          placeholder="Repeat Number"
                          required
                        />
                      )}
                    </div>

                    <label className="NewInputLabel" data-domain="Status">
                      <select
                        className="NewInput"
                        onChange={(event) =>
                          setSelectedStatus(event.target.value)
                        }
                        required
                      >
                        <option value="" disabled>
                          Select Status
                        </option>

                        <option value={"Disabled"}>{"Disabled"}</option>
                        <option value={"Active"}>{"Active"}</option>
                      </select>
                    </label>

                    <label className="NewInputLabel" data-domain="Start Date">
                      <input
                        className="NewInput"
                        type="date"
                        value={scheduledDate}
                        onChange={(e) => setScheduledDate(e.target.value)}
                        onFocus={(event) =>
                          (event.nativeEvent.target.defaultValue = "")
                        }
                        required
                      />
                    </label>
                  </div>
                </div>
              </form>
            </>
          }
          handleClose={togglePopup}
        />
      )}
    </>
  );
};

export default ChecklistMainPage;
