import React from "react";
import "../components/Training/Training.css";

import UserInfoItems from "../components/AdminPage/AdminUserPage/UserInfoItems";

const UserItems = () => {
  return (
    <>
      <UserInfoItems />
    </>
  );
};

export default UserItems;
