import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { useMainItemPageData } from "./fetchItemPageData";
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable, getPaginationRowModel } from "@tanstack/react-table";
import PublicFolder from "../../apis/PublicFolder";
import Header from "../Header/Header";
import PDFReportTemplate from "../Reports/PDFReportTemplate";
import { AiOutlineMenu } from "react-icons/ai";

const Pagination = ({ table }) => {
  const { pageIndex, pageSize } = table.getState().pagination;

  useEffect(() => {
    localStorage.setItem("InventoryPage", pageIndex);
  }, [pageIndex]);

  const totalRows = table.getPrePaginationRowModel().rows.length;
  const startRow = pageIndex * pageSize + 1;
  const endRow = Math.min(startRow + pageSize - 1, totalRows);

  return (
    <div className="pagination2">
      <div className="results-info">
        Showing {startRow} to {endRow} of {totalRows} results
      </div>
      <div className="pagination-controls">
        <button
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          Previous
        </button>
        <span>{pageIndex + 1}</span>
        <button
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          Next
        </button>
      </div>
    </div>
  );
};

const ItemPageHistory = () => {
  const { id } = useParams();
  const { currentUserSiteId } = useAuth();
  const { data, refetch } = useMainItemPageData(currentUserSiteId, id);
  const [itemHistory, setItemHistory] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [statusFilter, setStatusFilter] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [showImages, setShowImages] = useState({}); // Track which rows have images shown

  const history = useNavigate();

  useEffect(() => {
    setItemHistory(data?.data.data.itemHistory || []);
  }, [data]);

  useEffect(() => {
    const storedEventStatusFilter = localStorage.getItem("EventStatusFilters");
    if (storedEventStatusFilter) {
      setStatusFilter(JSON.parse(storedEventStatusFilter));
    }
  }, []);

  const toggleNav = () => {
    document.getElementById("topbar").classList.toggle("collapse");
    document.getElementById("sidebar").classList.toggle("collapse");
    document.getElementById("appContainer").classList.toggle("collapse");
    document.getElementById("appContainerHidden").classList.toggle("collapse");
  };

  const handleClearFilters = () => {
    setStatusFilter([]);
    localStorage.removeItem("EventStatusFilters");
    localStorage.removeItem("EventPage");
    refetch();
  };

  const toggleShowImages = (rowIndex) => {
    setShowImages((prevShowImages) => ({
      ...prevShowImages,
      [rowIndex]: !prevShowImages[rowIndex],
    }));
  };

  const columnHelper = createColumnHelper();
  const columns = React.useMemo(
    () => [
      columnHelper.accessor('date1', {
        header: 'Date',
        cell: info => (info.getValue() ? `${info.getValue()}` : 'No date'),
      }),
      columnHelper.accessor('type', {
        header: 'Type',
        cell: info => (
          <>
            {info.getValue() || 'No type'}
            {info.row.original.checklistname ? ` - ${info.row.original.checklistname}` : ''}
          </>
        ),
      }),
      columnHelper.accessor('username', {
        header: 'User Name',
        cell: info => info.getValue() || 'Unknown',
      }),
  
      columnHelper.accessor('notes', {
        header: 'Notes',
        cell: info => info.getValue() || '',
      }),
      columnHelper.accessor('tasklist', {
        header: 'Tasks',
        cell: info => (
          <>
            {(info.getValue() || []).map((task, index) => (
              <div key={index}>
                {task.taskstatus || 'No status'}
                {task.taskname ? ` - ${task.taskname}` : ''}
              </div>
            ))}
            {info.getValue().length > 0 && (
              <div>{info.getValue()[0].tasknote || ''}</div>
            )}
          </>
        ),
      }),
      columnHelper.accessor('tasklist', {
        header: 'Images',
        cell: info => {
          const rowIndex = info.row.index;
          const hasImages = info.getValue().length > 0 && info.getValue()[0].photos && info.getValue()[0].photos.length > 0;
          return (
            <>
              {hasImages && (
                <>
                  <button onClick={() => toggleShowImages(rowIndex)}>
                    {showImages[rowIndex] ? 'Hide Images' : 'Show Images'}
                  </button>
                  {showImages[rowIndex] && (
                    <div>
                      <div>Images Attached [{info.getValue()[0].photos.length}]</div>
                      <div>
                        {info.getValue()[0].photos.map((photo, index) => (
                          <div key={index} className="ItemHistoryPhoto">
                            <img
                              src={`${PublicFolder.baseURL}preview_${photo.filename}`}
                              alt="Inspection"
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          );
        },
      }),
    ],
    [showImages]
  );

  const table = useReactTable({
    data: itemHistory,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: { pagination: { pageIndex: 0, pageSize: 25 } }, // Set initial page size and page index
  });

  const pdfColumns = [
    { header: "Date", accessor: row => row.date1 },
    { header: "Title", accessor: row => row.title },
    { header: "Note", accessor: row => row.note },
    { header: "Status", accessor: row => row.status },
  ];

  return (
    <div className="containerInventory">
      <div className="topbarEmptySpace2"></div>
      <div className="top">
        <div id="topbar" className="topbar headerActive">

          <Header content={<b>History</b>} />
       
        </div>

   
      </div>

      <div className="middle-container">
        <div className="middle">
          <div className="inventoryList no-extra-content">
            <div className="ReportResultsContainer no-extra-content">
              <div className="reportsPopupContainer no-extra-content">
                {itemHistory.length > 0 ? (
                  <div className={`table-container ${isCollapsed ? "collapsed" : ""}`}>
                    <table className="custom-table">
                      <thead className="custom-table-header-container">
                        {table.getHeaderGroups().map(headerGroup => (
                          <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                              <th key={header.id} className="custom-table-header">
                                {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody>
                        {table.getRowModel().rows.map(row => (
                          <tr key={row.id}>
                            {row.getVisibleCells().map(cell => (
                              <td key={cell.id} className="custom-table-cell">
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div>No history available</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bottom">
        <Pagination table={table} />
      </div>
    </div>
  );
};

export default ItemPageHistory;
