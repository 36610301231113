import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "./Navbar.css";
import { BsCalendar2Event } from "react-icons/bs";
import { BiLogOutCircle } from "react-icons/bi";
import {
  MdOutlineSpaceDashboard,
  MdQrCodeScanner,
  MdHelpOutline,
} from "react-icons/md";
import { AiOutlineAppstoreAdd, AiOutlineTool } from "react-icons/ai";

import { TbReportAnalytics, TbArrowsDownUp } from "react-icons/tb";
import { IoIosArrowUp, IoMdSettings } from "react-icons/io";
import { IoPersonSharp } from "react-icons/io5";
import LogonLogo from "../../PublicPhotos/LogonIcon.png";

//import PublicFolder from "../../apis/PublicFolder";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
//import NavbarMobile from "./NavbarMobile";
import { AiFillFolderOpen } from "react-icons/ai";
import { BsCardChecklist } from "react-icons/bs";
import { IoBagAddOutline } from "react-icons/io5";

function Navbar() {
  const history = useNavigate();

  const {currentUserId, logout, currentLastName, currentFirstName, currentUserSiteName } =
    useAuth();

  async function handleLogout() {
    try {
      await logout();
      document.getElementById("body2").style.removeProperty("position");
      document.getElementById("body").style.overflow = "auto";
      history("/login");
    } catch (eer) {
      document.getElementById("body2").style.removeProperty("position");
      document.getElementById("body").style.overflow = "auto";
      // console.log(eer);
    }
  }
  /*
  const test = () => {
    let sideBar = document.getElementById("sidebar");
    let appContainer = document.getElementById("appContainer");
    let topBar = document.getElementById("topbar");
    let overlay = document.getElementsByClassName("popup-overlay")[0];
    let holderTest = document.getElementById("holderTest");

    let arrowCollapseRight = document.getElementById("bx-arrow-from-right");
    let arrowCollapseLeft = document.getElementById("bx-arrow-from-left");

    sideBar.classList.toggle("collapse");
    appContainer.classList.toggle("collapse");
    topBar.classList.toggle("collapse");

    arrowCollapseRight.classList.toggle("collapse");

    holderTest !== null ? holderTest.classList.toggle("collapse") : <></>;

    if (arrowCollapseRight.classList.contains("collapse")) {
      arrowCollapseRight.classList.remove("disabled");
      arrowCollapseLeft.classList.add("disabled");
      overlay !== undefined ? overlay.classList.remove("collapse") : <></>;
      overlay !== undefined ? overlay.classList.add("collapse2") : <></>;
    } else {
      overlay !== undefined ? overlay.classList.remove("collapse2") : <></>;
      overlay !== undefined ? overlay.classList.add("collapse") : <></>;

      arrowCollapseRight.classList.add("disabled");
      arrowCollapseLeft.classList.remove("disabled");
    }
  };
  */
  let rrrrr = window.matchMedia("only screen and (max-width: 1500px)").matches;

  const [isMobile, setIsMobile] = useState(rrrrr ? "True" : "False");

  const mediaQuery = window.matchMedia("(max-width: 1500px)");

  mediaQuery.addListener(handleTabletChange);

  function handleTabletChange(e) {
    if (e.matches) {
      setIsMobile("True");
      removeNav();
      // console.log("3214");
    } else {
      setIsMobile("False");
      addNav();
      //console.log("55555");
    }
  }

  useEffect(() => {
    if (isMobile === "False") {
      setIsMobile("False");
      // console.log("3344");

      //addNav();
    } else {
      setIsMobile("True");
      // console.log("115555111");

      removeNav();
      //  console.log("rrrrr");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleNav = () => {

    const keysToRemove = [
      'InventoryFilters',
      'InventorySearchQuery',
      'InventoryPage',
      'InventoryInspectionFilter',
      'InventoryMemberFilter',
      'EventCategoryFilters',
      'EventStatusFilters',
      'EventMemberFilter',
      'InventoryBagFilter',
      'InventoryLocationFilter',
      'BagsLocationFilter',
      'BagsSearchQuery',

    ];
  
    keysToRemove.forEach((key) => localStorage.removeItem(key));

 //   localStorage.setItem('InventoryFilters', []);

    console.log("22222");
    if (isMobile === "True") {
      console.log("11111");

      let topbar = document.getElementById("topbar");
      let sideBar = document.getElementById("sidebar");
      let appContainer = document.getElementById("appContainer");
      let appContainerHidden = document.getElementById("appContainerHidden");

      topbar.classList.toggle("collapse");
      sideBar.classList.toggle("collapse");
      appContainer.classList.toggle("collapse");
      appContainerHidden.classList.toggle("collapse");
    }
  };

  const addNav = () => {
    console.log("dddfdsf");
    let topBar = document.getElementById("topbar");

    let topbar = document.getElementById("topbar");
    let sideBar = document.getElementById("sidebar");
    let appContainer = document.getElementById("appContainer");
    let appContainerHidden = document.getElementById("appContainerHidden");

    topbar.classList.add("collapse");
    sideBar.classList.remove("collapse");
    appContainer.classList.add("collapse");
    appContainerHidden.classList.add("collapse");
    topBar.classList.add("collapse");
  };

  const removeNav = () => {
    //console.log("Web");
    let topbar = document.getElementById("topbar");
    let sideBar = document.getElementById("sidebar");
    let appContainer = document.getElementById("appContainer");
    let appContainerHidden = document.getElementById("appContainerHidden");

    topbar.classList.remove("collapse");
    sideBar.classList.add("collapse");
    appContainer.classList.remove("collapse");
    appContainerHidden.classList.remove("collapse");
  };

  function toggleClass(id) {
    document.getElementById(id).classList.toggle("show");
  }

  return (
    <>
      <div className="side-bar collapse" id="sidebar">
        <NavLink
          className="nav-links"
          to="/site"
          onClick={() => toggleNav(false)}
        >
          <div className="logo-name">
          <img src={LogonLogo} alt="Did Not Load" className="logo" />

            <div className="logo-name__name">
              <b>{currentUserSiteName}</b>
            </div>
            <TbArrowsDownUp />
          </div>
        </NavLink>

        <ul className="features-list">
          <NavLink
            className="nav-links"
            to="/"
            onClick={() => toggleNav(false)}
          >
            <div className="features-item-holder">
              <div className="features-item-Icon">
                <MdOutlineSpaceDashboard />
              </div>
              <div className="features-item-text">Home</div>
            </div>
          </NavLink>

          <NavLink
            className="nav-links"
            to="/inventory"
            onClick={() => toggleNav(false)}
          >
            <div className="features-item-holder">
              <div className="features-item-Icon">
                <AiOutlineTool />
              </div>
              <div className="features-item-text">Inventory</div>
            </div>
          </NavLink>

          <NavLink
            className="nav-links"
            to="/events"
            onClick={() => toggleNav(false)}
          >
            <div className="features-item-holder">
              <div className="features-item-Icon">
                <BsCalendar2Event />
              </div>
              <div className="features-item-text">Events</div>
            </div>
          </NavLink>




          <NavLink
            className="nav-links"
            to="/apparatus"
            onClick={() => toggleNav(false)}
          >
            <div className="features-item-holder">
              <div className="features-item-Icon">
                <AiOutlineAppstoreAdd />
              </div>
              <div className="features-item-text">Locations</div>
            </div>
          </NavLink>

          <NavLink
            className="nav-links"
            to="/bags"
            onClick={() => toggleNav(false)}
          >
            <div className="features-item-holder">
              <div className="features-item-Icon">
                <IoBagAddOutline/>
              </div>
              <div className="features-item-text">Bags</div>
            </div>
          </NavLink>


          <NavLink
            className="nav-links"
            to="/checklistWeb"
            onClick={() => toggleNav(false)}
          >
            <div className="features-item-holder">
              <div className="features-item-Icon">
                <BsCardChecklist  />
              </div>
              <div className="features-item-text">Checklist</div>
            </div>
          </NavLink>

        
          <NavLink
            className="nav-links"
            to="/qr"
            onClick={() => toggleNav(false)}
          >
            <div className="features-item-holder">
              <div className="features-item-Icon">
                <MdQrCodeScanner />
              </div>
              <div className="features-item-text">QR</div>
            </div>
          </NavLink>

          {currentUserId === "1" && (

            <>  <NavLink
            className="nav-links"
            to="/reports"
            onClick={() => toggleNav(false)}
          >
            <div className="features-item-holder">
              <div className="features-item-Icon">
                <TbReportAnalytics />
              </div>
              <div className="features-item-text">Reports</div>
            </div>
          </NavLink>
          
          <NavLink
              className="nav-links"
              to="/admin"
              onClick={() => toggleNav(false)}
            >
              <div className="features-item-holder">
               
                <div className="features-item-text">Admin</div>
              </div>
            </NavLink>
          </>
            
            
          )}
          {/*  {(currentUserRole === "Advanced" || currentUserRole === "Admin") && (
            <NavLink
              className="nav-links"
              to="/memberlist"
              onClick={() => toggleNav(false)}
            >
              <div className="features-item-holder">
                <div className="features-item-Icon">
                  <BsPeople />
                </div>
                <div className="features-item-text">Site Members</div>
              </div>
            </NavLink>
          )}
          {currentUserRole === "Admin" && (
            <NavLink
              className="nav-links"
              to="/sitesettings/categories"
              onClick={() => toggleNav(false)}
            >
              <div className="features-item-holder">
                <div className="features-item-Icon">
                  <BsPeople />
                </div>
                <div className="features-item-text">Site Categories</div>
              </div>
            </NavLink>
          )}
         
          <div className="nav-links" onClick={handleLogout}>
            <div className="features-item-holder">
              <div className="features-item-Icon">
                <BiLogOutCircle />
              </div>
              <div className="features-item-text">Log Out</div>
            </div>
          </div>*/}
        </ul>

        <div className="bottom-div-holder">
          <div className="nav-links-22" id="name-holder">
            <NavLink
              className="nav-links"
              to="/"
              onClick={() => toggleNav(false)}
            >
              <div className="features-item-holder">
                <div className="features-item-Icon">
                  <MdHelpOutline />
                </div>
                <div className="features-item-text">Help</div>
              </div>
            </NavLink>

            <NavLink
              className="nav-links"
              to="/sitesettings"
              onClick={() => toggleNav(false)}
            >
              <div className="features-item-holder">
                <div className="features-item-Icon">
                  <IoMdSettings />
                </div>
                <div className="features-item-text">Site Settings</div>
              </div>
            </NavLink>

            <NavLink
              className="nav-links"
              to="/settings"
              onClick={() => toggleNav(false)}
            >
              <div className="features-item-holder">
                <div className="features-item-Icon">
                  <IoPersonSharp />
                </div>
                <div className="features-item-text">My Profile</div>
              </div>
            </NavLink>

            <div className="nav-links" onClick={handleLogout}>
              <div className="features-item-holder">
                <div className="features-item-Icon">
                  <BiLogOutCircle />
                </div>
                <div className="features-item-text">Sign Out</div>
              </div>
            </div>
          </div>
          <div
            className="name-holder"
            onClick={() => {
              toggleClass(`name-holder`);
            }}
          >
            <div className="nameIcon">
              <b>
                {currentFirstName?.charAt(0)}
                {currentLastName?.charAt(0)}
              </b>
            </div>
            <div className="nameDisplay">
              <b>
                {currentFirstName} {currentLastName}
              </b>
              <div className="nameDisplayArrow">
                <b>
                  <IoIosArrowUp />
                </b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Navbar;
