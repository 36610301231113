import React from "react";

//import Scanner from "../Scanner/Scanner";
import ChecklistLocationPage from "./ChecklistLocationPage";
import ChecklistMainPageWeb from "./ChecklistMainPageWeb";
import ChecklistFrequency from "./ChecklistFrequency";




import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const ChecklistHeader = () => {
  //Photos
  //https://www.cleanpng.com/free/id.html

  //var bottomBar = document.getElementById("popup-1");

  return (
    <>
      <div className="topbarEmptySpace"></div>

      <div className="detailsPopupContainer">
        <Tabs>
          <TabList>
            <Tab>
              <b>Checklist</b>
            </Tab>
            <Tab>
              <b>Location</b>
            </Tab>
            <Tab>
              <b>Frequency</b>
            </Tab>
          </TabList>

          <TabPanel>
            <ChecklistMainPageWeb />
          </TabPanel>
          <TabPanel>
            <ChecklistLocationPage />
          </TabPanel>
          <TabPanel>
            <ChecklistFrequency />
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
};

export default ChecklistHeader;
