import { useQuery } from "@tanstack/react-query";
import SiteFinder from "../../apis/SiteFinder";

const fetchToDoPage = ({ queryKey }) => {

  var  bagSearchQuery = localStorage.getItem('BagsSearchQuery');
  var  apparatusFilter= localStorage.getItem('BagsLocationFilter');


  const currentUserSiteId = queryKey[1];
  return SiteFinder.get(`/getBagList`, {
    params: {
      currentUserSiteId,
      bagSearchQuery,
      apparatusFilter
    },
  });
};

export const useMainSiteCategoryData = (currentUserSiteId) => {
  return useQuery(["mainToDo", currentUserSiteId], fetchToDoPage);
};
