import React, { useEffect, useState, useContext } from "react";
import { UserInfoContext } from "../../../context/UserInfoContext";
import { useParams } from "react-router-dom";

import { useNavigate } from "react-router-dom";

import { MdArrowBackIosNew } from "react-icons/md";

import { CgProfile } from "react-icons/cg";
import ClipLoader from "react-spinners/ClipLoader";

import { useAuth } from "../../../context/AuthContext";

import { useSelectedMemberData } from "../fetchMemberData";

const AdminProfilePageHeader = () => {
  const { id } = useParams();
  const { currentUserSiteId } = useAuth();

  const { data } = useSelectedMemberData(currentUserSiteId, id);

  const history = useNavigate();

  useEffect(() => {
    if (data) {
      setFirstName(data?.data.data.userInfo[0].firstname);
      setLastName(data?.data.data.userInfo[0].lastname);
      setUserItemsCount(data?.data.data.userItems.length);

      setEventCount(data?.data.data.trainingInfo.length);
      setIndiviualTrainingCount(data?.data.data.individulTraining.length);

      setEventInfo(data?.data.data.trainingInfo);
      setEventCount(data?.data.data.trainingInfo.length);
      setItemInfo(data?.data.data.userItems);
    } // eslint-disable-next-line
  }, [data]);
  //console.log(data?.data.data);
  /*
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await UserFinder.get(`/userInfo`, {
          params: {
            userid: id,
            currentUserSiteId,
          },s
        });

        // console.log(response.data.data.trainingInfo);
        setUserName(response.data.data.userInfo[0].username);
        setFirstName(response.data.data.userInfo[0].firstname);
        setLastName(response.data.data.userInfo[0].lastname);

        setUserItemsCount(response.data.data.userItemsCount);
        setEventCount(response.data.data.trainingInfo.length);

        setIndiviualTrainingCount(response.data.data.individulTraining.length);
        setEventInfo(response.data.data.trainingInfo);
        setTrainerInfo(response.data.data.trainerInfo);
        setItemInfo(response.data.data.userItems);
      } catch (err) {
        console.log(err);
      }
    };

    if (currentUserSiteId) {
      fetchData();
    } // eslint-disable-next-line
  }, [currentUserSiteId]);
*/
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [userItemsCount, setUserItemsCount] = useState("0");
  const [eventCount, setEventCount] = useState("0");

  const [indiviualTrainingCount, setIndiviualTrainingCount] = useState("0");

  const { setItemInfo, setEventInfo } = useContext(UserInfoContext);

  const handleAttendedEvents = () => {
    history(`/userInfo/${id}/events`);
  };

  const handleUserItems = () => {
    history(`/userInfo/${id}/items`);
  };
  const handleIndividualTraining = () => {
    history(`/userInfo/${id}/individualtraining`);
  };

  const sideBar = document.getElementById("sidebar");

  return (
    <div className="profileHeaderContainer">
      <div
        id="topbar"
        className={
          sideBar === null
            ? "topbar headerActive"
            : sideBar.clientWidth === 80
            ? "topbar headerActive collapse"
            : "topbar headerActive"
        }
      >
        <div className="goBackDiv" onClick={() => history(-1)}>
          <div className="goBackDivarrow">
            <MdArrowBackIosNew />
          </div>
          <div className="goBackDivtxt">Site Members</div>
        </div>
      </div>

      <div className="profileHeaderHolder">
        <div className="profileHeaderHolderPic">
          <CgProfile size={60} />
        </div>
        <div className="profileHeaderHolderName">
          <b>
            {firstName} {lastName}
          </b>
        </div>
      </div>
      {!firstName && (
        <div className="popupLoading1">
          <div className="popupLoading2">
            <ClipLoader className="override" size={35} />
          </div>
        </div>
      )}
      <div className="profileBoxContainer">
        <div className="profileBoxHolder" onClick={handleUserItems}>
          <div className="profileBoxName">
            <b>Assigned Items</b>
          </div>
          <div className="profileBoxName">
            <b>{userItemsCount}</b>
          </div>
        </div>
        <div className="profileBoxHolder" onClick={handleUserItems}>
          <div className="profileBoxName">
            <b>Assigned Items Check</b>
          </div>
          <div className="profileBoxName">
            <b>{userItemsCount}</b>
          </div>
        </div>
        <div className="profileBoxHolder" onClick={handleIndividualTraining}>
          <div className="profileBoxName">
            <b>Individual Events</b>
          </div>
          <div className="profileBoxName">
            <b>{indiviualTrainingCount}</b>
          </div>
        </div>

        <div className="profileBoxHolder" onClick={handleAttendedEvents}>
          <div className="profileBoxName">
            <b>Events Attended</b>
          </div>
          <div className="profileBoxName">
            <b>{eventCount}</b>
          </div>
        </div>
      </div>

      <div className=" "></div>
      <div className="footerHolder"> </div>
    </div>
  );
};

export default AdminProfilePageHeader;
