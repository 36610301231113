import React from "react";
import "./Header.css";

const Header = (props) => {
  // When the user scrolls down 50px from the top of the document, resize the header's font size

  const editItemPopup = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div className="small" id="header">
        <div className="smallHeader" onClick={editItemPopup}>
          {props.content}
        </div>
      </div>
    </>
  );
};

export default Header;
