import React from "react";
import ProfileHeader from "../components/Profile/ProfileHeader";
import ProfileTrainings from "../components/Profile/ProfileTrainings";
import ProfileItems from "../components/Profile/ProfileItems";

import { ProfileContextProvider } from "../context/ProfileContext";

import "../components/Profile/Profile.css";

const Profile = () => {
  return (
    <ProfileContextProvider>
      <>
        <ProfileHeader />
        <ProfileItems />
        <ProfileTrainings />
      </>
    </ProfileContextProvider>
  );
};

export default Profile;
