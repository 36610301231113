import React, { useState } from "react";
import SiteFinder from "../../apis/SiteFinder";

import Header from "../Header/Header";
import PopupWeb from "../Popup/PopupNew";

import { AiOutlineMenu } from "react-icons/ai";
import ClipLoader from "react-spinners/ClipLoader";

import { IoIosArrowForward } from "react-icons/io";

import { useAuth } from "../../context/AuthContext";

import { useMainSiteCategoryData } from "./fetchSiteSettings";

const SiteCategories = () => {
  const { currentUserSiteId, currentUserId } = useAuth();
  //console.log(currentUserRole);
  //  const { data, refetch } = useMainMemberData(currentUserSiteId);
  const { data, refetch } = useMainSiteCategoryData(currentUserSiteId);

  const [categoryTypeAdd, setCategoryTypeAdd] = useState("Event Category");
  const [categoryNameAdd, setCategoryNameAdd] = useState("");

  let rrrrr = window.matchMedia("only screen and (max-width: 1000px)").matches;

  const [isMobile, setIsMobile] = useState(rrrrr ? "True" : "False");

  const mediaQuery = window.matchMedia("(max-width: 1000px)");

  mediaQuery.addListener(handleTabletChange);

  function handleTabletChange(e) {
    if (e.matches) {
      setIsMobile("True");
      // console.log("3214");
    } else {
      setIsMobile("False");
      //console.log("55555");
    }
  }
  const [categoryEditId, setCategoryEditId] = useState("");
  const [categoryEditName, setCategoryEditName] = useState("");
  const [categoryEditType, setCategoryEditType] = useState("");

  //Popup
  const [isOpen1, setIsOpen] = useState(false);
  const togglePopup = (categoryId, name, type) => {
    setIsOpen(!isOpen1);
    setCategoryEditId(categoryId);
    setCategoryEditName(name);
    setCategoryEditType(type);
  };

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  //Delete User
  const [isOpen3, setIsOpen3] = useState(false);
  const togglePopup3 = () => {
    setIsOpen(!isOpen1);
    setIsOpen3(!isOpen3);
  };

  const togglePopup33 = () => {
    setIsOpen3(false);
  };

  const [isOpen4, setIsOpen4] = useState(false);
  const togglePopup4 = () => {
    setIsOpen4(!isOpen4);
    setCategoryNameAdd("");
    setCategoryTypeAdd("Event Category");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      // eslint-disable-next-line
      const response = await SiteFinder.post("/newCategory", {
        currentUserId: currentUserId,

        name: categoryNameAdd,
        type: categoryTypeAdd,
        currentUserSiteId,
      });

      setLoading(false);
      refetch();
      togglePopup4();
    } catch (err) {
      setLoading(false);
      // console.log(err);
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      // eslint-disable-next-line
      const response = await SiteFinder.post("/editCategory", {
        categoryId: categoryEditId,
        name: categoryEditName,
        type: categoryEditType,
        currentUserSiteId,
      });

      setLoading(false);
      refetch();
      togglePopup();
    } catch (err) {
      setLoading(false);
      // console.log(err);
    }
  };

  const handleDelete = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      // eslint-disable-next-line
      const response = await SiteFinder.post("/deleteCategory", {
        categoryId: categoryEditId,
        currentUserSiteId,
      });

      setLoading(false);
      refetch();

      togglePopup33();
    } catch (err) {
      setLoading(false);
    }
  };

  const [query, setQuery] = useState("");
  const [dropdownQuery, setDropdownQuery] = useState("");

  const toggleNav = () => {
    let topbar = document.getElementById("topbar");
    let sideBar = document.getElementById("sidebar");
    let appContainer = document.getElementById("appContainer");
    let appContainerHidden = document.getElementById("appContainerHidden");

    topbar.classList.toggle("collapse");
    sideBar.classList.toggle("collapse");
    appContainer.classList.toggle("collapse");
    appContainerHidden.classList.toggle("collapse");
  };
  return (
    <>
      <div id="topbar" className="topbar headerActive">
        <div className="hometopbarleft" onClick={toggleNav}>
          <AiOutlineMenu />
        </div>

        <div className="addItem" onClick={togglePopup4}>
          +
        </div>

        <Header content={<b>Site Info</b>} />
      </div>

      <div className="topbarEmptySpace22"></div>

      <div className="inventorySelectHolder">
        <div className="inventorySelect2">
          <select
            className="inventorySelect"
            onChange={(event) => setDropdownQuery(event.target.value)}
          >
            <option value="">Any Type</option>

                        <option value={"Event Category"}>
                          {"Event Category"}
                        </option>
                        <option value={"Tag"}>
                          {"Tag"}
                        </option>
          </select>
        </div>
      </div>
      <div className="searchDiv11">
        <div className="searchDiv1">
          <input
            className="addItemInput"
            id="myInput"
            placeholder="Search"
            type="search"
            autoComplete="off"
            autoCorrect="off"
            onChange={(event) => setQuery(event.target.value)}
          />
        </div>
      </div>

      {isMobile === "False" ? (
        <>
          <div className="emptySpace"></div>
          <div className="memberListDivTable">
            <div className="memberListheaders">
              <div className="memberListheadersContent"> Name</div>
              <div className="memberListheadersContent"> Type</div>
              <div className="memberListheadersContentSmall"> View</div>
            </div>
            <div className="memberListRow">
              {data?.data.data.category &&
                data?.data.data.category
                  // eslint-disable-next-line
                  .filter((post) => {
                    if (dropdownQuery === "") {
                      if (query === "") {
                        return post;
                      } else if (
                        post.name.toLowerCase().includes(query.toLowerCase())
                      ) {
                        return post;
                      }
                    } else if (
                      post.type
                        .toLowerCase()
                        .includes(dropdownQuery.toLowerCase())
                    ) {
                      if (query === "") {
                        return post;
                      } else if (
                        post.name.toLowerCase().includes(query.toLowerCase())
                      ) {
                        return post;
                      }
                    }
                  })
                  .map((data, i) => {
                    return (
                      <div key={i}>
                        <div
                          className="memberListHolder"
                          onClick={() =>
                            togglePopup(data.categoryid, data.name, data.type)
                          }
                        >
                          <div className="memberListHolderCell">
                            {data.name}
                          </div>
                          <div className="memberListHolderCell">
                            {data.type}
                          </div>
                          <div className="memberListHolderCellSmall">
                            <IoIosArrowForward size={20} />
                          </div>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="emptySpace"></div>
          <div className="memberListDivTableMobile">
            <div className="memberListRowMobile">
              {data?.data.data.category &&
                data?.data.data.category
                  // eslint-disable-next-line
                  .filter((post) => {
                    if (dropdownQuery === "") {
                      if (query === "") {
                        return post;
                      } else if (
                        post.name.toLowerCase().includes(query.toLowerCase())
                      ) {
                        return post;
                      }
                    } else if (
                      post.type
                        .toLowerCase()
                        .includes(dropdownQuery.toLowerCase())
                    ) {
                      if (query === "") {
                        return post;
                      } else if (
                        post.name.toLowerCase().includes(query.toLowerCase())
                      ) {
                        return post;
                      }
                    }
                  })
                  .map((data, i) => {
                    //console.log(data);
                    return (
                      <div key={i}>
                        <div
                          className="memberListHolderMobile"
                          onClick={() =>
                            togglePopup(data.categoryid, data.name, data.type)
                          }
                        >
                          <div className="memberListHolderCellRightMobile">
                            <IoIosArrowForward size={20} />
                          </div>
                          <div className="memberListheadersContentMobile">
                            Name
                          </div>
                          <div className="memberListHolderCellMobile">
                            {data.name}
                          </div>
                          <div className="memberListheadersContentMobile">
                            Type
                          </div>
                          <div className="memberListHolderCellMobile">
                            {data.type}
                          </div>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
        </>
      )}

      {isOpen4 && (
        <PopupWeb
          content={
            <div>
              <form onSubmit={handleSubmit} className="userForm">
         

                {error && <div className="alert">{error}</div>}

                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>
                <div className="popupContainer">
                  <label className="NewInputLabel" data-domain="Name">
                    <input
                      className="NewInput"
                      type="text"
                      placeholder=""
                      onChange={(e) => setCategoryNameAdd(e.target.value)}
                      value={categoryNameAdd}
                      required
                    />
                  </label>
                  <label className="NewInputLabel" data-domain="Status">
                    <select
                      className="NewInput"
                      onChange={(event) =>
                        setCategoryTypeAdd(event.target.value)
                      }
                      required
                    >
                      <option value="" disabled>
                        Select Category
                      </option>

                      <option value={"Event Category"}>
                        {"Event Category"}
                      </option>
                      <option value={"Tag"}>{"Tag"}</option>
                    </select>
                  </label>
                </div>
              </form>
            </div>
          }

          handleClose={togglePopup4}
          isOpen={isOpen4}
          handleSubmit={handleSubmit}
          showSubmitButton={true}
          headerText={  <b> Add Category</b>}
          submitButtonText={loading ? "Loading..." : "Add"} 

        />
      )}

      {isOpen1 && (
        <PopupWeb
          content={
            <>
              <div>
                <form onSubmit={handleUpdate} className="userForm">
            

                  {error && <div className="alert">{error}</div>}

                  <div className="text-center p-3">
                    <ClipLoader
                      loading={loading}
                      className="override"
                      size={35}
                    />
                  </div>
                  <div className="popupContainer">
                    <label className="NewInputLabel" data-domain="Name">
                      <input
                        className="NewInput"
                        type="text"
                        placeholder=""
                        onChange={(e) => setCategoryEditName(e.target.value)}
                        value={categoryEditName}
                        required
                      />
                    </label>
                    <label className="NewInputLabel" data-domain="Status">
                      <select
                        className="NewInput"
                        onChange={(event) =>
                          setCategoryEditType(event.target.value)
                        }
                        required
                        defaultValue={categoryEditType}
                      >
                        <option value="" disabled>
                          Select Category
                        </option>
                        <option value={"Event Category"}>
                          {"Event Category"}
                        </option>
                        <option value={"Tag"}>
                          {"Tag"}
                        </option>
                      </select>
                    </label>
                 
                  </div>
                </form>
              </div>
            </>
          }
          handleClose={togglePopup}


          isOpen={isOpen1}
          handleSubmit={handleUpdate}
          showSubmitButton={true}
          headerText={ <b> Edit Category</b>}
          submitButtonText={loading ? "Loading..." : "Save"} 
        />
      )}

      {isOpen3 && (
        <PopupWeb
          content={
            <>
           
              <div className="deleteInfoApparatusContainer">
                <b>Are you sure you want to delete {categoryEditName}</b>
              </div>

      
            </>
          }

          handleClose={togglePopup33}
          isOpen={isOpen3}
          handleSubmit={handleDelete}
          showSubmitButton={true}
          headerText={ <b>Delete </b>}
          submitButtonText={loading ? "Loading..." : "Delete"} 
        />
      )}
    </>
  );
};

export default SiteCategories;
