import React from "react";
import "../components/Reports/Reports.css";
import ReportsCheckListHistoryComponet from "../components/Reports/ReportsChecklistHistory";

const ReportsChecklistHistory = () => {
  return (
    <>
      <ReportsCheckListHistoryComponet />
    </>
  );
};

export default ReportsChecklistHistory;
