import React, { useState } from "react";
import UserFinder from "../../apis/UserFinder";

import Header from "../Header/Header";
import PopupWeb from "../Popup/PopupNew";

import { AiOutlineMenu } from "react-icons/ai";
import ClipLoader from "react-spinners/ClipLoader";

import { IoIosArrowForward } from "react-icons/io";
import SiteFinder from "../../apis/SiteFinder";

import Select from "react-select";

import { useAuth } from "../../context/AuthContext";

import { useMainSiteCategoryData } from "./fetchSiteSettings";
import {  Zoom, toast } from "react-toastify";

const SiteInvites = () => {
  const {  currentUserSiteId, currentUserId } = useAuth();
  //console.log(currentUserRole);
  const { data, refetch } = useMainSiteCategoryData(currentUserSiteId);



  const rolesList = [
    { value: "Basic", label: "Basic" },
    { value: "Advanced", label: "Advanced" },
    { value: "Admin", label: "Admin" },
  ];



  let rrrrr = window.matchMedia("only screen and (max-width: 1000px)").matches;

  const [isMobile, setIsMobile] = useState(rrrrr ? "True" : "False");

  const mediaQuery = window.matchMedia("(max-width: 1000px)");

  mediaQuery.addListener(handleTabletChange);

  function handleTabletChange(e) {
    if (e.matches) {
      setIsMobile("True");
      // console.log("3214");
    } else {
      setIsMobile("False");
      //console.log("55555");
    }
  }

  const [lookUpUserId, setLookUpUserId] = useState("");
  const [currentUserName, setCurrentUserName] = useState("");

  //Popup
  const togglePopup = (
    user_id,
    email,
  ) => {
    setIsOpen3(!isOpen3);
    setLookUpUserId(user_id);
    setCurrentUserName(email);

  };

  //Edit user Popup

  const [email, setEmail] = useState("");

  const [status, setStatus] = useState(false);

  const [role, setRole] = useState("Basic");

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [isOpen2, setIsOpen2] = useState(false);




  //Delete User
  const [isOpen3, setIsOpen3] = useState(false);
  const togglePopup3 = () => {
    setIsOpen2(!isOpen2);
    setIsOpen3(!isOpen3);
  };

  const togglePopup33 = () => {
    setIsOpen3(false);
  };

  const handleDelete = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      //eslint-disable-next-line
      const response = await SiteFinder.post("/deleteMemberInvite", {
        userid: lookUpUserId,
        currentUserSiteId,
       });
       notifyRemove()
      refetch();
      setLoading(false);
      togglePopup33();
    } catch (err) {
      setLoading(false);
    }
  };

  const [isOpen4, setIsOpen4] = useState(false);
  const togglePopup4 = () => {
    setIsOpen4(!isOpen4);
    setEmail("");

    setStatus(false);
    setRole("Basic");
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setError("");
      setLoading(true);


      const response = await UserFinder.post("/addUserToSite", {
        currentUserId: currentUserId,

        email: email,
        status: status,
        role: role,
        currentUserSiteId,
      });

      setLoading(false);
      if (response.data.data.alert) {
        setError(response.data.data.alert);
        return;
      }
      notifyAdd()
      refetch();
      togglePopup4();
    } catch (err) {
      setLoading(false);
      // console.log(err);
      setError("Failed to add account");
    }
  };

  const [query, setQuery] = useState("");





  const toggleNav = () => {
    let topbar = document.getElementById("topbar");
    let sideBar = document.getElementById("sidebar");
    let appContainer = document.getElementById("appContainer");
    let appContainerHidden = document.getElementById("appContainerHidden");

    topbar.classList.toggle("collapse");
    sideBar.classList.toggle("collapse");
    appContainer.classList.toggle("collapse");
    appContainerHidden.classList.toggle("collapse");
  };


  const notifyAdd= () => {
    toast.success("Member Invited", {
      position: "bottom-right",
      autoClose: 500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
      transition: Zoom,
      });
    }
  const notifyRemove = () => {
    toast.success("Member Invite Removed", {
      position: "bottom-right",
      autoClose: 500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "light",
      transition: Zoom,
      });
    }

  return (
    <>
      <div id="topbar" className="topbar headerActive">
        <div className="hometopbarleft" onClick={toggleNav}>
          <AiOutlineMenu />
        </div>


  
  <div className="submitItemBtn2" onClick={togglePopup4}>
          + New Member
        </div>
        <Header content={<b>Site Info</b>} />
      </div>

      <div className="topbarEmptySpace22"></div>

      <div className="searchDiv1">
        <div className="searchDiv1">
          <input
            className="addItemInput"
            id="myInput"
            placeholder="Search"
            type="search"
            autoComplete="off"
            autoCorrect="off"
            onChange={(event) => setQuery(event.target.value)}
          />
        </div>
      </div>

      {isMobile === "False" ? (
        <>
          {" "}
          <div className="memberListDivTable">
            <div className="memberListheaders">
              <div className="memberListheadersContent"> Email</div>
              <div className="memberListheadersContent"> Role</div>
              <div className="memberListheadersContent"> Date Sent</div>
              <div className="memberListheadersContentSmall"> View</div>
            </div>
            <div className="memberListRow">
              {data?.data.data.inviteMemeberList &&
                data?.data.data.inviteMemeberList
                  // eslint-disable-next-line
                  .filter((post) => {
                    if (query === "") {
                      return post;
                    } else if (
                      post.firstname
                        .toLowerCase()
                        .includes(query.toLowerCase()) ||
                      post.lastname.toLowerCase().includes(query.toLowerCase())
                    ) {
                      return post;
                    }
                  })
                  .map((data, i) => {
                    // console.log(data);
                    return (
                      <div key={i}>
                        <div
                          className="memberListHolder"
                          onClick={() =>
                            togglePopup(
                              data.userinviterequest_id,
                   data.email
                            )
                          }
                        >
                          <div className="memberListHolderCell">
                            {data.email} 
                          </div>

                          <div className="memberListHolderCell">
                            {data.role}
                          </div>

                          <div className="memberListHolderCell">
                            {data.date}
                          </div>

                 

                          <div className="memberListHolderCellSmall">
                            <IoIosArrowForward size={20} />
                          </div>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="memberListDivTableMobile">
            <div className="memberListRowMobile">
              {data?.data.data.inviteMemeberList &&
                data?.data.data.inviteMemeberList
                  // eslint-disable-next-line
                  .filter((post) => {
                    if (query === "") {
                      return post;
                    } else if (
                      post.firstname
                        .toLowerCase()
                        .includes(query.toLowerCase()) ||
                      post.lastname.toLowerCase().includes(query.toLowerCase())
                    ) {
                      return post;
                    }
                  })
                  .map((data, i) => {
                    //console.log(data);
                    return (
                      <div key={i}>
                        <div
                          className="memberListHolderMobile"
                          onClick={() =>
                            togglePopup(
                              data.userinviterequest_id,
                              data.email
                            )
                          }
                        >
                          <div className="memberListHolderCellRightMobile">
                            <IoIosArrowForward size={20} />
                          </div>
                          <div className="memberListheadersContentMobile">
                            Email
                          </div>
                          <div className="memberListHolderCellMobile">
                            {data.email}
                          </div>
                          <div className="memberListheadersContentMobile">
                            Role
                          </div>

                          <div className="memberListHolderCellMobile">
                            {data.role}
                          </div>
                          <div className="memberListheadersContentMobile">
                            Date Sent
                          </div>

                          <div className="memberListHolderCellMobile">
                            {data.date}
                          </div>
              
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
        </>
      )}




      {isOpen3 && (
        <PopupWeb
          content={
            <>
       




              <div id="holderTest" className="holderPopupHeader">
                <div className="popupTitle">
                <b>Remove Member Invite</b>
                </div>
              </div>
              <div className="emptySpace22"></div>


              <div className="removeMemberNameContainer">
                {currentUserName}
              </div>
       

            </>
          }
         

          handleClose={togglePopup33}
          isOpen={isOpen3}
          handleSubmit={handleDelete}
          showSubmitButton={true}
          headerText={   <b>Remove Member Invite</b>}
          submitButtonText={loading ? "Loading..." : "Remove"} 
        />
      )}

      {isOpen4 && (
        <PopupWeb
          content={
            <div>
              <form onSubmit={handleSubmit} className="userForm">
             

                {error && <div className="alert">{error}</div>}

                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>
                <div className="popupContainer">
                  <label className="NewInputLabel" data-domain="Email">
                    <input
                      className="NewInput"
                      type="email"
                      placeholder=""
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      required
                    />
                  </label>



<label className="NewInputLabel" data-domain="Role">
                  <Select
                      defaultValue={rolesList[0]}
                      options={rolesList}
                      onChange={(e) => setRole(e.value)}
                    name="addMembers"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                </label>

</div>


              </form>
            </div>
          }

          
          handleClose={togglePopup4}
          isOpen={isOpen4}
          handleSubmit={handleSubmit}
          showSubmitButton={true}
          headerText={   <b> Add User to Site</b>}
          submitButtonText={loading ? "Loading..." : "Send Invite"} 

        />
      )}

      
    </>
  );
};

export default SiteInvites;
