import React from "react";
import ItemListPageHeader from "../components/ItemListPage/ItemListPageHeader";

import "../components/ItemListPage/ItemListPage.css";
import { ItemListPageContextProvider } from "../context/ItemListPageContext";
import "../components/ItemListPage/Slider.css";

const ItemListPage = () => {
  return (
    <ItemListPageContextProvider>
      <>
        <ItemListPageHeader />
      </>
    </ItemListPageContextProvider>
  );
};

export default ItemListPage;
