import React, { useState, createContext } from "react";

export const ApparatusContext = createContext();

export const ApparatusContextProvider = (props) => {
  const [apparatus, setApparatus] = useState([]);

  //call to add apparatus
  const addApparatus = (apparatus1) => {
    //copy old apparatus add new apparatus
    setApparatus([...apparatus, apparatus1]);
  };

  return (
    <ApparatusContext.Provider
      value={{ apparatus, setApparatus, addApparatus }}
    >
      {props.children}
    </ApparatusContext.Provider>
  );
};
