import React, { useRef, useState } from "react";

import { useAuth } from "../../context/AuthContext";

import { MdOutlineLock } from "react-icons/md";

import { useLocation, useNavigate } from "react-router-dom";

function useQuery() {
  const location = useLocation();
  return new URLSearchParams(location.search);
}

const ResetPasswordHeader = () => {
  const query = useQuery();
  const history = useNavigate();

  const passwordRef = useRef();
  const passwordConfirmRef = useRef();

  const { resetPasswordAny } = useAuth();

  const [error, setError] = useState("");
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);

  var passw =
    /^(?=.*[0-9])(?=.*[!@#$%^&*?/.,<>])[a-zA-Z0-9!@#$%^&*?/.,<>]{7,25}$/;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError("Passwords do not match");
    } else if (passwordRef.current.value.match(passw)) {
      //working
      try {
        setMessage("");
        setError("");
        setLoading(true);

        //eslint-disable-next-line
        await resetPasswordAny(query.get("oobCode"), passwordRef.current.value);
        history("/login");
      } catch (err) {
        // console.log(err);
        setError("Failed to update password");
        setLoading(false);
      }
    } else {
      setError(
        "Password must be at least 7 characters and contains at least one numeric digit and a special character"
      );
      return false;
    }
  };

  // font-family: "work sans";
  return (
    <>
      <div className="loginContainer">
        <div className="loginTitle">
          <b>Reset Password</b>
        </div>

        {error && <div className="alert">{error}</div>}
        {message && <div className="success">{message}</div>}

        <form onSubmit={handleSubmit}>
          <div className="loginInput">
            <div className="loginUsername">Password</div>
            <div className="login__field">
              <i className="login__icon fas fa-user">
                <MdOutlineLock />
              </i>
              <input
                className="login__input"
                placeholder="Password"
                type="password"
                ref={passwordRef}
                required
              />
            </div>
          </div>

          <div className="loginInput">
            <div className="loginUsername">Password</div>
            <div className="login__field">
              <i className="login__icon fas fa-user">
                <MdOutlineLock />
              </i>
              <input
                className="login__input"
                placeholder="Password"
                type="password"
                ref={passwordConfirmRef}
                required
              />
            </div>
          </div>
          <div className="loginBtnContainer">
            <button disabled={loading} type="submit" className="loginBtn">
              Reset Password
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default ResetPasswordHeader;
