import React, { useState, createContext } from "react";

export const ProfileContext = createContext();

export const ProfileContextProvider = (props) => {
  const [trainingsAttended, setTrainingsAttended] = useState([]);
  const [userItems, setUserItems] = useState([]);

  return (
    <ProfileContext.Provider
      value={{
        trainingsAttended,
        setTrainingsAttended,
        userItems,
        setUserItems,
      }}
    >
      {props.children}
    </ProfileContext.Provider>
  );
};
