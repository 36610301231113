import React, { useState, useEffect } from "react";
import imageToBase64 from "image-to-base64/browser";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import LogonLogo from "../../PublicPhotos/LogonIcon.png";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const PDFReportTemplate = ({
  data,  // Pass the full dataset directly
  title,
  reportDateRange,
  category,
  filters,
  members,
  logoUrl,
  pdfColumns,
  includeDateRange = false,
  includeCategory = false,
  includeMembers = false,
  includeFilters = false,
}) => {
  const [base64IMG, setBase64IMG] = useState("");

  useEffect(() => {
    convertToBase64();
  }, []);

  const convertToBase64 = () => {
    imageToBase64(LogonLogo) // Path to the image
      .then((response) => {
        setBase64IMG(response);
      });
  };

  const generatePdf = () => {
  const heading = pdfColumns.map((col) => ({
    text: col.header,
    bold: true,
    fontSize: 10,
  }));
    const bodyData = [
      heading,
      ...data.map((row) =>
        pdfColumns.map((col) => ({
          text: col.accessor(row),
          fontSize: 8,
        }))
      ),
    ];

    const numColumns = pdfColumns.length;
    const widths = Array(numColumns).fill(`${100 / numColumns}%`);

    const filterContent = includeFilters && filters && filters.length > 0
      ? {
          text: `Filters: ${filters.filter(f => f != null).map((filter) => filter.label).join(", ")}`,
          fontSize: 8,
          margin: [20, 0, 0, 10],
        }
      : null;

    const content = [
      ...(includeDateRange
        ? [
            {
              text: `Date range: ${reportDateRange}`,
              fontSize: 10,
              margin: [20, 0, 0, 10],
            },
          ]
        : []),
      ...(includeCategory
        ? [
            {
              text: `Category: ${category}`,
              fontSize: 10,
              margin: [20, 0, 0, 10],
            },
          ]
        : []),
      ...(includeMembers
        ? [
            {
              text: `Members: ${members.join(", ")}`,
              fontSize: 10,
              margin: [20, 0, 0, 10],
            },
          ]
        : []),
      ...(filterContent ? [filterContent] : []),

      { width: "*", text: "", margin: [0, 0, 0, 10] },
      {
        text: `Number of records: ${data.length}`, // Use the full dataset length
        fontSize: 10,
        alignment: "center",
        margin: [0, 0, 0, 10],
      },
      {
        style: "tableStyle",
        table: {
          headerRows: 1,
          widths: widths,
          body: bodyData,
          // keepWithHeaderRows: true,
          // dontBreakRows: true,
        },
      },
    ];

  const docDefinition = {
    pageMargins: [20, 70, 20, 25],
    pageOrientation: "landscape",
    info: {
      title: title,
    },
    footer: function (currentPage, pageCount) {
      return {
        text: `${currentPage.toString()} of ${pageCount}`,
        alignment: "center",
      };
    },
    header: (currentPage, pageCount, pageSize) => {
      return {
        columns: [
          {
            text: title,
            bold: true,
            fontSize: 26,
            margin: [20, 20, 0, 0],
          },
          {
            image: `data:image/png;base64,${base64IMG}`,
            width: 50,
            height: 50,
            alignment: "right",
            margin: [0, 10, 15, 0],
          },
        ]
      };
    },
    content: content,
    styles: {
      tableStyle: {
        fontSize: 10,
      },
    },
  };
  const fileName = `${title.replace(/\s+/g, '_')}_${new Date().toISOString().slice(0,10)}.pdf`;


    pdfMake.createPdf(docDefinition).download(fileName);
  };

  return <button className="clear-filters" onClick={generatePdf}>Generate PDF</button>;
};

export default PDFReportTemplate;
