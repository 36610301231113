import React from "react";
import "../components/ToDo/ToDo.css";
import ToDoHeader from "../components/ToDo/ToDoHeader";

const ToDo = () => {
  return (
 
      <>
        <ToDoHeader />
      </>

  );
};

export default ToDo;
