import React from "react";

import SubmitedChecklistHeader from "../components/ApparatusChecklist/SubmitedChecklistHeader";
import "../components/ApparatusChecklist/ApparatusChecklist.css";

function SubmitedChecklist() {
  return (
    <div>
      <SubmitedChecklistHeader />
    </div>
  );
}

export default SubmitedChecklist;
