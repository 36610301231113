import React from "react";

const ProfileWidget = (props) => {
  return (
    <>
      <div className="ProfileWidgetBox">{props.content}</div>
    </>
  );
};

export default ProfileWidget;
