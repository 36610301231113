import React from "react";
import ResetPasswordHeader from "../components/Login/ResetPasswordHeader";

const ResetPassword = () => {
  return (
    <>
      <ResetPasswordHeader />
    </>
  );
};

export default ResetPassword;
