import React from "react";
import SitesHeader from "../components/SiteSettings/SiteSettingsHeader";

import "../components/Sites/SiteList.css";

const SiteSettingsHeader = () => {
  return (
    <>
      <SitesHeader />
    </>
  );
};

export default SiteSettingsHeader;
