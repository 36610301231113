import React, { useState, createContext } from "react";

export const ApparatusInventoryContext = createContext();

export const ApparatusInventoryContextProvider = (props) => {
  const [apparatusInventory, setApparatusInventory] = useState([]);
  const [inventory, setInventory] = useState([]);
  const [compartments, setCompartments] = useState([]);
  const [bags, setBags] = useState([]);
  const [name, setName] = useState([]);

  const [apparatusLocation, setApparatusLocation] = useState([]);
  const [addItemCompartmentId, setAddItemCompartmentId] = useState("");
  const [addItemCompartmentName, setAddItemCompartmentName] = useState("");

  const [compartmentItems, setCompartmentItems] = useState("");
  
  const [compartmentOpen2, setCompartmentOpen2] = useState(false);
  const [addItemOpen, setAddItemOpen] = useState(false);
  const [inspectionOpen, setInspectionOpen] = useState(false);
  const [itemInfoOpen2, setItemInfoOpen2] = useState(false);

  const [selectedInvId, setSelectedInvId] = useState(false);

  const [itemGroup, setItemGroup] = useState([]);

  //call to add compartment
  const addCompartment = (compartment1) => {
    //copy old inventory add training
    setCompartments([...compartments, compartment1]);
  };

  //call to add bags
  const addBag = (bag1) => {
    //copy old inventory add training
    setBags([...bags, bag1]);
  };

  return (
    <ApparatusInventoryContext.Provider
      value={{
        apparatusInventory,
        setApparatusInventory,
        inventory,
        setInventory,
        compartments,
        setCompartments,
        bags,
        setBags,
        addCompartment,
        addBag,
        name,
        setName,
        apparatusLocation,
        setApparatusLocation,
        addItemCompartmentId,
        setAddItemCompartmentId,
        addItemCompartmentName,
        setAddItemCompartmentName,
        compartmentItems,
        setCompartmentItems,
        compartmentOpen2,
        setCompartmentOpen2,
        addItemOpen,
        setAddItemOpen,
        itemGroup,
        setItemGroup,inspectionOpen, setInspectionOpen,selectedInvId, setSelectedInvId,itemInfoOpen2, setItemInfoOpen2
      }}
    >
      {props.children}
    </ApparatusInventoryContext.Provider>
  );
};
