import React, { useState, useEffect } from "react";
import ReportsFinder from "../../apis/ReportsFinder";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import Header from "../Header/Header";
import Pagination from "./Pagination";
import FilterPanel from "./FilterPanel";
import ReportTable from "./ReportTable";
import imageToBase64 from "image-to-base64/browser";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import PublicFolder from "../../apis/PublicFolder";
import { AiOutlineMenu } from "react-icons/ai";
import {
  useReactTable,
  createColumnHelper,
  getCoreRowModel,
  getPaginationRowModel,
  flexRender,
} from "@tanstack/react-table";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const ReportsMain = () => {
  const { currentUserSiteId } = useAuth();
  const [results, setResults] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [trainingList, setTrainingList] = useState([]);
  const [from, setFrom] = useState("");
  const [thru, setThru] = useState("");
  const [pdfFrom, setPdfFrom] = useState("");
  const [pdfThru, setPdfThru] = useState("");
  const [pdfCategoryList, setPdfCategoryList] = useState("");
  const [pdfMemberList, setPdfMemberList] = useState("");
  const [pdfNotes, setPdfNotes] = useState("");
  const [selectedReport, setSelectedReport] = useState(null);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [base64IMG, setBase64IMG] = useState("");

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  let history = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ReportsFinder.get(`/getMainReportOnLoad`, {
          params: { currentUserSiteId },
        });
        setCategoryList(response.data.data.categoryList);
        setMemberList(response.data.data.memberNames);
        setTrainingList(response.data.data.trainingList); // Added for Item Usage
      } catch (err) {
        // Handle error
      }
    };

    if (currentUserSiteId) {
      fetchData();
    }
  }, [currentUserSiteId]);

  const getReset = () => {
    setResults([]);
    setFrom("");
    setThru("");
    setSelectedMembers([]);
    setSelectedCategories([]);
    setPdfNotes("");
  };

  const getResults = async (e) => {
    e.preventDefault();
    fetchResults();
    convertToBase64();
  };

  const getPDF = () => {
    generatePdf();
  };

  const toggleNav = () => {
    let topbar = document.getElementById("topbar");
    let sideBar = document.getElementById("sidebar");
    let appContainer = document.getElementById("appContainer");
    let appContainerHidden = document.getElementById("appContainerHidden");

    topbar.classList.toggle("collapse");
    sideBar.classList.toggle("collapse");
    appContainer.classList.toggle("collapse");
    appContainerHidden.classList.toggle("collapse");
  };

  const fetchResults = async () => {
    try {
      const members = selectedMembers.map((option) => option.value);
      const category = selectedCategories.map((option) => option.value);
      let endpoint = ""; // Default endpoint
      let params = {
        currentUserSiteId,
        from,
        thru,
        category: category.length === 0 ? "" : category,
        members: members.length === 0 ? "" : members,
      };

      if (selectedReport) {
        switch (selectedReport.value) {
          case "Item Usage":
            endpoint = "/getItemUsage"; // Change endpoint if needed
            params = {
              ...params,
              category: selectedCategories.map((option) => option.value),
              members: selectedMembers.map((option) => option.value),
            };
            break;
          case "Item Information":
            endpoint = "/getItemInformation";
            break;
          case "Checklist History":
            endpoint = "/getChecklistHistory";
            break;
          case "Events":
            endpoint = "/getEventsResults";
            break;
          case "Individual Events":
            endpoint = "/getIndividualEvents";
            break;
          case "To Do List":
            endpoint = "/getToDoList";
            break;
          default:
            break;
        }
      }

      const response = await ReportsFinder.get(endpoint, { params });

      const fromholder = new Date(from).toLocaleDateString();
      const thruholder = new Date(thru).toLocaleDateString();
      setPdfFrom(fromholder);
      setPdfThru(thruholder);

      setPdfCategoryList(
        category.length === 0 ? "Any category" : category.toString()
      );

      setPdfMemberList(
        members.length === 0 ? "Not specified" : members.toString()
      );

      setResults(response.data.data.results);
      setPdfNotes(response.data.data.results2 || []);
    } catch (err) {
      console.error(err);
    }
  };

  const convertToBase64 = () => {
    imageToBase64(PublicFolder.baseURL + "LogonIcon.png") // Path to the image
      .then((response) => {
        setBase64IMG(response);
      });
  };

  const generatePdf = async () => {
    let heading = [];
    let bodyData = [];

    if (selectedReport) {
      switch (selectedReport.value) {
        case "Item Usage":
          heading = [
            { text: "Category", bold: true },
            { text: "Equipment", bold: true },
            { text: "Serial Number", bold: true },
            { text: "Location", bold: true },
            { text: "Date Used", bold: true },
            { text: "Event Name", bold: true },
          ];
          bodyData = results.map((elt) => [
            elt.type,
            elt.identificationname ? elt.identificationname : elt.name,
            elt.serialnumber,
            `${elt.apparatus} - ${elt.compartment}${
              elt.bagname ? `- ${elt.bagname}` : ""
            }`,
            elt.date,
            elt.training_name,
          ]);
          break;
        case "Item Information":
          heading = [
            { text: "Item ID", bold: true },
            { text: "Name", bold: true },
            { text: "Category", bold: true },
            // Add more columns as required
          ];
          bodyData = results.map((elt) => [
            elt.itemid,
            elt.name,
            elt.category,
            // Add more fields as required
          ]);
          break;
        case "Checklist History":
          heading = [
            { text: "Date", bold: true },
            { text: "Checklist", bold: true },
            // Add more columns as required
          ];
          bodyData = results.map((elt) => [
            elt.date,
            elt.checklist,
            // Add more fields as required
          ]);
          break;
        case "Events":
          heading = [
            { text: "Date", bold: true },
            { text: "Event Name", bold: true },
            { text: "Category", bold: true },
            { text: "Description", bold: true },
            { text: "Notes", bold: true },
            { text: "Members", bold: true },
          ];
          bodyData = results.map((elt) => [
            elt.date,
            elt.training_name,
            elt.category,
            elt.description,
            pdfNotes
              .filter((note) => note.id === elt.id)
              .map((note) => note.subject)
              .join(", "),
            elt.members
              .map((member) => `${member.firstname} ${member.lastname}`)
              .join(", "),
          ]);
          break;
        case "Individual Events":
          heading = [
            { text: "Date", bold: true },
            { text: "Event", bold: true },
            // Add more columns as required
          ];
          bodyData = results.map((elt) => [
            elt.date,
            elt.event,
            // Add more fields as required
          ]);
          break;
        case "To Do List":
          heading = [
            { text: "Task", bold: true },
            { text: "Status", bold: true },
            // Add more columns as required
          ];
          bodyData = results.map((elt) => [
            elt.task,
            elt.status,
            // Add more fields as required
          ]);
          break;
        default:
          break;
      }
    }

    var docDefinition = {
      pageMargins: [20, 70, 20, 25],
      info: { title: "Report" },
      footer: function (currentPage, pageCount) {
        return {
          text: currentPage.toString() + " of " + pageCount,
          alignment: "center",
        };
      },
      header: {
        columns: [
          { text: "Report", bold: true, fontSize: 26, margin: [20, 20, 0, 0] },
          {
            image: `data:image/png;base64,${base64IMG}`,
            width: 50,
            height: 50,
            alignment: "right",
            margin: [0, 10, 15, 0],
          },
        ],
      },
      content: [
        {
          text: `Date range:    ${pdfFrom} - ${pdfThru}`,
          fontSize: 10,
          margin: [20, 0, 0, 10],
        },
        {
          text: `Category:       ${pdfCategoryList}`,
          fontSize: 10,
          margin: [20, 0, 0, 10],
        },
        {
          text: `Members:       ${pdfMemberList}`,
          fontSize: 10,
          margin: [20, 0, 0, 0],
        },
        { width: "*", text: "", margin: [0, 0, 0, 30] },
        {
          text: `Number of records: ${results.length}`,
          fontSize: 10,
          alignment: "center",
          margin: [0, 0, 0, 10],
        },
        {
          style: "tableSyle",
          table: {
            headerRows: 1,
            widths: Array(heading.length).fill("*"),
            body: [heading, ...bodyData],
          },
        },
      ],
      styles: { tableSyle: { fontSize: 10 } },
    };

    pdfMake.createPdf(docDefinition).open();
  };

  const columnHelper = createColumnHelper();

  const getColumns = () => {
    switch (selectedReport?.value) {
      case "Item Usage":
        return [
          columnHelper.accessor("type", { header: "Category" }),
          columnHelper.accessor("identificationname", {
            header: "Equipment",
            cell: (info) => info.getValue() || info.row.original.name,
          }),
          columnHelper.accessor("serialnumber", { header: "Serial Number" }),
          columnHelper.accessor(
            (row) =>
              `${row.apparatus} - ${row.compartment}${
                row.bagname ? `- ${row.bagname}` : ""
              }`,
            {
              id: "location",
              header: "Location",
            }
          ),
          columnHelper.accessor("date", { header: "Date Used" }),
          columnHelper.accessor("training_name", { header: "Event Name" }),
        ];
      case "Item Information":
        return [
          columnHelper.accessor("itemid", { header: "Item ID" }),
          columnHelper.accessor("name", { header: "Name" }),
          columnHelper.accessor("category", { header: "Category" }),
          // Add more columns as required
        ];
      case "Checklist History":
        return [
          columnHelper.accessor("date", { header: "Date" }),
          columnHelper.accessor("checklist", { header: "Checklist" }),
          // Add more columns as required
        ];
      case "Events":
        return [
          columnHelper.accessor("date", { header: "Date" }),
          columnHelper.accessor("training_name", { header: "Event Name" }),
          columnHelper.accessor("category", { header: "Category" }),
          columnHelper.accessor("description", { header: "Description" }),
          columnHelper.accessor(
            (row) =>
              row.members
                .map((member) => `${member.firstname} ${member.lastname}`)
                .join(", "),
            { id: "members", header: "Members" }
          ),
          // Add more columns as required
        ];
      case "Individual Events":
        return [
          columnHelper.accessor("date", { header: "Date" }),
          columnHelper.accessor("event", { header: "Event" }),
          // Add more columns as required
        ];
      case "To Do List":
        return [
          columnHelper.accessor("task", { header: "Task" }),
          columnHelper.accessor("status", { header: "Status" }),
          // Add more columns as required
        ];
      default:
        return [];
    }
  };

  return (
    <div className="containerInventory">
      <div className="topbarEmptySpace2"></div>
      <div className="top">
        <div className="hometopbarleft" onClick={toggleNav}>
          <AiOutlineMenu />
        </div>
        <div id="topbar" className="topbar headerActive">
          <Header content={<b>Reports</b>} />
        </div>

        <div className="filter-search-toggle-container-bottom">
          <FilterPanel
            selectedReport={selectedReport}
            setSelectedReport={setSelectedReport}
            from={from}
            setFrom={setFrom}
            thru={thru}
            setThru={setThru}
            memberList={memberList}
            categoryList={categoryList}
            trainingList={trainingList}
            selectedMembers={selectedMembers}
            setSelectedMembers={setSelectedMembers}
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
            isCollapsed={isCollapsed}
            toggleCollapse={toggleCollapse}
          />
 <div>
          <button className="clear-filters2" onClick={getReset}>
            Clear
          </button>
          <button className="clear-filters2" onClick={getResults}>
            Generate Results
          </button>

          {results.length > 0 && (
            <button className="clear-filters2" onClick={getPDF}>
              PDF
            </button>
          )}
           </div>
        </div>
      </div>

    
      <div className="ReportResultsContainer">
        <div className="reportsPopupContainer">
          {results.length > 0 && (
            <ReportTable results={results} columns={getColumns()} />
          )}
        </div>
      </div>
      <div className="emptySpaceReport"></div>
    </div>
  );
};

export default ReportsMain;
