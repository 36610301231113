import React from "react";

import BagsMainPage from "../components/Bags/BagsHeader";

import "../components/Bags/Bags.css";

function Bags() {
  return (
    <div>
      <BagsMainPage />
    </div>
  );
}

export default Bags;
