import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import ProfileWidget from "./ProfileWidget";
import { ProfileContext } from "../../context/ProfileContext";

const ProfileTrainings = () => {
  // Members avaiable
  const { trainingsAttended } = useContext(ProfileContext);

  const history = useNavigate();

  const handleTraining = (id) => {
    history(`/trainingInfo/${id}`);
  };

  let loopingMonth = null;

  return (
    <>
      <div className="ProfileWidgetContainer">
        <div className="widgetTitleContainer">
          <b>Trainings Attended</b>
        </div>
        <ProfileWidget
          content={
            <>
              <div className="ProfileWidgetInformation">
                {trainingsAttended.length > 0 ? (
                  trainingsAttended.map((data, i) => {
                    //// console.log(data);
                    let currentMonth = data.month;

                    if (loopingMonth === currentMonth) {
                      return (
                        <div key={i}>
                          <div className="ProfileTrainingContainer">
                            <div
                              className="ProfileTrainingListContainer"
                              onClick={() => handleTraining(data.id)}
                            >
                              <div className="ProfileTrainingName">
                                <b>{data.training_name}</b>
                              </div>
                              <div className="ProfileTrainingDay">
                                {data.date2}
                              </div>

                              <div className="ProfileTrainingDesc">
                                Description: {data.description}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    } else {
                      loopingMonth = currentMonth;

                      return (
                        <div key={i}>
                          <div className="ProfileTrainingMonth">
                            {currentMonth}
                          </div>

                          <div className="ProfileTrainingContainer">
                            <div
                              className="ProfileTrainingListContainer"
                              onClick={() => handleTraining(data.id)}
                            >
                              <div className="ProfileTrainingName">
                                <b>{data.training_name}</b>
                              </div>
                              <div className="ProfileTrainingDay">
                                {data.date2}
                              </div>

                              <div className="ProfileTrainingDesc">
                                Description: {data.description}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })
                ) : (
                  <div className="ProfileWidgetEmpty">
                    <b>No Attended Traininings</b>
                  </div>
                )}
              </div>
            </>
          }
        />
      </div>
      <div className="ProfileWidgetContainerBottom"></div>
    </>
  );
};

export default ProfileTrainings;
