import React, { useState, useRef } from "react";
import UserFinder from "../../apis/UserFinder";

import Header from "../Header/Header";
import { useNavigate } from "react-router-dom";
import PopupWeb from "../Popup/PopupNew";

import { AiOutlineMenu } from "react-icons/ai";
import ClipLoader from "react-spinners/ClipLoader";

import { IoIosArrowForward } from "react-icons/io";
import { RiImageAddFill } from "react-icons/ri";
import PublicFolder from "../../apis/PublicFolder";

import Select from "react-select";

import { useAuth } from "../../context/AuthContext";

import { useMainMemberData } from "./fetchMemberData";

const SettingsHeader = () => {
  const { currentUserRole, currentUserSiteId, currentUserId } = useAuth();
  //console.log(currentUserRole);
  const { data, refetch } = useMainMemberData(currentUserSiteId);

  const history = useNavigate();

  const handleUserPage = (id) => {
    history(`/userInfo/${id}`);
  };

  const rolesList = [
    { value: "Basic", label: "Basic" },
    { value: "Advanced", label: "Advanced" },
    { value: "Admin", label: "Admin" },
  ];

  const statusList = [
    { value: false, label: "Active" },
    { value: true, label: "Disabled" },
  ];

  let rrrrr = window.matchMedia("only screen and (max-width: 1000px)").matches;

  const [isMobile, setIsMobile] = useState(rrrrr ? "True" : "False");

  const mediaQuery = window.matchMedia("(max-width: 1000px)");

  mediaQuery.addListener(handleTabletChange);

  function handleTabletChange(e) {
    if (e.matches) {
      setIsMobile("True");
      // console.log("3214");
    } else {
      setIsMobile("False");
      //console.log("55555");
    }
  }

  const [lookUpUserId, setLookUpUserId] = useState("");
  const [currentUserName, setCurrentUserName] = useState("");

  //Popup
  const [isOpen1, setIsOpen] = useState(false);
  const togglePopup = (
    user_id,
    username,
    firstname,
    lastname,
    email,
    role,
    disabled,
    phoneNumber
  ) => {
    setIsOpen(!isOpen1);
    setLookUpUserId(user_id);
    setCurrentUserName(firstname + " " + lastname);

    email ? setEmail(email) : setEmail("");

    setRole(role);
    disabled === "true" ? setStatus(true) : setStatus(false);
  };

  //Edit user Popup

  const [email, setEmail] = useState("");

  const [status, setStatus] = useState(false);

  const [role, setRole] = useState("Basic");

  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const [isOpen2, setIsOpen2] = useState(false);
  const togglePopup2 = () => {
    setIsOpen2(!isOpen2);
    setIsOpen(!isOpen1);
  };
  const togglePopup22 = () => {
    setIsOpen2(!isOpen2);
  };

  const handleEditUser = async (e) => {
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      // eslint-disable-next-line
      const response = await UserFinder.post("/updateUserStatus", {
        userid: lookUpUserId,
        currentUserId: currentUserId,
        email,
        status: status,
        role: role,
      });
      setLoading(false);
      if (response.data.data.alert) {
        setError(response.data.data.alert);
        return;
      }
      refetch();
      togglePopup22();
    } catch (err) {
      setLoading(false);
      setError("Failed to update account");
    }
    setLoading(false);
  };

  //Delete User
  const [isOpen3, setIsOpen3] = useState(false);
  const togglePopup3 = () => {
    setIsOpen2(!isOpen2);
    setIsOpen3(!isOpen3);
  };

  const togglePopup33 = () => {
    setIsOpen3(false);
  };

  const handleDelete = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      // eslint-disable-next-line
      //const response = await UserFinder.post("/deleteUser", {
      //  userid: lookUpUserId,
      // });

      refetch();
      setLoading(false);
      togglePopup33();
    } catch (err) {
      setLoading(false);
    }
  };

  const [isOpen4, setIsOpen4] = useState(false);
  const togglePopup4 = () => {
    setIsOpen4(!isOpen4);
    setEmail("");

    setStatus(false);
    setRole("Basic");
  };

  const [isOpen5, setIsOpen5] = useState(false);
  const togglePopup5 = () => {
    setIsOpen5(!isOpen5);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      //  const response = await signup(
      //    emailRef.current.value,
      //     passwordRef.current.value
      //  );
      //// console.log(response._tokenResponse.email);
      /* const response = await UserFinder.post("/create", {
        email: email,
        password: password,
        userName: userName,
        firstName: firstName,
        lastName: lastName,
        status: status,
        changePass: changePass,
        phoneNumber: phoneNumber,
        role: role,
        currentUserSiteId,
      });
*/

      const response = await UserFinder.post("/addUserToSite", {
        currentUserId: currentUserId,

        email: email,
        status: status,
        role: role,
        currentUserSiteId,
      });

      setLoading(false);
      if (response.data.data.alert) {
        setError(response.data.data.alert);
        return;
      }

      refetch();
      togglePopup4();
    } catch (err) {
      setLoading(false);
      // console.log(err);
      setError("Failed to add account");
    }
  };

  const [query, setQuery] = useState("");
  const [file, setFile] = useState(null);

  //custom image
  const fileRef = useRef();
  const [selectedImage, setSelectedImage] = useState();

  const handleFileInput = (e) => {
    setFile(e.target.files[0]);
    setSelectedImage(e.target.files[0]);
  };

  //edit item holder
  const headers = {
    "content-type": "multipart/form-data",
  };

  const [oldImgPath] = useState("");

  const changeImg = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    try {
      setLoading(true);

      const formData = new FormData();

      formData.set("inventoryId", "f");

      if (file) {
        formData.append("file", file);
      }
      // eslint-disable-next-line
      const response = await UserFinder.post("/updateLogo", formData, headers);
      setLoading(false);
      setSelectedImage("");
      setFile(null);
    } catch (err) {
      setLoading(false);
      // console.log(err);
    }
  };

  const toggleNav = () => {
    let topbar = document.getElementById("topbar");
    let sideBar = document.getElementById("sidebar");
    let appContainer = document.getElementById("appContainer");
    let appContainerHidden = document.getElementById("appContainerHidden");

    topbar.classList.toggle("collapse");
    sideBar.classList.toggle("collapse");
    appContainer.classList.toggle("collapse");
    appContainerHidden.classList.toggle("collapse");
  };
  return (
    <>
      <div id="topbar" className="topbar headerActive">
        <div className="hometopbarleft" onClick={toggleNav}>
          <AiOutlineMenu />
        </div>

        {currentUserId === "1" && (
          <div className="editCheckListBtn" onClick={togglePopup5}>
            O
          </div>
        )}

        {currentUserRole === "Admin" && (
          <div className="addItem" onClick={togglePopup4}>
            +
          </div>
        )}

        <Header content={<b>Site Info</b>} />
      </div>

      <div className="topbarEmptySpace22"></div>

      <div className="searchDiv1">
        <div className="searchDiv1">
          <input
            className="addItemInput"
            id="myInput"
            placeholder="Search"
            type="search"
            autoComplete="off"
            autoCorrect="off"
            onChange={(event) => setQuery(event.target.value)}
          />
        </div>
      </div>

      {isMobile === "False" ? (
        <>
          {" "}
          <div className="memberListDivTable">
            <div className="memberListheaders">
              <div className="memberListheadersContent"> Name</div>
              <div className="memberListheadersContent"> Role</div>
              <div className="memberListheadersContent"> Email</div>
              <div className="memberListheadersContentSmall"> Status</div>
              <div className="memberListheadersContentSmall"> View</div>
            </div>
            <div className="memberListRow">
              {data?.data.data.memberList &&
                data?.data.data.memberList
                  // eslint-disable-next-line
                  .filter((post) => {
                    if (query === "") {
                      return post;
                    } else if (
                      post.firstname
                        .toLowerCase()
                        .includes(query.toLowerCase()) ||
                      post.lastname.toLowerCase().includes(query.toLowerCase())
                    ) {
                      return post;
                    }
                  })
                  .map((data, i) => {
                    // console.log(data);
                    return (
                      <div key={i}>
                        <div
                          className="memberListHolder"
                          onClick={() =>
                            togglePopup(
                              data.user_id,
                              data.username,
                              data.firstname,
                              data.lastname,
                              data.email,
                              data.role,
                              data.disabled,
                              data.changepass,
                              data.cardid,
                              data.phone
                            )
                          }
                        >
                          <div className="memberListHolderCell">
                            {data.firstname} {data.lastname}
                          </div>

                          <div className="memberListHolderCell">
                            {data.role}
                          </div>

                          <div className="memberListHolderCell">
                            {data.email}
                          </div>

                          <div className="memberListHolderCellSmall">
                            {data.disabled === "true" ? "Disabled" : "Active"}
                          </div>

                          <div className="memberListHolderCellSmall">
                            <IoIosArrowForward size={20} />
                          </div>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="memberListDivTableMobile">
            <div className="memberListRowMobile">
              {data?.data.data.memberList &&
                data?.data.data.memberList
                  // eslint-disable-next-line
                  .filter((post) => {
                    if (query === "") {
                      return post;
                    } else if (
                      post.firstname
                        .toLowerCase()
                        .includes(query.toLowerCase()) ||
                      post.lastname.toLowerCase().includes(query.toLowerCase())
                    ) {
                      return post;
                    }
                  })
                  .map((data, i) => {
                    //console.log(data);
                    return (
                      <div key={i}>
                        <div
                          className="memberListHolderMobile"
                          onClick={() =>
                            togglePopup(
                              data.user_id,
                              data.username,
                              data.firstname,
                              data.lastname,
                              data.email,
                              data.role,
                              data.disabled,
                              data.changepass,
                              data.cardid,
                              data.phone
                            )
                          }
                        >
                          <div className="memberListHolderCellRightMobile">
                            <IoIosArrowForward size={20} />
                          </div>
                          <div className="memberListheadersContentMobile">
                            Name
                          </div>
                          <div className="memberListHolderCellMobile">
                            {data.firstname} {data.lastname}
                          </div>
                          <div className="memberListheadersContentMobile">
                            Role
                          </div>

                          <div className="memberListHolderCellMobile">
                            {data.role}
                          </div>
                          <div className="memberListheadersContentMobile">
                            Email
                          </div>

                          <div className="memberListHolderCellMobile">
                            {data.email}
                          </div>
                          <div className="memberListheadersContentMobile">
                            Status
                          </div>

                          <div className="memberListHolderCellMobile">
                            {data.disabled === "true" ? "Disabled" : "Active"}
                          </div>
                        </div>
                      </div>
                    );
                  })}
            </div>
          </div>
        </>
      )}

      {isOpen1 && (
        <PopupWeb
          content={
            <>
          
              <div className="popupBtnContainers">
                <div className="btnDiv">
                  <div
                    className="addMemberBtn"
                    onClick={() => handleUserPage(lookUpUserId)}
                  >
                    <b>User Profile</b>
                  </div>
                </div>

                {currentUserRole === "Admin" && (
                  <div className="btnDiv">
                    <div className="addMemberBtn" onClick={togglePopup2}>
                      <b>Edit User</b>
                    </div>
                  </div>
                )}
              </div>
            </>
          }
          handleClose={togglePopup}

          isOpen={isOpen1}
          showSubmitButton={false}
          headerText={        <b> {currentUserName}</b>}

        />
      )}

      {isOpen2 && (
        <PopupWeb
          content={
            <div>
              <form onSubmit={handleEditUser} className="userForm">
          

                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>

                {error && <div className="alert">{error}</div>}

                <div className="popupContainer">
                  {currentUserId === "1" && (
                    <label className="NewInputLabel" data-domain="Email">
                      <input
                        className="NewInput"
                        type="email"
                        placeholder=""
                        onChange={(e) => setEmail(e.target.value)}
                        value={email}
                      />
                    </label>
                  )}
                  <div className="loginUsername">Role</div>

                  <Select
                    defaultValue={{ label: role, value: role }}
                    options={rolesList}
                    onChange={(e) => setRole(e.value)}
                    classNamePrefix="select"
                    className="basic-multi-select"
                    isSearchable={false}
                  />

                  <div className="loginUsername">Status</div>

                  <Select
                    defaultValue={
                      status === true
                        ? { label: "Disabled", value: true }
                        : { label: "Active", value: false }
                    }
                    options={statusList}
                    onChange={(e) => setStatus(e.value)}
                    classNamePrefix="select"
                    className="basic-multi-select"
                    isSearchable={false}
                  />
                </div>
              </form>

              {currentUserRole === "Admin" && (
                <div className="editApparatusBtnContainer">
                  <div className="editApparatusBtn" onClick={togglePopup3}>
                    <b>Delete User</b>
                  </div>
                </div>
              )}
            </div>
          }

          handleClose={togglePopup2}
          isOpen={isOpen2}
          handleSubmit={handleEditUser}
          showSubmitButton={true}
          headerText={<b> Edit User</b>}
          submitButtonText={loading ? "Loading..." : "Save"} 

        />
      )}

      {isOpen3 && (
        <PopupWeb
          content={
            <>
       
              <div className="deleteInfoApparatusContainer">
                <b>This users account will be deleted</b>
              </div>

              <div className="deleteInfoApparatusContainer">
                <b>THIS WILL DELETE ALL INFORMATION ABOUT THE USER</b>
              </div>

         
            </>
          }

          handleClose={togglePopup33}
          isOpen={isOpen3}
          handleSubmit={handleDelete}
          showSubmitButton={true}
          headerText={  <b>Remove User - {currentUserName} </b>}
          submitButtonText={loading ? "Loading..." : "Remove"} 

        />
      )}

      {/*  {isOpen4 && (
        <PopupWeb
          content={
            <div>
              <form onSubmit={handleSubmit} className="userForm">
                <div id="holderTest" className="holderTest">
                  <button
                    type="submit"
                    className="submitItemBtn2"
                    disabled={loading}
                  >
                    Submit
                  </button>
                  <div className="popupTitle3">
                    <b> New User</b>
                  </div>
                </div>
                <div className="emptySpace"></div>

                {error && <div className="alert">{error}</div>}

                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>
                <div className="popupContainer">
                  <label className="NewInputLabel" data-domain="First Name">
                    <input
                      className="NewInput"
                      type="text"
                      placeholder=""
                      onChange={(e) => setFirstName(e.target.value)}
                      value={firstName}
                      required
                    />
                  </label>

                  <label className="NewInputLabel" data-domain="Last Name">
                    <input
                      className="NewInput"
                      type="text"
                      placeholder=""
                      onChange={(e) => setLastName(e.target.value)}
                      value={lastName}
                      required
                    />
                  </label>

                  <label className="NewInputLabel" data-domain="Username">
                    <input
                      className="NewInput"
                      type="text"
                      placeholder=""
                      onChange={(e) => setUserName(e.target.value)}
                      value={userName}
                    />
                  </label>

                  <label className="NewInputLabel" data-domain="Phone">
                    <input
                      className="NewInput"
                      type="text"
                      placeholder=""
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      value={phoneNumber}
                    />
                  </label>

                  <label className="NewInputLabel" data-domain="Email">
                    <input
                      className="NewInput"
                      type="email"
                      placeholder=""
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      required
                    />
                  </label>

                  <label className="NewInputLabel" data-domain="Password">
                    <input
                      className="NewInput"
                      type="Password"
                      placeholder=""
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      required
                    />
                  </label>

                  <div className="loginUsername">Role</div>

                  <Select
                    defaultValue={rolesList[0]}
                    options={rolesList}
                    onChange={(e) => setRole(e.value)}
                    classNamePrefix="select"
                    className="basic-multi-select"
                    isSearchable={false}
                  />

                  <div className="loginUsername">Status</div>

                  <Select
                    defaultValue={statusList[0]}
                    options={statusList}
                    onChange={(e) => setStatus(e.value)}
                    classNamePrefix="select"
                    className="basic-multi-select"
                    isSearchable={false}
                  />
                </div>
              </form>
            </div>
          }
          handleClose={togglePopup4}
        />
      )}
        */}
      {isOpen4 && (
        <PopupWeb
          content={
            <div>
              <form onSubmit={handleSubmit} className="userForm">
        
                {error && <div className="alert">{error}</div>}

                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>
                <div className="popupContainer">
                  <label className="NewInputLabel" data-domain="Email">
                    <input
                      className="NewInput"
                      type="email"
                      placeholder=""
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      required
                    />
                  </label>

                  <div className="loginUsername">Role</div>

                  <Select
                    defaultValue={rolesList[0]}
                    options={rolesList}
                    onChange={(e) => setRole(e.value)}
                    classNamePrefix="select"
                    className="basic-multi-select"
                    isSearchable={false}
                  />

                  <div className="loginUsername">Status</div>

                  <Select
                    defaultValue={statusList[0]}
                    options={statusList}
                    onChange={(e) => setStatus(e.value)}
                    classNamePrefix="select"
                    className="basic-multi-select"
                    isSearchable={false}
                  />
                </div>
              </form>
            </div>
          }

          handleClose={togglePopup4}
          isOpen={isOpen4}
          handleSubmit={handleSubmit}
          showSubmitButton={true}
          headerText={ <b> Add User to Site</b>}
          submitButtonText={loading ? "Loading..." : "Add"} 

        />
      )}

      {isOpen5 && (
        <PopupWeb
          content={
            <div>
              <form onSubmit={changeImg}>
        
                <div className="text-center p-3">
                  <ClipLoader
                    loading={loading}
                    className="override"
                    size={35}
                  />
                </div>
                <div className="popupContainer">
                  <div className="NewInputLabel" data-domain="Add Image">
                    <input
                      ref={fileRef}
                      onChange={handleFileInput}
                      multiple={false}
                      type="file"
                      accept="image/*"
                      hidden
                    />
                    <div
                      onClick={() => fileRef.current.click()}
                      className="NewInputImage"
                    >
                      <RiImageAddFill size={30} />
                      {selectedImage ? (
                        <div className="addImageHolder">
                          <img
                            src={URL.createObjectURL(selectedImage)}
                            alt="imageDidNotLoad"
                            className="addImage"
                          />
                        </div>
                      ) : (
                        oldImgPath && (
                          <div className="addImageHolder">
                            <img
                              src={
                                PublicFolder.baseURL + "preview_" + oldImgPath
                              }
                              alt="imageDidNotLoad"
                              className="addImage"
                            />
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          }

          handleClose={togglePopup5}
          isOpen={isOpen5}
          handleSubmit={changeImg}
          showSubmitButton={true}
          headerText={  <b>Change App Logo</b>}
          submitButtonText={loading ? "Loading..." : "Save"} 
        />
      )}
    </>
  );
};

export default SettingsHeader;
