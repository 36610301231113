import React, { useEffect, useState } from "react";
import UserFinder from "../../../apis/UserFinder";

import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { useParams } from "react-router-dom";

import { IoMdArrowRoundBack } from "react-icons/io";
import { useAuth } from "../../../context/AuthContext";

const UserInfoItems = () => {
  const { id } = useParams();
  const { currentUserSiteId } = useAuth();

  const [eventInfo, setEventInfo] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [dropdownQuery, setDropdownQuery] = useState("");

  let history = useNavigate();

  let loopingGroup = null;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await UserFinder.get(`/getEventsTrained`, {
          params: {
            userid: id,
            currentUserSiteId,
          },
        });
        setEventInfo(response.data.data.trainerInfo);
        setFirstName(response.data.data.userInfo[0].firstname);
        setLastName(response.data.data.userInfo[0].lastname);
      } catch (err) {
        // console.log(err);
      }
    };

    if (currentUserSiteId) {
      fetchData();
    } // eslint-disable-next-line
  }, [currentUserSiteId]);

  const handleTraining = (id) => {
    history(`/trainingInfo/${id}`);
  };

  let options = [
    { value: "", label: "Any" },
    { value: "Fire", label: "Fire" },
    { value: "Tech", label: "Tech" },
    { value: "Incident", label: "Incident" },
  ];

  const sideBar = document.getElementById("sidebar");

  return (
    <div className="eventHolder">
      <div
        id="topbar"
        className={
          sideBar === null
            ? "topbar headerActive"
            : sideBar.clientWidth === 80
            ? "topbar headerActive collapse"
            : "topbar headerActive"
        }
      >
        <div className="goBackDiv" onClick={() => history(-1)}>
          <IoMdArrowRoundBack />
        </div>

        <div className="smallOnScreen" id="header">
          <div className="smallHeader">
            <b>
              {firstName} {lastName}
            </b>
          </div>
        </div>
      </div>

      <div className="eventsHolder">
        <b>Trained Events</b>
      </div>

      <div className="detailsPopupContainer22">
        <div className="select-container">
          <Select
            options={options}
            defaultValue={{ label: "Any", value: "" }}
            onChange={(event) => setDropdownQuery(event.value)}
            name="addMembers"
            className="mb-3"
            isSearchable={false}
          />
        </div>
      </div>
      {eventInfo.length > 0 ? (
        eventInfo
          // eslint-disable-next-line
          .filter((data) => {
            if (dropdownQuery === "") {
              return data;
            } else if (
              data.category.toLowerCase().includes(dropdownQuery.toLowerCase())
            ) {
              return data;
            }
          })
          .map((data, i) => {
            if (loopingGroup === data.month) {
              return (
                <div key={i}>
                  <div
                    className="itemListContainerHolder"
                    onClick={() => handleTraining(data.id)}
                  >
                    <div className="userItemListPageHolder">
                      <div className="itemListPageName">
                        {data.training_name}
                      </div>
                      <div className="userInfoEventTypeName">
                        {data.category}
                      </div>
                    </div>
                    <div className="userItemListPageAmount">{data.date2}</div>
                  </div>
                </div>
              );
            } else {
              loopingGroup = data.month;
              return (
                <div key={i}>
                  <div className="itemListHeader">
                    <b>{data.month}</b>
                  </div>
                  <div
                    className="itemListContainerHolder"
                    onClick={() => handleTraining(data.id)}
                  >
                    <div className="userItemListPageHolder">
                      <div className="itemListPageName">
                        {data.training_name}
                      </div>
                      <div className="userInfoEventTypeName">
                        {data.category}
                      </div>
                    </div>
                    <div className="userItemListPageAmount">{data.date2}</div>
                  </div>
                </div>
              );
            }
          })
      ) : (
        <>No attended events</>
      )}
    </div>
  );
};

export default UserInfoItems;
