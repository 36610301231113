import React from "react";
import "../components/Training/Training.css";
import IndividualTraining from "../components/AdminPage/AdminUserPage/IndividualTraining";
import { TrainingContextProvider } from "../context/TrainingContext";

const Training = () => {
  return (
    <TrainingContextProvider>
      <>
        <IndividualTraining />
      </>
    </TrainingContextProvider>
  );
};

export default Training;
