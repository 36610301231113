import React from "react";

//import Scanner from "../Scanner/Scanner";
import InventoryWeb from "./InventoryWeb";
import InventoryList from "./InventoryList";

import InventoryBagsList from "./InventoryBagsList";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const InventoryHeader = () => {
  //Photos
  //https://www.cleanpng.com/free/id.html

  //var bottomBar = document.getElementById("popup-1");


  
 

  return (
    <>

      <div className="detailsPopupContainer">
      <InventoryWeb /> 
       {/* <Tabs>
          <TabList>
            <Tab>
              <b>Items</b>
            </Tab>
            <Tab>
              <b>Bags</b>
            </Tab>
            <Tab>
              <b>Item Holder</b>
            </Tab>
          </TabList>

          <TabPanel>
          <InventoryWeb /> 
          </TabPanel>
          <TabPanel>
            <InventoryBagsList />
          </TabPanel>
          <TabPanel>
            <InventoryList />
          </TabPanel>
  </Tabs>*/}
      </div>
    </>
  );
};

export default InventoryHeader;
