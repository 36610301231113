import { useQuery } from "@tanstack/react-query";
import ApparatusFinder from "../../apis/ApparatusFinder";

const fetchMainLocationsInventory = ({ queryKey }) => {
  const currentUserSiteId = queryKey[1];
  return ApparatusFinder.get(`/getAllApparatus`, {
    params: {
      currentUserSiteId,
    },
  });
};

export const useMainLocationsData = (currentUserSiteId) => {
  return useQuery(
    ["mainLocations", currentUserSiteId],
    fetchMainLocationsInventory
  );
};
