import React from "react";
import ApparatusHeader from "../components/Apparatus/ApparatusHeader";
import ApparatusList from "../components/Apparatus/ApparatusList";
import "../components/Apparatus/Apparatus.css";
import { ApparatusContextProvider } from "../context/ApparatusContext";

function Apparatus() {
  return (
    <ApparatusContextProvider>
      <>
        <ApparatusHeader />
        <ApparatusList />
      </>
    </ApparatusContextProvider>
  );
}

export default Apparatus;
