import React, { useEffect, useState } from "react";
import UserFinder from "../../../apis/UserFinder";
import { useParams } from "react-router-dom";

import { IoMdArrowRoundBack } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";

const UserInfoItems = () => {
  const { id } = useParams();
  const { currentUserSiteId } = useAuth();

  const [itemInfo, setItemInfo] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  let history = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await UserFinder.get(`/getUserItems`, {
          params: {
            userid: id,
            currentUserSiteId,
          },
        });

        setItemInfo(response.data.data.userItems);
        setFirstName(response.data.data.userInfo[0].firstname);
        setLastName(response.data.data.userInfo[0].lastname);
      } catch (err) {
        // console.log(err);
      }
    };

    if (currentUserSiteId) {
      fetchData();
    } // eslint-disable-next-line
  }, [currentUserSiteId]);

  const handleItem = (itemid) => {
    history({
      pathname: `/inventory/${itemid}/itempage`,
    });
  };
  let loopingGroup = null;
  const sideBar = document.getElementById("sidebar");

  return (
    <div className="eventHolder">
      <div
        id="topbar"
        className={
          sideBar === null
            ? "topbar headerActive"
            : sideBar.clientWidth === 80
            ? "topbar headerActive collapse"
            : "topbar headerActive"
        }
      >
        <div className="goBackDiv" onClick={() => history(-1)}>
          <IoMdArrowRoundBack />
        </div>

        <div className="smallOnScreen" id="header">
          <div className="smallHeader">
            <b>
              {firstName} {lastName}
            </b>
          </div>
        </div>
      </div>

      <div className="eventsHolder">
        <b>User Items</b>
      </div>

      {itemInfo.length > 0 ? (
        itemInfo.map((data, i) => {
          // console.log(itemInfo);

          if (loopingGroup === data.itemgroup) {
            return (
              <div key={i}>
                <div
                  className="itemListContainerHolder"
                  onClick={() => handleItem(data.itemid, data.id)}
                >
                  <div className="userItemListPageHolder">
                    <div className="itemListPageName">
                      {data.name}
                      {data.identificationname
                        ? ` - ${data.identificationname}`
                        : ""}
                    </div>
                  </div>
                  <div className="userItemListPageAmount">{data.amount}</div>
                </div>
              </div>
            );
          } else {
            loopingGroup = data.itemgroup;
            return (
              <div key={i}>
                <div className="itemListHeader">
                  <b>{data.itemgroup}</b>
                </div>
                <div
                  className="itemListContainerHolder"
                  onClick={() => handleItem(data.itemid, data.id)}
                >
                  <div className="userItemListPageHolder">
                    <div className="itemListPageName">
                      {data.name}
                      {data.identificationname
                        ? ` - ${data.identificationname}`
                        : ""}
                    </div>
                  </div>
                  <div className="userItemListPageAmount">{data.amount}</div>
                </div>
              </div>
            );
          }
        })
      ) : (
        <>No items assigned</>
      )}
    </div>
  );
};

export default UserInfoItems;
