import React from "react";
import "../components/Events/Events.css";
import EventsHeader from "../components/Events/EventsHeader";
import { EventsContextProvider } from "../context/EventsContext";

const Events = () => {
  return (
    <EventsContextProvider>
      <>
        <EventsHeader />
      </>
    </EventsContextProvider>
  );
};

export default Events;
