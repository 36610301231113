import React, { useState, useRef, useEffect } from "react";

import PopupWeb from "../Popup/PopupNew";
import SiteFinder from "../../apis/SiteFinder";

import { useNavigate } from "react-router-dom";
import PublicFolder from "../../apis/PublicFolder";

import Header from "../Header/Header";
import { useAuth } from "../../context/AuthContext";
import { RiImageAddFill, RiCloseFill } from "react-icons/ri";

import { AiOutlineMenu } from "react-icons/ai";

import { useMainSiteCategoryData } from "./fetchBagsData";
import { FaArrowRight } from "react-icons/fa";

import Select from "react-select";
import { notify } from "../../ToastNotifier";
import { BsImage } from "react-icons/bs";
import { BsThreeDotsVertical } from "react-icons/bs";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getPaginationRowModel,
} from "@tanstack/react-table";

import PDFReportTemplate from "../Reports/PDFReportTemplate";

const Pagination = ({ table }) => {
  const { pageIndex, pageSize } = table.getState().pagination;

  // Store the current page index in local storage
  useEffect(() => {
    localStorage.setItem("InventoryPage", pageIndex);
  }, [pageIndex]);

  const totalRows = table.getPrePaginationRowModel().rows.length;
  const startRow = pageIndex * pageSize + 1;
  const endRow = Math.min(startRow + pageSize - 1, totalRows);

  return (
    <div className="pagination2">
      <div className="results-info">
        Showing {startRow} to {endRow} of {totalRows} results
      </div>
      <div className="pagination-controls">
        <button
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          Previous
        </button>
        <span>
          {/*  Page {pageIndex + 1} of {table.getPageCount()}*/}
          {pageIndex + 1}
        </span>
        <button
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          Next
        </button>
      </div>
    </div>
  );
};

const BagsHeader = () => {
  const {
    currentUserSiteId,
    currentUserId,
    currentFirstName,
    currentLastName,
  } = useAuth();
  const navigate = useNavigate();

  const { data, refetch } = useMainSiteCategoryData(currentUserSiteId);

  const [bagList, setBagList] = useState([]);
  const [apparatusList, setApparatusList] = useState([]);
  const [selectedCompartmentId, setSelectedCompartmentId] = useState([]);

  useEffect(() => {
    if (data) {
      setBagList(data?.data.data.bagsList);
      setApparatusList(data?.data.data.apparatusListNew);
    }
  }, [data]);

  //custom image
  const fileRef = useRef();
  const [selectedImage, setSelectedImage] = useState("");
  const headers = {
    "content-type": "multipart/form-data",
  };

  const [loading, setLoading] = useState(false);

  const [categoryFilter, setCategoryFilter] = useState([]);
  const [statusFilter, setStatusFilter] = useState([]);
  const [memberFilter, setMemebrFilter] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState([]);

  //Popup create new events
  const [isOpen7, setIsOpen7] = useState(false);
  const newUpcomingPopupClose = () => {
    setIsOpen7(!isOpen7);
    clearForm();
  };

  const newUpcomingPopup = () => {
    setIsOpen7(!isOpen7);
    // setIsOpen8(!isOpen8);
  };

  //Popup create new events
  const [isOpen8, setIsOpen8] = useState(false);
  const newSelectPopup = () => {
    setIsOpen8(!isOpen8);
  };
  const apparatusoptions =
    data?.data.data.apparatusList.map(function (data) {
      return {
        value: data.id,
        label: data.name,
      };
    }) || [];


  const combineOptions = (
    location, apparatusOptions // Add apparatus options
  ) => {console.log(apparatusOptions)

    return [
      ...apparatusOptions
        .filter((option) => locationFilter.includes(option.value))
        .map((option) => ({ ...option, type: "Apparatus" })),
    ];
  };



  const updateSelectedOptions = (location,apparatusOptions) => {
    console.log(location)
    const combinedOptions = combineOptions(
      location ,
      apparatusoptions
    );
    setSelectedOptions(combinedOptions);
  };

  const [inventoryLookUpItem, setInventoryLookUpItem] = useState("");
  const [locationFilter, setLocationFilter] = useState([]);

  useEffect(() => {
    const storedSearchQuery = localStorage.getItem("BagsSearchQuery");

    const storedLocationFilter = (
      localStorage.getItem("BagsLocationFilter") || "[]"
    )
      .split(",")
      .filter(Boolean)
      .map(Number);

    if (storedSearchQuery) {
      setInventoryLookUpItem(storedSearchQuery);
    }

    if (storedLocationFilter.length > 0) {
      setLocationFilter(storedLocationFilter);
    }
  }, []);



  useEffect(() => {
    // Ensure that options are available before attempting to update selected options
    if (
      apparatusoptions.length 
    ) {
      // Only update if there's a change in the filters or the necessary options are loaded
      if (
        locationFilter.length
      ) {
        updateSelectedOptions(
          locationFilter
        );
      }
    }
  }, [
    locationFilter,
    apparatusoptions.length, // Watch for when options are loaded
  ]);
  


  const locationdefaultValues = apparatusoptions.filter((option) =>
  locationFilter.includes(option.value)
);






  const [selectedBag, setSelectedBag] = useState("");
  const [selectedBagId, setSelectedBagId] = useState("");


  const [newDate, setNewDate] = useState();

  const [status, setStatus] = useState("Active");

  //note

  const [category, setCategory] = useState("");

  const [eventType, setEventType] = useState("");


  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const togglePopupFilter = () => {
    setIsFilterOpen(!isFilterOpen);
  };

  const [errors, setErrors] = useState({});

  const validationRules = {
    newForm: {
      selectedBag: "Please enter a name",
      selectedCompartmentId: "Please select a location",
    },
    // Add more validation rules for other forms here
  };

  const validateForm = (values, rules) => {
    const newErrors = {};
    let isValid = true;

    Object.keys(rules).forEach((key) => {
      if (!values[key]) {
        newErrors[key] = rules[key];
        isValid = false;
      }
    });

    setErrors(newErrors);

    if (!isValid) {
      notify("Please fill out all required fields", "error");
    }

    return isValid;
  };

  const clearForm = () => {
    setSelectedImage(null);
    setSelectedCompartmentId([])
    setLoading(false);
    setErrors({});
   setSelectedBag("");
    setCategory("");
    setEventType("");
    setNewDate("");
    setStatus("Active");
  };

  const handleCompartmentChange = (e) => {
    setSelectedCompartmentId(e ? [e.value] : []);
    setErrors({});
  };

  const [isOpen1, setIsOpen] = useState(false);
  const togglePopup = (name, bagid, cid, image) => {
    // console.log(cid)
    setIsOpen(!isOpen1);
    setSelectedBag(name);
    setSelectedBagId(bagid);
    setSelectedCompartmentId(cid ? [cid] : []);
    setOldImgPath(image)
  };

  

  const togglePopupClose = () => {
    setIsOpen(!isOpen1);
clearForm()
  };

  const [isOpen2, setIsOpen2] = useState(false);
  const togglePopup2 = () => {
    setIsOpen2(true);
    setIsOpen(false);
  };
  const togglePopup22 = () => {
    setIsOpen2(false);
    setIsOpen(false);
  };

  const StatusOptions = [
    { value: "Active", label: "Active" },
    { value: "Completed", label: "Completed" },
  ];

  const toggleNav = () => {
    let topbar = document.getElementById("topbar");
    let sideBar = document.getElementById("sidebar");
    let appContainer = document.getElementById("appContainer");
    let appContainerHidden = document.getElementById("appContainerHidden");

    topbar.classList.toggle("collapse");
    sideBar.classList.toggle("collapse");
    appContainer.classList.toggle("collapse");
    appContainerHidden.classList.toggle("collapse");
  };

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor((row) => row.imagepath, {
      id: "imagepath",
      cell: (info) =>
        info.getValue() ? (
          <div
            className="min-max-widthLogo"
            onClick={() => handleBagClick(info.row.original.bagid)}
          >
            <img
              src={PublicFolder.baseURL + "preview_" + info.getValue()}
              alt="Did Not Load"
              className="InventoryTableImg"
            />
          </div>
        ) : (
          <div
            className="min-max-widthLogo"
            onClick={() => handleBagClick(info.row.original.bagid)}
          >
            <BsImage size={50} />
          </div>
        ),
      header: () => <div className="min-max-widthLogo">Image</div>,
    }),
    columnHelper.accessor((row) => row.bagname, {
      id: "bagname",
      cell: (info) => (
        <div
          className="min-max-width"
          onClick={() => handleBagClick(info.row.original.bagid)}
        >
          {info.getValue()}
        </div>
      ),
      header: () => <div className="min-max-width">Bag Name</div>,
    }),
    columnHelper.accessor((row) => row.compartmentname, {
      id: "title",
      cell: (info) => (
        <div
          className="min-max-width"
          onClick={() => handleBagClick(info.row.original.bagid)}
        >
          {info.row.original.apparatus} - {info.row.original.compartmentname}
        </div>
      ),
      header: () => <div className="min-max-width">Location</div>,
    }),

    columnHelper.accessor((row) => row.compartmentname, {
      id: "Edit",
      cell: (info) => (
        <div
          onClick={() =>
            togglePopup(
              info.row.original.bagname,
              info.row.original.bagid,
              info.row.original.cid,
              info.row.original.imagepath
            )
          }
        >
          <BsThreeDotsVertical />
        </div>
      ),
      header: () => <div className="min-max-width">Edit</div>,
    }),
  ];

  const initialPage = parseInt(localStorage.getItem("BagsPage")) || 0;

  const table = useReactTable({
    data: bagList,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: { pagination: { pageIndex: initialPage, pageSize: 25 } }, // Set initial page size and page index
  });

  const pdfColumns = [
    { header: "Date", accessor: (row) => row.date1 },
    { header: "Title", accessor: (row) => row.title },
    { header: "Note", accessor: (row) => row.note },
    { header: "Status", accessor: (row) => row.status },
  ];

  const handleClearFilters = () => {
    setLocationFilter([]);
    localStorage.removeItem("BagsLocationFilter");
    localStorage.removeItem("BagsPage");
    localStorage.removeItem("BagsSearchQuery");

    setSelectedOptions([]);
    setInventoryLookUpItem("");

    refetch();
  };


  const handleSearchChange = (e) => {
    const searchQuery = inventoryLookUpItem;
    localStorage.setItem("BagsSearchQuery", searchQuery); // Store the search query
    localStorage.setItem("BagsPage", 0); // Reset the page index
    table.setPageIndex(0); // Reset the table page index
    setInventoryLookUpItem(searchQuery); // Update the state
    refetch(); // Refetch data
  };


  const handleChangeLocation = (e) => {
    const LocationFilter = Array.isArray(e) ? e.map((x) => x.value) : [];
    localStorage.setItem("BagsLocationFilter", LocationFilter); // Store as JSON string
    localStorage.setItem("BagsPage", 0); // Reset the page index
    table.setPageIndex(0); // Reset the table page index
    setLocationFilter(LocationFilter); // Update the state

    refetch(); // Refetch data
  };

  // Get default values for the Select component from the state
  const StatusdefaultValues = StatusOptions.filter((option) =>
    statusFilter.includes(option.value)
  );

  const optionsApparatus = apparatusList.map((data) => ({
    label: data.name,
    options: data.compartments.map((data2) => ({
      value: data2.cid,
      label: data2.compartmentname,
    })),
  }));

  const flatOptionsApparatus = optionsApparatus.flatMap(
    (group) => group.options
  );


  const bagLocationValues = flatOptionsApparatus.filter((option) =>
  selectedCompartmentId.includes(option.value)

);


  const handleBagSubmit = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    if (loading) return; // Prevent double submission
    const formValues = {
      selectedBag,
      selectedCompartmentId,
    };

    if (!validateForm(formValues, validationRules.newForm)) {
      return;
    }

    setLoading(true);
    try {
      const formData = new FormData();
      // // console.log("handleFileInput working!");

      formData.set("selectedBag", selectedBag);
      formData.set("selectedCompartmentId", selectedCompartmentId);
      formData.set("currentUserSiteId", currentUserSiteId);
      // Ensure selectedImage is an array
      if (Array.isArray(selectedImage)) {
        selectedImage.forEach((image, index) => {
          formData.append(`file${index}`, image);
        });
      } else if (selectedImage) {
        // If it's a single image, append it directly
        formData.append("file", selectedImage);
      }
      // eslint-disable-next-line
      const response = await SiteFinder.post(
        "/addSiteBag",
        formData,
        headers
      );
      refetch();
       clearForm();
       newUpcomingPopupClose();
      notify("Location created successfully!", "success"); // Show success notification
    } catch (err) {
      // console.log(err);
      setLoading(false);
      notify("Failed to create location. Please try again.", "error"); // Show error notification
    
    }
  };




  

  const handleUpdate = async (e) => {
    //stop the page from reloading
    e.preventDefault();
    if (loading) return; // Prevent double submission
    const formValues = {
      selectedBag,
      selectedCompartmentId,
    };

    if (!validateForm(formValues, validationRules.newForm)) {
      return;
    }

    setLoading(true);
    try {
      const formData = new FormData();
      // // console.log("handleFileInput working!");
      formData.set("selectedBagId", selectedBagId);

      formData.set("selectedBag", selectedBag);
      formData.set("selectedCompartmentId", selectedCompartmentId);
      formData.set("currentUserSiteId", currentUserSiteId);
      formData.set("oldImgPath", oldImgPath);

      // Ensure selectedImage is an array
      if (Array.isArray(selectedImage)) {
        selectedImage.forEach((image, index) => {
          formData.append(`file${index}`, image);
        });
      } else if (selectedImage) {
        // If it's a single image, append it directly
        formData.append("file", selectedImage);
      }
      const response = await SiteFinder.post(
        "/moveBag",
        formData,
        headers
      );
      refetch();
       clearForm();
       togglePopupClose();
      notify("Location created successfully!", "success"); // Show success notification
    } catch (err) {
      // console.log(err);
      setLoading(false);
      notify("Failed to create location. Please try again.", "error"); // Show error notification
    
    }
  };






  const handleBagClick = (bagid) => {
    // console.log("mfdsklmflds")
    localStorage.setItem("InventoryBagFilter", bagid);
    navigate("/inventory", { state: { from: "/bags" } });

  };

  
  const handleFileInput = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
  };
  const removehandleFileInput = (index) => {
    setSelectedImage(null);
  };

  const [oldImgPath, setOldImgPath] = useState("");

  const handleDelete = async (e) => {
    //stop the page from reloading
    e.preventDefault();

    try {
      // eslint-disable-next-line
      const response = await SiteFinder.post(`/deleteBag`, {
        selectedBagId,
        oldImgPath,
        currentUserSiteId,
      });

      refetch();
      togglePopup22();
      notify("Location deleted successfully!", "success"); // Show success notification

  } catch (err) {
      // console.log(err);
      setLoading(false);
      notify("Failed to delete location. Please try again.", "error"); // Show error notification

    }
  };

  

  return (
    <div className="containerInventory">
      <div className="container22">
        <div className="top2">
          <div className="top">
            <div id="topbar" className="topbar headerActive">
              <div className="hometopbarleft" onClick={toggleNav}>
                <AiOutlineMenu />
              </div>

              <Header content={<b>Bags</b>} />

              <div className="addItemReact" onClick={newUpcomingPopup}>
                <div className="addItemReactText"> New Bag</div>
              </div>
            </div>

            <div className="filter-search-toggle-container">
              <div className={`filter-search-container`}>
              <div className="search-input-container">
                  <input
                    className="inventorySearchButton"
                    placeholder="Search Inventory"
                    type="search"
                    value={inventoryLookUpItem}
                    onChange={(e) => setInventoryLookUpItem(e.target.value)}
                  />
                  <button
                    className="search-button"
                    onClick={handleSearchChange}
                  >
                    <span className="arrow-icon">
                      <FaArrowRight />
                    </span>
                  </button>
                </div>


                <button className="clear-filters" onClick={handleClearFilters}>
                  Clear
                </button>

                <PDFReportTemplate
                  table={table}
                  title="Event Report"
                  reportDateRange="2024-01-01 to 2024-12-31"
                  category="Inventory"
                  members={["John Doe", "Jane Smith"]}
                  filters={selectedOptions}
                  logoUrl={`${PublicFolder.baseURL}LogonIcon.png`}
                  pdfColumns={pdfColumns}
                  includeDateRange={false} // Pass these flags based on your requirement
                  includeCategory={false}
                  includeMembers={false}
                  includeFilters={true}
                />

                <button className="clear-filters" onClick={togglePopupFilter}>
                  Filters
                </button>

                <div className="selected-options-display">
                  <div className="selected-options-row">
                    {selectedOptions.map((option, index) => (
                      <div key={index} className="selected-option">
                        {option.label}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="middle2">
          <div className="table-container2">
            {bagList ? (
              <>
                <table>
                  <thead>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => (
                          <th key={header.id}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody>
                    {table.getRowModel().rows.map((row) => (
                      <tr key={row.id}>
                        {row.getVisibleCells().map((cell) => (
                          <td key={cell.id}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="bottom2">
          {" "}
          <Pagination table={table} />
        </div>
      </div>

      {isOpen7 && (
        <PopupWeb
          content={
            <>
              <form onSubmit={handleBagSubmit}>
                <div className="popupContainer">
                  <label className="NewInputLabel" data-domain="Name">
                    <input
                      className="NewInput"
                      type="text"
                      placeholder=""
                      onChange={(e) => setSelectedBag(e.target.value)}
                      value={selectedBag}
                      required
                    />

                    {errors.selectedBag && (
                      <div className="errorInputLabel">
                        {errors.selectedBag}
                      </div>
                    )}
                  </label>

                  <label className="NewInputLabel" data-domain="Location">
                    <Select
                      options={optionsApparatus}
                      onChange={handleCompartmentChange}
                      value={
                        flatOptionsApparatus.find(
                          (option) => option.value === selectedCompartmentId
                        ) || null
                      }
                      name="addMembers"
                      className="basic-select"
                      classNamePrefix="select"
                      menuPortalTarget={document.body}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                    />
                    {errors.selectedCompartmentId && (
                      <div className="errorInputLabel">
                        {errors.selectedCompartmentId}
                      </div>
                    )}
                  </label>
                  <div className="NewInputLabel" data-domain="Add Image">
                  <input
                    ref={fileRef}
                    onChange={handleFileInput}
                    type="file"
                    accept="image/*"
                    hidden
                  />
                  <div
                    onClick={() => fileRef.current.click()}
                    className="NewInputImage"
                  >
                    <RiImageAddFill size={30} />
                  </div>
                  <div className="imagePreviewContainer">
                    {selectedImage && (
                      <div className="imageHolder">
                        <img
                          src={URL.createObjectURL(selectedImage)}
                          alt="imageDidNotLoad"
                          className="addImage"
                        />
                        <button
                          type="button"
                          className="removeImageButton"
                          onClick={removehandleFileInput}
                        >
                          <RiCloseFill size={20} />
                        </button>
                      </div>
                    )}
                  </div>
                  </div>
                </div>
              </form>
            </>
          }
          handleClose={newUpcomingPopupClose}
          isOpen={isOpen7}
          handleSubmit={handleBagSubmit}
          showSubmitButton={true}
          headerText={<b>New Bag</b>}
          submitButtonText={loading ? "Loading..." : "Add"}
          loading={loading}
        />
      )}

      {isFilterOpen && (
        <PopupWeb
          content={
            <div>       <label
                className="NewInputLabel123"
                data-domain="Assigned To Location"
              >
                <Select
                  isMulti
                  options={apparatusoptions}
                  onChange={handleChangeLocation}
                  value={locationdefaultValues}
                  className="filter-dropdown"
                  classNamePrefix="Select"
                  menuPortalTarget={document.body}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                />
              </label>
            </div>
          }
          handleClose={togglePopupFilter}
          isOpen={isFilterOpen}
          showSubmitButton={false}
          headerText={<b>Filters</b>}
          submitButtonText={loading ? "Loading..." : "Add"}
        />
      )}

      {isOpen1 && (
        <PopupWeb
          content={
            <>
              <div>
                <form onSubmit={handleUpdate} className="userForm">
                  <div className="popupContainer">
                    <label className="NewInputLabel" data-domain="Name">
                      <input
                        className="NewInput"
                        type="text"
                        placeholder=""
                        onChange={(e) => setSelectedBag(e.target.value)}
                        value={selectedBag}
                        required 
                      /> 
                      {errors.selectedBag && (
                        <div className="errorInputLabel">
                          {errors.selectedBag}
                        </div>
                      )}
                    </label>
                    <label className="NewInputLabel" data-domain="Location">
                      <Select
                        options={optionsApparatus}
                        onChange={handleCompartmentChange}
                        value={bagLocationValues }
                 
                        name="addMembers"
                        className="basic-select"
                        classNamePrefix="select"
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />      {errors.selectedCompartmentId && (
                        <div className="errorInputLabel">
                          {errors.selectedCompartmentId}
                        </div>
                      )}
                    </label>

                    <div className="NewInputLabel" data-domain="Add Image">
                  <input
                    ref={fileRef}
                    onChange={handleFileInput}
                    type="file"
                    accept="image/*"
                    hidden
                  />
                  <div
                    onClick={() => fileRef.current.click()}
                    className="NewInputImage"
                  >
                    <RiImageAddFill size={30} />
                  </div>
                  <div className="imagePreviewContainer">
                    {selectedImage && (
                      <div className="imageHolder">
                        <img
                          src={URL.createObjectURL(selectedImage)}
                          alt="imageDidNotLoad"
                          className="addImage"
                        />
                        <button
                          type="button"
                          className="removeImageButton"
                          onClick={removehandleFileInput}
                        >
                          <RiCloseFill size={20} />
                        </button>
                      </div>
                    )}
                  </div>
                  </div>
                  
                    <div className="editApparatusBtnContainer">
                <div className="editApparatusBtn" onClick={togglePopup2}>
                  <b>Delete</b>
                </div>
              </div>
                  </div>
                </form>
              </div>
            </>
          }
          handleClose={togglePopupClose}
          isOpen={isOpen1}
          handleSubmit={handleUpdate}
          showSubmitButton={true}
          headerText={<b> Edit Bag</b>}
          submitButtonText={loading ? "Loading..." : "Save"}
        />
      )}


{isOpen2 && (
        <PopupWeb
          content={
            <>
    

              <div className="deleteInfoApparatusContainer">
                <b>This bag will no longer be available and items in bag will be unassigned</b>
              </div>

            </>
          }


                  handleClose={togglePopup22}
          isOpen={isOpen2}
          handleSubmit={handleDelete}
          showSubmitButton={true}
          headerText={   <b>Delete - {selectedBag} </b>}
          submitButtonText={loading ? "Loading..." : "Delete"} // Optionally change the text to indicate loading

        />
      )}
    </div>
  );
};

export default BagsHeader;
